import { BooleanIcon } from '../../components/BooleanIcon';
import { SendBillingSummaryEmailsForm } from './actions/SendBillingSummaryEmailsForm';
import { useMutation, useQuery } from '@apollo/client';
import { graphql } from '@orthly/graphql-inline-react';
import { LoadBlocker, RootActionDialog, useRootActionCommand } from '@orthly/ui';
import { FlossPalette, Grid, IconButton, Text, VisibilityIcon } from '@orthly/ui-primitives';
import _ from 'lodash';
import React from 'react';

interface BillingSummaryEmailResult {
    already_sent: number;
    success: number;
    failed_to_send: number;
    did_not_send: number;
    reasons_not_sent: string[];
}

type InvoiceEmailViewerProps = {
    partner_id: string;
    close: () => void;
};

const GetInvoiceEmailSummary_Query = graphql(`
    query GetLastInvoiceEmailForOrganization($organizationId: String!) {
        getLastInvoiceEmailForOrganization(organizationId: $organizationId) {
            html
            sent_to_partner
            reason_cannot_send
        }
    }
`);

const SendInvoiceSummaryEmail_Mutation = graphql(`
    mutation SendInvoiceSummaryEmail($organizationId: String!, $overrideEmail: String) {
        sendInvoiceSummaryEmail(organizationId: $organizationId, overrideEmail: $overrideEmail) {
            success
            already_sent
            failed_to_send
            did_not_send
            reasons_not_sent
        }
    }
`);

const InvoiceEmailViewer: React.FC<InvoiceEmailViewerProps> = props => {
    const { partner_id, close } = props;
    const { data, loading: emailDataLoading } = useQuery(GetInvoiceEmailSummary_Query, {
        variables: { organizationId: partner_id },
    });
    const lastInvoiceEmailData = data?.getLastInvoiceEmailForOrganization;

    const sendInvoiceEmail = useMutation(SendInvoiceSummaryEmail_Mutation);
    const { submit, submitting } = useRootActionCommand(sendInvoiceEmail, {
        onSuccess: async ({ data }) => {
            const result = data.sendInvoiceSummaryEmail;
            const message = emailSendResultMessage(result);
            window.alert(message);
            if (result.success === 1) {
                close();
            }
        },
    });

    const send = (overrideEmail?: string) => {
        return submit({ organizationId: partner_id, overrideEmail: overrideEmail });
    };

    const sentToPartner = !!lastInvoiceEmailData?.sent_to_partner;

    // displays html of the email for last invoices created, whether already sent or not
    const iframeSrcLast = React.useMemo<string | undefined>(() => {
        if (lastInvoiceEmailData !== undefined) {
            const blobLast = new Blob([lastInvoiceEmailData.html], { type: 'text/html' });
            return URL.createObjectURL(blobLast);
        }
        return undefined;
    }, [lastInvoiceEmailData]);

    return (
        <LoadBlocker blocking={emailDataLoading || submitting}>
            <Grid
                container
                justifyContent={'flex-start'}
                alignItems={'center'}
                style={{ height: '90vh', width: '80vw' }}
                direction={'column'}
                wrap={'nowrap'}
            >
                <Text variant={'h6'} style={{ width: '100%', padding: 8 }}>
                    Current Billing Summary Email
                </Text>
                {iframeSrcLast && (
                    <iframe
                        src={iframeSrcLast}
                        title={'Latest Invoice Summary'}
                        style={{ height: '80%', width: '100%', border: `2px dashed ${FlossPalette.STAR_GRASS}` }}
                    />
                )}
                <Grid container style={{ padding: 16 }}>
                    {lastInvoiceEmailData && (
                        <Grid container direction={'row'} justifyContent={'center'} alignItems={'center'}>
                            <BooleanIcon val={sentToPartner} />
                            <Text
                                variant={'h5'}
                                style={{
                                    color: sentToPartner ? FlossPalette.GREEN : FlossPalette.ATTENTION,
                                    marginLeft: 5,
                                }}
                            >
                                {sentToPartner ? 'Already sent to partner' : 'Not sent to partner'}
                            </Text>
                        </Grid>
                    )}
                    <SendBillingSummaryEmailsForm
                        send={send}
                        getReasonCannotSend={(overrideEmail?: string) => {
                            if (overrideEmail) {
                                return undefined;
                            }
                            return lastInvoiceEmailData?.reason_cannot_send ?? undefined;
                        }}
                        count={1}
                    />
                </Grid>
            </Grid>
        </LoadBlocker>
    );
};

export const InvoiceEmailViewerDialog: React.FC<{ partner_id: string }> = ({ partner_id }) => {
    const [open, setOpen] = React.useState<boolean>(false);
    const CustomButton = React.useCallback(
        () => (
            <IconButton onClick={() => setOpen(true)}>
                <VisibilityIcon />
            </IconButton>
        ),
        [],
    );
    return (
        <RootActionDialog
            showCloseButton
            loading={false}
            open={open}
            setOpen={setOpen}
            title={''}
            dialogProps={{ maxWidth: 'md' }}
            titleProps={{ style: { display: 'none' } }}
            content={!open ? null : <InvoiceEmailViewer partner_id={partner_id} close={() => setOpen(false)} />}
            buttonText={''}
            CustomButton={CustomButton}
        />
    );
};

function emailSendResultMessage(send: BillingSummaryEmailResult): string {
    const total = send.success + send.already_sent + send.did_not_send + send.failed_to_send;
    const attempted = send.success + send.did_not_send + send.failed_to_send;
    return _.compact([
        `Attempted: ${attempted} (out of total ${total} partners)`,
        `Success: ${send.success}.`,
        `Failed: ${send.failed_to_send}`,
        send.did_not_send > 0 ? `Did not send: ${send.did_not_send} (in this batch of ${attempted}).` : null,
        send.reasons_not_sent.length > 0 ? `Reasons for not sending: ${send.reasons_not_sent}` : null,
    ]).join('\n');
}
