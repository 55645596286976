import type { LastEditedData } from '../NotesSummary.types';
import { Text, styled, Button, Tooltip, FlossPalette } from '@orthly/ui-primitives';
import React from 'react';

const FooterContainer = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
});

const LastEditedDataContainer = styled('div')({
    display: 'flex',
    alignItems: 'center',
    gridGap: 8,
    marginLeft: 'auto',
});

const InitialsBadge = styled('div')({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 24,
    width: 24,
    backgroundColor: FlossPalette.DARK_TAN,
    borderRadius: 4,
});

function getInitials(fullUserString: string) {
    const sections = fullUserString.split(' ');
    const firstNameInitial = sections[0]?.charAt(0) ?? '';
    const lastNameInitial = sections[1]?.charAt(0) ?? '';

    return `${firstNameInitial}${lastNameInitial}`;
}

export const NotesTileFooter: React.FC<{
    showReadMoreButton: boolean;
    setFullHeightVisible: (visible: boolean) => void;
    fullHeightVisible: boolean;
    readMoreText: string;
    lastEditedData?: LastEditedData;
}> = props => {
    const { showReadMoreButton, setFullHeightVisible, fullHeightVisible, readMoreText, lastEditedData } = props;
    const localDateString: string | undefined = lastEditedData?.lastEditedTime.toLocaleString('en-US', {
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: '2-digit',
        timeZoneName: 'short',
    });

    return (
        <FooterContainer>
            {showReadMoreButton && (
                <Button variant={'ghost'} inline onClick={() => setFullHeightVisible(!fullHeightVisible)}>
                    {readMoreText}
                </Button>
            )}
            {lastEditedData?.lastEditedTime && (
                <LastEditedDataContainer>
                    <Text variant={'caption'}>{localDateString}</Text>
                    {lastEditedData?.lastEditedBy?.user_display_text && (
                        <Tooltip title={lastEditedData.lastEditedBy.user_display_text}>
                            <InitialsBadge>
                                <Text variant={'caption'} medium>
                                    {getInitials(lastEditedData.lastEditedBy.user_display_text)}
                                </Text>
                            </InitialsBadge>
                        </Tooltip>
                    )}
                </LastEditedDataContainer>
            )}
        </FooterContainer>
    );
};
