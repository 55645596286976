import { type ICameraControls, ModelViewerControls } from '../ModelViewer';
import { OrthographicCamera } from '@react-three/drei';
import { Canvas } from '@react-three/fiber';
import React from 'react';
import * as THREE from 'three';

export type ScanReviewPanelProps = {
    scene: THREE.Scene;
    initializeCameraControlsCallback: (controls: ICameraControls) => void;
    canvasRef: React.MutableRefObject<HTMLCanvasElement | null>;
    cameraRef: React.MutableRefObject<THREE.OrthographicCamera | null>;
};

export const ScanReviewPanel: React.FC<ScanReviewPanelProps> = ({
    scene,
    initializeCameraControlsCallback,
    canvasRef,
    cameraRef,
    ...props
}) => {
    return (
        <Canvas
            ref={canvasRef}
            orthographic={true}
            linear={true}
            mode={'concurrent'}
            gl={{ preserveDrawingBuffer: true }}
            onCreated={({ gl }) => {
                gl.toneMapping = THREE.NoToneMapping;
                gl.outputEncoding = THREE.LinearEncoding;
            }}
        >
            <fog color={new THREE.Color(0x001e45)} near={100} far={1000} />

            <OrthographicCamera ref={cameraRef} makeDefault zoom={4} position={[0, 0, 100]}>
                <pointLight intensity={0.8} color={0xffffff} />
            </OrthographicCamera>
            <ModelViewerControls ref={initializeCameraControlsCallback} use3ShapeViewerControls={true} />
            <ambientLight intensity={0.05} color={0xffffff} />
            <primitive object={scene} />
            {props.children}
        </Canvas>
    );
};
