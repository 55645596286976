import { getDenturesTitle, getImplantSectionTitle, getSingleItemSectionTitle } from '../../OrderSummary';
import type { DedupedOrderFormItem } from '../../OrderSummary/util/mergeOrderItems';
import { RefabGeneralNotesEditorChip } from '../../Refabs/RefabGeneralNotesEditorChip';
import { EditClinicalSupportNotesAction } from '../OrderDetailClinicalSupportNotesBlock';
import { EditDoctorNotesAction } from '../OrderDetailDoctorNotesBlock';
import type { AllNotes, NotesSummaryOrder, AlertsText, NotesSummaryActionChildProps } from './NotesSummary.types';
import { NotesTile } from './NotesTile';
import type { NotesTileProps } from './NotesTile';
import { CartItemV2Utils, LabOrderItemSKUType } from '@orthly/items';
import { compact } from 'lodash';
import React from 'react';

function getNoteTitle(item: DedupedOrderFormItem): string {
    if (item.sku === LabOrderItemSKUType.Denture) {
        return getDenturesTitle(item) ?? '';
    }
    if (item.sku === LabOrderItemSKUType.Bridge) {
        return CartItemV2Utils.getFullDisplayName(item, true);
    }
    if (item.sku === LabOrderItemSKUType.Implant || item.sku === LabOrderItemSKUType.ImplantBridge) {
        return getImplantSectionTitle(item).boldSection;
    }
    return getSingleItemSectionTitle(item);
}

type SelectedTileProps = Pick<NotesTileProps, 'forceExpanded'>;

export function useCreateNoteTiles(
    order: NotesSummaryOrder,
    allNotes: AllNotes,
    alertsText: AlertsText,
    refetchOrder?: () => Promise<void>,
    tileProps: SelectedTileProps = {},
    showLabSlipAlerts: boolean = false,
): React.ReactElement[] {
    const editChildProps: NotesSummaryActionChildProps = { order: order, deleteNoteContent: false };
    const deleteChildProps: NotesSummaryActionChildProps = { order: order, deleteNoteContent: true };
    const refabChildProps: NotesSummaryActionChildProps = {
        ...editChildProps,
        savedNotesForRefab: order.notes_for_refabrication,
        showRefabEditChips: true,
    };

    const designNoteAlerts = [];
    if (alertsText.designTaskAlertText) {
        designNoteAlerts.push(alertsText.designTaskAlertText);
    }
    if (allNotes.lsrNotes?.noteText) {
        designNoteAlerts.push('LSR performed on this order');
    }
    const designNoteText = [];
    if (allNotes.designTaskAlertNotes.noteText) {
        designNoteText.push(allNotes.designTaskAlertNotes.noteText);
    }
    if (allNotes?.lsrNotes?.noteText) {
        designNoteText.push(allNotes.lsrNotes.noteText);
    }

    return compact([
        allNotes.clinicalSupportNotes.noteText && (
            <NotesTile
                key={'clinical support note'}
                noteTitle={'Clinical support team'}
                noteText={[allNotes.clinicalSupportNotes.noteText]}
                lastEditedData={allNotes.clinicalSupportNotes.lastEditedData}
                editButtonProps={
                    refetchOrder && {
                        Component: EditClinicalSupportNotesAction,
                        refetch: refetchOrder,
                        childProps: editChildProps,
                    }
                }
                deleteButtonProps={
                    refetchOrder && {
                        Component: EditClinicalSupportNotesAction,
                        refetch: refetchOrder,
                        childProps: deleteChildProps,
                    }
                }
                {...tileProps}
            />
        ),
        allNotes.doctorNotes.noteText && (
            <NotesTile
                key={'doctor note'}
                noteTitle={'Doctor note'}
                noteText={[allNotes.doctorNotes.noteText]}
                lastEditedData={allNotes.doctorNotes.lastEditedData}
                editButtonProps={
                    refetchOrder && {
                        Component: EditDoctorNotesAction,
                        refetch: refetchOrder,
                        childProps: editChildProps,
                    }
                }
                deleteButtonProps={
                    refetchOrder && {
                        Component: EditDoctorNotesAction,
                        refetch: refetchOrder,
                        childProps: deleteChildProps,
                    }
                }
                {...tileProps}
            />
        ),
        ...allNotes.itemNotes.map(i => {
            return (
                i.noteText &&
                i.item && (
                    <NotesTile
                        key={i.item.id}
                        noteTitle={getNoteTitle(i.item)}
                        noteText={[i.noteText]}
                        lastEditedData={i.lastEditedData}
                        {...tileProps}
                    />
                )
            );
        }),
        allNotes.refabNotes.noteText && (
            <NotesTile
                key={'refab note'}
                noteTitle={'Refab note'}
                noteText={[allNotes.refabNotes.noteText]}
                lastEditedData={allNotes.refabNotes.lastEditedData}
                editButtonProps={
                    refetchOrder && {
                        Component: RefabGeneralNotesEditorChip,
                        refetch: refetchOrder,
                        childProps: refabChildProps,
                    }
                }
                {...tileProps}
            />
        ),
        (designNoteAlerts.length || designNoteText.length) && (
            <NotesTile
                key={'design task note'}
                noteTitle={'Design task'}
                noteText={designNoteText}
                lastEditedData={allNotes.designTaskAlertNotes.lastEditedData}
                alerts={designNoteAlerts}
                {...tileProps}
            />
        ),
        showLabSlipAlerts && allNotes.labSlipAlertNotes && allNotes.labSlipAlertNotes.noteText && (
            <NotesTile
                key={'lab slip alerts note'}
                noteTitle={'Lab slip alerts'}
                noteText={[allNotes.labSlipAlertNotes.noteText ?? '']}
                lastEditedData={allNotes.labSlipAlertNotes.lastEditedData}
                {...tileProps}
            />
        ),
    ]);
}
