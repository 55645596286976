import { DeleteNoteDialogContent } from './NotesSummary/DeleteNoteDialogContent';
import type { NotesSummaryMenuActionProps } from './NotesSummary/NotesSummary.types';
import { OrderDetailBlock } from './OrderDetailBlock';
import type { LabsGqlOrder, LabsGqlUpdateOrderClinicalSupportNotesMutationVariables } from '@orthly/graphql-operations';
import { useUpdateOrderClinicalSupportNotesMutation } from '@orthly/graphql-react';
import { useHasCapability } from '@orthly/session-client';
import { useChangeSubmissionFn, RootActionDialog, QuickForm } from '@orthly/ui';
import { FlossPalette, Text, PencilOutlinedIcon, TrashIcon, styled, Button } from '@orthly/ui-primitives';
import React from 'react';

type UpdateNotesVars = LabsGqlUpdateOrderClinicalSupportNotesMutationVariables['data'];
type MinimalOrder = Pick<LabsGqlOrder, 'id' | 'doctor_notes' | 'clinical_support_notes'>;

const StyledNoteButton = styled(Button)({
    minWidth: 'unset',
    padding: 0,
    height: 'fit-content',
});

export const EditClinicalSupportNotesAction: React.FC<NotesSummaryMenuActionProps> = ({
    refetch,
    childProps,
    open,
    setOpen,
}) => {
    const deleteNoteContent = childProps?.deleteNoteContent;
    const order = childProps?.order;
    const hasEditCapability = useHasCapability('order', 'order.cst_notes.edit');

    const [submitNotesMtn] = useUpdateOrderClinicalSupportNotesMutation();
    const mtnSubmitter = (data: UpdateNotesVars) => submitNotesMtn({ variables: { data } });
    const { submit, submitting } = useChangeSubmissionFn<any, [UpdateNotesVars]>(mtnSubmitter, {
        closeOnComplete: true,
        successMessage: () => ['Clinical support notes updated!', {}],
        onSuccess: async () => {
            if (refetch) {
                await refetch();
            }
            setOpen(false);
        },
    });

    const openButton = React.useMemo(() => {
        return (
            <StyledNoteButton variant={'ghost'} onClick={() => setOpen(true)}>
                {deleteNoteContent ? <TrashIcon /> : <PencilOutlinedIcon />}
            </StyledNoteButton>
        );
    }, [setOpen, deleteNoteContent]);

    if (!hasEditCapability) {
        return null;
    }

    return (
        <RootActionDialog
            loading={submitting}
            open={open}
            setOpen={setOpen}
            title={deleteNoteContent ? 'Are you sure you want to delete this note?' : 'Edit Clinical Support Notes'}
            showCloseButton={true}
            content={
                deleteNoteContent ? (
                    <DeleteNoteDialogContent
                        setOpen={setOpen}
                        submit={async () => {
                            await submit({ notes: '', order_id: order?.id ?? '' });
                        }}
                    />
                ) : (
                    <QuickForm<{ notes?: string }>
                        fields={{
                            notes: {
                                type: 'text',
                                optional: true,
                                fieldProps: { multiline: true, minRows: 3, maxRows: 100 },
                            },
                        }}
                        initialValues={{
                            notes: order?.clinical_support_notes?.notes ?? undefined,
                        }}
                        onSubmit={async ({ notes }) => {
                            if (notes !== order?.clinical_support_notes?.notes) {
                                await submit({ notes, order_id: order?.id ?? '' });
                            }
                        }}
                    />
                )
            }
            CustomButton={() => openButton}
        />
    );
};

interface OrderDetailClinicalSupportNotesBlockProps {
    order: MinimalOrder;
    refetchOrder: () => any;
    disableEditing?: boolean;
}

const StyledClinicalNotesBlockText = styled(Text)({
    whiteSpace: 'pre-line',
});

export const OrderDetailClinicalSupportNotesBlock: React.VFC<OrderDetailClinicalSupportNotesBlockProps> = ({
    order,
    refetchOrder,
    disableEditing,
}) => {
    const [open, setOpen] = React.useState<boolean>(false);

    return (
        <OrderDetailBlock
            title={'Notes from the Clinical Support Team'}
            rootStyle={{ backgroundColor: FlossPalette.WHITE }}
            innerRootStyle={{ backgroundColor: FlossPalette.WARNING_BACKGROUND }}
            variant={'full'}
            actions={
                disableEditing ? undefined : (
                    <EditClinicalSupportNotesAction
                        refetch={refetchOrder}
                        childProps={{ order, deleteNoteContent: false }}
                        open={open}
                        setOpen={setOpen}
                    />
                )
            }
        >
            <div>
                <StyledClinicalNotesBlockText
                    variant={'body2'}
                    color={order.clinical_support_notes?.notes ? 'BLACK' : 'DARK_GRAY'}
                >
                    {order.clinical_support_notes?.notes ?? `No notes added`}
                </StyledClinicalNotesBlockText>
            </div>
        </OrderDetailBlock>
    );
};
