import { OrderDetailPageFrame } from '../../../OrderDetailPageFrame.graphql';
import { useOrderDetailContext } from '../../../state/OrderDetailProvider.graphql';
import { OrderDesignToolbarFileDownload } from '../../OrderDesignToolbar/OrderDesignToolbarFileDownload.graphql';
import { useApolloClient } from '@apollo/client';
import { graphql } from '@orthly/graphql-inline-react';
import { useTagsQuery } from '@orthly/graphql-react';
import { type LabsGqlFinishingInBrowserPayload, LabsGqlTaggableEntityType } from '@orthly/graphql-schema';
import { DTL_ALPHA_TEST_TAG_ID } from '@orthly/shared-types';
import { WindowHeightMinusToolbar, useQueryParam } from '@orthly/ui';
import { stylesFactory, Grid } from '@orthly/ui-primitives';
import {
    OrderDesignRevisions,
    useDesignOrderRevisionsLoader,
    DesignFinishingStartBannerInternal,
    useFeatureFlag,
} from '@orthly/veneer';
import React from 'react';
import { useHistory } from 'react-router-dom';

const useStyles = stylesFactory(theme => ({
    root: {
        background: 'linear-gradient(112.77deg, #FDFCFA 0%, #FFFFFF 100%)',
        ...WindowHeightMinusToolbar(theme),
    },
    bannerContainer: {
        padding: 16,
        width: 360,
    },
}));

const SubmitAutomateReview_Mutation = graphql(`
    mutation SubmitAutomateReview($data: SubmitAutomateReviewCommand!) {
        submitAutomateReview(data: $data) {
            id
        }
    }
`);

const SubmitFinishingInBrowser_Mutation = graphql(`
    mutation SubmitFinishingInBrowser($payload: FinishingInBrowserPayload!) {
        submitFinishingInBrowser(payload: $payload)
    }
`);

function useSubmitCallbacks(orderId: string) {
    const client = useApolloClient();

    const submitReviewDecision = async (args: { approved: boolean; reviewDurationMs: number }) => {
        await client.mutate({
            mutation: SubmitAutomateReview_Mutation,
            variables: {
                data: { order_id: orderId, approved: args.approved, review_duration_ms: args.reviewDurationMs },
            },
        });
    };

    const submitFinishedDesign = async (args: { payload: LabsGqlFinishingInBrowserPayload }) => {
        await client.mutate({
            mutation: SubmitFinishingInBrowser_Mutation,
            variables: { payload: args.payload },
        });
    };

    return {
        submitReviewDecision,
        submitFinishedDesign,
    };
}

export const OrderDetailFinishingTabPage: React.FC = () => {
    const { value: disableFinishingBannerGating } = useFeatureFlag('disableFinishingBannerGating');

    const classes = useStyles();
    const { order, id: orderId } = useOrderDetailContext();

    const {
        slimDesignFragments,
        selectedDesignFragment: selectedDesign,
        loadAndSelectDesign,
    } = useDesignOrderRevisionsLoader(orderId);

    const { data: tagsData } = useTagsQuery({
        variables: { entity_id: orderId, entity_type: LabsGqlTaggableEntityType.Order, id: DTL_ALPHA_TEST_TAG_ID },
    });

    const enabled = !!(tagsData?.tags.length || disableFinishingBannerGating);

    const [openVal, setOpenQueryParam] = useQueryParam('open');
    const initiallyOpen = openVal === 'true';

    const submitCallbacks = useSubmitCallbacks(orderId);
    const onCancel = () => {
        // Clear the "open" param so that `initiallyOpen` is false if the UI is opened again.
        setOpenQueryParam(undefined);
    };

    const history = useHistory();

    const onComplete = () => {
        // Clear the "open" param so that the UI is not automatically opened if the user navigates back to this page.
        setOpenQueryParam(undefined);
        history.push(`/orders/${orderId}`);
    };

    return (
        <OrderDetailPageFrame
            body={
                order && (
                    <Grid container className={classes.root} key={order.id}>
                        <Grid item className={classes.bannerContainer}>
                            {selectedDesign ? (
                                <DesignFinishingStartBannerInternal
                                    order={order}
                                    design={selectedDesign}
                                    disabled={!enabled}
                                    initiallyOpen={initiallyOpen}
                                    callbacks={{ ...submitCallbacks, onComplete, onCancel }}
                                />
                            ) : (
                                <div>This order does not have any designs.</div>
                            )}
                        </Grid>
                    </Grid>
                )
            }
            sidebar={null}
            toolbarActions={
                <Grid container style={{ flexWrap: 'nowrap', justifyContent: 'flex-end' }}>
                    {order && selectedDesign && (
                        <OrderDesignToolbarFileDownload
                            order={order}
                            selectedDesignFragment={selectedDesign}
                            isDesignMostRecent={selectedDesign.source_file_zip_path === order.design_file_path}
                        />
                    )}
                    {order && (
                        <OrderDesignRevisions
                            order={order}
                            userRole={'psr'}
                            setSelectedDesignRevisionId={loadAndSelectDesign}
                            designRevisionFragments={slimDesignFragments}
                            selectedDesignRevisionId={selectedDesign?.id}
                        />
                    )}
                </Grid>
            }
        />
    );
};
