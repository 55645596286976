import type { BillingCreditCategories } from '../../CreditCategories/BillingCreditCategoriesQuery.graphql';
import { GetAllBillingCreditCategories_Query } from '../../CreditCategories/BillingCreditCategoriesQuery.graphql';
import type { Credit, Invoice } from '../../InvoicesTable/InvoiceTable.types';
import type { RefundCategories } from '../../RefundCategories/RefundCategoriesQuery.graphql';
import { ListRefundCategories_Query } from '../../RefundCategories/RefundCategoriesQuery.graphql';
import type { PartnerBillingOverviewData } from '../../types';
import {
    ContractOrgNeedsBillingSummaryEmail_Query,
    GetInvoicesForOrganization_Query,
    GetNextInvoiceStatusForContractOrgs_Query,
    GetPartnerBillingOverviewData_Query,
    GetPartnerBillingOverviewDataForContract_Query,
    ListInvoiceCreditsForOrganization_Query,
} from '../BillingOverviewQueries.graphql';
import { useQuery } from '@apollo/client';
import type { GetActiveContractDataQuery } from '@orthly/graphql-inline-react';
import type { LabsGqlNextInvoiceStatusSummary } from '@orthly/graphql-schema';
import { useFeatureFlag } from '@orthly/veneer';
import { compact } from 'lodash';
import React from 'react';

interface BillingDetailsContextType {
    practice: PartnerBillingOverviewData | undefined;
    practiceLoading: boolean;
    refetchPractice: () => Promise<unknown>;
    invoices: Invoice[];
    invoicesLoading: boolean;
    refetchInvoices: () => Promise<unknown>;
    credits: Credit[];
    creditsLoading: boolean;
    refetchCredits: () => Promise<unknown>;
    refetchBillingDetails: () => Promise<unknown>;
    // Multi-location
    associatedOrganizations: PartnerBillingOverviewData[];
    primaryPracticeForContract: string | undefined;
    getOrganizationFromId: (id: string) => PartnerBillingOverviewData | undefined;
    getNextInvoiceStatusForOrg: (organizationId: string) => LabsGqlNextInvoiceStatusSummary;
    getOrgNeedsBillingSummaryEmail: (organizationId: string) => boolean;
    creditCategories: BillingCreditCategories;
    refundCategories: RefundCategories;
}

const BillingDetailsContext = React.createContext<BillingDetailsContextType | null>(null);

interface BillingDetailsCtxProviderProps {
    practiceId: string;
    contractAndAssociates?: GetActiveContractDataQuery['contractAndAssociates'];
}

export const BillingDetailsCtxProvider: React.FC<BillingDetailsCtxProviderProps> = ({
    practiceId,
    contractAndAssociates,
    children,
}) => {
    const { value: enableMultiLocationContracts } = useFeatureFlag('enableMultiLocationContracts');

    // TODO: EPDB-975 remove legacy query
    const {
        data: { getPartnerBillingAccount, getOrganization } = {},
        refetch: refetchPractice,
        loading: practiceLoading,
    } = useQuery(GetPartnerBillingOverviewData_Query, {
        variables: { partnerId: practiceId },
        fetchPolicy: 'no-cache',
        nextFetchPolicy: 'no-cache',
        refetchWritePolicy: 'overwrite',
        skip: enableMultiLocationContracts,
    });

    const {
        data: { contractAccounts } = {},
        refetch: refetchPractices,
        loading: practicesLoading,
    } = useQuery(GetPartnerBillingOverviewDataForContract_Query, {
        variables: { partnerId: practiceId },
        fetchPolicy: 'no-cache',
        nextFetchPolicy: 'no-cache',
        refetchWritePolicy: 'overwrite',
        skip: !enableMultiLocationContracts,
    });

    let practice: PartnerBillingOverviewData | undefined;
    let associatedOrganizations: PartnerBillingOverviewData[] = [];

    // TODO: EPDB-975: clean up ternary
    if (enableMultiLocationContracts) {
        associatedOrganizations = compact(
            contractAccounts?.map(account => {
                const name = contractAndAssociates?.associatedPractices.find(
                    practice => practice.id === account.id,
                )?.name;
                return {
                    ...account,
                    name: name ?? '',
                };
            }),
        );
        practice = associatedOrganizations?.find(org => org.id === practiceId);
    } else {
        practice = getPartnerBillingAccount
            ? { ...getPartnerBillingAccount, name: getOrganization?.name ?? '' }
            : undefined;
        associatedOrganizations = compact([practice]);
    }

    const getOrganizationFromId = (id: string): PartnerBillingOverviewData | undefined => {
        if (!enableMultiLocationContracts) {
            return practice;
        }
        return associatedOrganizations?.find(org => org.id === id);
    };

    const {
        data: { invoices = [] } = {},
        loading: invoicesLoading,
        refetch: refetchRawInvoices,
    } = useQuery<{
        invoices: Invoice[];
    }>(GetInvoicesForOrganization_Query, {
        variables: { organizationId: practiceId },
    });

    const {
        data: { credits = [] } = {},
        loading: creditsLoading,
        refetch: refetchCredits,
    } = useQuery<{ credits: Credit[] }>(ListInvoiceCreditsForOrganization_Query, {
        variables: { organizationId: practiceId, includeDeleted: true },
    });

    const { data: { getAllBillingCreditCategories: creditCategories = [] } = {}, loading: creditCategoriesLoading } =
        useQuery<{
            getAllBillingCreditCategories: BillingCreditCategories;
        }>(GetAllBillingCreditCategories_Query, {
            variables: { include_archived: false },
        });

    const { data: { listRefundCategories: refundCategories = [] } = {} } = useQuery<{
        listRefundCategories: RefundCategories;
    }>(ListRefundCategories_Query, {
        variables: { includeArchived: false },
    });

    const {
        data: { nextInvoiceStatusList = [] } = {},
        loading: getNextInvoiceStatusLoading,
        refetch: refetchNextInvoiceStatus,
    } = useQuery(GetNextInvoiceStatusForContractOrgs_Query, {
        variables: { organizationId: practiceId },
    });

    const getNextInvoiceStatusForOrg = (organizationId: string): LabsGqlNextInvoiceStatusSummary => {
        const nextInvoiceStatus = nextInvoiceStatusList.find(status => status.organization_id === organizationId);
        return (
            nextInvoiceStatus?.next_invoice_status_summary ?? {
                pending_item_count: 0,
                previous_invoice_amount_cents: 0,
                will_be_invoiced: false,
            }
        );
    };

    const partnerBillingLoading = practiceLoading || getNextInvoiceStatusLoading;

    const { data: { needsBillingSummaryEmailList = [] } = {}, refetch: refetchInvoiceEmailStatus } = useQuery(
        ContractOrgNeedsBillingSummaryEmail_Query,
        {
            variables: { organizationId: practiceId },
        },
    );

    const getOrgNeedsBillingSummaryEmail = (organizationId: string): boolean => {
        const orgNeedsSummaryEmail = needsBillingSummaryEmailList.find(v => v.organizationId === organizationId);
        return orgNeedsSummaryEmail?.needsSummaryEmail ?? false;
    };

    const refetchInvoices = React.useCallback(async () => {
        await refetchRawInvoices();
        await refetchInvoiceEmailStatus();
        await refetchNextInvoiceStatus();
    }, [refetchRawInvoices, refetchInvoiceEmailStatus, refetchNextInvoiceStatus]);

    const refetchBillingDetails = React.useCallback(async () => {
        // refetch partner billing data. if the new ff is not enabled, this will include invoices and credits
        // (note credit data isn't used in the table, so we don't need to refetch even if the ff is enabled)
        const refetchPracticeData = enableMultiLocationContracts ? refetchPractices : refetchPractice;
        await refetchPracticeData();
        await refetchInvoices();
    }, [refetchPractice, refetchInvoices, enableMultiLocationContracts, refetchPractices]);

    const loading = enableMultiLocationContracts ? practicesLoading : partnerBillingLoading;
    const refetch = enableMultiLocationContracts ? refetchPractices : refetchPractice;

    return (
        <BillingDetailsContext.Provider
            value={{
                practice,
                practiceLoading: loading,
                refetchPractice: refetch,
                invoices,
                invoicesLoading,
                refetchInvoices,
                credits,
                creditsLoading: creditsLoading || creditCategoriesLoading,
                refetchCredits,
                refetchBillingDetails,
                primaryPracticeForContract: contractAndAssociates?.primaryPracticeId,
                associatedOrganizations,
                getOrganizationFromId,
                getNextInvoiceStatusForOrg,
                getOrgNeedsBillingSummaryEmail,
                creditCategories,
                refundCategories,
            }}
        >
            {children}
        </BillingDetailsContext.Provider>
    );
};

export function useBillingDetailsContext() {
    const ctx = React.useContext(BillingDetailsContext);
    if (!ctx) {
        throw new Error('useBillingDetailsContext must be used within a BillingDetailsCtxProvider');
    }

    return ctx;
}
