import { BillingDetailsTable } from '../BillingDetails/components/BillingDetailsTable';
import type { PracticeContract, SpendMinimumProps, PracticeContractSpendTermRow } from './PracticeContracts.types';
import { useQuery } from '@apollo/client';
import { graphql } from '@orthly/graphql-inline-react';
import type { MUITableColumn } from '@orthly/mui-table';
import { Format } from '@orthly/runtime-utils';
import { Text } from '@orthly/ui-primitives';
import dayjs from 'dayjs';
import React from 'react';

export const CalculatePracticeAggregateSpendOverPeriod_Query = graphql(`
    query CalculatePracticeAggregateSpendOverPeriod(
        $partnerId: String!
        $periodStart: DateTime!
        $periodEnd: DateTime!
    ) {
        calculatePracticeAggregateSpendOverPeriod(
            partnerId: $partnerId
            periodStart: $periodStart
            periodEnd: $periodEnd
        )
    }
`);

const SpendMinimum: React.FC<SpendMinimumProps> = ({ organizationId, termInfo }) => {
    const { data } = useQuery(CalculatePracticeAggregateSpendOverPeriod_Query, {
        variables: {
            partnerId: organizationId,
            periodStart: termInfo.effective_start_date ?? '',
            periodEnd: termInfo.effective_end_date ?? '',
        },
        skip: termInfo.spend_cycle_length === 1,
    });
    const aggregateSpend = Format.currency(data?.calculatePracticeAggregateSpendOverPeriod ?? 0);
    const commitment = Format.currency(termInfo.spend_minimum_cents);

    return (
        <Text variant={'caption'} color={'DARK_GRAY'}>
            {commitment} ({aggregateSpend})
        </Text>
    );
};

export const ListPracticeContractsV2ByOrganizationId_Query = graphql(`
    query ListPracticeContractsV2ByOrganizationId($organizationId: String!) {
        listPracticeContractsV2ByOrganizationId(organization_id: $organizationId, filter_criteria: { deleted: false }) {
            id
            created_at
            effective_start_date
            effective_end_date
            spend_terms {
                id
                effective_start_date
                effective_end_date
                spend_minimum_cents
                spend_cycle_length
            }
        }
    }
`);

export const PartnerContractsTable: React.FC<{ organizationId: string }> = ({ organizationId }) => {
    const { data: { listPracticeContractsV2ByOrganizationId: contracts = [] } = {} } = useQuery<{
        listPracticeContractsV2ByOrganizationId: PracticeContract[];
    }>(ListPracticeContractsV2ByOrganizationId_Query, {
        variables: { organizationId },
    });

    const unsortedRows = [...contracts].reduce<PracticeContractSpendTermRow[]>((acc, contract) => {
        const spendTerms = contract.spend_terms.map<PracticeContractSpendTermRow>(term => ({
            ...term,
            contract_id: contract.id,
        }));
        return acc.push(...spendTerms), acc;
    }, []);

    const columns: MUITableColumn<PracticeContractSpendTermRow>[] = [
        {
            name: 'Term Type',
            sort: false,
            render: r => {
                const value = r.spend_cycle_length === 1 ? 'Monthly' : 'Multi-month';
                return (
                    <Text variant={'caption'} medium color={'DARK_GRAY'}>
                        {value}
                    </Text>
                );
            },
        },
        {
            name: 'Start Month',
            sort: true,
            type: 'date',
            defaultSort: 'desc',
            render: r => {
                const value = r.effective_start_date ? dayjs.utc(r.effective_start_date).format('MMM YYYY') : 'Not set';
                return (
                    <Text variant={'caption'} medium color={'DARK_GRAY'}>
                        {value}
                    </Text>
                );
            },
        },
        {
            name: 'End Month',
            type: 'date',
            render: r => {
                const value = r.effective_end_date ? dayjs.utc(r.effective_end_date).format('MMM YYYY') : '-';
                return (
                    <Text variant={'caption'} medium color={'DARK_GRAY'}>
                        {value}
                    </Text>
                );
            },
        },
        {
            name: 'Min Spend (Spend)',
            type: 'currency',
            render: r => <SpendMinimum organizationId={organizationId} termInfo={r} />,
        },
    ];

    // TODO: locations
    // TODO: download CSV
    return (
        <BillingDetailsTable<PracticeContractSpendTermRow>
            title={'Contract Specification'}
            rows={unsortedRows}
            columns={columns}
            search={true}
            searchPlaceholder={'Find a contract'}
            handleSort={(a, b, sortDirection) => {
                if (sortDirection === 'asc') {
                    return dayjs(a.effective_start_date).valueOf() - dayjs(b.effective_start_date).valueOf();
                }

                return dayjs(b.effective_start_date).valueOf() - dayjs(a.effective_start_date).valueOf();
            }}
            handleFilter={(r, searchTerm) => r.contract_id?.includes(searchTerm) ?? false}
        />
    );
};
