import { SettingsBaseModal } from './SettingsBaseModal';
import { TrashIcon } from '@orthly/ui';
import { FlossPalette, Button, IconButton } from '@orthly/ui-primitives';
import React from 'react';

export const RemoveItemModal: React.VFC<{
    title: string;
    subtitle: string;
    confirmRemoveText: string;
    removeItem: () => void;
}> = ({ title, subtitle, confirmRemoveText, removeItem }) => {
    const [open, setOpen] = React.useState(false);
    return (
        <SettingsBaseModal
            open={open}
            setOpen={setOpen}
            title={title}
            subtitle={subtitle}
            loading={false}
            CustomButton={() => (
                <IconButton onClick={() => setOpen(!open)} data-test={'remove-item-button'}>
                    <TrashIcon style={{ color: FlossPalette.GRAY }} />
                </IconButton>
            )}
            content={
                <div style={{ float: 'right', marginTop: '16px' }}>
                    <Button
                        variant={'secondary'}
                        style={{ marginRight: '8px' }}
                        onClick={() => setOpen(false)}
                        data-test={'cancel-remove-item-button'}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant={'alert'}
                        onClick={() => {
                            removeItem();
                            setOpen(false);
                        }}
                        data-test={'confirm-remove-item-button'}
                    >
                        {confirmRemoveText}
                    </Button>
                </div>
            }
        />
    );
};
