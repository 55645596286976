import type { AdminLabsOrderActionProps } from '../order-action-types';
import { useIsDesignActionEditable } from './DesignActionUtils';
import { DesignConfigDropdown } from './DesignConfigDropdown';
import { useConfigureDesignerTypeMutation } from '@orthly/graphql-react';
import { LabsGqlLabOrderStatus } from '@orthly/graphql-schema';
import { LoadBlocker, useChangeSubmissionFn } from '@orthly/ui';
import React from 'react';

function labDesignerDisplayName(manufacturer_name?: string) {
    return manufacturer_name ? `${manufacturer_name.trim()} (Lab)` : 'Lab';
}

interface OrderDesignerTypeSummaryRowProps extends AdminLabsOrderActionProps {
    manufacturer_name?: string;
}

export const EditOrderDesignerType: React.FC<OrderDesignerTypeSummaryRowProps> = props => {
    const { order, manufacturer_name, refetchOrder } = props;
    const labDesignerName = labDesignerDisplayName(manufacturer_name);
    const { internal_design_required, internal_design_prep_required } = order.fulfillment_workflow.configuration;

    const currentDesignerType = !internal_design_required ? labDesignerName : 'In House';
    const newDesignerType = internal_design_required ? labDesignerName : 'In House';

    const isEditable = useIsDesignActionEditable(order) || order.status === LabsGqlLabOrderStatus.OnHold;

    const [submitMtn] = useConfigureDesignerTypeMutation();
    const { submit, submitting } = useChangeSubmissionFn<any, []>(
        () =>
            submitMtn({
                variables: {
                    id: order.id,
                    internal_design_required: !internal_design_required,
                    internal_design_prep_required: !internal_design_required ? internal_design_prep_required : false,
                    separate_model_design_step: null,
                    automate_review_required: null,
                },
            }),
        {
            successMessage: () => [`Design team changed to ${newDesignerType}`, {}],
            onSuccess: async () => {
                await refetchOrder();
            },
        },
    );

    return (
        <LoadBlocker
            blocking={submitting}
            overlayColor={'transparent'}
            ContainerProps={{ style: { alignItems: 'center' } }}
        >
            <DesignConfigDropdown<string>
                options={[
                    { label: labDesignerName, value: labDesignerName },
                    { label: 'In House', value: 'In House' },
                ]}
                selected={{ label: currentDesignerType, value: currentDesignerType }}
                submit={async value => {
                    if (value !== currentDesignerType && window.confirm(`Change design team to ${newDesignerType}?`)) {
                        await submit();
                        return true;
                    }

                    return false;
                }}
                disabled={!isEditable}
            />
        </LoadBlocker>
    );
};
