import { useFeatureFlag } from '../Providers/LaunchDarkly';
import { CancelAndResubmitBanner } from './components/CancelAndResubmitBanner';
import { OrderEditDeliveryDate } from './components/OrderEditDeliveryDate';
import { OrderEditDialog } from './components/OrderEditDialog';
import { PricingGuideLink } from './components/PricingGuideLink';
import type { PageFactory } from './orderEditState';
import { useItems, useGroupSelection, usePageState, useOrderEditMutation } from './orderEditState';
import { getExtrasPage } from './pages/OrderEditExtrasPage';
import { getItemPage } from './pages/OrderEditItemPage';
import { useGetItemSelectPage } from './pages/OrderEditItemSelectPage';
import { getReviewPage } from './pages/OrderEditReviewPage.graphql';
import { itemEditSummary, disableNextForNoChanges, getCurrentExtras } from './utils/changedItemsUtils';
import { getNextButtonProps, getNextTitle, type OrderEditCustomStrings } from './utils/navigationButtonUtils';
import { BrowserAnalyticsClientFactory } from '@orthly/analytics/dist/browser';
import { FullScreenWorkflowContainer } from '@orthly/dentin';
import type { LabsGqlOrder } from '@orthly/graphql-operations';
import { OrderItemV2Utils, type IOrderItemV2DTO, type ICartItemV2DTOWithId } from '@orthly/items';
import type { OnCloseOrderEditProps } from '@orthly/shared-types';
import { OrderEditMode } from '@orthly/shared-types';
import { useScreenIsMobile, Text, styled } from '@orthly/ui-primitives';
import _ from 'lodash';
import React from 'react';

const SuperTitleContainer = styled('div')({
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    columnGap: 8,
});

type ContentProps = {
    itemGroup: Array<{ id: string }>;
};

interface OrderEditRootProps {
    order: LabsGqlOrder;
    // Instead of using order.items_v2, we can use a dedicated property that is using IOrderItemV2DTO type as it's easier to make
    // updates to it, when it is not provided, we will fall back to order.items_v2. An example where we use this field: In sk Dr v2
    // we let ops users suggest a recommended change, so they go in the C&R tool, make updates to items, save these changes locally
    // and continue filling a form. However, they can still go back to the configuration they created and edit it before sending
    // it to the back-end. Therefore we can't use the order.items_v2 array since it may have changed in their suggested changes.
    orderItemsOverrides?: IOrderItemV2DTO[];
    // Callback to close the edit UI. If `needsRefresh` is true, the order should be refreshed
    // (an edit was made successfully).
    onClose: (props: OnCloseOrderEditProps) => void;
    InjectedPricingGuideComponent?: React.ReactElement;
    isProvideInstructionsFlow?: boolean;
    isFromQuickOrderActions?: boolean;
    customStrings?: OrderEditCustomStrings;
    onSubmit: (items: ICartItemV2DTOWithId[], orderEditMode: OrderEditMode) => void;
    isLoading?: boolean;
}

/** Root component for order edits. Intended to be displayed in a full-screen dialog. */
/* eslint-disable max-lines-per-function */
export const OrderEditRoot: React.VFC<OrderEditRootProps> = ({
    order,
    onClose,
    InjectedPricingGuideComponent,
    isProvideInstructionsFlow,
    isFromQuickOrderActions,
    customStrings,
    onSubmit,
    orderItemsOverrides,
    isLoading = false,
}) => {
    const enableNuancedCr = !!useFeatureFlag('enableNuancedCr').value;

    const isMobile = useScreenIsMobile();
    const canEdit = order.can_edit;
    const orderItems = orderItemsOverrides ?? OrderItemV2Utils.parseItems(order.items_v2);
    const {
        itemGroups,
        changedItems,
        originalItemMap,
        setEditableItems,
        allItems,
        nonEditableItems,
        addedExtraIds,
        setAddedExtraIds,
    } = useItems(orderItems);
    const { setGroupSelected, selectedItemGroups } = useGroupSelection(itemGroups);
    const [confirmDelayDialogOpen, setConfirmDelayDialogOpen] = React.useState(false);
    const [hasPassedEtaValidation, setHasPassedEtaValidation] = React.useState(false);
    const [hasPassedItemValidation, setHasPassedItemValidation] = React.useState(false);
    const [showExtrasPage, setShowExtrasPage] = React.useState(false);
    const hasPassedValidation = hasPassedEtaValidation && hasPassedItemValidation;

    const itemEditSummaryDict = itemEditSummary(order, changedItems, originalItemMap);
    const addlessOrderEditMode = itemEditSummaryDict.new_order_edit_mode;
    const wereItemsAdded = changedItems.some(item => !originalItemMap[item.id]);
    const orderEditMode = wereItemsAdded ? OrderEditMode.CancelAndResubmit : addlessOrderEditMode;

    // PageFactory makes easy page-creation functions out of all of the state variables above,
    // so that `usePageState` can have a reasonable API.
    const pageFactory: PageFactory = {
        useMakeItemSelectPage: goPageForwardArg =>
            useGetItemSelectPage({
                itemGroups,
                selectedItemGroups,
                setGroupSelected,
                nonEditableItems,
                addedExtraIds,
                title: isProvideInstructionsFlow ? 'Select products to modify instructions' : undefined,
                goPageForward: goPageForwardArg,
                setShowExtrasPage,
            }),
        makeExtrasPage: () =>
            getExtrasPage({
                itemGroups,
                addedExtraIds,
                setGroupSelected,
                setEditableItems,
                setAddedExtraIds,
                orderId: order.id,
            }),
        makeItemPage: itemGroup =>
            getItemPage({
                itemGroup,
                setEditableItems,
                hasPassedItemValidation,
                setHasPassedItemValidation,
                doctorPreferencesId: order.doctor_preferences_id,
                autoFocusOnInstructions: isProvideInstructionsFlow,
            }),
        makeReviewPage: () =>
            getReviewPage({ itemGroups, originalItemMap, nonEditableItems, customStrings, order, orderEditMode }),
    };

    const { isFirstPage, isLastPage, currentPage, goPageForward, goPageBack } = usePageState({
        showExtrasPage,
        selectedItemGroups,
        pageFactory,
    });

    const submitOrderEdits = useOrderEditMutation(changedItems, allItems, orderEditMode, onSubmit);

    if (!currentPage || orderEditMode === OrderEditMode.Disabled || !canEdit) {
        return null;
    }

    const nextIsSkip = currentPage.nextIsSkip;
    const isItemSelectionScreen = currentPage.type === 'item_select';
    const orderType =
        orderEditMode === 'CancelAndResubmit'
            ? 'Order Submitted - Cancel and resubmit submitted'
            : 'Order Edited - Order edit submitted';
    const analyticsTitle = `All - Portal - ${orderType}` as const;

    const currentPageContent = currentPage.Content as React.Component<ContentProps>;
    const itemGroup = currentPageContent.props?.itemGroup;
    const currentItemId = itemGroup && itemGroup[0]?.id;
    const disableNextNoChanges = disableNextForNoChanges(
        isLastPage,
        isItemSelectionScreen,
        changedItems,
        originalItemMap,
        currentItemId,
    );

    const disableNext =
        currentPage.nextIsDisabled ||
        disableNextNoChanges ||
        selectedItemGroups.length === 0 ||
        (isLastPage && (changedItems.length === 0 || !hasPassedValidation)) ||
        isLoading;

    return (
        <>
            <FullScreenWorkflowContainer
                title={
                    <>
                        <SuperTitleContainer>
                            <Text variant={isMobile ? 'body1' : 'h4'} color={'LIGHT_GRAY'} style={{ opacity: 0.5 }}>
                                {currentPage.supertitle}
                            </Text>
                            <PricingGuideLink InjectedPricingGuideComponent={InjectedPricingGuideComponent} />
                        </SuperTitleContainer>
                        <Text variant={isMobile ? 'h5' : 'h3'} medium>
                            {currentPage.title}
                        </Text>
                    </>
                }
                backTitle={isFirstPage ? `Cancel` : `Back`}
                onBack={() => {
                    if (isFirstPage) {
                        onClose({ shouldRefetch: false });
                        return;
                    }

                    if (currentPage.type === 'extras') {
                        setShowExtrasPage(false);
                    }

                    goPageBack();
                }}
                disableNext={disableNext}
                nextTitle={getNextTitle({
                    isLastPage,
                    isLoading,
                    nextIsSkip,
                    defaultTitle: isProvideInstructionsFlow && isItemSelectionScreen ? 'Edit Instructions' : undefined,
                    customStrings,
                })}
                nextButtonProps={getNextButtonProps({
                    isLastPage,
                    nextIsSkip,
                })}
                onNext={() => {
                    if (!isLastPage) {
                        goPageForward();
                    } else {
                        void submitOrderEdits();
                        // Analytics for C/R extras flow
                        if (addedExtraIds.length) {
                            BrowserAnalyticsClientFactory.Instance?.track(
                                'All - Portal - Cancel & Resubmit - Order submitted with added extras',
                                {
                                    $groups: { order: order.id },
                                    extras: getCurrentExtras(_.flatten(itemGroups), addedExtraIds),
                                },
                            );
                        }
                        if (enableNuancedCr) {
                            BrowserAnalyticsClientFactory.Instance?.track(analyticsTitle, {
                                $groups: { order: order.id },
                                itemEditSummaryDict,
                                isFromQuickOrderActions: isFromQuickOrderActions,
                                isProvideInstructions: isProvideInstructionsFlow,
                            });
                        }
                    }
                }}
                disableNextForNoChanges={disableNextNoChanges}
                AdditionalContent={
                    !isItemSelectionScreen && (
                        <OrderEditDeliveryDate
                            originalDeliveryEta={order.practice_dates.estimated_delivery_date}
                            originalDesignPreviewEta={
                                order.practice_dates.digital_design_preview_estimated_completion_date
                            }
                            changedItems={changedItems}
                            hasPassedEtaValidation={hasPassedEtaValidation}
                            setHasPassedEtaValidation={setHasPassedEtaValidation}
                            doctorId={order.doctor_preferences_id}
                            hasWaxup={order.fulfillment_workflow.configuration.waxup_required}
                            isLastPage={isLastPage}
                            orderItems={orderItems}
                        />
                    )
                }
            >
                {orderEditMode === OrderEditMode.CancelAndResubmit && !enableNuancedCr && (
                    <CancelAndResubmitBanner
                        orderStatus={order.status}
                        orderActiveTaskType={order.fulfillment_workflow?.active_task?.type || ''}
                    />
                )}
                <div>{currentPage.Content}</div>
            </FullScreenWorkflowContainer>

            {/* Edit confirmation dialog – Explains when requested changes may incur a delay. */}
            <OrderEditDialog
                open={confirmDelayDialogOpen}
                onClose={() => setConfirmDelayDialogOpen(false)}
                title={`⚠️ This may cause a delay`}
                width={'sm'}
                children={
                    <Text variant={'body1'} style={{ marginBottom: 16 }}>
                        Making this change may result in a delay to your current delivery date. Our lab technicians will
                        make these adjustments and get your case to you as soon as possible.
                    </Text>
                }
                cancelButtonTitle={`Cancel`}
                confirmButtonTitle={`Proceed`}
                onConfirm={() => {
                    void submitOrderEdits();
                    setConfirmDelayDialogOpen(false);
                }}
            />
        </>
    );
};
