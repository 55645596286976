import { useFeatureFlag } from '../Providers/LaunchDarkly';
import { SessionTimer } from './SessionTimer';
import type { MinimalOrder, MarginLinesMap } from './Types';
import type {
    FinishingApp,
    MainViewCameraControlsRef,
    DesignFinishingRestorativeModel,
    TexturedModel,
} from '@orthly/dentin';
import { useFinishingApp } from '@orthly/dentin';
import { LabsGqlWorkflowTaskType } from '@orthly/graphql-schema';
import type { ArrayMin1 } from '@orthly/runtime-utils';
import constate from 'constate';
import type { MutableRefObject } from 'react';
import React from 'react';

export type CanvasRef = MutableRefObject<HTMLCanvasElement | null>;

export type FinishingStage =
    | {
          type: 'occlusion';
      }
    | {
          type: 'ip_contacts';
      }
    | {
          type: 'touch_up';
      };

export interface StageState {
    // Whether or not the user has completed the review stage
    isReviewComplete: boolean;
    setIsReviewComplete: React.Dispatch<React.SetStateAction<boolean>>;

    // The time at which the user opened the Finishing UI (timestamp in milliseconds)
    startTimeMs: number;

    stages: FinishingStage[];
    currentStageIdx: number;
    setCurrentStageIdx: React.Dispatch<React.SetStateAction<number>>;

    // Tracks timing during the editing phase
    editingSessionTimer: SessionTimer;
}

export interface AppState {
    app: FinishingApp;
    cameraControlsRef: MainViewCameraControlsRef;
    canvasRef: CanvasRef;
    stageState: StageState;
}

export interface ScanModels {
    upperJaw: TexturedModel;
    lowerJaw: TexturedModel;
    prePrepUpperJaw?: TexturedModel;
    prePrepLowerJaw?: TexturedModel;
}

export function isCompleteScanModels(models: Partial<ScanModels>): models is ScanModels {
    return !!(models.upperJaw && models.lowerJaw);
}

interface AppStateProps {
    order: MinimalOrder;
    restorativeModels: ArrayMin1<DesignFinishingRestorativeModel>;
    scanModels: ScanModels;
    marginLines: MarginLinesMap;
}

function useAppStateInner({ order, restorativeModels, scanModels, marginLines }: AppStateProps): AppState {
    const { value: enableTubeMarginLine } = useFeatureFlag('enableTubeMarginLine');
    const { value: enableFinishingCommitInvalidIntaglio } = useFeatureFlag('enableFinishingCommitInvalidIntaglio');

    const cameraControlsRef: MainViewCameraControlsRef = React.useRef(null);
    const canvasRef: CanvasRef = React.useRef(null);

    // Skip the review window if this order's active task is InternalDesign rather than AutomateReview.
    const [isReviewComplete, setIsReviewComplete] = React.useState(
        order.fulfillment_workflow.active_task?.type === LabsGqlWorkflowTaskType.InternalDesign,
    );

    const editingSessionTimer = useEditingSessionTimer(isReviewComplete);

    const app = useFinishingApp(order.id, restorativeModels, scanModels, cameraControlsRef, canvasRef, marginLines, {
        enableFinishingCommitInvalidIntaglio: enableFinishingCommitInvalidIntaglio ?? false,
        enableTubeMarginLine: enableTubeMarginLine ?? false,
    });

    const startTimeMs = React.useMemo(() => Date.now(), []);
    const [currentStageIdx, setCurrentStageIdx] = React.useState<number>(0);
    const [stages] = React.useState<FinishingStage[]>([
        { type: 'occlusion' },
        { type: 'ip_contacts' },
        { type: 'touch_up' },
    ]);

    const stageState: StageState = React.useMemo(
        () => ({
            isReviewComplete,
            setIsReviewComplete,
            startTimeMs,
            stages,
            currentStageIdx,
            setCurrentStageIdx,
            editingSessionTimer,
        }),
        [isReviewComplete, startTimeMs, stages, currentStageIdx, editingSessionTimer],
    );

    return { app, cameraControlsRef, canvasRef, stageState };
}

function useEditingSessionTimer(isReviewComplete: boolean): SessionTimer {
    const editingSessionTimer = React.useMemo(() => new SessionTimer(), []);

    React.useEffect(() => {
        if (isReviewComplete) {
            editingSessionTimer.start();
        }
    }, [editingSessionTimer, isReviewComplete]);

    return editingSessionTimer;
}

export const [
    AppStateProvider,
    useLoading,
    useCaseMetadata,
    useSceneState,
    useSculptingState,
    useDeformState,
    useOperationsState,
    useViewManager,
    useCrossSection,
    useCanvasChildren,
    useCanvasSiblings,
    useCameraControlsRef,
    useCanvasRef,
    useStageState,
    useInsertionAxisState,
] = constate(
    useAppStateInner,
    val => val.app.loading,
    val => val.app.caseMetadata,
    val => val.app.sceneState,
    val => val.app.sculptingState,
    val => val.app.deformState,
    val => val.app.operationsState,
    val => val.app.viewManager,
    val => val.app.crossSection,
    val => val.app.canvasChildren,
    val => val.app.canvasSiblings,
    val => val.cameraControlsRef,
    val => val.canvasRef,
    val => val.stageState,
    val => val.app.insertionAxisState,
);
