import { BooleanIcon } from '../../../components/BooleanIcon';
import { JsonDevTools } from '../../../components/JsonDevTools';
import { InvoiceEmailViewerDialog } from '../InvoiceEmailViewer.graphql';
import { LegacyPartnerCreditsTable } from '../LegacyPartnerCreditsTable.graphql';
import { PartnerBillingChangelogViewerDialog } from '../PartnerBillingChangelogViewer';
import { LegacyPracticeContractsTable } from '../PracticeContracts/LegacyPracticeContractsTable.graphql';
import { CreateBillingCreditAction } from '../actions/CreateBillingCredit.graphql';
import { CreateOrPreviewUsageInvoice } from '../actions/CreateOrPreviewUsageInvoice.graphql';
import { EditAccountManagementContact } from '../actions/EditAccountManagementContact.graphql';
import { EditBillingFlag } from '../actions/EditBillingFlag.graphql';
import { EditPaymentSplitConfig } from '../actions/EditPaymentSplitConfig/EditPaymentSplitConfig';
import { UpdatePrimaryBillingContactUser } from '../actions/UpdatePrimaryBilliingContactUser.graphql';
import { EditBillingContact } from '../actions/edit-billing-contact/EditBillingContact.graphql';
import { EditBillingContactsToolTip } from '../actions/edit-billing-contact/EditBillingContactTooltip';
import type { PartnerBillingOverviewData, BillingDetailsProps } from '../types';
import { activationDate, nextInvoiceDate } from './BillingDetails.utils';
import { useBillingDetailsContext } from './providers/BillingDetailsProvider.graphql';
import { LabsGqlPartnerBillingBoolean, LabsGqlStripeInvoiceStatus } from '@orthly/graphql-schema';
import type { Column, MUITableProps } from '@orthly/mui-table';
import { MUITable } from '@orthly/mui-table';
import { LoadBlocker } from '@orthly/ui';
import { Grid, Icon, styled, Text, Tooltip } from '@orthly/ui-primitives';
import { useFeatureFlag } from '@orthly/veneer';
import React from 'react';

const BillingActionsCell: React.FC<{
    organizationId: string;
    practiceName: string;
    refetchBillingDetails: () => Promise<unknown>;
    refetchCredits: () => Promise<unknown>;
}> = ({ organizationId, practiceName, refetchBillingDetails, refetchCredits }) => {
    const { getNextInvoiceStatusForOrg } = useBillingDetailsContext();
    const nextInvoiceStatus = getNextInvoiceStatusForOrg(organizationId);

    return (
        <Grid container wrap={'nowrap'}>
            <CreateOrPreviewUsageInvoice
                organizationId={organizationId}
                nextInvoiceStatus={nextInvoiceStatus}
                mode={'create'}
                refetch={refetchBillingDetails}
            />
            <CreateBillingCreditAction
                practiceId={organizationId}
                practiceName={practiceName}
                refetchCredits={refetchCredits}
                refetchBillingDetails={refetchBillingDetails}
            />
        </Grid>
    );
};

const TitleText = styled(Text)({
    marginBottom: '24px',
});

// TODO: EPDB-975: clean up ternary
const StyledTableWrapper = styled('div')(({ isMultiLoc }: { isMultiLoc: boolean }) => {
    return isMultiLoc
        ? {
              '& .MuiTableRow-head': {
                  height: '32px',
                  textWrap: 'nowrap',
              },
              overflowX: 'scroll',
              marginBottom: '24px',
          }
        : {
              overflowX: 'scroll',
              marginBottom: '24px',
              //   necessary to ensure the drop-shadow is visible
              padding: '2px',
          };
});

const CreditsContainer = styled(Grid)({
    paddingRight: '24px',
    marginBottom: '24px',
});

const MarginBottomContainer = styled(Grid)({
    marginBottom: '24px',
});

const PartnerBillingOverviewColumns = (enableMultiLocationContracts: boolean): Column<PartnerBillingOverviewData>[] => {
    const {
        primaryPracticeForContract,
        invoices,
        refetchPractice,
        refetchInvoices,
        refetchCredits,
        refetchBillingDetails,
        getNextInvoiceStatusForOrg,
        getOrgNeedsBillingSummaryEmail,
    } = useBillingDetailsContext();

    return [
        { name: 'id', hidden: true, render: 'id' },
        { name: 'Stripe ID', hidden: true, render: 'stripe_customer_id' },
        { name: enableMultiLocationContracts ? 'Location' : 'Partner', render: r => r?.name },
        {
            name: 'Primary Payee',
            type: 'boolean',
            hidden: !enableMultiLocationContracts,
            render: r => {
                const isPrimary = r.id === primaryPracticeForContract;
                return (
                    <>
                        <BooleanIcon val={isPrimary} />
                        {isPrimary && (
                            <Tooltip
                                title={
                                    'If a practice location is marked as the primary payee, they can see and pay all invoices across all the locations under the current contract.'
                                }
                                placement={'right'}
                            >
                                <Icon icon={'InfoOutlinedIcon'} />
                            </Tooltip>
                        )}
                    </>
                );
            },
        },
        {
            name: 'Primary Billing Contact',
            render: r => (
                <UpdatePrimaryBillingContactUser
                    practiceId={r.id}
                    primaryBillingContactUserId={r.primary_billing_contact_user_id}
                    refetchPractice={refetchPractice}
                />
            ),
            field: 'primary_billing_contact',
        },
        {
            name: 'Secondary Contact Emails',
            title: <EditBillingContactsToolTip />,
            render: r => <EditBillingContact practiceId={r.id} contactEmails={r.contact_emails} />,
            field: 'contact_emails',
        },
        { name: 'Activation Date', render: r => activationDate(r.activation_date) },
        {
            name: 'Next Invoice Date',
            render: r => {
                const nextInvoiceStatus = getNextInvoiceStatusForOrg(r.id);
                const orgInvoices = invoices.filter(i => i.organization_id === r.id);
                return nextInvoiceDate(nextInvoiceStatus?.will_be_invoiced ?? false, orgInvoices ?? []);
            },
        },
        {
            name: 'Will be Invoiced',
            type: 'boolean',
            render: r => {
                const nextInvoiceStatus = getNextInvoiceStatusForOrg(r.id);
                return nextInvoiceStatus.will_be_invoiced ?? false;
            },
        },
        {
            name: 'Upcoming Usage Invoice',
            render: r => {
                const nextInvoiceStatus = getNextInvoiceStatusForOrg(r.id);
                return (
                    <CreateOrPreviewUsageInvoice
                        organizationId={r.id}
                        nextInvoiceStatus={nextInvoiceStatus}
                        mode={'preview'}
                        refetch={refetchInvoices}
                    />
                );
            },
            filterOptions: { type: 'checkbox', exact: false },
        },
        {
            name: 'Auto-Charge Enabled?',
            render: r => (
                <EditBillingFlag
                    practiceId={r.id}
                    currentValue={r.autocharge_enabled}
                    property={LabsGqlPartnerBillingBoolean.AutochargeEnabled}
                />
            ),
            field: 'autocharge_enabled',
        },
        {
            name: 'Is on Payment Plan?',
            render: r => (
                <EditBillingFlag
                    practiceId={r.id}
                    currentValue={r.is_on_payment_plan}
                    property={LabsGqlPartnerBillingBoolean.IsOnPaymentPlan}
                />
            ),
        },
        {
            name: 'Usage Invoice Split Config',
            render: r => <EditPaymentSplitConfig practiceId={r.id} config={r.usage_payment_source_config} />,
            type: 'boolean',
        },
        {
            name: 'Unpaid invoices',
            render: r =>
                invoices.filter(i => i.organization_id === r.id && i.status === LabsGqlStripeInvoiceStatus.Open).length,
        },
        {
            name: 'Needs Summary Email?',
            render: r => getOrgNeedsBillingSummaryEmail(r.id),
            type: 'boolean',
        },
        {
            name: 'View Billing Email',
            render: r => <InvoiceEmailViewerDialog partner_id={r.id} />,
            download: false,
        },
        {
            name: 'Account Management Contact Emails',
            render: r => (
                <EditAccountManagementContact
                    practiceId={r.id}
                    acctManagementContactEmails={r.account_management_contact_emails}
                />
            ),
            field: 'account_management_contact_emails',
        },
        {
            name: 'Actions',
            render: r => (
                <BillingActionsCell
                    organizationId={r.id}
                    practiceName={r.name ?? ''}
                    refetchBillingDetails={refetchBillingDetails}
                    refetchCredits={refetchCredits}
                />
            ),
            download: false,
        },
        {
            name: 'View Configuration History',
            render: r => <PartnerBillingChangelogViewerDialog partner_id={r.id} />,
            download: false,
        },
        {
            name: 'Will be charged CC Processing Fees',
            type: 'boolean',
            render: 'will_be_charged_cc_fee',
        },
    ];
};

export const LegacyBillingDetailPanel: React.FC<BillingDetailsProps> = ({ practiceId }) => {
    const { value: enableMultiLocationContracts = false } = useFeatureFlag('enableMultiLocationContracts');
    const {
        practice,
        associatedOrganizations,
        practiceLoading,
        invoicesLoading,
        credits,
        creditsLoading,
        refetchCredits,
        refetchBillingDetails,
        primaryPracticeForContract,
    } = useBillingDetailsContext();

    // TODO: EPDB-975: clean up ternary
    const displayOptions: MUITableProps<PartnerBillingOverviewData>['displayOptions'] = enableMultiLocationContracts
        ? { elevation: 0 }
        : {};

    return (
        <LoadBlocker blocking={practiceLoading || invoicesLoading}>
            {enableMultiLocationContracts && (
                <div>
                    <TitleText variant={'h5'}>{practice?.name} Overview</TitleText>
                    {primaryPracticeForContract === practiceId && (
                        <TitleText variant={'body1'}>Locations ({associatedOrganizations?.length})</TitleText>
                    )}
                </div>
            )}

            <StyledTableWrapper isMultiLoc={enableMultiLocationContracts}>
                <MUITable<PartnerBillingOverviewData>
                    title={enableMultiLocationContracts ? '' : `${practice?.name} Overview`}
                    columns={PartnerBillingOverviewColumns(enableMultiLocationContracts)}
                    data={associatedOrganizations ?? []}
                    paginationOptions={{ disable: true }}
                    actions={{
                        global: [
                            {
                                icon: 'refresh',
                                position: 'toolbar',
                                onClick: refetchBillingDetails,
                                disabled: practiceLoading,
                            },
                        ],
                    }}
                    displayOptions={displayOptions}
                />
            </StyledTableWrapper>
            <CreditsContainer container item xs={8}>
                {!creditsLoading && (
                    <LegacyPartnerCreditsTable
                        onRefresh={refetchCredits}
                        practiceId={practiceId}
                        practiceName={practice?.name ?? ''}
                        credits={credits ?? []}
                        refetchBillingDetails={refetchBillingDetails}
                    />
                )}
            </CreditsContainer>
            <MarginBottomContainer container item xs={4}>
                {practice && (
                    <JsonDevTools
                        src={practice}
                        dataType={'PartnerBilling'}
                        title={<Text variant={'h5'}>Dev Tools</Text>}
                        containerStyle={{ padding: '0px !important' }}
                        expansionPanelSummaryStyle={{ height: '56px' }}
                    />
                )}
            </MarginBottomContainer>
            <MarginBottomContainer container item xs={12}>
                <LegacyPracticeContractsTable practiceId={practiceId} />
            </MarginBottomContainer>
        </LoadBlocker>
    );
};
