import { DesignTrainingStartFinishingButton } from './DesignTrainingStartFinishingButton.graphql';
import type { DesignTrainingOrderFragment } from '@orthly/graphql-inline-react';
import { StackX, StackY } from '@orthly/ui';
import { styled, FlossPalette, Text } from '@orthly/ui-primitives';

const StyledStartButton = styled(DesignTrainingStartFinishingButton)({
    flexShrink: 0,
});

const StartNextCard = styled(StackX)({
    border: `1px solid ${FlossPalette.STROKE_LIGHT}`,
    borderRadius: 8,
    padding: 24,
    background: FlossPalette.WHITE,
    alignItems: 'center',
    gap: '16px',
    justifyContent: 'space-between',
});

export const DesignTrainingStartNextCard: React.VFC<{
    trainingOrder: DesignTrainingOrderFragment;
    onComplete: () => void;
}> = ({ trainingOrder, onComplete }) => {
    return (
        <StartNextCard>
            <StackY>
                <Text variant={'body1'} medium>
                    {`Next up: Training case #${trainingOrder.orderNumber}`}
                </Text>
                <Text variant={'body2'}>5-6 minutes</Text>
            </StackY>
            <StyledStartButton trainingOrder={trainingOrder} prefetch={true} onComplete={onComplete}>
                Start training case
            </StyledStartButton>
        </StartNextCard>
    );
};
