import type { LabsGqlDesignFileValidationError } from '@orthly/graphql-schema';
import { DesignFileValidationError } from '@orthly/shared-types';
import { isApolloError } from '@orthly/ui';
import _ from 'lodash';

export const DESIGN_FILE_VALIDATION_ERROR_TO_HUMAN_STRING_MAP: {
    [key in DesignFileValidationError | LabsGqlDesignFileValidationError]: string;
} = {
    [DesignFileValidationError.WRONG_PATIENT_ERROR_MSG]: 'Upload appears to be for the WRONG PATIENT',
    [DesignFileValidationError.MISSING_DCM_STL]:
        'No DCM or STL files found in the Scans, CAD, or Anatomy elements directories',
    [DesignFileValidationError.MISSING_3OXZ]:
        'Missing OrderSource folder or .3oxz file. Unable to verify patient matches order',
    [DesignFileValidationError.MISSING_CASE_FILE]:
        'Design files are not associated with a 3oxz _case file. Unable to verify patient matches order',
    [DesignFileValidationError.BAD_ARCHIVE_NAME]: 'Design file name does not match the base folder name within it',
};

/**
 * Used to extract the validation errors returned by `AttachDesignFiles` and `CompleteInternalDesignTask`.
 * This is different from the apolloErrorMessage function used elsewhere because these commands return an array of validation
 * errors, which is returned in the response body and then moved into the `extensions` object in the GQL response
 */
export function getDesignFileValidationErrors(error: Error): DesignFileValidationError[] {
    if (!isApolloError(error)) {
        return [];
    }
    return _.get(error, 'graphQLErrors[0].extensions.exception.response.validationErrors', []);
}

export function getFormattedDesignValidationMessage(
    validationErrors: DesignFileValidationError[] | LabsGqlDesignFileValidationError[],
    prefix?: string,
    suffix?: string,
) {
    let message = prefix ?? '';
    for (const validationError of validationErrors) {
        message += `\n  - ${_.get(DESIGN_FILE_VALIDATION_ERROR_TO_HUMAN_STRING_MAP, validationError, validationError)}`;
    }
    if (suffix) {
        message += `\n\n${suffix}`;
    }
    return message;
}
