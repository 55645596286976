import {
    AutomationSelectField,
    AutomationTextField,
    AutomationAutocompleteField,
} from '../../components/AutomationBuilderFields';
import type { LabsGqlOrderFilterRuleFragment } from '@orthly/graphql-operations';
import { useListOrderFilterOptionsQuery } from '@orthly/graphql-react';
import type { LabsGqlICustomFieldOption } from '@orthly/graphql-schema';
import { LabsGqlFilterComparator, LabsGqlFilterComparisonValueType } from '@orthly/graphql-schema';
import { SimpleMultiSelectChips, SimpleDatePicker, LoadBlocker } from '@orthly/ui';
import { BasicTagsAutocomplete } from '@orthly/veneer';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';

interface OrderFilterComparisonValueFieldProps {
    comparator: LabsGqlFilterComparator;
    value?: string | boolean | number | string[] | null;
    onChange: (value?: string | boolean | number | string[]) => void;
    selectedFilter?: LabsGqlOrderFilterRuleFragment;
}

const OrderFilterComparisonValueDateField: React.FC<OrderFilterComparisonValueFieldProps> = props => {
    const { value } = props;
    const dateValue = React.useMemo(() => {
        if (Array.isArray(value) || value === null || value === undefined || typeof value === 'boolean') {
            return null;
        }
        return new Date(value);
    }, [value]);
    return (
        <SimpleDatePicker
            label={''}
            minDate={moment('2020-01-01').toDate()}
            sx={{ paddingTop: 0 }}
            value={dateValue}
            onChange={v => {
                props.onChange(v ? new Date(v).toJSON() : undefined);
            }}
        />
    );
};

const SELECT_TO_AUTOCOMPLETE_OPTS_THRESHOLD = 25;

const ComparisonValueFieldInner: React.VFC<
    OrderFilterComparisonValueFieldProps & { options?: LabsGqlICustomFieldOption[] }
    // EPDPLT-3246 High cognitive complexity. Consider refactoring to make this function easier to test and maintain.
    // eslint-disable-next-line sonarjs/cognitive-complexity
> = props => {
    const { comparator, value, onChange, selectedFilter, options } = props;
    const selectOpts = React.useMemo(() => {
        if (!options) {
            return undefined;
        }
        return _.sortBy(
            options.map(o => ({ value: o.value, label: o.label ?? undefined })),
            o => (!isNaN(parseInt(o.label ?? o.value)) ? parseInt(o.label ?? o.value) : o.label ?? o.value),
        );
    }, [options]);
    const numberFieldOnChange = React.useCallback(
        (value?: string) => {
            if (value && !isNaN(parseInt(value))) {
                return onChange(parseInt(value));
            }
            !value && onChange(undefined);
        },
        [onChange],
    );
    if (selectedFilter && selectedFilter.comparison_value_type === LabsGqlFilterComparisonValueType.Tag) {
        return (
            <BasicTagsAutocomplete
                value={Array.isArray(value) ? value : []}
                options={Array.isArray(options) ? options : []}
                onChange={onChange}
            />
        );
    }
    if (selectedFilter && selectedFilter.comparison_value_type === LabsGqlFilterComparisonValueType.Date) {
        if (comparator === LabsGqlFilterComparator.DaysAfter || comparator === LabsGqlFilterComparator.DaysBefore) {
            return (
                <AutomationTextField
                    label={''}
                    TextFieldProps={{ type: 'number' }}
                    value={typeof value === 'string' ? value : `${value}`}
                    onChange={numberFieldOnChange}
                />
            );
        }
        return <OrderFilterComparisonValueDateField {...props} />;
    }
    if (selectOpts) {
        if (comparator === LabsGqlFilterComparator.NotEquals || comparator === LabsGqlFilterComparator.Equals) {
            if (selectOpts.length > SELECT_TO_AUTOCOMPLETE_OPTS_THRESHOLD) {
                const autoCompleteValue = selectOpts.find(o => o.value === value);
                return (
                    <AutomationAutocompleteField
                        options={selectOpts}
                        label={''}
                        onChange={v => onChange(v ?? undefined)}
                        AutocompleteProps={{ value: autoCompleteValue }}
                    />
                );
            }
            return (
                <AutomationSelectField
                    options={selectOpts}
                    onChange={onChange}
                    label={''}
                    value={typeof value === 'string' ? value : ''}
                />
            );
        }
        if (comparator === LabsGqlFilterComparator.OneOf || comparator === LabsGqlFilterComparator.NotOneOf) {
            return (
                <BasicTagsAutocomplete
                    value={Array.isArray(value) ? value : []}
                    onChange={onChange}
                    options={selectOpts ?? []}
                />
            );
        }
    }
    switch (comparator) {
        case LabsGqlFilterComparator.OneOf:
        case LabsGqlFilterComparator.NotOneOf:
            return (
                <SimpleMultiSelectChips
                    options={selectOpts ?? []}
                    onChange={onChange}
                    label={''}
                    FormControlProps={{ style: { marginBottom: 0 }, variant: 'standard' }}
                    value={Array.isArray(value) ? value : []}
                />
            );
        case LabsGqlFilterComparator.NotEquals:
        case LabsGqlFilterComparator.Equals:
            if (selectedFilter?.comparison_value_type === LabsGqlFilterComparisonValueType.Number) {
                return (
                    <AutomationTextField
                        label={''}
                        TextFieldProps={{ type: 'number' }}
                        value={typeof value === 'string' ? value : `${value}`}
                        onChange={numberFieldOnChange}
                    />
                );
            }
            return <AutomationTextField value={typeof value === 'string' ? value : ''} onChange={onChange} />;
        case LabsGqlFilterComparator.Gt:
        case LabsGqlFilterComparator.Gte:
        case LabsGqlFilterComparator.Lt:
        case LabsGqlFilterComparator.Lte:
            return (
                <AutomationTextField
                    label={''}
                    TextFieldProps={{ type: 'number' }}
                    value={typeof value === 'string' ? value : `${value}`}
                    onChange={numberFieldOnChange}
                />
            );
        case LabsGqlFilterComparator.Disabled:
        case LabsGqlFilterComparator.NotSet:
        case LabsGqlFilterComparator.Set:
        default:
            return null;
    }
};

export const OrderFilterComparisonValueField: React.FC<OrderFilterComparisonValueFieldProps> = props => {
    const { selectedFilter } = props;
    const { loading, data: optionsData } = useListOrderFilterOptionsQuery({
        variables: { filterId: selectedFilter?.id ?? '', partnerId: null },
        skip: !selectedFilter?.hasOptions,
    });
    const options = selectedFilter?.hasOptions ? optionsData?.loadOrderFilterOptions ?? [] : undefined;
    return (
        <LoadBlocker blocking={loading}>
            <ComparisonValueFieldInner {...props} options={options} />
        </LoadBlocker>
    );
};
