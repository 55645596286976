import type { ICameraControls } from '../ModelViewer/utils3d/TrackballControls';
import { drawCircle, updateCircle } from '../ModelViewer/utils3d/interaction.util';
import { updateRaycaster, updateRaycasterFromMousePosition } from '../ModelViewer/utils3d/raycast-bvh.util';
import type { BrushSettings } from '../PortalDesignEditor';
import { BrushType } from '../PortalDesignEditor';
import type { HandleSculptFn } from '../PortalDesignEditor/SculptingTool.types';
import type { HandleSculptCompleteFn } from './Sculpting.types';
import { isIntersectionInSculptMask } from './Sculpting.utils';
import type { MeshConnectivityGraph } from '@orthly/forceps';
import { ensureMeshIndex, getNeighbors } from '@orthly/forceps';
import { FlossPalette } from '@orthly/ui-primitives';
import _ from 'lodash';
import * as THREE from 'three';
import type { OrthographicCamera, Vector3 } from 'three';

export class BaseSculptTool {
    mouseState: 'dragging' | 'pressed' | 'none' = 'none';
    pointer: THREE.Vector2 = new THREE.Vector2();
    lastPointer?: THREE.Vector2;
    pointerIntersection?: THREE.Intersection;
    downIntersection?: THREE.Intersection;
    raycaster: THREE.Raycaster = new THREE.Raycaster();
    camera: OrthographicCamera;
    totalFrameTime: number = 0;
    totalFrames: number = 0;
    minimumFrameTime: number = Infinity;
    maximumFrameTime: number = 0;
    targetTWorld: THREE.Matrix4;

    constructor(
        public active: boolean,
        public canvas: HTMLCanvasElement | null,
        public cameraControls: ICameraControls | null,
        public activeBrush: BrushSettings,
        public onSculptIter: HandleSculptFn,
        public onSculptComplete: HandleSculptCompleteFn,
        public meshConnectivityGraph: MeshConnectivityGraph,
        public group?: THREE.Group,
        public geometry?: THREE.BufferGeometry,
        public onSculptActive?: (active: boolean) => void,
        public isScan?: boolean,
        protected worldTTarget?: THREE.Matrix4,
        protected originalGeometry?: THREE.BufferGeometry | undefined,
        protected enableBackside?: boolean,
    ) {
        this.camera = this.cameraControls?.object as OrthographicCamera;
        this.targetTWorld = this.worldTTarget ? this.worldTTarget.clone().invert() : new THREE.Matrix4();

        this.init();
    }

    init() {
        if (this.group) {
            this.group.renderOrder = 1000;
        }
    }

    intersectGeometry() {
        if (!this.geometry) {
            return false;
        }

        const intersects = ensureMeshIndex(this.geometry).raycast(
            this.raycaster.ray,
            this.enableBackside ? THREE.DoubleSide : THREE.FrontSide,
        );
        this.pointerIntersection = _.minBy(intersects, intersect => intersect.distance);

        // we did not click on the mesh or we clicked inside the sculpt mask region
        if (
            !this.pointerIntersection ||
            !this.pointerIntersection.face ||
            isIntersectionInSculptMask(this.pointerIntersection, this.geometry)
        ) {
            this.mouseState = 'dragging';
            return false;
        }
        return true;
    }

    pointerMove(_evt: MouseEvent) {}

    pointerDown(evt: MouseEvent) {
        if (!this.active || evt.button !== 0 || evt.altKey || !this.geometry) {
            return;
        }

        this.onSculptActive?.(true);
        this.pointer.set(evt.clientX, evt.clientY);
        this.lastPointer = new THREE.Vector2(evt.clientX, evt.clientY);

        this.mouseState = 'pressed';
        this.customPointerDown(evt);
    }

    customPointerDown(_evt: MouseEvent) {}

    pointerUp(evt: MouseEvent) {
        if (!this.active || evt.button !== 0 || this.mouseState === 'none') {
            return;
        }

        this.customPointerUp(evt);

        this.onSculptActive?.(false);
        this.mouseState = 'none';
        if (this.cameraControls) {
            this.cameraControls.enabled = true;
        }
        this.lastPointer = undefined;
        this.downIntersection = undefined;
    }

    customPointerUp(_evt: MouseEvent) {}
}

export class SculptTool extends BaseSculptTool {
    private effectCircle?: THREE.Line<THREE.CircleGeometry, THREE.LineBasicMaterial>;

    private accumulatedNeighborSet: Set<number> = new Set();

    private didASculpt: boolean = false;

    private sculpt() {
        if (!this.geometry) {
            return;
        }

        // Disable the user from being able to move the view while they are sculpting.
        if (this.cameraControls) {
            this.cameraControls.enabled = false;
        }
        const step = new THREE.Vector2(0, 0);
        if (this.lastPointer) {
            step.subVectors(this.pointer, this.lastPointer);
        } else {
            this.lastPointer = this.pointer.clone();
        }

        const dist = step.length();
        const minSpacing = 0.75 * (this.camera.zoom / 10) * this.activeBrush.radius;
        if (dist <= minSpacing && dist !== 0) {
            return;
        }

        // Do at least one step so that a click without a drag still causes a modification.
        const numberOfSteps = Math.max(1, Math.floor(dist / minSpacing));
        if (numberOfSteps > 1) {
            step.multiplyScalar(1 / numberOfSteps);
        }

        const pickedPos = new THREE.Vector2().copy(this.lastPointer);
        for (let i = 0; i < numberOfSteps; ++i) {
            if (!this.doSculptStep(pickedPos)) {
                break;
            }
            pickedPos.add(step);
        }

        if (this.geometry?.attributes.position) {
            this.geometry.attributes.position.needsUpdate = true;
        }
        if (this.geometry?.attributes.normal) {
            this.geometry.attributes.normal.needsUpdate = true;
        }
        ensureMeshIndex(this.geometry).refit();
        this.lastPointer.copy(this.pointer);
    }

    private doSculptStep(pickedPos: THREE.Vector2): boolean {
        if (!this.geometry) {
            return false;
        }

        if (this.canvas) {
            updateRaycasterFromMousePosition(this.raycaster, this.canvas, this.camera, pickedPos, this.targetTWorld);
        }

        const intersects = ensureMeshIndex(this.geometry).raycast(
            this.raycaster.ray,
            this.enableBackside ? THREE.DoubleSide : THREE.FrontSide,
        );
        const intersection = _.minBy(intersects, intersect => intersect.distance);

        if (!intersection || !intersection.face) {
            console.log('intersection not found!');
            this.hideEffectCircle();
            return false;
        }
        this.didASculpt = true;

        const side = intersection.face.normal.dot(this.raycaster.ray.direction) > 0 ? THREE.BackSide : THREE.FrontSide;

        this.showEffectCircle();

        const neighbors = getNeighbors({
            adjacencyMatrix: this.meshConnectivityGraph.adjacencyVertexToVertex,
            mainHandle: intersection.face.a,
            maxRadiusMm: 1.001 * this.activeBrush.radius,
            geometry: this.geometry,
            excludeMask: !this.isScan,
        });

        // Accumulate the unique verts we have traversed
        neighbors.forEach(n => this.accumulatedNeighborSet.add(n));

        if (neighbors.length > 0) {
            this.applyBrushStroke(neighbors, intersection.point, intersection.face.normal, side);
            this.onSculptIter(this.geometry, neighbors);
        }
        return true;
    }

    private applyBrushStroke(neighbors: number[], pickedPos: Vector3, pickedNormal: Vector3, pickedSide: THREE.Side) {
        if (!this.geometry) {
            return;
        }
        if (
            (this.activeBrush.brushType === BrushType.add || this.activeBrush.brushType === BrushType.subtract) &&
            !this.activeBrush.reverseOnBack &&
            pickedSide === THREE.BackSide
        ) {
            pickedNormal.negate();
        }
        if (this.activeBrush.brushAction) {
            this.activeBrush.brushAction.apply({
                pickedPosition: pickedPos,
                pickedSide,
                normal: pickedNormal,
                adjacencyMatrix: this.meshConnectivityGraph.adjacencyVertexToVertex,
                neighbors,
                geometry: this.geometry,
                radius: this.activeBrush.radius,
                intensity: this.activeBrush.intensity,
                negative: this.activeBrush.brushType === BrushType.subtract,
                proximalLimitMm: this.activeBrush.proximalLimitMm,
                occlusalLimitMm: this.activeBrush.occlusalLimitMm,
                curtainsLimitMm: this.activeBrush.curtainsLimitMm,
                isProximalRaiseVerticesEnabled: this.activeBrush.isProximalRaiseVerticesEnabled,
            });
        }
    }

    private updateLiveVisualObjects(position?: THREE.Vector3, normal?: THREE.Vector3) {
        if (!(this.group && position)) {
            return;
        }

        const worldPosition = this.worldTTarget ? position.clone().applyMatrix4(this.worldTTarget) : position;
        const worldNormal = this.worldTTarget ? normal?.clone().transformDirection(this.worldTTarget) : normal;

        if (!this.effectCircle) {
            this.effectCircle = drawCircle({ lineWidth: 8 });
            this.group.add(this.effectCircle);
        }

        let rotation = this.camera.rotation;
        if (worldNormal) {
            const target = new THREE.Vector3().copy(worldPosition).addScaledVector(worldNormal, 0.1);
            const mat = new THREE.Matrix4();
            mat.lookAt(worldPosition, target, new THREE.Vector3(0, 0, 1));
            rotation = new THREE.Euler().setFromRotationMatrix(mat);
        }

        updateCircle(this.effectCircle, {
            pos: worldPosition,
            effectDistance: this.activeBrush.radius,
            rot: rotation,
        });

        this.effectCircle.visible = true;
    }

    private showEffectCircle() {
        if (this.effectCircle) {
            this.effectCircle.visible = true;
            this.effectCircle.material.color.setRGB(1, 0, 0);
        }
    }

    private hideEffectCircle() {
        if (this.effectCircle) {
            this.effectCircle.visible = false;
            this.effectCircle.material.color.set(FlossPalette.LIGHT_GRAY);
        }
    }

    removeEffectCircle() {
        if (this.effectCircle) {
            this.effectCircle.geometry.dispose();
            this.group?.remove(this.effectCircle);
        }
    }

    customPointerDown(_evt: MouseEvent) {
        this.didASculpt = false;
        const intersected = this.intersectGeometry();
        if (intersected) {
            this.downIntersection = _.clone(this.pointerIntersection);
        }
    }

    pointerMove(evt: MouseEvent): void {
        if (!this.active) {
            return;
        }

        // communicate mouse position to scene via camera etc
        this.pointer.set(evt.clientX, evt.clientY);

        if (this.canvas) {
            updateRaycaster(this.raycaster, this.canvas, this.camera, evt, this.targetTWorld);
        }

        if (this.mouseState !== 'pressed') {
            this.mouseState = 'dragging';
        }

        const intersected = this.intersectGeometry();
        if (!intersected) {
            this.hideEffectCircle();
        } else {
            this.updateLiveVisualObjects(this.pointerIntersection?.point, this.pointerIntersection?.face?.normal);

            // apply sculpt move
            if (this.mouseState === 'pressed') {
                const startTime = performance.now();
                this.sculpt();
                const endTime = performance.now();
                const runTime = endTime - startTime;
                if (runTime > 0) {
                    this.totalFrameTime += runTime;
                    this.totalFrames++;
                    if (runTime < this.minimumFrameTime) {
                        this.minimumFrameTime = runTime;
                    }
                    if (runTime > this.maximumFrameTime) {
                        this.maximumFrameTime = runTime;
                    }
                }
            }
        }
    }

    customPointerUp(_evt: MouseEvent): void {
        this.hideEffectCircle();

        // To enable single click sculpting if we did not sculpt anything (no drag before)
        if (!this.didASculpt && this.geometry && this.lastPointer && this.downIntersection) {
            const pickedPos = new THREE.Vector2().copy(this.lastPointer);
            this.doSculptStep(pickedPos);

            if (this.geometry?.attributes.position) {
                this.geometry.attributes.position.needsUpdate = true;
            }
            if (this.geometry?.attributes.normal) {
                this.geometry.attributes.normal.needsUpdate = true;
            }
            ensureMeshIndex(this.geometry).refit();
            this.lastPointer.copy(this.pointer);
        }

        // update non-active heatmaps on accumulated region
        if (this.accumulatedNeighborSet.size) {
            const accumulatedVerts = Array.from(this.accumulatedNeighborSet);
            const trackingInfo = {
                minFrameTime: this.minimumFrameTime,
                maxFrameTime: this.maximumFrameTime,
                avgFrameTime: this.totalFrameTime / this.totalFrames,
                totalNeighbors: accumulatedVerts.length,
                brushRadius: this.activeBrush.radius,
                brushType: BrushType[this.activeBrush.brushType],
                isScan: this.isScan ?? false,
            };
            this.onSculptComplete(this.geometry, accumulatedVerts, trackingInfo);

            // reset for the next round
            this.totalFrameTime = 0;
            this.totalFrames = 0;
            this.minimumFrameTime = Infinity;
            this.maximumFrameTime = 0;
        }

        this.accumulatedNeighborSet = new Set();
        this.didASculpt = false;
    }
}
