import { TagDialog } from './TagDialog';
import type { TagsAutocompleteProps } from './TagsAutocomplete.types';
import type { LabsGqlTagFragment } from '@orthly/graphql-operations';
import { useTagEntityMutation, useUntagEntityMutation, useTagsQuery } from '@orthly/graphql-react';
import { useHasCapability } from '@orthly/session-client';
import { LoadBlocker, useChangeSubmissionFn } from '@orthly/ui';
import {
    TextField,
    Chip,
    makeStyles,
    createStyles,
    getLuminance,
    Autocomplete,
    createFilterOptions,
} from '@orthly/ui-primitives';
import compact from 'lodash/compact';
import React from 'react';

export const useAutocompleteStyles = makeStyles(() =>
    createStyles({
        autocompleteInput: {
            padding: `4px !important`,
        },
        chipDeleteIcon: {
            color: '#fff',
            display: 'none',
            marginLeft: '-22px',
            marginRight: '0px !important',
            position: 'relative',
            zIndex: 2,
            background: 'inherit',
            borderRadius: '50%',
        },
        tagContainer: {
            '&:hover $chipDeleteIcon': {
                display: 'inline-block',
            },
        },
        tagLabel: {
            padding: '0 6px',
        },
    }),
);

const filter = createFilterOptions<LabsGqlTagFragment>();
const filterAutoComplete = createFilterOptions<TagsAutocompleteOption>();

// given a background color, calculate a foreground with good contrast.

export const tagForegroundForBackground = (color: string) => {
    return getLuminance(color) > 0.5 ? `black` : `white`;
};

export const TagsAutocomplete: React.FC<TagsAutocompleteProps> = props => {
    const classes = useAutocompleteStyles();
    const { entityId, entityType, placeholder, onLoad, onChange, disabled } = props;

    const [dialog, setDialog] = React.useState({
        open: false,
        tag: {} as Omit<LabsGqlTagFragment, 'id'> & { id?: string },
    });

    const {
        data: { tags: rawTags } = { tags: [] as LabsGqlTagFragment[] },
        loading: loadingTags,
        refetch: refetchTags,
    } = useTagsQuery({
        variables: { entity_id: props.entityId, entity_type: props.entityType, id: null },
    });

    const [tagsLoaded, setTagsLoaded] = React.useState(false);
    React.useEffect(() => {
        if (loadingTags && !tagsLoaded) {
            setTagsLoaded(true);
            onLoad?.();
        }
    }, [loadingTags, tagsLoaded, onLoad]);

    const {
        data: { tags: availableTags } = { tags: [] as LabsGqlTagFragment[] },
        loading: loadingAvailableTags,
        refetch: refetchAvailableTags,
    } = useTagsQuery({
        variables: { entity_type: entityType, id: null, entity_id: null },
        fetchPolicy: 'cache-first',
        nextFetchPolicy: 'cache-first',
    });

    const tags = React.useMemo(() => {
        return rawTags.filter(r => availableTags.find(a => r.id === a.id));
    }, [rawTags, availableTags]);

    const handleTagsChanged = React.useCallback(() => {
        void refetchTags();
        onChange && onChange();
    }, [onChange, refetchTags]);

    const [tagEntityMutation] = useTagEntityMutation();
    const { submit: tagEntity, submitting: taggingEntity } = useChangeSubmissionFn(
        (tagId: string) => tagEntityMutation({ variables: { entity_id: entityId, tag_id: tagId } }),
        {
            successMessage: () => [`${entityType} tagged`, {}],
            onSuccess: () => handleTagsChanged(),
            onError: () => handleTagsChanged(),
        },
    );

    const [untagEntityMutation] = useUntagEntityMutation();
    const { submit: untagEntity, submitting: untaggingEntity } = useChangeSubmissionFn(
        (tagId: string) => untagEntityMutation({ variables: { entity_id: entityId, tag_id: tagId } }),
        {
            successMessage: () => [`${entityType} untagged`, {}],
            onSuccess: () => handleTagsChanged(),
            onError: () => handleTagsChanged(),
        },
    );

    const canCreateTags = useHasCapability('tag', 'tag.create');

    return (
        <>
            <LoadBlocker
                blocking={taggingEntity || untaggingEntity || loadingTags || loadingAvailableTags}
                ContainerProps={{ style: { width: '300px', ...props.rootStyle } }}
            >
                <Autocomplete<LabsGqlTagFragment, true, true, true>
                    disabled={!!disabled}
                    multiple
                    freeSolo={canCreateTags as true}
                    options={availableTags}
                    value={tags}
                    onChange={(_event, newTagsParam: (LabsGqlTagFragment | string)[]) => {
                        if (newTagsParam.some(tag => typeof tag === 'string')) {
                            return;
                        }
                        const newTags = newTagsParam as LabsGqlTagFragment[];
                        const created = newTags.find(tag => !tag?.id);
                        const tagged = newTags.filter(tag => !tags.includes(tag) && tag.id);
                        const untagged = tags.filter(tag => !newTags.includes(tag));
                        if (created && canCreateTags) {
                            setDialog({ open: true, tag: created });
                        }
                        tagged.forEach(tag => void tagEntity(tag.id));
                        untagged.forEach(tag => void untagEntity(tag.id));
                    }}
                    filterOptions={(options, params) =>
                        [
                            ...filter(options, params).filter(({ id }) => !tags.find(tag => tag.id === id)),
                            ...(params.inputValue && !tags.find(tag => tag.name === params.inputValue)
                                ? [{ name: params.inputValue }]
                                : []),
                        ] as LabsGqlTagFragment[]
                    }
                    getOptionLabel={(tag: LabsGqlTagFragment | string) => {
                        if (typeof tag === 'string') {
                            return tag;
                        }
                        if (tag.id) {
                            return tag.name;
                        }
                        return `Create tag named "${tag.name}"`;
                    }}
                    renderOption={(props, option) => (
                        <li {...props} key={option.id}>
                            {option.name}
                        </li>
                    )}
                    renderTags={(tagsParam, getTagProps) => {
                        const tags = tagsParam.filter(tag => typeof tag !== 'string') as LabsGqlTagFragment[];
                        return tags.map((tag, index) => (
                            <Chip
                                variant={'outlined'}
                                style={{
                                    backgroundColor: tag.color ?? undefined,
                                    color: tag.color ? tagForegroundForBackground(tag.color) : undefined,
                                    height: '24px',
                                    margin: 0,
                                    marginRight: '5px',
                                    padding: 0,
                                }}
                                classes={{
                                    deleteIcon: classes.chipDeleteIcon,
                                    label: classes.tagLabel,
                                    root: classes.tagContainer,
                                }}
                                label={tag.name}
                                clickable={true}
                                onClick={() => setDialog({ tag, open: true })}
                                {...getTagProps({ index })}
                                key={tag.id}
                            />
                        ));
                    }}
                    style={{ width: `100%`, padding: 0, margin: 0 }}
                    disableClearable
                    clearOnEscape
                    renderInput={params => (
                        <TextField
                            variant={'standard'}
                            {...params}
                            onClick={ev => ev.stopPropagation()}
                            fullWidth
                            InputProps={{
                                ...params.InputProps,
                                style: { padding: '0' },
                            }}
                            inputProps={{
                                ...params.inputProps,
                                className: `${(params.inputProps as { className: string }).className} ${
                                    classes.autocompleteInput
                                }`,
                            }}
                            placeholder={tags.length ? '' : placeholder || 'Add...'}
                        />
                    )}
                />
            </LoadBlocker>
            <TagDialog
                open={dialog.open}
                setOpen={open => setDialog(dialog => ({ ...dialog, open }))}
                entityType={entityType}
                entityId={entityId}
                tag={dialog.tag}
                onChange={() => {
                    void refetchTags();
                    void refetchAvailableTags();
                    onChange && onChange();
                }}
            />
        </>
    );
};

interface TagsAutocompleteOption {
    value: string;
    label?: string | null;
    color?: string | null;
}

export interface BasicTagsAutocompleteProps {
    value: string[];
    options: TagsAutocompleteOption[];
    onChange: (value: string[]) => void;
}

export const BasicTagsAutocomplete: React.FC<BasicTagsAutocompleteProps> = props => {
    const classes = useAutocompleteStyles();
    const { value: ids, options, onChange } = props;
    const tags = compact(ids.map(id => options.find(option => option.value === id)));

    return (
        <Autocomplete
            multiple
            options={options}
            value={tags}
            onChange={(_event, newTags: TagsAutocompleteOption[]) => {
                onChange(newTags.map(tag => tag.value));
            }}
            filterOptions={(options, params) =>
                filterAutoComplete(options, params).filter(({ value: val }) => !tags.find(tag => tag.value === val))
            }
            getOptionLabel={(tag: TagsAutocompleteOption) => tag.label || tag.value}
            renderTags={(tags: TagsAutocompleteOption[], getTagProps) =>
                tags.map((tag, index) => (
                    <Chip
                        variant={'outlined'}
                        style={{
                            backgroundColor: tag.color ?? undefined,
                            color: tag.color ? tagForegroundForBackground(tag.color) : undefined,
                        }}
                        label={tag.label}
                        {...getTagProps({ index })}
                        key={tag.value}
                    />
                ))
            }
            style={{ width: `100%` }}
            disableClearable
            clearOnEscape
            renderInput={params => (
                <TextField
                    variant={'standard'}
                    {...params}
                    onClick={ev => ev.stopPropagation()}
                    fullWidth
                    InputProps={{ ...params.InputProps, style: { padding: `6px` } }}
                    inputProps={{
                        ...params.inputProps,
                        className: `${(params.inputProps as { className: string }).className} ${
                            classes.autocompleteInput
                        }`,
                    }}
                    placeholder={``}
                />
            )}
        />
    );
};
