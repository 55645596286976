import { isGuidedPreset } from '../GuidedWaxup.util';
import {
    DESKTOP_SCREEN_CONTAINER_STYLES,
    GUIDED_WAXUP_DESKTOP_SCREEN_HEIGHT,
    PRESET_SIDEBAR_WIDTH,
    TIMELINE_AND_IMAGES_SIDEBAR_WIDTH_COLLAPSED,
    TIMELINE_AND_IMAGES_SIDEBAR_WIDTH_EXPANDED,
} from '../VisualConstants.util';
import { GuidedPresetControls, RevisionHistoryTabs } from '../components';
import type { CurrentWaxupModelViewerProps } from '../components/CurrentWaxupModelViewerControls';
import { CurrentWaxupModelViewerAndControls } from '../components/CurrentWaxupModelViewerControls';
import { GuidedWaxupNavArea } from '../components/GuidedWaxupNavArea';
import { GuidedWaxupTimelineAndImagesSidebar } from '../components/GuidedWaxupTimelineAndImagesSidebar';
import { IncompletedWorkReminderModal } from '../components/IncompletedWorkReminderModal';
import { PresetTabsSidebar } from '../components/PresetTabSidebar';
import { SkipToGeneralReviewModal } from '../components/SkipToGeneralReviewModal';
import { useGuidedWaxupContext } from '../state/GuidedWaxupContext';
import { useScrollToContainer } from '../state/GuidedWaxupState';
import { stylesFactory, Grid } from '@orthly/ui-primitives';
import React from 'react';

const useStyles = stylesFactory<{ isSidebarExpanded?: boolean }>(() => ({
    root: {
        flexGrow: 1,
        position: 'relative',
        height: GUIDED_WAXUP_DESKTOP_SCREEN_HEIGHT,
    },
    modelViewerContainer: {
        width: ({ isSidebarExpanded }) =>
            `calc(100dvw - ${
                isSidebarExpanded
                    ? TIMELINE_AND_IMAGES_SIDEBAR_WIDTH_EXPANDED
                    : TIMELINE_AND_IMAGES_SIDEBAR_WIDTH_COLLAPSED
            }px - 230px)`,
        // We add left padding to the model viewer container itself
        // so the margin here is just the width of the sidebar
        marginLeft: PRESET_SIDEBAR_WIDTH,
        ...DESKTOP_SCREEN_CONTAINER_STYLES,
    },
    presetControlsContainer: {
        marginBottom: 20,
    },
    revisionTabsContainer: {
        margin: '20px 20px 5px 20px',
        width: 'fit-content',
    },
}));

interface CurrentWaxupScreenProps extends CurrentWaxupModelViewerProps {
    submit: () => Promise<unknown>;
}

export const CurrentWaxupDesktopScreen: React.VFC<CurrentWaxupScreenProps> = ({ submit }) => {
    const { selectedDesignRevisionId, selectedTab, enableGuidedWaxupComparison, isSidebarExpanded } =
        useGuidedWaxupContext();
    const guidedPreset = isGuidedPreset(selectedTab);
    const scrollableRef = React.useRef<HTMLDivElement | null>(null);
    useScrollToContainer(scrollableRef);
    const classes = useStyles({ isSidebarExpanded });

    return (
        <Grid container className={classes.root}>
            <SkipToGeneralReviewModal />
            <IncompletedWorkReminderModal />
            <PresetTabsSidebar />
            <div
                className={classes.modelViewerContainer}
                key={selectedDesignRevisionId}
                ref={scrollableRef}
                data-testid={'model-viewer-container'}
            >
                {enableGuidedWaxupComparison && <RevisionHistoryTabs className={classes.revisionTabsContainer} />}
                <CurrentWaxupModelViewerAndControls style={{ height: '500px', width: 'auto' }} />
                <Grid
                    item
                    container
                    justifyContent={guidedPreset ? 'space-between' : 'flex-end'}
                    className={classes.presetControlsContainer}
                    key={selectedTab}
                >
                    {guidedPreset && <GuidedPresetControls />}
                </Grid>
                <div style={{ flexGrow: 1 }} />
                <GuidedWaxupNavArea submit={submit} />
            </div>
            <GuidedWaxupTimelineAndImagesSidebar />
        </Grid>
    );
};
