import type {
    SimpleSelectProps,
    SimpleMultiSelectProps,
    SimpleMultiSelectChipsProps,
    SimpleInputProps,
    SimpleAutocompleteProps,
} from '@orthly/ui';
import { SimpleSelect, SimpleMultiSelect, SimpleMultiSelectChips, SimpleInput, SimpleAutocomplete } from '@orthly/ui';
import { FlossPalette, makeStyles, createStyles } from '@orthly/ui-primitives';
import _ from 'lodash';
import React from 'react';
import type { SetOptional } from 'type-fest';

const useStyles = makeStyles(() =>
    createStyles({
        formControl: {
            marginBottom: 0,
            '& > div': {
                borderRadius: 6,
                background: FlossPalette.TAN,
                overflow: 'hidden',
            },
            '& fieldset': {
                border: 'none !important',
            },
        },
        selectRoot: {
            paddingTop: '0 !important',
        },
        textField: {
            overflow: 'hidden',
            fontWeight: 500,
        },
        input: { borderRadius: 6, fontWeight: 400 },
        inputNoLabel: { paddingTop: 2, borderRadius: 6 },
    }),
);

export const AutomationSelectField: React.FC<SimpleSelectProps> = props => {
    const classes = useStyles();
    return (
        <SimpleSelect
            {...props}
            FormControlProps={{
                ...props.FormControlProps,
                classes: { root: classes.formControl },
                variant: 'standard',
            }}
            SelectProps={{
                ...props.SelectProps,
                classes: { select: classes.selectRoot, ...props.SelectProps?.classes },
                variant: 'standard',
            }}
        />
    );
};

export const AutomationAutocompleteField: React.FC<SimpleAutocompleteProps> = props => {
    const classes = useStyles();
    return (
        <SimpleAutocomplete
            {...props}
            AutocompleteProps={{ style: { width: '100%' }, className: classes.formControl, ...props.AutocompleteProps }}
            TextFieldProps={{
                ...props.TextFieldProps,
                classes: { root: classes.textField },
                InputProps: {
                    className: props.label ? classes.input : classes.inputNoLabel,
                    ...props.TextFieldProps?.InputProps,
                },
            }}
        />
    );
};

export const AutomationMultiSelectField: React.FC<SimpleMultiSelectProps> = props => {
    const classes = useStyles();
    return (
        <SimpleMultiSelect
            {...props}
            FormControlProps={{
                ...props.FormControlProps,
                classes: {
                    root: classes.formControl,
                },
                variant: 'standard',
            }}
            SelectProps={{
                ...props.SelectProps,
                classes: { select: classes.selectRoot, ...props.SelectProps?.classes },
                variant: 'standard',
            }}
        />
    );
};

export const AutomationMultiSelectChipsField: React.FC<SimpleMultiSelectChipsProps> = props => {
    const classes = useStyles();
    return (
        <SimpleMultiSelectChips
            {...props}
            FormControlProps={{
                ...props.FormControlProps,
                classes: {
                    root: classes.formControl,
                },
                variant: 'standard',
            }}
            SelectProps={{
                ...props.SelectProps,
                classes: { select: classes.selectRoot, ...props.SelectProps?.classes },
                variant: 'standard',
            }}
        />
    );
};

type CancelableRef = ((newVal?: string) => void) & _.Cancelable;

/** NOTE on the `enableLocalValue` field:
 *  - By default, Automations text fields are slow, because ALL of them go through the
 *    redux reducer at the root of automations. The `enableLocalValue` field unhooks the
 *    `SimpleInput` in the text field component from Redux, allowing instant feedback,
 *    and also preventing the feedback from coming in on a later render and causing
 *    the cursor to be reset.
 *
 *    The following type:
 *    - Requires `value` if `enableLocalValue` is false or not present, because
 *      without using the local value, a value must be provided by the parent.
 *    - Does not require `value` if `enableLocalValue` is true, because the
 *      input element can use the local copy instead.
 */
type AutomationTextFieldProps =
    | (SetOptional<SimpleInputProps, 'label'> & { enableLocalValue?: false | undefined })
    | (SetOptional<SimpleInputProps, 'value' | 'label'> & { enableLocalValue: true });

export const AutomationTextField: React.FC<AutomationTextFieldProps> = props => {
    const classes = useStyles();
    const debouncedOnChange = React.useRef<CancelableRef>(_.debounce(props.onChange));
    const [localValue, setValue] = React.useState<string | undefined>(props.value);

    React.useEffect(() => {
        if (props.enableLocalValue) {
            debouncedOnChange.current = _.debounce(props.onChange, 50);
        } else {
            debouncedOnChange.current = _.debounce(props.onChange);
        }
    }, [props.enableLocalValue, props.onChange]);

    return (
        <SimpleInput
            {...props}
            value={props.enableLocalValue ? localValue : props.value}
            label={props.label ?? ''}
            onChange={newValue => {
                setValue(newValue);

                debouncedOnChange.current.cancel();
                debouncedOnChange.current(newValue);
            }}
            variant={'standard'}
            fullWidth
            TextFieldProps={{
                ...props.TextFieldProps,
                InputProps: {
                    className: props.label ? classes.input : classes.inputNoLabel,
                    ...props.TextFieldProps?.InputProps,
                } as any,
                classes: { ...props.TextFieldProps?.classes, root: classes.textField },
            }}
        />
    );
};
