export async function validateStlFile(file: File): Promise<{ isValid: boolean; errorMessage?: string }> {
    const headerLen = 80;
    const minBufferLength = headerLen + 4; // Minimum buffer length required to read the number of triangles
    const MAX_STL_TRI_SIZE_ALLOWED = 2000000;

    // Use FileReader to read the Blob as an ArrayBuffer
    const arrayBuffer = await new Promise<ArrayBuffer>((resolve, reject) => {
        const reader = new FileReader();
        const slice = file.slice(0, minBufferLength); // Slice the first 80 + 4 bytes from the file

        reader.onload = () => resolve(reader.result as ArrayBuffer);
        reader.onerror = () => reject(new Error('Failed to read file.'));

        reader.readAsArrayBuffer(slice); // Read the slice as an ArrayBuffer
    });

    const buffer = new Uint8Array(arrayBuffer);

    // Convert Uint8Array to Buffer and decode the header
    const headerText = Buffer.from(buffer.slice(0, headerLen)).toString('utf-8');

    // Check if it's an ASCII STL file based on the header
    if (headerText.startsWith('solid')) {
        return { isValid: true };
    }

    // Ensure the buffer is large enough to contain the header and triangle count
    if (buffer.length < minBufferLength) {
        return { isValid: false, errorMessage: 'File is too small to be a valid STL.' };
    }

    // Check if it's a binary STL file by reading the number of triangles
    const numTriangles = new DataView(buffer.buffer).getUint32(headerLen, true);

    // Validate the number of triangles
    if (numTriangles <= 0 || numTriangles >= MAX_STL_TRI_SIZE_ALLOWED) {
        return { isValid: false, errorMessage: 'Invalid number of triangles in STL file.' };
    }

    return { isValid: true };
}
