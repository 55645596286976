import type { InvoiceDataForOverview } from '../types';
import moment from 'moment';

export function activationDate(date: string | null | undefined) {
    if (!date) {
        return 'N/A';
    }
    return moment(date).format('MM/DD/YYYY');
}

export function nextInvoiceDate(willBeInvoiced: boolean, invoices: InvoiceDataForOverview[]) {
    if (!willBeInvoiced) {
        return 'N/A';
    }
    const now = moment();
    // if today is the first of the month, check if they have been invoiced yet
    if (now.date() === 1) {
        const invoiceToday = invoices.find(i => moment(i.created_at).isSame(now, 'day'));
        if (!invoiceToday) {
            return now.format('MMM Do');
        }
    }
    return moment().subtract(1, 'days').add(1, 'month').startOf('month').format('MMM Do');
}
