import * as THREE from 'three';

/** Encapsulates geometry with an optional colorMap. */
export class TexturedModel {
    constructor(
        readonly geometry: THREE.BufferGeometry,
        readonly colorMap?: THREE.Texture,
    ) {}

    /** Returns a new TexturedModel whose geometry is cloned from this one. */
    clone(): TexturedModel {
        return new TexturedModel(this.geometry.clone(), this.colorMap);
    }

    /** Returns a mesh for the geometry with the material supplied by the `makeMaterial` callback. */
    toMesh(
        makeMaterial: (colorMap?: THREE.Texture) => THREE.Material | undefined,
    ): THREE.Mesh<THREE.BufferGeometry, THREE.Material> {
        return new THREE.Mesh(this.geometry, makeMaterial(this.colorMap));
    }
}
