import type { SplineData } from './Types';
import { MarginLineMesh } from '@orthly/dentin';
import { HIGHLIGHT_TOOTH_COLOR, MODEL_GRAY_COLOR } from '@orthly/dentin';
import type { DcmGeometryInjector, FacetPaletteType } from '@orthly/forceps';
import { createBufferGeometryForFacetMarks } from '@orthly/forceps';
import * as THREE from 'three';

export function getMeshFromDcm(
    dcm: DcmGeometryInjector,
    filename: string,
): THREE.Mesh<THREE.BufferGeometry, THREE.Material> {
    const bufferGeom = dcm.buildGeometry({ applyTextureCoords: false });
    bufferGeom.computeVertexNormals();

    const material = new THREE.MeshPhongMaterial({
        color: filename.toLowerCase().includes('scan') ? MODEL_GRAY_COLOR : HIGHLIGHT_TOOTH_COLOR,
        side: THREE.DoubleSide,
    });

    const mesh = new THREE.Mesh(bufferGeom, material);
    mesh.name = filename;
    return mesh;
}

export function getMeshForFacetsFromDcm(
    dcm: DcmGeometryInjector,
    filename: string,
    markPalette: FacetPaletteType,
    targetBit: number,
): THREE.Mesh<THREE.BufferGeometry, THREE.Material> {
    const bufferGeom = createBufferGeometryForFacetMarks(dcm, markPalette, targetBit, { applyTextureCoords: false });
    bufferGeom.computeVertexNormals();

    // vertex color material for facet marks
    const material = new THREE.MeshPhongMaterial({
        vertexColors: true,
        side: THREE.DoubleSide,
    });

    const mesh = new THREE.Mesh(bufferGeom, material);
    mesh.name = filename;
    return mesh;
}

export function addSplinesFromDcm(dcm: DcmGeometryInjector, filename: string, splines: SplineData[]): void {
    const splineOccurrences = new Map<string, number>();

    dcm.parseSplines()
        // We visualize the morph points, which are drawn from the spline data, separately.
        .filter(dcmSpline => !dcmSpline.name.startsWith('MorphPoints') && dcmSpline.points.length > 0)
        .forEach(dcmSpline => {
            let name = `${filename}: ${dcmSpline.name}`;
            if (splineOccurrences.has(dcmSpline.name)) {
                const currIndex = splineOccurrences.get(dcmSpline.name) as number;
                name = name.concat(' ', currIndex.toString());
                splineOccurrences.set(dcmSpline.name, currIndex + 1);
            } else {
                splineOccurrences.set(dcmSpline.name, 1);
            }

            // Duplicate the first point to close the loop.
            const points = [...dcmSpline.points, dcmSpline.points[0] as THREE.Vector3];

            const color = getSplineColor(splines.length);

            splines.push({
                name,
                color,
                mesh: new MarginLineMesh(points, { color }),
            });
        });
}

const SPLINE_COLORS: Readonly<string[]> = ['#ff0000', '#00ff00', '#0000ff', '#ffff00', '#ff00ff', '#00ffff', '#000000'];

function getSplineColor(index: number): string {
    return SPLINE_COLORS[index % SPLINE_COLORS.length] as string;
}
