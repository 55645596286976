import * as THREE from 'three';

export type DepthMapParams = {
    renderer: THREE.WebGLRenderer;
    resolution: number;
};

const DEFAULT_RESOLUTION = 2048 as const;

export class DepthMapComputer {
    private _renderer: THREE.WebGLRenderer;
    private _target: THREE.WebGLRenderTarget;
    private _camera: THREE.OrthographicCamera;
    private _scene: THREE.Scene = new THREE.Scene();
    private _depthData: Float32Array;
    private _depthTexture: THREE.DataTexture;
    private _resolution: number;
    private _ownsRenderer: boolean;
    private _disposed: boolean = false;

    constructor(geom: readonly THREE.Mesh[], params?: Partial<DepthMapParams>) {
        this._renderer = params?.renderer ?? new THREE.WebGLRenderer({ antialias: false });
        this._ownsRenderer = params?.renderer === undefined;
        const depthMat = new THREE.MeshDepthMaterial();
        const group = new THREE.Group();
        const bbox = new THREE.Box3();
        geom.forEach(mesh => {
            const child = new THREE.Mesh(mesh.geometry, depthMat);
            mesh.matrixWorld.decompose(child.position, child.quaternion, child.scale);
            group.add(child);
        });
        bbox.expandByObject(group);
        this._scene.add(group);
        this._camera = new THREE.OrthographicCamera(
            bbox.min.x,
            bbox.max.x,
            bbox.min.y,
            bbox.max.y,
            bbox.min.z,
            bbox.max.z,
        );
        this._scene.add(this._camera);
        this._resolution = params?.resolution ?? DEFAULT_RESOLUTION;
        this._target = new THREE.WebGLRenderTarget(this._resolution, this._resolution, {
            format: THREE.RedFormat,
            type: THREE.FloatType,
            minFilter: THREE.NearestFilter,
            magFilter: THREE.NearestFilter,
        });
        this._depthData = new Float32Array(this._resolution * this._resolution);
        this._depthTexture = new THREE.DataTexture(
            this._depthData,
            this._resolution,
            this._resolution,
            THREE.RedFormat,
            THREE.FloatType,
        );
    }

    get texture() {
        return this._depthTexture;
    }

    get camera() {
        return this._camera;
    }

    compute(): void {
        if (this._disposed) {
            throw new Error('Attempted to compute on a disposed instance.');
        }

        if (this._ownsRenderer && this._renderer.getContext().isContextLost()) {
            console.warn('Renderer context was lost. Reinitializing new renderer.');
            this._renderer.dispose();

            this._renderer = new THREE.WebGLRenderer({ antialias: false });
        }

        this._renderer.setRenderTarget(this._target);
        this._renderer.render(this._scene, this._camera);
        try {
            this._renderer.readRenderTargetPixels(
                this._target,
                0,
                0,
                this._resolution,
                this._resolution,
                this._depthData,
            );
            this._depthTexture.needsUpdate = true;
        } catch (error) {
            console.error('computeDepthMap readRenderPixels error', error);
        }
    }

    dispose(): void {
        if (this._ownsRenderer) {
            this._renderer.forceContextLoss();
            this._renderer.dispose();
        }
        this._target.dispose();

        this._depthTexture.dispose();
        this._disposed = true;
    }
}
