import { Format } from '@orthly/runtime-utils';
import { useSession } from '@orthly/session-client';
import { SimpleCheckbox, SimpleInput } from '@orthly/ui';
import { FlossPalette, Button, Collapse, Grid, Text } from '@orthly/ui-primitives';
import _ from 'lodash';
import React from 'react';

type SendBillingSummaryEmailsProps = {
    // returns a string if there's a reason for disabling sending
    getReasonCannotSend?: (overrideEmail?: string) => string | undefined;
    send: (overrideEmail?: string) => void;
    count: number;
};

export const SendBillingSummaryEmailsForm: React.FC<SendBillingSummaryEmailsProps> = props => {
    const { getReasonCannotSend, send: sendFn, count } = props;
    const session = useSession();
    const currentUserEmail = session?.user.email;
    const [shouldOverrideEmail, setShouldOverrideEmail] = React.useState<boolean>(false);
    const [overrideEmail, setOverrideEmail] = React.useState<string | undefined>(currentUserEmail);
    const targetEmail = shouldOverrideEmail && overrideEmail ? overrideEmail : undefined;
    const reasonCannotSend = getReasonCannotSend?.(targetEmail);
    return (
        <Grid container>
            <Grid container>
                <SimpleCheckbox
                    checked={shouldOverrideEmail}
                    setChecked={setShouldOverrideEmail}
                    label={'Override Email Recipient?'}
                />
            </Grid>
            <Collapse in={shouldOverrideEmail} style={{ width: '100%' }}>
                <Grid container style={{ paddingTop: 8 }}>
                    <SimpleInput fullWidth onChange={setOverrideEmail} value={overrideEmail} label={'Send to'} />
                </Grid>
            </Collapse>
            <Grid container style={{ padding: '16px 0' }}>
                {!!reasonCannotSend && (
                    <Text style={{ width: '100%', textAlign: 'center', padding: 8 }}>
                        Cannot send:{' '}
                        <span style={{ color: FlossPalette.ATTENTION }}>{_.startCase(reasonCannotSend)}</span>
                    </Text>
                )}
                <Button
                    onClick={() => sendFn(targetEmail)}
                    disabled={!!reasonCannotSend}
                    fullWidth
                    variant={'contained'}
                >
                    Send {Format.pluralize('email', count)} to {targetEmail ?? 'partners'}
                </Button>
            </Grid>
        </Grid>
    );
};
