import { mapStyles, useAddressLatLng } from '../../util';
import { RemoveItemModal } from './RemoveItemModal';
import { useDeliveryAddressControls } from './useDeliveryAddressControls';
import type { LabsGqlDoctorDeliveryAddressFragment } from '@orthly/graphql-operations';
import { useLabsAddresses } from '@orthly/graphql-react';
import { Text, FlossPalette, stylesFactory, Grid } from '@orthly/ui-primitives';
import { GoogleMap, Marker } from '@react-google-maps/api';
import React from 'react';

const useStyles = stylesFactory(() => ({
    addressBase: { display: 'flex', padding: '20px 12px' },
    addressGrid: { padding: 12 },
    root: {
        backgroundColor: FlossPalette.TAN,
        display: 'flex',
        flexDirection: 'column',
        borderRadius: '16px',
        marginBottom: '12px',
        padding: '24px 0',
    },
    addressBlock: { maxWidth: '60%' },
    addressLine: { fontWeight: 600, overflow: 'scroll', whiteSpace: 'nowrap' },
    container: { display: 'flex', justifyContent: 'space-between', padding: '0 24px 16px' },
    mapContainer: { display: 'flex', justifyContent: 'center', padding: '0 24px' },
}));

interface DeliveryAddressProps {
    address: LabsGqlDoctorDeliveryAddressFragment;
    partnerId: string;
}

const DeliveryAddressBase: React.FC<DeliveryAddressProps> = props => {
    const classes = useStyles();
    const { address } = props;
    const { submitDelete } = useDeliveryAddressControls();
    const displayAddress = React.useMemo(() => {
        let line1 = address.street_one;
        if (address.street_two) {
            line1 += ` - ${address.street_two}`;
        }
        const line2 = `${address.city}, ${address.state} ${address.postal_code}`;
        return { line1, line2 };
    }, [address]);
    const latLong = useAddressLatLng(address);
    const userAgent = navigator.userAgent;
    // MSIE for IE 10 or older
    const msie = userAgent.indexOf('MSIE ');
    // Trident for IE 11
    const trident = userAgent.indexOf('Trident/');
    // isIE is true for IE 11 or older
    const isIE = msie > 0 || trident > 0;

    return (
        <div className={classes.root}>
            <Grid container alignContent={'flex-start'} className={classes.container}>
                <Grid item className={classes.addressBlock}>
                    <Text variant={'body2'} className={classes.addressLine} data-test={'display-address-line-1'}>
                        {displayAddress.line1}
                    </Text>
                    <Text
                        color={'DARK_GRAY'}
                        variant={'caption'}
                        className={classes.addressLine}
                        data-test={'display-address-line-2'}
                    >
                        {displayAddress.line2}
                    </Text>
                </Grid>
                <Grid item>
                    <RemoveItemModal
                        title={`You're about to remove an address`}
                        subtitle={`Are you sure you want to remove ${displayAddress.line1} from your addresses?`}
                        confirmRemoveText={'Yes, remove address'}
                        removeItem={() => submitDelete({ addressId: address.id, isDeleted: true })}
                    />
                </Grid>
            </Grid>
            {!isIE && (
                <Grid item className={classes.mapContainer}>
                    <GoogleMap
                        mapContainerStyle={{ width: '100%', height: '120px', borderRadius: 12 }}
                        options={{
                            styles: mapStyles,
                            fullscreenControl: false,
                        }}
                        zoom={15}
                        center={latLong}
                    >
                        {latLong && <Marker position={latLong} />}
                    </GoogleMap>
                </Grid>
            )}
        </div>
    );
};

export const DeliveryAddresses: React.FC<{ partnerId: string }> = props => {
    const classes = useStyles();
    const { addresses } = useLabsAddresses({ variables: { partner_id: props.partnerId } });
    const activeAddresses = (addresses || []).filter(a => a.deleted_at === null);

    return (
        <Grid container className={classes.addressBase}>
            {activeAddresses.map(addr => (
                <Grid item key={addr.id} xs={12} md={6} lg={4} className={classes.addressGrid}>
                    <DeliveryAddressBase address={addr} partnerId={props.partnerId} />
                </Grid>
            ))}
        </Grid>
    );
};
