import { useFirebaseFileDownload } from '../../hooks/useFirebaseFileDownload.graphql';
import { OrderDownloadFilesUtils } from '../../util';
import { BulkDownloadDialog } from '../BulkDownloadDialog/BulkDownloadDialog';
import { BrowserAnalyticsClientFactory } from '@orthly/analytics/dist/browser';
import type { DandyAnalyticsEventSchemaType } from '@orthly/analytics/dist/common';
import type { LabsGqlScanFileForOrderFragment, LabsGqlLabOrderFragment } from '@orthly/graphql-operations';
import { useRetainerToken } from '@orthly/session-client';
import { DateUtils } from '@orthly/shared-types';
import { LoadBlockerLoader, OrthlyErrorBoundary, RootActionDialog, SimpleSelect } from '@orthly/ui';
import { Text, Button, Grid, MenuItem } from '@orthly/ui-primitives';
import _ from 'lodash';
import type { ReactNode } from 'react';
import React from 'react';

export interface DownloadItemConfirmationModalProps {
    onClose: () => void;
    onConfirm: () => void;
    orderId: string;
}

interface DownloadItemProps {
    url?: string | null;
    filename?: string;
    title: ReactNode;
    orderId: string;
    actionName: DandyAnalyticsEventSchemaType['All - Portal - Order Files Menu Used']['action'];
    ConfirmationModal?: React.VFC<DownloadItemConfirmationModalProps>;
}

export const DownloadItem: React.FC<DownloadItemProps> = ({
    url,
    filename,
    title,
    orderId,
    actionName,
    ConfirmationModal,
}) => {
    const downloader = useFirebaseFileDownload(url || ``, filename || ``);
    const download = React.useCallback(() => downloader.execute(), [downloader]);

    const [isConfirmationOpen, setIsConfirmationOpen] = React.useState<boolean>(false);

    const rawDownload = React.useCallback(() => {
        setIsConfirmationOpen(false);

        BrowserAnalyticsClientFactory.Instance?.track('All - Portal - Order Files Menu Used', {
            $groups: { order: orderId },
            action: actionName,
        });

        void download();
    }, [download, orderId, actionName, setIsConfirmationOpen]);

    return (
        <>
            {!!ConfirmationModal && isConfirmationOpen && (
                <ConfirmationModal
                    orderId={orderId}
                    onClose={() => setIsConfirmationOpen(false)}
                    onConfirm={rawDownload}
                />
            )}
            <MenuItem
                disabled={!url}
                onClick={() => {
                    // If a confirmation modal is provided, we will now open it instead of doing our raw download directly.
                    if (!!ConfirmationModal) {
                        setIsConfirmationOpen(true);
                    } else {
                        rawDownload();
                    }
                }}
            >
                {title}
                <LoadBlockerLoader blocking={downloader.loading} />
            </MenuItem>
        </>
    );
};

const OrderAlreadyPreppedModal: React.VFC<DownloadItemConfirmationModalProps> = ({ onClose, onConfirm, orderId }) => {
    const [reason, setReason] = React.useState<string | undefined>(undefined);

    return (
        <RootActionDialog
            title={'A prep file for this case exists'}
            loading={false}
            open={true}
            setOpen={open => {
                if (!open) {
                    onClose();
                }
            }}
            CustomButton={() => null}
            content={
                <Grid container direction={'column'} spacing={4}>
                    <Grid item>
                        <Text variant={'body1'} color={'GRAY'}>
                            You have selected to download the original scans but the prep file already exists for this
                            case.
                        </Text>
                    </Grid>
                    <Grid item>
                        <SimpleSelect
                            label={'Select reason for downloading the original scans'}
                            value={reason}
                            options={[{ value: 'Prep file is incorrect' }, { value: 'Checking for differences' }]}
                            onChange={setReason}
                        />
                    </Grid>
                    <Grid item container direction={'row'}>
                        <Grid item>
                            <Button variant={'secondary'} onClick={onClose}>
                                Cancel
                            </Button>
                        </Grid>
                        <Grid item xs />
                        <Grid item>
                            <Button
                                variant={'primary'}
                                onClick={() => {
                                    // Should never happen, but type safety is king.
                                    if (!reason) {
                                        return;
                                    }

                                    BrowserAnalyticsClientFactory.Instance?.track(
                                        'All - Portal - Scans Downloaded When Prepped Design Exists',
                                        {
                                            reason,
                                            $groups: { order: orderId },
                                        },
                                    );

                                    onConfirm();
                                }}
                                disabled={!reason}
                            >
                                Download original
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            }
        />
    );
};

interface ScanDownloadItemEditedProps {
    order: Pick<LabsGqlLabOrderFragment, 'id' | 'order_number'>;
    scan: LabsGqlScanFileForOrderFragment;
    prefix: string;
    suffix: string;
    ConfirmationModal?: React.VFC<DownloadItemConfirmationModalProps>;
}

const ScanDownloadItemEdited: React.VFC<ScanDownloadItemEditedProps> = ({
    order,
    scan,
    prefix,
    suffix,
    ConfirmationModal,
}) => {
    const { retainerToken } = useRetainerToken();

    const [isDownloadDialogOpen, setDownloadDialogOpen] = React.useState(false);
    const [isConfirmationOpen, setIsConfirmationOpen] = React.useState<boolean>(false);

    // We use a compact array that's joined here to avoid adding extra spaces to the beginning or end of the string.
    const fileName = `${_.compact([prefix, `Order`, order.order_number, suffix]).join(' ')}.3oxz`;

    const rawDownload = React.useCallback(() => {
        BrowserAnalyticsClientFactory.Instance?.track('All - Portal - Order Files Menu Used', {
            $groups: { order: order.id },
            action: 'download_edited_3oxz',
        });

        setIsConfirmationOpen(false);
        setDownloadDialogOpen(true);
    }, [setDownloadDialogOpen, setIsConfirmationOpen, order.id]);

    return (
        <>
            {!!ConfirmationModal && isConfirmationOpen && (
                <ConfirmationModal
                    orderId={order.id}
                    onClose={() => setIsConfirmationOpen(false)}
                    onConfirm={rawDownload}
                />
            )}
            <MenuItem
                style={{ position: 'relative' }}
                onClick={() => {
                    if (!!ConfirmationModal) {
                        setIsConfirmationOpen(true);
                    } else {
                        rawDownload();
                    }
                }}
            >
                {prefix}3oxz{suffix}
            </MenuItem>

            <BulkDownloadDialog
                open={isDownloadDialogOpen}
                setOpen={setDownloadDialogOpen}
                files={
                    retainerToken
                        ? [
                              {
                                  source: 'external',
                                  path: OrderDownloadFilesUtils.getSanitizedScanUrl({
                                      retainerToken,
                                      orderId: order.id,
                                      scanExportId: scan.id,
                                  }),
                                  name: fileName,
                              },
                          ]
                        : []
                }
            />
        </>
    );
};

interface ScanDownloadItemsProps {
    order: Pick<LabsGqlLabOrderFragment, 'id' | 'order_number'>;
    scan: LabsGqlScanFileForOrderFragment;
    hasOrderBeenPrepped: boolean;
    replacement?: boolean;
}

export const ScanDownloadItems: React.FC<ScanDownloadItemsProps> = ({
    order,
    scan,
    hasOrderBeenPrepped,
    replacement = false,
}) => {
    const prefix = replacement ? `Replaced ` : ``;
    const suffix = replacement ? ` (${DateUtils.format(scan.created_at)})` : ``;

    return (
        <OrthlyErrorBoundary>
            <DownloadItem
                url={scan.stl_url}
                filename={`${prefix}${order.order_number}${suffix}_stl`}
                title={`${prefix}STL${suffix}`}
                orderId={order.id}
                actionName={'download_stl'}
                ConfirmationModal={hasOrderBeenPrepped ? OrderAlreadyPreppedModal : undefined}
            />
            <ScanDownloadItemEdited
                order={order}
                scan={scan}
                prefix={prefix}
                suffix={suffix}
                ConfirmationModal={hasOrderBeenPrepped ? OrderAlreadyPreppedModal : undefined}
            />
        </OrthlyErrorBoundary>
    );
};
