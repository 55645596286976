import { useSplitsSelector } from '../state/Splits.context';
import type { LabsGqlOrganizationDtoFragment } from '@orthly/graphql-operations';
import type { RootActionDialogProps } from '@orthly/ui';
import { QuickForm, RootActionDialog } from '@orthly/ui';
import { Button, AddIcon } from '@orthly/ui-primitives';
import React from 'react';

interface AddSplitParams {
    manufacturerId: string;
    designDays: number;
    fabricationDays: number;
    bufferDays: number;
}

export type AddSplitActionProps = {
    manufacturers: ReadonlyArray<LabsGqlOrganizationDtoFragment>;
    onSubmit: (args: AddSplitParams) => void;
    dialogProps?: Partial<RootActionDialogProps>;
};

export const AddSplitAction: React.VFC<AddSplitActionProps> = props => {
    const { onSubmit, dialogProps, manufacturers } = props;
    const existingManufacturers = useSplitsSelector(s => s.splits?.map(s => s.manufacturerId) ?? []);
    const [isOpen, setIsOpen] = React.useState(false);
    const unassignedManufacturers = React.useMemo(() => {
        return manufacturers.filter(m => !existingManufacturers.includes(m.id));
    }, [existingManufacturers, manufacturers]);
    return (
        <RootActionDialog
            title={'Add Split'}
            open={isOpen}
            setOpen={setIsOpen}
            loading={false}
            content={
                <QuickForm<AddSplitParams>
                    fields={{
                        manufacturerId: {
                            type: 'select',
                            label: 'Which lab should this split be assigned to?',
                            options: unassignedManufacturers.map(m => ({ label: m.name, value: m.id })),
                        },
                        designDays: { type: 'number', label: 'How many design days are required?' },
                        fabricationDays: { type: 'number', label: 'How many fabrication days are required?' },
                        bufferDays: { type: 'number', label: 'How many buffer days are required?' },
                    }}
                    initialValues={{}}
                    onSubmit={data => {
                        onSubmit(data);
                        setIsOpen(false);
                    }}
                    submitButtonProps={{ children: 'Create New Split' }}
                />
            }
            buttonText={''}
            CustomButton={props => (
                <Button {...props} disabled={unassignedManufacturers.length === 0} variant={'ghost'}>
                    <AddIcon /> Add Split
                </Button>
            )}
            {...dialogProps}
        />
    );
};
