import { RuleTableDialog } from '../../../../components/RuleTable/RuleTableDialog';
import { RoutingRuleUtils } from '../../components/RoutingRule.util';
import { useCreateCapacityRuleSubmitter } from './CapacityRule.hooks';
import { CapacityRuleForm } from './CapacityRuleForm';
import { CapacityRuleRowActions } from './CapacityRuleRowActions';
import type { LabsGqlCapacityRuleFragment } from '@orthly/graphql-operations';
import { useCapacityRulesForManufacturerQuery, useListLabOrgs } from '@orthly/graphql-react';
import MUITable from '@orthly/mui-table';
import { Grid } from '@orthly/ui-primitives';
import React from 'react';

export const CapacityRuleList: React.VFC = () => {
    const {
        data: { capacityRulesForManufacturer: capacityRules } = {},
        loading: loadingRules,
        refetch,
    } = useCapacityRulesForManufacturerQuery({
        fetchPolicy: 'cache-and-network',
    });
    const { data: { listOrganizations: labs } = {}, loading: loadingLabs } = useListLabOrgs({
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first',
    });
    const rows = React.useMemo(
        () =>
            (capacityRules ?? []).map(rule => ({
                ...rule,
                manufacturer_name: labs?.find(({ id }) => id === rule.manufacturer_id)?.name ?? undefined,
            })),
        [capacityRules, labs],
    );
    const loading = loadingRules || loadingLabs;
    const [openAddDialog, setOpenAddDialog] = React.useState(false);

    const { submit: submitCapacityRule, submitting: submittingCapacityRule } = useCreateCapacityRuleSubmitter({
        onComplete: async () => {
            await refetch();
            setOpenAddDialog(false);
        },
    });

    return (
        <Grid container>
            <Grid item xs={12}>
                <MUITable<LabsGqlCapacityRuleFragment & { manufacturer_name?: string }>
                    title={`Capacity Rules`}
                    data={rows}
                    loading={loading}
                    actions={{
                        global: [
                            { icon: 'add', position: 'toolbar', onClick: () => setOpenAddDialog(true) },
                            { icon: 'refresh', position: 'toolbar', onClick: () => refetch().catch(console.error) },
                        ],
                    }}
                    displayOptions={{ download: true, filter: true, sort: true, filterValues: true }}
                    columns={[
                        ...RoutingRuleUtils.getCapacityListColumns(),
                        {
                            title: 'Disabled?',
                            name: 'actions',
                            field: 'disabled',
                            render: row => <CapacityRuleRowActions row={row} refetch={refetch} />,
                            filter: true,
                            sort: false,
                            customFilterFn: (values, row) => values.includes(`${row.disabled}`),
                            type: 'boolean',
                            filterOptions: {
                                defaultValues: ['false'],
                            },
                        },
                    ]}
                />
                <RuleTableDialog
                    open={openAddDialog}
                    setOpen={setOpenAddDialog}
                    form={
                        <CapacityRuleForm
                            loading={submittingCapacityRule}
                            onSubmit={values => {
                                submitCapacityRule({ ...values }).catch(console.error);
                            }}
                        />
                    }
                    title={'Add Capacity Rule'}
                />
            </Grid>
        </Grid>
    );
};
