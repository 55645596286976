/* eslint-disable max-lines */
import { PrintTable } from '../../../../../../components/PrintTable';
import { AnalyticsClient } from '../../../../../../utils/analyticsClient';
import { useOrderDetailContext } from '../../state/OrderDetailProvider.graphql';
import type { MenuItemProps } from './OrderDetailToolbarFiles.types';
import { NonProdQuickAddDesignAction } from './OrderDetailToolbarFilesActions/NonProdQuickAddDesignAction.graphql';
import { ReplaceInjectionMoldFilesMenuItem } from './OrderDetailToolbarFilesActions/ReplaceInjectionMoldFilesMenuItem';
import { SkipAutomateReviewTaskMenuItem } from './SkipAutomateReviewTaskMenuItem.graphql';
import { DownloadUtils } from '@orthly/dentin';
import type {
    LabsGqlAttachScanStlsMutationVariables,
    LabsGqlReplaceThreeoxzMutationVariables,
} from '@orthly/graphql-operations';
import {
    useAttachScanStlsMutation,
    useRefreshScanImagesMutation,
    useReplaceThreeoxzMutation,
    useSchedule3oxzConvertMutation,
    useSendOrderToAutomateMutation,
    useRerunDesignConversionMutation,
    useCancelOrderAutomateTaskMutation,
    useGenerateEmptyDesignFileMutation,
    useScanWithAssetsQuery,
} from '@orthly/graphql-react';
import { LabsGqlLabOrderStatus } from '@orthly/graphql-schema';
import { OrderItemV2Utils } from '@orthly/items';
import { getFullStoragePath, DesignStorageConfigs, OrderingStorageConfigs } from '@orthly/shared-types';
import {
    LoadBlocker,
    LoadBlockerLoader,
    QuickForm,
    RootActionDialog,
    useChangeSubmissionFn,
    useRootActionCommand,
    OrthlyBrowserConfig,
} from '@orthly/ui';
import { Button, Divider, Grid, MenuItem } from '@orthly/ui-primitives';
import type { FileUploaderFieldResult, FileUploaderProps } from '@orthly/veneer';
import {
    useFirebaseStorage,
    firebaseDownloadFile,
    getFirebaseDownloadUrl,
    OrderFilesMenu,
    UploadDesignFileAction,
    useFirebasePreview,
    FileUploader,
    getQFUploadFieldDef,
    useFeatureFlag,
    ScanExportAssetUtils,
} from '@orthly/veneer';
import axios from 'axios';
import _ from 'lodash';
import moment from 'moment';
import { basename } from 'path-browserify';
import React from 'react';

// TODO: Replace this with the real scanner id if we need to.
// This ID is used for regenerating 3oxz's, which is only used for local development right now.
// Fetching the real scanner id is a bit of a pain, so leaving as a constant until we are sure we need it.
const _DEFAULT_SCANNER_ID = '00000000-0000-0000-0000-000000000000';

const RunSTLConvertMenuItem: React.FC<MenuItemProps> = props => {
    const { order } = props;
    const { submit, submitting } = useRootActionCommand(useSchedule3oxzConvertMutation(), {
        successMessage: '3oxz conversion scheduled, check back later... (and pray)',
        onSuccess: () => props.onClose(),
    });

    return (
        <>
            <MenuItem
                disabled={submitting}
                onClick={() => {
                    AnalyticsClient.track('All - Portal - Order Files Menu Used', {
                        $groups: { order: order.id },
                        action: 'run_stl_convert',
                    });

                    void submit({ scanId: order.scan_export_id });
                }}
            >
                Run STL Convert
            </MenuItem>
            <LoadBlockerLoader blocking={submitting} />
        </>
    );
};

const ReRunDesignConversionMenuItem: React.FC<MenuItemProps> = props => {
    const { order } = props;
    const { submit, submitting } = useRootActionCommand(useRerunDesignConversionMutation(), {
        successMessage: 'Design conversion scheduled:  Check back soon',
        onSuccess: () => props.onClose(),
    });

    const { value: isEnabled } = useFeatureFlag('rerunDesignConversionEnabled');

    if (!order.design_file_path || !isEnabled) {
        return null;
    }

    return (
        <>
            <MenuItem
                disabled={submitting}
                onClick={() => {
                    AnalyticsClient.track('All - Portal - Order Files Menu Used', {
                        $groups: { order: order.id },
                        action: 'rerun_design_conversion',
                    });
                    void submit({
                        order_id: order.id,
                        revision_id: null,
                        force_temporal_workflow: null,
                    });
                }}
            >
                Reconvert Design
            </MenuItem>
            <LoadBlockerLoader blocking={submitting} />
        </>
    );
};

const GenerateEmptyDesignMenuItem: React.FC<MenuItemProps> = props => {
    const { order } = props;
    const firebase = useFirebaseStorage();

    const { submit, submitting } = useRootActionCommand(useGenerateEmptyDesignFileMutation(), {
        successMessage: 'Successfully generated design',
        onSuccess: async res => {
            const gcsPath = res.data.generateEmptyDesignFile;

            await firebaseDownloadFile(firebase, gcsPath, _.last(gcsPath.split('/')));
        },
    });

    const { value: isEnabled } = useFeatureFlag('designGenerationGenerateEmptyDesignEnabled');

    if (!isEnabled) {
        return null;
    }

    return (
        <>
            <MenuItem
                disabled={submitting}
                onClick={() => {
                    AnalyticsClient.track('All - Portal - Order Files Menu Used', {
                        $groups: { order: order.id },
                        action: 'generate_empty_design',
                    });

                    void submit({ order_id: order.id });
                }}
            >
                Generate Empty Design
            </MenuItem>
            <LoadBlockerLoader blocking={submitting} />
        </>
    );
};

const RegenerateThreeoxzMenuItem: React.FC<MenuItemProps> = props => {
    const { order } = props;
    const firebase = useFirebaseStorage();
    const [processing, setProcessing] = React.useState<boolean>(false);

    const regenerateDesign = React.useCallback(async () => {
        if (!order.scan_export.sanitized_url) {
            return;
        }

        setProcessing(true);

        try {
            const downloadUrl = await getFirebaseDownloadUrl(firebase, order.scan_export.sanitized_url);
            const downloadResult = await axios.get<ArrayBuffer>(downloadUrl, { responseType: 'arraybuffer' });
            const buffer = downloadResult?.data;

            const { ThreeOxzReloader } = await import('@orthly/forceps');

            const reloader = await ThreeOxzReloader.loadThreeoxz(
                {
                    order_id: order.id,
                    patient: {
                        ...order.patient,
                        last_name: `(${order.order_number})`,
                    },
                    items: OrderItemV2Utils.parseItems(order.items_v2),
                    scanner_id: _DEFAULT_SCANNER_ID,
                    doctor_preferences_id: order.doctor_preferences_id,
                },
                buffer,
            );

            const regenerated = await reloader.regenerate();

            await regenerated.generateAsync({ type: 'blob' }).then((res: Blob) => {
                DownloadUtils.downloadBlob(
                    res,
                    `Order ${order.order_number} ${moment().format('MMDDYYYY_hhmmss')}.3oxz`,
                );
            });
        } catch (err) {
            console.error(err);
        } finally {
            setProcessing(false);
        }
    }, [order, firebase]);

    const { value: isEnabled } = useFeatureFlag('threeoxzEditingRegenerateButtonEnabled');

    if (!order.scan_export.sanitized_url || !isEnabled) {
        return null;
    }

    return (
        <>
            <MenuItem
                disabled={processing}
                onClick={() => {
                    void regenerateDesign();
                }}
            >
                Regenerate 3oxz
            </MenuItem>
            <LoadBlockerLoader blocking={processing} />
        </>
    );
};

const SendToAutomateMenuItem: React.FC<MenuItemProps> = props => {
    const { order } = props;
    const { submit, submitting } = useRootActionCommand(useSendOrderToAutomateMutation(), {
        successMessage: 'Successfully sent to automate!',
        onSuccess: () => props.onClose(),
    });

    const { value: isEnabled } = useFeatureFlag('threeshapeAutomateOrderSubmissionFlowEnabled');

    if (!isEnabled) {
        return null;
    }

    return (
        <>
            <MenuItem
                disabled={submitting}
                onClick={() => {
                    void submit({ order_id: order.id });
                }}
            >
                Send to Automate
            </MenuItem>
            <LoadBlockerLoader blocking={submitting} />
        </>
    );
};

const CancelAutomateTaskMenuItem: React.FC<MenuItemProps> = props => {
    const { order } = props;
    const { submit, submitting } = useRootActionCommand(useCancelOrderAutomateTaskMutation(), {
        successMessage: 'Successfully canceled automate task!',
        onSuccess: () => props.onClose(),
    });

    const { value: isEnabled } = useFeatureFlag('threeshapeAutomateOrderSubmissionFlowEnabled');

    if (!isEnabled || order.fulfillment_workflow.active_task?.type !== 'AutomateDesign') {
        return null;
    }

    return (
        <>
            <MenuItem
                disabled={submitting}
                onClick={() => {
                    void submit({ order_id: order.id });
                }}
            >
                Cancel Automate Task
            </MenuItem>
            <LoadBlockerLoader blocking={submitting} />
        </>
    );
};

const RefreshScanImagesMenuItem: React.FC<MenuItemProps> = props => {
    const { order } = props;
    const scan = order.scan_export;
    const [submitMtn] = useRefreshScanImagesMutation({ variables: { processedScanId: order.scan_export_id } });
    const { submit, submitting } = useChangeSubmissionFn(() => submitMtn(), {
        closeOnComplete: true,
        successMessage: () => ['Scan images refreshed', {}],
        onSuccess: () => props.onClose(),
    });
    // can only refresh images for processed scans
    if (!scan?.file_url || !order) {
        return null;
    }
    return (
        <>
            <MenuItem
                disabled={submitting}
                onClick={() => {
                    AnalyticsClient.track('All - Portal - Order Files Menu Used', {
                        $groups: { order: order.id },
                        action: 'refresh_scan_images',
                    });

                    void submit();
                }}
            >
                Refresh Scan Images
            </MenuItem>
            <LoadBlockerLoader blocking={submitting} />
        </>
    );
};

const ReplaceThreeoxzMenuItem: React.FC<MenuItemProps> = props => {
    type Vars = LabsGqlReplaceThreeoxzMutationVariables['data'];
    const { order } = props;
    const [submitMtn] = useReplaceThreeoxzMutation();
    const mtnSubmitter = (data: Vars) => submitMtn({ variables: { data } });
    const { open, setOpen, submit, submitting } = useChangeSubmissionFn<any, [Vars]>(mtnSubmitter, {
        closeOnComplete: true,
        successMessage: () => ['Threeoxz replaced!', {}],
        onSuccess: async () => {
            await props.refetchOrder();
            props.onClose();
        },
    });
    const firebasePreview = useFirebasePreview(order.scan_export.file_url ?? undefined);
    if ([LabsGqlLabOrderStatus.Cancelled, LabsGqlLabOrderStatus.NeedsRefabrication].includes(order.status)) {
        return null;
    }
    const storagePathConfig = getFullStoragePath(OrthlyBrowserConfig.env, OrderingStorageConfigs.scans, order.id);
    return (
        <RootActionDialog
            loading={submitting}
            open={open}
            setOpen={newOpen => {
                setOpen(newOpen);
                !newOpen && props.onClose();
            }}
            title={'Replace .3oxz File'}
            content={
                <QuickForm<{ file_url: string }>
                    fields={{
                        file_url: getQFUploadFieldDef({
                            fileField: { fieldKey: '.30xzScan' },
                            label: 'Attachment',
                            storagePathConfig: storagePathConfig,
                        }),
                    }}
                    initialValues={{}}
                    onSubmit={result => {
                        AnalyticsClient.track('All - Portal - Order Files Menu Used', {
                            $groups: { order: order.id },
                            action: 'replace_3oxz_file',
                        });

                        void submit({
                            order_id: order.id,
                            new_3oxz_gcs_path: result.file_url,
                            old_3oxz_preview_link: firebasePreview?.result ?? null,
                        });
                    }}
                />
            }
            buttonText={'Replace .3oxz'}
            CustomButton={() => <MenuItem onClick={() => setOpen(true)}>Replace .3oxz File</MenuItem>}
        />
    );
};

const UploadDesignFilesMenuItem: React.FC<MenuItemProps> = props => {
    return (
        <UploadDesignFileAction
            CustomButton={buttonProps => <MenuItem onClick={() => buttonProps.onClick()}>{buttonProps.text}</MenuItem>}
            order={props.order}
            refetchOrder={props.refetchOrder}
            onClose={props.onClose}
            onOpen={() => {
                AnalyticsClient.track('All - Portal - Order Files Menu Used', {
                    $groups: { order: props.order.id },
                    action: 'replace_design_file',
                });
            }}
        />
    );
};

type STLFileKeys = 'StlsZip' | 'UpperSTL1' | 'UpperSTL2' | 'LowerSTL1' | 'LowerSTL2' | 'BiteSTL1' | 'BiteSTL2';
type STLType = 'upper' | 'lower' | 'bite';

function getUploadedPathsForSTLType(files: FileUploaderFieldResult<STLFileKeys>[], stlType: STLType) {
    const keysByStlType: { [K in STLType]-?: STLFileKeys[] } = {
        upper: ['UpperSTL1', 'UpperSTL2'],
        lower: ['LowerSTL1', 'LowerSTL2'],
        bite: ['BiteSTL1', 'BiteSTL2'],
    };
    const allowedKeys = keysByStlType[stlType];
    return files.flatMap(f => (allowedKeys.includes(f.fieldKey) ? [f.uploadedPath] : []));
}

const ExistingScans: React.VFC<{ stlZipPath: string | null; scanExportId: string }> = ({
    stlZipPath,
    scanExportId,
}) => {
    const { data, loading } = useScanWithAssetsQuery({
        variables: {
            scanId: scanExportId,
        },
    });
    const assets = data?.scan_file.assets;

    const existingFilesRow = React.useMemo(() => {
        const stl_paths = ScanExportAssetUtils.getStlPaths(assets ?? []);
        const toString = (paths: string[]) => paths.map(p => basename(p)).join(', ');
        return {
            stls_zip: stlZipPath ? basename(stlZipPath) : '-',
            upper_stls: toString(stl_paths.upper),
            lower_stls: toString(stl_paths.lower),
            bitescan_stls: toString(stl_paths.bitescan),
        };
    }, [stlZipPath, assets]);

    return (
        <LoadBlocker blocking={loading}>
            <PrintTable title={'Current STLs'} rows={[existingFilesRow]} />
        </LoadBlocker>
    );
};

const AttachScanStlsMenuItem: React.FC<MenuItemProps> = props => {
    type Vars = LabsGqlAttachScanStlsMutationVariables['data'];
    const scan = props.order.scan_export;
    const [submitMtn] = useAttachScanStlsMutation();
    const mtnSubmitter = (data: Vars) => submitMtn({ variables: { data } });
    const { submit, submitting, open, setOpen } = useChangeSubmissionFn<any, [Vars]>(mtnSubmitter, {
        closeOnComplete: true,
        successMessage: () => ['Scan Stls attached!', {}],
        onSuccess: async () => {
            await props.refetchOrder();
            props.onClose();
        },
    });
    const storagePathConfig = getFullStoragePath(OrthlyBrowserConfig.env, DesignStorageConfigs.stls, scan.id);
    const fileUploaderProps = React.useMemo<FileUploaderProps<STLFileKeys>>(() => {
        return {
            submitting,
            fileFields: [
                { fieldKey: 'StlsZip', uploadName: 'all-stls', displayName: 'All Stls (must be .zip)' },
                { fieldKey: 'UpperSTL1', uploadName: 'upper-1', displayName: 'Upper STL 1' },
                { fieldKey: 'LowerSTL1', uploadName: 'lower-1', displayName: 'Lower STL 1' },
                {
                    fieldKey: 'UpperSTL2',
                    uploadName: 'upper-2',
                    optional: true,
                    displayName: 'Upper STL 2 (optional)',
                },
                {
                    fieldKey: 'LowerSTL2',
                    uploadName: 'lower-2',
                    optional: true,
                    displayName: 'Lower STL 2 (optional)',
                },
                {
                    fieldKey: 'BiteSTL1',
                    uploadName: 'bite-1',
                    optional: true,
                    displayName: 'Bitescan STL 1 (optional)',
                },
                {
                    fieldKey: 'BiteSTL2',
                    uploadName: 'bite-2',
                    optional: true,
                    displayName: 'Bitescan STL 2 (optional)',
                },
            ],
            storagePathConfig: storagePathConfig,
            prependTimestampToFilename: false, // Should keep the design filenames as they are when exported
            dropzoneOptions: {
                accept: {
                    'application/sla': ['.stl'],
                    'application/vnd.ms-pki.stl': ['.stl'],
                    'application/x-navistyle': ['.stl'],
                    'application/x-compressed': ['.zip'],
                    'application/x-zip-compressed': ['.zip'],
                    'application/zip': ['.zip'],
                    'multipart/x-zip': ['.zip'],
                },
            },
            onComplete: files => {
                const zipped_stls_url = files.find(f => f.fieldKey === 'StlsZip')?.uploadedPath;
                if (!zipped_stls_url) {
                    window.alert('Zip file not found in uploads, exiting!');
                    return;
                }
                const upper_paths = getUploadedPathsForSTLType(files, 'upper');
                const lower_paths = getUploadedPathsForSTLType(files, 'lower');
                const bitescan_paths = getUploadedPathsForSTLType(files, 'bite');

                AnalyticsClient.track('All - Portal - Order Files Menu Used', {
                    $groups: { order: props.order.id },
                    action: 'upload_scan_stls',
                });

                submit({ upper_paths, lower_paths, bitescan_paths, zipped_stls_url, scan_export_id: scan.id }).catch(
                    console.error,
                );
            },
        };
    }, [storagePathConfig, scan.id, submit, submitting, props.order.id]);
    return (
        <RootActionDialog
            loading={submitting}
            open={open}
            setOpen={newOpen => {
                setOpen(newOpen);
                !newOpen && props.onClose();
            }}
            title={`Replace STLs for ${scan.patient_first_name} ${scan.patient_last_name}`}
            content={
                <Grid container>
                    <Grid container>
                        <ExistingScans stlZipPath={scan.stl_url} scanExportId={scan.id} />
                    </Grid>
                    <FileUploader {...(fileUploaderProps as FileUploaderProps)} />
                </Grid>
            }
            CustomButton={() => <MenuItem onClick={() => setOpen(true)}>Replace Scan STLs</MenuItem>}
        />
    );
};

export const OrderDetailToolbarFiles: React.FC = () => {
    const { order, id, refetch: refetchOrder } = useOrderDetailContext();
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
    const menuItemProps = React.useMemo<MenuItemProps | undefined>(() => {
        return order ? { order, refetchOrder, onClose: () => setAnchorEl(null) } : undefined;
    }, [order, refetchOrder]);

    return (
        <>
            <Button
                style={{ marginRight: 8 }}
                variant={'ghost'}
                onClick={e => setAnchorEl(e.currentTarget)}
                startIcon={'DownloadIcon'}
            >
                Files
            </Button>
            <OrderFilesMenu orderId={id} anchorEl={anchorEl} order={order} onClose={() => setAnchorEl(null)}>
                {menuItemProps && [
                    <Divider key={'divider'} style={{ margin: '4px 0' }} />,
                    <RunSTLConvertMenuItem key={'stl-convert'} {...menuItemProps} />,
                    <ReRunDesignConversionMenuItem key={'convert'} {...menuItemProps} />,
                    <GenerateEmptyDesignMenuItem key={'generate-empty-design'} {...menuItemProps} />,
                    <RegenerateThreeoxzMenuItem key={'regenerate-threeoxz'} {...menuItemProps} />,
                    <SkipAutomateReviewTaskMenuItem key={'skip-automate-review-task'} {...menuItemProps} />,
                    <CancelAutomateTaskMenuItem key={'cancel-automate-task'} {...menuItemProps} />,
                    <SendToAutomateMenuItem key={'automate'} {...menuItemProps} />,
                    <RefreshScanImagesMenuItem key={'refresh'} {...menuItemProps} />,
                    <ReplaceThreeoxzMenuItem key={'replace'} {...menuItemProps} />,
                    <AttachScanStlsMenuItem key={'attach'} {...menuItemProps} />,
                    <UploadDesignFilesMenuItem key={'design'} {...menuItemProps} />,
                    <ReplaceInjectionMoldFilesMenuItem key={'injection-mold'} {...menuItemProps} />,
                    <NonProdQuickAddDesignAction key={'quick-add-design'} {...menuItemProps} />,
                ]}
            </OrderFilesMenu>
        </>
    );
};
