import { useAskDoctorAboutHoldDialogV2 } from '../OrderDetailToolbar/OrderDetailToolbarActions/AskDoctorV2/AskDoctorV2Dialog';
import { OpsCancelOrderAction } from '../OrderDetailToolbar/OrderDetailToolbarActions/CancelOrderToolbarAction.graphql';
import { useResolveScanRejectionDialog } from './ResolveScanRejectionDialog';
import type {
    LabsGqlOrder,
    LabsGqlWorkflowTask_ResolveScanRejectionWorkflowTask_Fragment as ResolveScanRejectionWorkflowTask,
} from '@orthly/graphql-operations';
import { LabsGqlDoctorRequestInitiatingFlow, LabsGqlResolveScanRejectionDecisionEnum } from '@orthly/graphql-schema';
import { Format } from '@orthly/runtime-utils';
import { OrderFormatUtils } from '@orthly/shared-types';
import type { ActionCardButtonPropsBase } from '@orthly/ui';
import { ActionCard } from '@orthly/ui';
import { Text, Grid, Menu, MenuItem, Button } from '@orthly/ui-primitives';
import { AttachmentPreview } from '@orthly/veneer';
import React from 'react';

interface ScanRejectionImagesProps {
    task: ResolveScanRejectionWorkflowTask;
    open: boolean;
    setOpen: (open: boolean) => void;
}

const ScanRejectionImages: React.FC<ScanRejectionImagesProps> = props => {
    const { task, open, setOpen } = props;

    if (!task.configuration.file_urls) {
        return null;
    }

    return (
        <AttachmentPreview
            open={open}
            setOpen={setOpen}
            title={'Scan issue files'}
            sources={task.configuration.file_urls.map((source, idx) => ({ source, name: `Scan issue #${idx + 1}` }))}
            buttonText={''}
            CustomButton={() => null}
        />
    );
};

interface SecondaryMenuProps {
    task: ResolveScanRejectionWorkflowTask;
    setFilesOpen: (open: boolean) => void;
    setShareDialogV2Open: (open: boolean) => void;
}

const SecondaryMenu = (props: SecondaryMenuProps) => {
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
    const handleClose = () => {
        setAnchorEl(null);
    };
    const hasAttachments = !!props.task.configuration.file_urls?.length;
    const hasBeenShared = !!props.task.configuration.shared_with_practice;
    return (
        <div>
            <Button
                variant={'ghost'}
                onClick={event => {
                    setAnchorEl(event.currentTarget ?? null);
                }}
                endIcon={'KeyboardArrowDownIcon'}
            >
                Other actions
            </Button>
            <Menu
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                elevation={0}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                onClose={handleClose}
            >
                <MenuItem
                    onClick={() => {
                        props.setFilesOpen(true);
                        handleClose();
                    }}
                    disabled={!hasAttachments}
                >
                    {hasAttachments ? 'View files' : 'No files'}
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        props.setShareDialogV2Open(true);

                        handleClose();
                    }}
                    disabled={hasBeenShared}
                >
                    {hasBeenShared ? 'Doctor asked' : 'Ask doctor'}
                </MenuItem>
            </Menu>
        </div>
    );
};

interface ResolveScanRejectionActionCardProps {
    task: ResolveScanRejectionWorkflowTask;
    order: LabsGqlOrder;
    refetchOrder: () => Promise<any>;
}

function useTitle(task: ResolveScanRejectionWorkflowTask, doctorName: string): string {
    switch (task.configuration.practice_response?.practice_decision) {
        case LabsGqlResolveScanRejectionDecisionEnum.Cancel:
            return `${doctorName} wants to cancel & rescan`;
        case LabsGqlResolveScanRejectionDecisionEnum.Proceed:
            return `${doctorName} responded to scan rejection outreach`;
        default:
            return task.configuration.shared_with_practice
                ? "This order is on hold pending doctor's response to flagged scan"
                : 'This order is on hold due to scan being flagged';
    }
}

const ResolveScanRejectionActionCardSubtitle: React.FC<{
    task: ResolveScanRejectionWorkflowTask;
}> = props => {
    const config = props.task.configuration;
    const proceedNotes = config.practice_response?.practice_notes;
    const proceedSelection = config.practice_response?.practice_selected_option;
    const proceedDetailsExist = proceedNotes || proceedSelection;
    const { categories, internal_notes, options_for_doctor, notes_for_doctor } = config;

    return (
        <Grid container>
            {!proceedDetailsExist && (
                <>
                    <Text variant={'body2'} medium style={{ width: '100%' }}>
                        {Format.pluralize('Category', categories.length, 'Categories')}: {categories.join(', ')}
                    </Text>
                    {internal_notes && (
                        <Text variant={'body2'} style={{ width: '100%' }}>
                            Internal notes: {internal_notes}
                        </Text>
                    )}
                    {options_for_doctor && <Text variant={'body2'}>Options: {options_for_doctor.join(', ')}</Text>}
                    {notes_for_doctor && <Text variant={'body2'}>Notes for doctor: {notes_for_doctor}</Text>}
                </>
            )}
            {proceedSelection && (
                <Text variant={'body2'} style={{ width: '100%' }}>{`Doctor choice: ${proceedSelection}`}</Text>
            )}
            {proceedNotes && (
                <Text variant={'body2'} style={{ width: '100%' }}>{`Notes from doctor: ${proceedNotes}`}</Text>
            )}
        </Grid>
    );
};

export const ResolveScanRejectionActionCard: React.FC<ResolveScanRejectionActionCardProps> = props => {
    const { order, refetchOrder, task } = props;
    const [setAskDoctorDialogV2Open, askDoctorDialogV2] = useAskDoctorAboutHoldDialogV2({
        order,
        refetchOrder,
        initiatingFlow: LabsGqlDoctorRequestInitiatingFlow.ScanRejection,
        issues: task.configuration.categories,
        notesForDoctor: task.configuration.notes_for_doctor,
    });
    const [cancelDialogOpen, setCancelDialogOpen] = React.useState<boolean>(false);
    const [filesOpen, setFilesOpen] = React.useState<boolean>(false);
    const practiceDecision = task.configuration.practice_response?.practice_decision;
    const [setResolveDialogOpen, resolveDialog] = useResolveScanRejectionDialog({
        task,
        order,
        refetchOrder,
        CustomButton: () => null,
        uploadNewScanUnchecked: practiceDecision === LabsGqlResolveScanRejectionDecisionEnum.Proceed,
    });
    const primaryAction = React.useMemo<ActionCardButtonPropsBase>(() => {
        if (practiceDecision === LabsGqlResolveScanRejectionDecisionEnum.Cancel) {
            return {
                onClick: () => setCancelDialogOpen(true),
                text: 'Cancel Order',
                endIcon: 'XIcon',
                errorButton: true,
            };
        }
        return {
            onClick: () => setResolveDialogOpen(true),
            text:
                // Nested ternaries are harder to read and should be avoided. Consider using an if/else statement instead.
                // eslint-disable-next-line no-nested-ternary
                practiceDecision === LabsGqlResolveScanRejectionDecisionEnum.Proceed
                    ? 'Send to design'
                    : 'Fix flagged scan',
            endIcon: 'CheckIcon',
        };
    }, [setResolveDialogOpen, practiceDecision]);
    const secondaryAction = (
        <SecondaryMenu task={task} setFilesOpen={setFilesOpen} setShareDialogV2Open={setAskDoctorDialogV2Open} />
    );
    const title = useTitle(task, OrderFormatUtils.getDisplayedStaffMemberName(order.doctor_name));
    return (
        <>
            <ActionCard
                variant={'alert'}
                title={title}
                subtitle={<ResolveScanRejectionActionCardSubtitle task={task} />}
                primaryAction={primaryAction}
                secondaryAction={secondaryAction}
            />
            <ScanRejectionImages task={task} open={filesOpen} setOpen={setFilesOpen} />
            {resolveDialog}
            {askDoctorDialogV2}
            <OpsCancelOrderAction
                open={cancelDialogOpen}
                setOpen={setCancelDialogOpen}
                order={order}
                refetchOrder={refetchOrder}
                CustomButton={() => null}
            />
        </>
    );
};
