import { useFeatureFlag } from '../../../Providers/LaunchDarkly';
import { useStageState } from '../../AppState.hooks';
import { useAutomateValidation, useIsTrainingOrder } from '../../OrderState.hooks';
import { useSubmitAutomateReview } from '../SubmitAutomateReview';
import { LabsGqlAutomateValidationError } from '@orthly/graphql-schema';
import { Box, Button, FlossPalette, Tooltip, styled } from '@orthly/ui-primitives';
import React from 'react';

interface ApproveDesignButtonProps {
    onClick: () => void;
    loading: boolean;
}

const ActionButton = styled(Button)({
    marginBottom: 8,
});

const ApproveDesignButton: React.VFC<ApproveDesignButtonProps> = ({ onClick, loading }) => {
    const tooltip = useGetApproveDesignButtonTooltip();

    return (
        <Tooltip title={tooltip ?? 'Approve the design for manufacturing'} placement={'top'}>
            <span>
                <ActionButton variant={'primary'} fullWidth onClick={onClick} disabled={!!tooltip || loading}>
                    Approve design
                </ActionButton>
            </span>
        </Tooltip>
    );
};

function useGetApproveDesignButtonTooltip(): string | null {
    const { value: enableSendDirectToLab } = useFeatureFlag('enableSendDirectToLab');
    const { value: enableBypassAutomateValidation } = useFeatureFlag('enableBypassAutomateValidation');
    const isTraining = useIsTrainingOrder();

    const { automateValidationDone, automateValidationErrors } = useAutomateValidation();

    if (!enableBypassAutomateValidation) {
        if (!automateValidationDone) {
            return '3Shape Automate output was not validated';
        }

        if (automateValidationErrors.includes(LabsGqlAutomateValidationError.ValidationFailed)) {
            return '3Shape Automate validation failed to run';
        }

        if (automateValidationErrors.length) {
            return '3Shape Automate output failed validation';
        }
    }

    return enableSendDirectToLab || isTraining ? null : 'Disabled by feature flag';
}

interface NeedsAdjustmentsButtonProps {
    onClick: () => void;
    loading: boolean;
    enableFinishingEditing: boolean;
}

const NeedsAdjustmentsButton: React.VFC<NeedsAdjustmentsButtonProps> = ({
    onClick,
    loading,
    enableFinishingEditing,
}) => {
    const { value: enableSendDirectToLab } = useFeatureFlag('enableSendDirectToLab');
    const isTraining = useIsTrainingOrder();

    const tooltip = getNeedsAdjustmentsButtonTooltip(!!enableSendDirectToLab, enableFinishingEditing);

    return (
        <Tooltip title={tooltip} placement={'top'}>
            <span>
                <ActionButton
                    variant={'secondary'}
                    fullWidth
                    onClick={onClick}
                    disabled={loading || (!enableSendDirectToLab && !isTraining)}
                >
                    Needs adjustments
                </ActionButton>
            </span>
        </Tooltip>
    );
};

function getNeedsAdjustmentsButtonTooltip(enableSendDirectToLab: boolean, enableFinishingEditing: boolean): string {
    if (!enableSendDirectToLab) {
        return 'Disabled by feature flag';
    }

    if (enableFinishingEditing) {
        return 'Reject the design and advance to design editing in the portal';
    }

    return 'Reject the design and close the review window';
}

/**
 * This button is a backdoor to access the editing UI without completing the AutomateReview task and should only be
 * shown to engineers and trainers for testing purposes.
 */
const AdvanceToFinishingButton: React.VFC = () => {
    const { value: enableFinishingEditingTesting } = useFeatureFlag('enableFinishingEditingTesting');

    const { setIsReviewComplete } = useStageState();
    const isTraining = useIsTrainingOrder();

    // We don't need this button for training orders.
    if (!enableFinishingEditingTesting || isTraining) {
        return null;
    }

    const onClick = () => setIsReviewComplete(true);

    const tooltipTitle = 'Advance to the editing UI without completing the AutomateReview task';

    return (
        <Tooltip title={tooltipTitle} placement={'top'}>
            <span>
                <ActionButton variant={'secondary'} fullWidth onClick={onClick}>
                    Advance to finishing
                </ActionButton>
            </span>
        </Tooltip>
    );
};

export const ActionBlock: React.VFC = () => {
    const { value: enableFinishingEditing } = useFeatureFlag('enableFinishingEditing');
    const isTraining = useIsTrainingOrder();
    const enableEditing = !!enableFinishingEditing || isTraining;
    const { approveAutomateDesign, rejectAutomateDesign, loading } = useSubmitAutomateReview(enableEditing);

    return (
        <Box
            sx={{
                padding: '8px 0',
                borderTop: `1px solid ${FlossPalette.DARK_TAN}`,
            }}
        >
            <ApproveDesignButton onClick={approveAutomateDesign} loading={loading} />
            <NeedsAdjustmentsButton
                onClick={rejectAutomateDesign}
                loading={loading}
                enableFinishingEditing={enableEditing}
            />
            <AdvanceToFinishingButton />
        </Box>
    );
};
