import { useAutomateValidation } from '../../OrderState.hooks';
import { LabsGqlAutomateValidationError } from '@orthly/graphql-schema';
import { CheckIcon, StackX, Icon } from '@orthly/ui';
import { Box, Collapse, FlossPalette, Text, XIcon } from '@orthly/ui-primitives';
import React from 'react';

interface HeaderProps {
    hasErrors: boolean;
    expanded: boolean;
    setExpanded: React.Dispatch<React.SetStateAction<boolean>>;
}

const Header: React.VFC<HeaderProps> = ({ hasErrors, expanded, setExpanded }) => {
    const onClick = () => setExpanded(curr => !curr);

    return (
        <StackX onClick={onClick}>
            <Icon icon={expanded ? 'ChevronDown' : 'ChevronRight'} />
            {hasErrors ? (
                <Text variant={'body2'} color={'ATTENTION'}>
                    This prep is not eligible to be sent directly to the lab
                </Text>
            ) : (
                <Text variant={'body2'} color={'GRAY'}>
                    This prep is eligible to be sent directly to the lab
                </Text>
            )}
        </StackX>
    );
};

interface EligibilityItemProps {
    text: string;
    valid: boolean;
}

const EligibilityItem: React.VFC<EligibilityItemProps> = ({ text, valid }) => {
    return (
        <div style={{ display: 'flex', paddingTop: 8 }}>
            {valid ? (
                <CheckIcon style={{ color: FlossPalette.PRIMARY_FOREGROUND }} />
            ) : (
                <XIcon style={{ color: FlossPalette.ATTENTION }} />
            )}
            <Text variant={'body2'} style={{ marginLeft: 8 }}>
                {text}
            </Text>
        </div>
    );
};

export const EligibilityBlock: React.VFC = () => {
    const { automateValidationDone, automateValidationErrors } = useAutomateValidation();

    // Set to initially expanded if there are errors.
    const hasValidationErrors = automateValidationErrors.length > 0;
    const [expanded, setExpanded] = React.useState<boolean>(hasValidationErrors);

    if (!automateValidationDone || automateValidationErrors.includes(LabsGqlAutomateValidationError.ValidationFailed)) {
        return (
            <Box
                sx={{
                    padding: '8px 0',
                }}
            >
                <Text variant={'body2'} color={'GRAY'}>
                    Automate validation not available
                </Text>
            </Box>
        );
    }

    const marginWasAdjusted =
        automateValidationErrors.includes(LabsGqlAutomateValidationError.MarginLineDiff) ||
        automateValidationErrors.includes(LabsGqlAutomateValidationError.MarginLineMeanDiff);
    const insertionAxisWasAdjusted = automateValidationErrors.includes(
        LabsGqlAutomateValidationError.InsertionAxisDiff,
    );
    const marginFromMeshError = automateValidationErrors.includes(
        LabsGqlAutomateValidationError.MarginLineFromMeshDiff,
    );
    const marginInUndercut = automateValidationErrors.includes(LabsGqlAutomateValidationError.MarginUndercut);
    const nearMarginUnacceptable =
        automateValidationErrors.includes(LabsGqlAutomateValidationError.NearMarginOffsetAngle) ||
        automateValidationErrors.includes(LabsGqlAutomateValidationError.NearMarginThickness);

    return (
        <Box
            sx={{
                padding: '8px 0',
            }}
        >
            <Header hasErrors={hasValidationErrors} expanded={expanded} setExpanded={setExpanded} />
            <Collapse in={expanded}>
                <EligibilityItem
                    text={marginWasAdjusted ? 'Margin was adjusted' : 'Margin was not adjusted'}
                    valid={!marginWasAdjusted}
                />
                <EligibilityItem
                    text={insertionAxisWasAdjusted ? 'Insertion axis was adjusted' : 'Insertion axis was not adjusted'}
                    valid={!insertionAxisWasAdjusted}
                />
                <EligibilityItem
                    text={marginFromMeshError ? 'Margin not on prep surface' : 'Margin falls on prep surface'}
                    valid={!marginFromMeshError}
                />
                <EligibilityItem
                    text={marginInUndercut ? 'Margin is in undercut' : 'Margin is not in undercut'}
                    valid={!marginInUndercut}
                />
                <EligibilityItem
                    text={`Near margin region is ${nearMarginUnacceptable ? 'out of' : 'within'} spec`}
                    valid={!nearMarginUnacceptable}
                />
            </Collapse>
        </Box>
    );
};
