import { useQuery } from '@apollo/client';
import { differenceInBusinessDays, isBusinessDay } from '@orthly/date-fns-business-days';
import { graphql } from '@orthly/graphql-inline-react';
import type { LabsGqlOrder, LabsGqlOrderSlaFragment } from '@orthly/graphql-operations';
import { SimpleDatePicker, SimpleInput } from '@orthly/ui';
import { FlossPalette, Icon, Text } from '@orthly/ui-primitives';
import { stylesFactory } from '@orthly/ui-primitives';
import type { useDelayDateSlaPreview } from '@orthly/veneer';
import moment from 'moment';
import React from 'react';

const AdminHoldRemovalSlaUpdateFormSuggestedDate_Query = graphql(`
    query AdminHoldRemovalSlaUpdateFormSuggestedDate_Query($order_id: String!) {
        getHoldRemovalSuggestedStageEndDate(order_id: $order_id)
    }
`);

const useStyles = stylesFactory(() => ({
    inputGrid: {
        width: '100%',
        display: 'grid',
        gridTemplateColumns: '1fr auto 1fr',
        gridGap: 8,
        alignItems: 'center',
        marginBottom: 16,
    },
    fullWidthRow: {
        marginBottom: 16,
    },
    boldedText: {
        fontWeight: 'bold',
        color: FlossPalette.BLACK,
    },
}));

function formatDate(date: Date | string): string {
    return moment(date).format('MM/DD/YY');
}

type SlaStageKey = keyof Omit<NonNullable<LabsGqlOrderSlaFragment['stages']>, '__typename'>;

interface HoldRemovalSlaUpdateFormProps {
    order: Pick<LabsGqlOrder, 'manufacturer_sla' | 'id' | 'hold_details' | 'practice_dates'>;
    // Somewhat hacky - the parent needs to own the data because they're submitting the results,
    // so we just ask for the entire hook so we can render it and update the values.
    delayDateHookData: ReturnType<typeof useDelayDateSlaPreview>;
    reason: string | undefined;
    setReason: (reason: string | undefined) => void;
}

/**
 * This component handles displaying the hold removal stage update logic.
 * It assumes the order is presently on hold, and fetches a suggested date to delay the stage to.
 * The user is able to edit it, and provide a reason.
 */
export const HoldRemovalSlaUpdateForm: React.FC<HoldRemovalSlaUpdateFormProps> = props => {
    const {
        order,
        delayDateHookData: { selectedDate, setSelectedDate, updatedSLA },
        reason,
        setReason,
    } = props;

    const classes = useStyles();

    const currentStage = order.manufacturer_sla.current_stage
        ? order.manufacturer_sla.stages?.[order.manufacturer_sla.current_stage as SlaStageKey]
        : null;
    const startingDate = currentStage?.eta ?? order.manufacturer_sla.due_date;
    const dateBeingChangedLabel = currentStage?.eta ? `Stage Due Date` : `Due Date`;

    const { data: previewData } = useQuery(AdminHoldRemovalSlaUpdateFormSuggestedDate_Query, {
        variables: {
            order_id: order.id,
        },
        // When this component renders for the first time, we will fetch the latest suggested date,
        // which is then piped into the selected date that the user is modifying.
        onCompleted: data => {
            void setSelectedDate(new Date(data.getHoldRemovalSuggestedStageEndDate));
        },
    });

    const suggestedEndDate = previewData?.getHoldRemovalSuggestedStageEndDate
        ? new Date(previewData.getHoldRemovalSuggestedStageEndDate)
        : undefined;

    const now = new Date();
    const holdDurationHours = moment().diff(order.hold_details?.hold_created_at, 'hours');
    const holdDurationDays = order.hold_details?.hold_created_at
        ? differenceInBusinessDays(now, new Date(order.hold_details.hold_created_at))
        : 0;
    const holdDurationLabel = holdDurationHours < 24 ? `${holdDurationHours} hours` : `${holdDurationDays} days`;

    const isDelayedFromCurrentEta = selectedDate && moment(selectedDate).isAfter(startingDate, 'day');

    return (
        <div>
            {(order.manufacturer_sla.version ?? 0) >= 4 && (
                <>
                    {suggestedEndDate && (
                        <Text variant={'body2'} className={classes.fullWidthRow} color={'GRAY'}>
                            The suggested stage due date ({formatDate(suggestedEndDate)}) is calculated based on the
                            duration of the hold ({holdDurationLabel}) and the TAT for the stage.{' '}
                            <span className={classes.boldedText}>Only make updates if it's incorrect.</span>
                        </Text>
                    )}

                    <div className={classes.inputGrid}>
                        {/* Date selection row titles */}
                        <Text variant={'body2'} medium>
                            Current {dateBeingChangedLabel}
                        </Text>
                        <span />
                        <Text variant={'body2'} medium>
                            New {dateBeingChangedLabel}
                        </Text>

                        {/* Actual Date Input Row */}
                        <SimpleDatePicker
                            disabled={true}
                            fullWidth={true}
                            label={`Current ${dateBeingChangedLabel}`}
                            value={new Date(startingDate)}
                            onChange={() => {
                                /* no-op */
                            }}
                        />
                        <Icon icon={'ChevronRight'} width={24} color={'disabled'} />
                        <SimpleDatePicker
                            fullWidth={true}
                            minDate={moment(order.manufacturer_sla.start_date).toDate()}
                            shouldDisableDate={dateParam => {
                                const date = dateParam.toDate();
                                // Non-business days cannot be a due date for anything.
                                if (!date || !isBusinessDay(date)) {
                                    return true;
                                }

                                // You can't make something retroactive due before today, it doesn't make sense.
                                if (moment(date).isBefore(new Date(), 'day')) {
                                    return true;
                                }

                                if (moment(date).isBefore(startingDate, 'day')) {
                                    return true;
                                }

                                return false;
                            }}
                            label={`New ${dateBeingChangedLabel}`}
                            value={selectedDate}
                            onChange={date => date && setSelectedDate(date)}
                        />

                        {/* ETAs */}
                        <Text variant={'body2'} color={'GRAY'}>
                            Current practice ETA: {formatDate(order.practice_dates.estimated_delivery_date)}
                        </Text>
                        <span />
                        <Text variant={'body2'} color={'GRAY'}>
                            {/* Delayed staged are red, stages shifted to be due sooner are green */}
                            New practice ETA:{' '}
                            <span
                                style={{
                                    color: isDelayedFromCurrentEta
                                        ? FlossPalette.ATTENTION
                                        : FlossPalette.PRIMARY_FOREGROUND,
                                }}
                            >
                                {updatedSLA ? formatDate(updatedSLA.estimated_delivery_date) : '-'}
                            </span>
                        </Text>
                    </div>
                </>
            )}
            <div>
                {/* Reason */}
                <Text variant={'body2'} medium>
                    Reason
                </Text>
                <SimpleInput
                    label={'Why is this hold being removed?'}
                    value={reason}
                    onChange={value => {
                        setReason(value);
                    }}
                    TextFieldProps={{
                        multiline: true,
                        minRows: 3,
                        maxRows: 100,
                        error: !reason,
                        className: classes.fullWidthRow,
                    }}
                    fullWidth
                />
            </div>
        </div>
    );
};
