import { useViewManager } from './ScanReview.hooks';
import {
    ScanReviewPartialScene,
    ScanReviewShadeMatchingPicker,
    type ScanReviewRecordFactory,
    type ScanReviewViewState,
} from './ScanReviewTypes';
import constate from 'constate';
import React from 'react';

interface ScanReviewShadeMatchingViewAppProps {
    lowerJawFactory: ScanReviewRecordFactory | null;
    upperJawFactory: ScanReviewRecordFactory | null;
    viewState: ScanReviewViewState;
    onShadePicked: (color: [number, number, number]) => void;
}

function useScanReviewShadeMatchingViewApp({
    lowerJawFactory,
    upperJawFactory,
    onShadePicked,
    viewState,
}: ScanReviewShadeMatchingViewAppProps) {
    const viewManager = useViewManager(viewState);
    const picker = React.useMemo(() => {
        const upperJaw = upperJawFactory?.() ?? null;
        const lowerJaw = lowerJawFactory?.() ?? null;
        const scene = new ScanReviewPartialScene(upperJaw, lowerJaw);
        return new ScanReviewShadeMatchingPicker(scene, viewManager);
    }, [upperJawFactory, lowerJawFactory, viewManager]);

    return {
        picker,
        onShadePicked,
    };
}

export const [ScanReviewShadeMatchingViewAppProvider, useScanReviewShadeMatchingViewContext] = constate(
    useScanReviewShadeMatchingViewApp,
);
