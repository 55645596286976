import type { OrderDetailSidebarTabPieces } from '../../OrderDetail.types';
import { useOrderDetailContext } from '../../state/OrderDetailProvider.graphql';
import { useGetOrderProceduresV2Query } from '@orthly/graphql-react';
import { LabtracDetailsSidebar } from '@orthly/veneer';
import React from 'react';

export const LabtracDetailsSidebarContainer: React.FC = () => {
    const { id: orderId, order } = useOrderDetailContext();
    const { data: procedureData, loading: proceduresLoading } = useGetOrderProceduresV2Query({
        variables: { order_id: orderId, manufacturer_id: order?.manufacturer_id ?? null },
    });
    return (
        <LabtracDetailsSidebar
            dataLoading={proceduresLoading}
            labtracId={procedureData?.getOrderProceduresV2?.labtracId}
            procedureData={procedureData?.getOrderProceduresV2?.procedures}
        />
    );
};

export function useLabtracDetailsSidebarParts(): OrderDetailSidebarTabPieces {
    return {
        name: 'Labtrac',
        sidebarBody: <LabtracDetailsSidebarContainer />,
    };
}
