import type { QcHeatmapRange } from '../ColorRamp';
import { useCompositeScene, useViewManager } from './ScanReview.hooks';
import type { ScanReviewRecordFactory } from './ScanReviewTypes';
import { type ScanReviewViewType, type ScanReviewViewState, ScanReviewDisplayType } from './ScanReviewTypes';
import constate from 'constate';
import React from 'react';

interface ScanReviewCompleteViewAppProps {
    lowerJawFactory: ScanReviewRecordFactory | null;
    upperJawFactory: ScanReviewRecordFactory | null;
    initialViewType: ScanReviewViewType;
    initialHeatmapRange: QcHeatmapRange;
    viewState: ScanReviewViewState;
}

function useScanReviewCompleteViewApp({
    lowerJawFactory,
    upperJawFactory,
    initialViewType,
    initialHeatmapRange,
    viewState,
}: ScanReviewCompleteViewAppProps) {
    const scene = useCompositeScene(lowerJawFactory, upperJawFactory);
    const viewManager = useViewManager(viewState);
    const [viewType, setViewType] = React.useState(initialViewType);

    const [heatMapRange, setHeatMapRange] = React.useState<QcHeatmapRange>(initialHeatmapRange);
    const setHeatMapRangeCallback = React.useCallback(
        (newHeatMapRange: QcHeatmapRange) => {
            scene.updateHeatMapRange(newHeatMapRange.min, newHeatMapRange.max);
            setHeatMapRange(newHeatMapRange);
        },
        [scene, setHeatMapRange],
    );

    const [displayType, setDisplayType] = React.useState(ScanReviewDisplayType.Scan);
    const setDisplayTypeCallback = React.useCallback(
        (newDisplayType: ScanReviewDisplayType) => {
            if (newDisplayType === ScanReviewDisplayType.Scan) {
                scene.setScanDisplay();
            } else if (newDisplayType === ScanReviewDisplayType.StoneModel) {
                scene.setStoneModelDisplay();
            } else if (newDisplayType === ScanReviewDisplayType.BiteAnalysis) {
                scene.setHeatMapDisplay();
                scene.updateHeatMapRange(heatMapRange.min, heatMapRange.max);
            }
            setDisplayType(newDisplayType);
        },
        [scene, heatMapRange, setDisplayType],
    );

    return {
        scene,
        viewManager,
        viewType,
        viewState,
        displayType,
        heatMapRange,
        setViewType,
        setDisplayTypeCallback,
        setHeatMapRangeCallback,
    };
}

export const [ScanReviewCompleteViewAppProvider, useScanReviewCompleteViewAppContext] =
    constate(useScanReviewCompleteViewApp);
