import { Text, Grid } from '@orthly/ui-primitives';
import React from 'react';

export const NotesTileTitle: React.FC<{ noteTitle: string; buttons: JSX.Element[] }> = props => {
    const { noteTitle, buttons } = props;

    return (
        <Grid container direction={'row'} justifyContent={'space-between'}>
            <Grid component={Text} item xs={7} variant={'body2'} medium>
                {noteTitle}
            </Grid>
            <Grid item>{buttons}</Grid>
        </Grid>
    );
};
