import { useAskDoctorAboutHoldDialogV2 } from '../OrderDetailToolbar/OrderDetailToolbarActions/AskDoctorV2/AskDoctorV2Dialog';
import { RemoveHoldFromOrder } from '../OrderDetailToolbar/OrderDetailToolbarActions/RemoveOrderHoldAction.graphql';
import type { AdminLabsOrderActionProps } from '../actions/order-action-types';
import { ResolveScanRejectionActionCard } from './ResolveScanRejectionActionCard';
import { UpdateOrderHold } from './UpdateOrderHold';
import type { LabsGqlOrder } from '@orthly/graphql-operations';
import { LabsGqlDoctorRequestInitiatingFlow } from '@orthly/graphql-schema';
import { HoldUtils } from '@orthly/shared-types';
import type { ActionCardButtonPropsBase } from '@orthly/ui';
import { ActionCard, SimpleDropdownMenu } from '@orthly/ui';
import { Text, FlossPalette } from '@orthly/ui-primitives';
import _ from 'lodash';
import React from 'react';

interface BasicHoldActionCardProps {
    title: React.ReactNode;
    subtitle: React.ReactNode;
    order: LabsGqlOrder;
    refetchOrder: () => Promise<any>;
}

export const BasicHoldActionCard: React.FC<BasicHoldActionCardProps> = props => {
    const { title, subtitle, order, refetchOrder } = props;
    const [removeIsOpen, setRemoveIsOpen] = React.useState<boolean>(false);
    const [editIsOpen, setEditIsOpen] = React.useState<boolean>(false);
    const [setAskDoctorDialogV2Open, askDoctorDialogV2] = useAskDoctorAboutHoldDialogV2({
        order,
        refetchOrder,
        initiatingFlow: LabsGqlDoctorRequestInitiatingFlow.Hold,
        issues: [
            _.compact([order?.hold_details?.hold_category, order?.hold_details?.hold_subcategory]).join(' - ') ??
                'Reason not provided',
        ],
    });
    const activeTask = order.fulfillment_workflow.active_task;
    const primaryAction = React.useMemo<ActionCardButtonPropsBase>(() => {
        return {
            onClick: () => setRemoveIsOpen(true),
            text: 'Remove hold',
            endIcon: 'CheckIcon',
        };
    }, [setRemoveIsOpen]);

    const secondaryAction = React.useMemo<React.ReactElement>(
        () => (
            <SimpleDropdownMenu
                buttonVariant={'secondary'}
                buttonLabel={'Other actions'}
                items={[
                    {
                        label: 'Edit hold',
                        onClick: () => setEditIsOpen(true),
                    },
                    {
                        label: 'Ask Doctor',
                        onClick: () => {
                            setAskDoctorDialogV2Open(true);
                        },
                        disabled: activeTask?.__typename === 'DrReviewHoldWorkflowTask',
                    },
                ]}
            />
        ),
        [setEditIsOpen, setAskDoctorDialogV2Open, activeTask?.__typename],
    );

    return (
        <>
            <ActionCard
                variant={'alert'}
                title={title}
                subtitle={subtitle}
                primaryAction={primaryAction}
                secondaryAction={secondaryAction}
            />
            <UpdateOrderHold
                order={order}
                refetchOrder={refetchOrder}
                open={editIsOpen}
                setOpen={setEditIsOpen}
                CustomButton={() => null}
            />
            <RemoveHoldFromOrder
                order={order}
                refetchOrder={refetchOrder}
                open={removeIsOpen}
                setOpen={setRemoveIsOpen}
                CustomButton={() => null}
            />
            {askDoctorDialogV2}
        </>
    );
};

export const OnHoldActionCard: React.FC<AdminLabsOrderActionProps> = props => {
    const { order, refetchOrder } = props;
    const holdDescription = `This order is on hold (${
        order.show_hold_status_to_practice ? 'visible to practice' : 'hidden from practice'
    })`;
    const holdReason = HoldUtils.composeHoldReason(order.hold_details) ?? 'Reason not provided';
    const activeTask = order.fulfillment_workflow.active_task;
    if (activeTask?.__typename === 'ResolveScanRejectionWorkflowTask') {
        return <ResolveScanRejectionActionCard {...props} task={activeTask} />;
    }
    if (activeTask?.__typename === 'ResolveHoldWorkflowTask') {
        return (
            <BasicHoldActionCard
                title={holdDescription}
                subtitle={holdReason}
                order={order}
                refetchOrder={refetchOrder}
            />
        );
    }
    if (activeTask?.__typename === 'DrReviewHoldWorkflowTask') {
        return (
            <BasicHoldActionCard
                title={'Waiting for doctor answer'}
                subtitle={
                    <>
                        <Text variant={'body2'} color={'DARK_GRAY'} style={{ whiteSpace: 'pre-wrap' }}>
                            <span style={{ display: 'block' }}>
                                <span style={{ textDecoration: 'underline' }}>Asked doctor</span>
                                {': '}
                                {activeTask.configuration.dr_visible_notes}
                            </span>
                            <span style={{ display: 'block' }}>
                                <span style={{ textDecoration: 'underline' }}>Gave structured options</span>
                                {': '}
                                {activeTask.configuration.structured_response_options.join(', ')}
                            </span>
                            <span style={{ color: FlossPalette.GRAY, display: 'block' }}>{holdDescription}</span>
                            {holdReason}
                        </Text>
                    </>
                }
                order={order}
                refetchOrder={refetchOrder}
            />
        );
    }
    return null;
};
