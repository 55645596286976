import { SplitsActions } from './Splits.actions';
import type { SplitInfo, SplitsDelta, SplitManagerState } from './Splits.types';
import type { LabsGqlLabOrderForListViewFragment, LabsGqlOrganizationDtoFragment } from '@orthly/graphql-operations';
import { LabsGqlLabOrderStatus } from '@orthly/graphql-schema';
import { OrderItemV2Utils } from '@orthly/items';
import { handleActions } from 'redux-actions';

const DISALLOW_MUTATION_STATUSES = [LabsGqlLabOrderStatus.Cancelled, LabsGqlLabOrderStatus.NeedsRefabrication];

export function orderSplitMutationsDisabled(s: SplitInfo): boolean {
    return s.status !== 'New Split' && DISALLOW_MUTATION_STATUSES.includes(s.status);
}

function splitInfoFromOrder(
    order: LabsGqlLabOrderForListViewFragment,
    manufacturers: ReadonlyArray<LabsGqlOrganizationDtoFragment>,
): SplitInfo {
    return {
        id: order.id,
        manufacturerId: order.manufacturer_id,
        manufacturerDisplay: manufacturers.find(m => m.id === order.manufacturer_id)?.name ?? 'Unknown',
        items: OrderItemV2Utils.parseItems(order.items_v2).map(item => ({ item, id: item.id })),
        fabricationDays: order.manufacturer_sla.fabrication_days,
        status: order.status,
    };
}

export function reduceDeltas(
    deltas: ReadonlyArray<SplitsDelta>,
    initialSplits: ReadonlyArray<SplitInfo>,
    manufacturers: ReadonlyArray<LabsGqlOrganizationDtoFragment>,
): ReadonlyArray<SplitInfo> {
    return Object.values(
        deltas.reduce<Record<string, SplitInfo>>(
            (state, delta) => {
                switch (delta.action) {
                    case 'add_split':
                        return {
                            ...state,
                            [delta.orderId]: {
                                id: delta.orderId,
                                manufacturerId: delta.manufacturerId,
                                manufacturerDisplay:
                                    manufacturers.find(m => m.id === delta.manufacturerId)?.name ?? 'Unknown',
                                items: [],
                                isNew: true,
                                fabricationDays: delta.fabricationDays,
                                status: 'New Split',
                            },
                        };
                    case 'move_item':
                        const srcOrder = state[delta.sourceOrderId];
                        const destOrder = state[delta.destOrderId];
                        const item = srcOrder?.items.find(item => item.id === delta.itemId);
                        if (!srcOrder || !destOrder || !item) {
                            return state;
                        }
                        return {
                            ...state,
                            [delta.sourceOrderId]: {
                                ...srcOrder,
                                items: srcOrder.items.filter(item => item.id !== delta.itemId),
                            },
                            [delta.destOrderId]: {
                                ...destOrder,
                                items: [...destOrder.items, item],
                            },
                        };
                    case 'add_item':
                        const orderForAdd = state[delta.orderId];
                        if (!orderForAdd) {
                            return state;
                        }
                        return {
                            ...state,
                            [delta.orderId]: {
                                ...orderForAdd,
                                items: [
                                    ...orderForAdd.items,
                                    {
                                        id: delta.itemId,
                                        item: delta.itemInput,
                                        isNew: true,
                                    },
                                ],
                            },
                        };
                    case 'change_manufacturer':
                        const orderForChangeManufacturer = state[delta.orderId];
                        if (!orderForChangeManufacturer) {
                            return state;
                        }
                        return {
                            ...state,
                            [delta.orderId]: {
                                ...orderForChangeManufacturer,
                                manufacturerId: delta.manufacturerId,
                                manufacturerDisplay:
                                    manufacturers.find(m => m.id === delta.manufacturerId)?.name ?? 'Unknown',
                            },
                        };
                    default:
                        return state;
                }
            },
            initialSplits.reduce<Record<string, SplitInfo>>((rec, split) => ({ ...rec, [split.id]: split }), {}),
        ),
    );
}

// Reducer specifically for the form object.
// This is where almost all of the heavy lifting of this redux lives.
export const splitsReducer = handleActions<SplitManagerState, any>(
    {
        ...SplitsActions.OPEN_MANAGER.reducer<SplitManagerState>((_state, action) => {
            const initialSplits = action.payload.splitOrders.map(o =>
                splitInfoFromOrder(o, action.payload.manufacturers),
            );
            return {
                initialSplits,
                open: true,
                order: action.payload.order,
                manufacturers: action.payload.manufacturers,
                splits: initialSplits,
                deltas: [],
            };
        }),
        ...SplitsActions.APPLY_DELTA.reducer<SplitManagerState>((state, action) => {
            if (!state.open) {
                return state;
            }
            const deltas = [...state.deltas, action.payload];
            const splits = reduceDeltas(deltas, state.initialSplits, state.manufacturers);
            return {
                ...state,
                deltas,
                splits,
            };
        }),
        ...SplitsActions.UNDO_DELTA.reducer<SplitManagerState>((state, _action) => {
            if (!state.open) {
                return state;
            }
            const deltas = state.deltas.slice(0, -1);
            const splits = reduceDeltas(deltas, state.initialSplits, state.manufacturers);
            return {
                ...state,
                deltas,
                splits,
            };
        }),
        ...SplitsActions.RESET_DELTAS.reducer<SplitManagerState>((state, _action) => {
            if (!state.open) {
                return state;
            }
            return {
                ...state,
                deltas: [],
                splits: state.initialSplits,
            };
        }),
        ...SplitsActions.CLOSE_MANAGER.reducer<SplitManagerState>((_state, _action) => ({
            open: false,
        })),
    },
    { open: false },
);
