import type { AdminLabsOrderActionProps } from '../order-action-types';
import { useIsDesignActionEditable } from './DesignActionUtils';
import { DesignConfigDropdown } from './DesignConfigDropdown';
import type {
    LabsGqlConfigureDesignReviewMutationVariables,
    LabsGqlOrderFulfillmentWorkflowConfigFragment,
} from '@orthly/graphql-operations';
import { useConfigureDesignReviewMutation, useConfigureDesignReview2Mutation } from '@orthly/graphql-react';
import type { ConstEnum } from '@orthly/runtime-utils';
import { LoadBlocker, useChangeSubmissionFn } from '@orthly/ui';
import { stylesFactory, Switch } from '@orthly/ui-primitives';
import _ from 'lodash';
import React from 'react';

type DesignReviewType = 'Mandatory' | 'ExternalOptional' | 'None';
const allDesignReviewTypes: ConstEnum<DesignReviewType> = {
    ExternalOptional: 'ExternalOptional',
    Mandatory: 'Mandatory',
    None: 'None',
};
const designReviewTypeLabels: { [K in DesignReviewType]: string } = {
    ExternalOptional: 'Optional',
    Mandatory: 'Required',
    None: 'None',
};

function getDesignReviewType(config: LabsGqlOrderFulfillmentWorkflowConfigFragment): DesignReviewType {
    if (!config.design_review_required) {
        return 'None';
    }
    if (config.internal_design_required || config.external_design_review_blocking) {
        return 'Mandatory';
    }
    return 'ExternalOptional';
}

function stringIsDesignReviewType(value?: string): value is DesignReviewType {
    return !!value && Object.values(allDesignReviewTypes).includes(value as any);
}

type Vars = Omit<LabsGqlConfigureDesignReviewMutationVariables, 'order_id'>;

function designTypeToVars(designType: DesignReviewType): Vars {
    switch (designType) {
        case 'ExternalOptional':
            return { external_design_review_blocking: false, design_review_required: true };
        case 'Mandatory':
            return { external_design_review_blocking: true, design_review_required: true };
        case 'None':
            return { external_design_review_blocking: false, design_review_required: false };
    }
}

export const OrderDesignReviewAction: React.FC<AdminLabsOrderActionProps> = props => {
    const { order, refetchOrder } = props;
    const { fulfillment_workflow } = order;
    const { internal_design_required } = fulfillment_workflow.configuration;
    const [submitMtn] = useConfigureDesignReviewMutation();
    const { submit, submitting } = useChangeSubmissionFn<any, [Vars]>(
        (vars: Vars) => submitMtn({ variables: { ...vars, order_id: order.id } }),
        {
            successMessage: () => [`Design review updated`, {}],
            onSuccess: async () => {
                await refetchOrder();
            },
        },
    );

    const options = _.compact<{ label: string; value: DesignReviewType }>([
        { label: 'None', value: 'None' },
        internal_design_required ? undefined : { label: 'Optional', value: 'ExternalOptional' },
        { label: 'Mandatory', value: 'Mandatory' },
    ]);

    const canTakeAction = useIsDesignActionEditable(order, { allowAfterLabAccept: true });

    const currentReviewType = {
        label: designReviewTypeLabels[getDesignReviewType(order.fulfillment_workflow.configuration)],
        value: getDesignReviewType(order.fulfillment_workflow.configuration),
    };
    return (
        <LoadBlocker
            blocking={submitting}
            overlayColor={'transparent'}
            ContainerProps={{ style: { alignItems: 'center' } }}
        >
            <DesignConfigDropdown<DesignReviewType>
                options={options}
                selected={currentReviewType}
                submit={async option => {
                    const newReviewType = stringIsDesignReviewType(option) ? option : undefined;

                    if (
                        newReviewType &&
                        newReviewType !== currentReviewType.value &&
                        window.confirm(`Change design review to ${option}`)
                    ) {
                        await submit(designTypeToVars(newReviewType));
                        return true;
                    }

                    return false;
                }}
                disabled={!canTakeAction}
            />
        </LoadBlocker>
    );
};

const useSwitchStyles = stylesFactory(() => ({
    switchRoot: {
        margin: 0,
        height: 16,
        width: 36,
        borderRadius: 12,
    },
    switchThumb: {
        borderRadius: 12,
        height: 12,
        width: 12,
    },
    switchBase: {
        width: 36,
        transform: 'translateX(-10px)',
        '&.Mui-checked': {
            transform: 'translateX(10px)',
        },
    },
    loadBlocker: { position: 'relative', alignItems: 'center', height: 16, width: 36 },
}));

export const OrderDesignReview2SummaryRow: React.VFC<AdminLabsOrderActionProps> = props => {
    const { order, refetchOrder } = props;
    const classes = useSwitchStyles();
    const { design_review2_required, internal_design_required } = order.fulfillment_workflow.configuration;
    const [submitMtn] = useConfigureDesignReview2Mutation();
    const { submit, submitting } = useChangeSubmissionFn<any, []>(
        () => submitMtn({ variables: { required: !design_review2_required, order_id: order.id } }),
        {
            successMessage: () => [`Double QC updated`, {}],
            onSuccess: async () => {
                await refetchOrder();
            },
        },
    );

    const canTakeAction = useIsDesignActionEditable(order, { allowAfterLabAccept: true });

    return (
        <LoadBlocker
            blocking={submitting}
            overlayColor={'transparent'}
            ContainerProps={{ className: classes.loadBlocker }}
            CircularProgressProps={{ style: { height: 16, width: 16 } }}
        >
            <Switch
                size={'medium'}
                color={'secondary'}
                disabled={!canTakeAction || !internal_design_required}
                checked={design_review2_required}
                onChange={() => submit()}
                classes={{ root: classes.switchRoot, thumb: classes.switchThumb, switchBase: classes.switchBase }}
            />
        </LoadBlocker>
    );
};
