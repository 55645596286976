import { useBillingDetailsContext } from '../BillingDetails/providers/BillingDetailsProvider.graphql';
import type { BillingCreditCategories } from '../CreditCategories/BillingCreditCategoriesQuery.graphql';
import { GetAllBillingCreditCategories_Query } from '../CreditCategories/BillingCreditCategoriesQuery.graphql';
import type { Invoice } from '../InvoicesTable/InvoiceTable.types';
import { NEXT_INVOICE_VALUE, type PartialBasicInvoice } from './CreateCreditOrRefund/CreditOrRefund.types';
import { CreditOrRefundWrapper } from './CreateCreditOrRefund/CreditOrRefundWrapper';
import { useMutation, useQuery } from '@apollo/client';
import { graphql } from '@orthly/graphql-inline-react';
import { LabsGqlStripeInvoiceStatus } from '@orthly/graphql-schema';
import { Format } from '@orthly/runtime-utils';
import { useHasCapability } from '@orthly/session-client';
import type { ButtonProps } from '@orthly/ui';
import { DefaultValidationMap, QuickForm, RootActionDialog, WarningIcon, useRootActionCommand } from '@orthly/ui';
import { Button, FlossPalette, Grid, Icon, IconButton, Text, Tooltip, styled } from '@orthly/ui-primitives';
import { useFeatureFlag } from '@orthly/veneer';
import dayjs from 'dayjs';
import { sortBy } from 'lodash';
import React from 'react';

interface CreateBillingCreditActionProps {
    practiceId: string;
    practiceName: string;
    refetchCredits: () => Promise<unknown>;
    refetchBillingDetails: () => Promise<unknown>;
    CustomButton?: React.FC<ButtonProps>;
    useTextButton?: boolean;
}

interface FormVars {
    apply_to_existing_invoice_id: string;
    amount_dollars: number;
    description: string;
    expires?: Date | null;
    credit_category_id: string;
}

const IssueCreditButton: React.FC<ButtonProps> = props => {
    return (
        <Tooltip title={'Issue Credit'}>
            <IconButton size={'small'} onClick={props.onClick}>
                <Icon icon={'Redeem'} />
            </IconButton>
        </Tooltip>
    );
};

const StyledWarningContainer = styled('div')({
    padding: 16,
    marginTop: 16,
    marginBottom: 16,
    alignItems: 'flex-start',
    gap: 8,
    borderRadius: 8,
    background: FlossPalette.WARNING_BACKGROUND,
});

interface ACHPendingPaymentWarningProps {
    isVisible: boolean;
    handleClick: () => void;
}

const StyledText = styled(Text)({
    cursor: 'pointer',
    display: 'inline',
});

const ACHPendingPaymentWarning: React.FC<ACHPendingPaymentWarningProps> = ({ isVisible, handleClick }) => (
    <StyledWarningContainer style={{ display: isVisible ? 'flex' : 'none' }}>
        <WarningIcon style={{ color: FlossPalette.WARNING }} />
        <Text variant={'body2'}>
            You can't create credit on this invoice because there's a pending ACH payment. Please create credit on{' '}
            <StyledText variant={'body2'} medium={true} onClick={handleClick} color={'PRIMARY_FOREGROUND'}>
                the next invoice instead
            </StyledText>
            .
        </Text>
    </StyledWarningContainer>
);

const CreateInvoiceCredit_Mutation = graphql(`
    mutation CreateInvoiceCredit($data: CreateInvoiceCreditInput!) {
        createInvoiceCredit(data: $data) {
            id
        }
    }
`);

const submitCredit = async (
    result: FormVars,
    practiceId: string,
    submitInvoice: (data: any) => Promise<unknown>, // Data is of shape LabsGqlCreateInvoiceCreditInput
) => {
    // convert string const back to undefined for form submission
    const invoiceId =
        result.apply_to_existing_invoice_id === NEXT_INVOICE_VALUE ? undefined : result.apply_to_existing_invoice_id;

    const amountCents = Math.round(result.amount_dollars * 100);
    const expirationTime = result.expires ? new Date(result.expires).toJSON() : null;
    const creditCategory = result.credit_category_id ?? null;

    await submitInvoice({
        data: {
            amount_issued_cents: amountCents,
            credit_category_id: creditCategory,
            description: result.description,
            expires: expirationTime,
            organization_id: practiceId,
            existing_invoice_id: invoiceId,
        },
    });
};

// eslint-disable-next-line max-lines-per-function
export const CreateBillingCreditAction: React.FC<CreateBillingCreditActionProps> = ({
    practiceId,
    practiceName,
    refetchCredits,
    refetchBillingDetails,
    CustomButton,
    useTextButton,
}) => {
    const { value: enableCreditAndRefundOverhaul } = useFeatureFlag('enableCreditAndRefundOverhaul');
    const { invoices, invoicesLoading } = useBillingDetailsContext();
    const [open, setOpen] = React.useState(false);

    const convertToBasicInvoice = (invoice: Invoice): PartialBasicInvoice => ({
        amount_remaining: invoice.amount_remaining,
        id: invoice.id,
        period_start: invoice.period_start,
        status: invoice.status,
        hasPendingPayment: invoice.payments.filter(p => p.status === 'pending').length > 0,
    });

    const isInvoiceEligibleForCredit = (invoice: PartialBasicInvoice): boolean =>
        invoice.status === LabsGqlStripeInvoiceStatus.Open;

    const convertInvoiceToOption = (invoice: PartialBasicInvoice) => ({
        value: invoice.id,
        label: `Orders ${dayjs.utc(invoice.period_start).format('MMM YYYY')}. ${Format.currency(
            invoice.amount_remaining,
        )} remaining${invoice.hasPendingPayment ? ' - has pending payment' : ''}`,
        start: dayjs.utc(invoice.period_start),
        disabled: invoice.hasPendingPayment,
    });

    const invoiceOptionsForCredit: { value: string; label: string; start: any; disabled: boolean }[] = invoices
        ?.map(convertToBasicInvoice)
        ?.filter(isInvoiceEligibleForCredit)
        .map(convertInvoiceToOption);

    const nextInvoiceMonthYear = dayjs.utc().format('MMM YYYY');
    const nextInvoiceText = `Credit Next Invoice (${nextInvoiceMonthYear})`;

    // using a string const here instead of undefined because we are controlling the component, and
    // controlled components require a value - specifically using a string const here instead of an empty
    // string because we want to represent the default "next invoice" label in the input
    const [selectedInvoiceId, setSelectedInvoiceId] = React.useState<string>(NEXT_INVOICE_VALUE);

    const selectedInvoice = React.useMemo<Invoice | undefined>(() => {
        // don't bother looking up the nonexistent "next invoice"
        if (selectedInvoiceId === NEXT_INVOICE_VALUE) {
            return undefined;
        }
        return invoices.find(i => i.id === selectedInvoiceId);
    }, [invoices, selectedInvoiceId]);

    const isACHPaymentOutstanding = React.useMemo(() => {
        if (!selectedInvoice) {
            return false;
        }

        const pendingPayments = selectedInvoice.payments.filter(p => p.status === 'pending');
        return (pendingPayments.length ?? 0) > 0;
    }, [selectedInvoice]);

    const { data: { getAllBillingCreditCategories } = {}, loading: creditCategoriesLoading } = useQuery<{
        getAllBillingCreditCategories: BillingCreditCategories;
    }>(GetAllBillingCreditCategories_Query, {
        skip: !open,
        variables: { include_archived: false },
    });

    const creditCategoryOptions = React.useMemo(
        () =>
            sortBy(
                getAllBillingCreditCategories?.map(category => ({
                    label: category.name,
                    value: category.id,
                })) ?? [],
                category => category.value,
            ),
        [getAllBillingCreditCategories],
    );

    const handleChangeInvoiceId = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedInvoiceId(event.target.value);
    };

    const createInvoiceCreditMtn = useMutation(CreateInvoiceCredit_Mutation);

    const onSuccess = async () => {
        setOpen(false);
        await refetchCredits();
        await refetchBillingDetails();
    };
    const successMessage = selectedInvoiceId ? 'Credit applied to invoice!' : 'Credit created!';
    const { submit: submitInvoice, submitting: submittingInvoice } = useRootActionCommand(createInvoiceCreditMtn, {
        onSuccess,
        successMessage,
    });

    const onSubmitCredit = async (result: FormVars) => submitCredit(result, practiceId, submitInvoice);
    const canIssueCredits = useHasCapability('billing', 'billing.issue_credits');

    if (!canIssueCredits) {
        return null;
    }

    return enableCreditAndRefundOverhaul ? (
        <>
            <CreditOrRefundWrapper
                open={open}
                setOpen={setOpen}
                organizationId={practiceId}
                refetchCredits={refetchCredits}
            />
            {useTextButton ? (
                <Button variant={'secondary'} onClick={() => setOpen(true)}>
                    Issue Credit
                </Button>
            ) : (
                <IssueCreditButton variant={'primary'} onClick={() => setOpen(true)} />
            )}
        </>
    ) : (
        <RootActionDialog
            loading={invoicesLoading || submittingInvoice || creditCategoriesLoading}
            open={open}
            setOpen={setOpen}
            title={`Create Credit for ${practiceName}`}
            content={
                <Grid container>
                    <QuickForm<FormVars>
                        fields={{
                            apply_to_existing_invoice_id: {
                                type: 'select',
                                label: 'Credit Invoice',
                                helperText: `Will apply the credit amount to the invoice. Cannot exceed amount remaining.`,
                                optional: false,
                                options: [
                                    { value: NEXT_INVOICE_VALUE, label: nextInvoiceText },
                                    ...(invoiceOptionsForCredit ?? []),
                                ],
                                fieldProps: {
                                    variant: 'standard', // controlling this input to allow the ACH warning to select "next invoice"
                                    inputProps: { value: selectedInvoiceId, onChange: handleChangeInvoiceId },
                                },
                                afterContent: (
                                    <ACHPendingPaymentWarning
                                        isVisible={isACHPaymentOutstanding}
                                        handleClick={() => {
                                            setSelectedInvoiceId(NEXT_INVOICE_VALUE);
                                        }}
                                    />
                                ),
                            },
                            amount_dollars: {
                                type: 'number',
                                validation: selectedInvoice
                                    ? DefaultValidationMap.number.min(1).max(selectedInvoice.amount_remaining / 100)
                                    : DefaultValidationMap.number.min(1),
                                fieldProps: {
                                    InputProps: { startAdornment: '$', style: { paddingLeft: '14px' } },
                                    disabled: isACHPaymentOutstanding,
                                },
                            },
                            description: {
                                type: 'text',
                                validation: DefaultValidationMap.text.min(1),
                                fieldProps: { disabled: isACHPaymentOutstanding },
                            },
                            expires: {
                                type: 'date',
                                label: 'Expiration Date',
                                hidden: selectedInvoiceId !== NEXT_INVOICE_VALUE,
                                optional: true,
                                fieldProps: { disablePast: true, disabled: isACHPaymentOutstanding },
                            },
                            credit_category_id: {
                                type: 'select',
                                label: 'Category',
                                options: creditCategoryOptions,
                                fieldProps: { variant: 'standard', disabled: isACHPaymentOutstanding },
                            },
                        }}
                        onChange={formValues => {
                            // the value in the form can be null, so we coalesce to a string
                            // since the invoice input is a controlled <string>input. We convert the
                            // "next invoice" string back to undefined on form submission
                            const invoiceId = formValues.apply_to_existing_invoice_id ?? NEXT_INVOICE_VALUE;
                            if (invoiceId !== selectedInvoiceId) {
                                setSelectedInvoiceId(invoiceId);
                            }
                        }}
                        resetOnInitialValueChange={true}
                        initialValues={{
                            apply_to_existing_invoice_id: selectedInvoiceId,
                            amount_dollars: 0,
                            description: '',
                        }}
                        onSubmit={onSubmitCredit}
                    />
                </Grid>
            }
            buttonText={'Issue Credit'}
            buttonProps={{ variant: 'primary', style: { height: '100%' } }}
            CustomButton={CustomButton ?? IssueCreditButton}
        />
    );
};
