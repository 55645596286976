import { AnalyticsClient } from '../../../../../../../utils/analyticsClient';
import { DoctorDesignTaskAlerts } from '../DoctorDesignTaskAlerts';
import type { OrderDesignSubmissionCoreProps } from './OrderDesignSubmissionCommon.graphql';
import { OrderDesignSubmissionCommon } from './OrderDesignSubmissionCommon.graphql';
import type {
    LabsGqlCompleteInternalDesignTaskMutationVariables,
    LabsGqlWorkflowTaskFragment,
} from '@orthly/graphql-operations';
import { useCompleteInternalDesignTaskMutation } from '@orthly/graphql-react';
import { LabsGqlWorkflowTaskType } from '@orthly/graphql-schema';
import { LoadBlocker, useChangeSubmissionFn } from '@orthly/ui';
import { FlossPalette, Text, Grid, Button, stylesFactory } from '@orthly/ui-primitives';
import {
    useFirebaseFileDownload,
    PreviousDesignRejectionDetailsFromTask,
    DesignFinishingStartBanner,
} from '@orthly/veneer';
import _ from 'lodash';
import React from 'react';

const useStyles = stylesFactory(() => ({
    finishingBanner: {
        marginBottom: '8px',
    },
}));

type Vars = LabsGqlCompleteInternalDesignTaskMutationVariables['data'];

export const OrderInternalDesignTaskAlert: React.FC<OrderDesignSubmissionCoreProps> = ({
    order,
    variant,
    refetchOrder,
}) => {
    const classes = useStyles();

    const latestDesignPath = order.design_file_path;
    const downloadPreppedFile = useFirebaseFileDownload(latestDesignPath || '', _.last(latestDesignPath?.split('/')));

    const [submitMtn] = useCompleteInternalDesignTaskMutation();
    const mtnSubmitter = (data: Vars) => submitMtn({ variables: { data } });
    const { submit, submitting } = useChangeSubmissionFn<any, [Vars]>(mtnSubmitter, {
        closeOnComplete: true,
        successMessage: () => [`Design task completed!`, {}],
        onSuccess: async () => {
            await refetchOrder();
            AnalyticsClient.track(`Ops - Portal - Internal Design Task Completed`, {
                $groups: {
                    order: order.id,
                },
            });
        },
    });

    const CardSubtitle = React.useCallback(
        (props: { task: LabsGqlWorkflowTaskFragment }) => {
            if (props.task.__typename !== 'InternalDesignWorkflowTask') {
                return null;
            }

            const resolvedScanNotes = props.task.configuration.resolved_scan_notes;

            return (
                <>
                    {resolvedScanNotes && (
                        <Grid container>
                            <Text variant={'body2'} color={'DARK_GRAY'}>
                                Notes from resolved scan:{' '}
                                <span style={{ color: FlossPalette.BLACK }}>{resolvedScanNotes}</span>
                            </Text>
                        </Grid>
                    )}
                    <DoctorDesignTaskAlerts lab_slip_alerts={order.lab_slip_alerts} />
                    <PreviousDesignRejectionDetailsFromTask task={props.task} />
                </>
            );
        },
        [order.lab_slip_alerts],
    );

    const CardTopAction = React.useCallback(
        (props: { task: LabsGqlWorkflowTaskFragment }) => {
            if (props.task.__typename !== 'InternalDesignWorkflowTask') {
                return null;
            }

            const hasOrderBeenPrepped = !!props.task.configuration.prepped_design_file_path && !!latestDesignPath;

            return hasOrderBeenPrepped ? (
                <LoadBlocker blocking={downloadPreppedFile.loading} ContainerProps={{ style: { width: 'auto' } }}>
                    <Button onClick={() => downloadPreppedFile.execute()} variant={'ghost'} startIcon={'CubeIcon'}>
                        Prep File
                    </Button>
                </LoadBlocker>
            ) : null;
        },
        [downloadPreppedFile, latestDesignPath],
    );

    const CardTitle = React.useCallback((props: { task: LabsGqlWorkflowTaskFragment }) => {
        if (props.task.__typename !== 'InternalDesignWorkflowTask') {
            return null;
        }

        const isRevision = props.task.configuration.is_revision;
        return <>Internal Design{isRevision ? ' (revision)' : ''}</>;
    }, []);

    return (
        <>
            <DesignFinishingStartBanner className={classes.finishingBanner} orderId={order.id} variant={'finishing'} />
            <OrderDesignSubmissionCommon
                taskType={LabsGqlWorkflowTaskType.InternalDesign}
                onSubmit={async ({
                    design_file_path,
                    notes,
                    metafields,
                    scan_arch_kind,
                    scan_stump_shades,
                    overrodeValidation,
                    design_prep_feedback,
                    model_upper_stl_path,
                    model_lower_stl_path,
                    device_stl_path,
                }) => {
                    if (!design_file_path) {
                        return;
                    }

                    await submit({
                        metafields,
                        scan_arch_kind,
                        scan_stump_shades,
                        design_file_path,
                        design_prep_feedback,
                        orderId: order.id,
                        internal_notes: notes,
                        is_manual_upload: true,
                        override_validation: overrodeValidation,
                        model_upper_stl_path,
                        model_lower_stl_path,
                        device_stl_path,
                    });
                }}
                submitting={submitting}
                CardTitle={CardTitle}
                CardTopAction={CardTopAction}
                CardSubtitle={CardSubtitle}
                order={order}
                refetchOrder={refetchOrder}
                variant={variant}
            />
        </>
    );
};
