import { Box } from '@orthly/ui-primitives';
import React from 'react';

export const LandingPage3D: React.VFC = () => {
    return (
        <Box style={{ padding: 20 }}>
            <h1>3D Landing Page</h1>
            <p>Tools for testing in production!</p>
            <ul>
                <li>
                    <a href={'/team_3d/dcm_viewer'}>DCM Viewer</a>
                </li>
                <li>
                    <a href={'/team_3d/scan_review'}>Scan Review Multi Model Viewer</a>
                </li>
                <li>
                    <a href={'/team_3d/scan_review_shade_matching'}>Scan Review Shade Matching Model Viewer</a>
                </li>
            </ul>
        </Box>
    );
};
