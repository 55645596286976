import { useStageState } from '../AppState.hooks';
import { useFinishingCallbacks, useIsTrainingOrder } from '../OrderState.hooks';
import { useSnackbar } from 'notistack';
import { useAsyncCallback } from 'react-async-hook';

export function useSubmitAutomateReview(enableFinishingEditing: boolean) {
    const { startTimeMs, setIsReviewComplete } = useStageState();
    const { submitReviewDecision, closeWindow, onComplete } = useFinishingCallbacks();
    const isTraining = useIsTrainingOrder();
    const { enqueueSnackbar } = useSnackbar();

    const exitFinishing = () => {
        closeWindow(true);
        onComplete();
    };

    const { loading: approveLoading, execute: approve } = useAsyncCallback(async () => {
        await submitReviewDecision({ approved: true, reviewDurationMs: Date.now() - startTimeMs });
        enqueueSnackbar('Design approved for manufacturing!');
        exitFinishing();
    });

    const approveAutomateDesign = () => {
        const confirmed = confirm(
            isTraining
                ? 'Approve the current design?'
                : 'This order will now be sent to the lab for manufacturing. Are you sure you want to continue?',
        );
        if (confirmed) {
            void approve();
        }
    };

    const { loading: rejectLoading, execute: rejectAutomateDesign } = useAsyncCallback(async () => {
        await submitReviewDecision({ approved: false, reviewDurationMs: Date.now() - startTimeMs });
        enqueueSnackbar('Design rejection recorded');
        enableFinishingEditing ? setIsReviewComplete(true) : exitFinishing();
    });

    return {
        approveAutomateDesign,
        rejectAutomateDesign,
        loading: approveLoading || rejectLoading,
    };
}
