import type { AdminLabsOrderActionProps } from '../order-action-types';
import { useIsDesignActionEditable } from './DesignActionUtils';
import { DesignConfigDropdown } from './DesignConfigDropdown';
import { useConfigureWaxupMutation } from '@orthly/graphql-react';
import { LoadBlocker, useChangeSubmissionFn } from '@orthly/ui';
import React from 'react';

export const EditOrderWaxupRequirement: React.FC<AdminLabsOrderActionProps> = props => {
    const { order, refetchOrder } = props;
    const { fulfillment_workflow } = order;
    const { waxup_required } = fulfillment_workflow.configuration;
    const [submitMtn] = useConfigureWaxupMutation();
    const { submit, submitting } = useChangeSubmissionFn<any, []>(
        () => submitMtn({ variables: { order_id: order.id, waxup_required: !waxup_required } }),
        {
            successMessage: () => [`Design Preview ${waxup_required ? 'removed' : 'added'}`, {}],
            onSuccess: async () => {
                await refetchOrder();
            },
        },
    );
    const currentValue = { label: waxup_required ? 'Yes' : 'No', value: waxup_required };
    const isEditable = useIsDesignActionEditable(order, { allowAfterLabAccept: true });
    return (
        <div>
            <LoadBlocker blocking={submitting}>
                <DesignConfigDropdown<boolean>
                    options={[
                        { label: 'Yes', value: true },
                        { label: 'No', value: false },
                    ]}
                    selected={currentValue}
                    submit={async newValue => {
                        if (
                            newValue !== currentValue.value &&
                            window.confirm(
                                `${waxup_required ? 'Remove design preview from' : 'Add design preview to'} order?`,
                            )
                        ) {
                            await submit();
                            return true;
                        }

                        return false;
                    }}
                    disabled={!isEditable}
                />
            </LoadBlocker>
        </div>
    );
};
