import { useUsersAction } from '../state/Users.actions';
import { useUsersSelector } from '../state/Users.context';
import { DesignStaffSection } from './DesignStaffSection';
import { EditUserCommonFields } from './EditUserCommonFields';
import { EditUserRoles } from './EditUserRoles';
import { SalesforceSync } from './SalesforceSync';
import { useUpsertUser } from './hooks/useUpsertUser';
import { useGetUserQuery, useListUserPositionsQuery } from '@orthly/graphql-react';
import { LoadBlocker } from '@orthly/ui';
import { Grid, Divider, Button, Text } from '@orthly/ui-primitives';
import { isEqual } from 'lodash';
import React from 'react';
import { useParams, useRouteMatch } from 'react-router-dom';

export const EditUser: React.FC = () => {
    const { open } = useUsersSelector(s => ({ open: s.open }));
    const { edited } = useUsersSelector(s => ({ edited: s.edited }));
    const editUser = useUsersAction('START_EDITING');
    const [requiredFieldsCompleted, setRequiredFieldsCompleted] = React.useState<boolean>(false);

    // load user from network
    const { id } = useParams<{ id: string }>();
    const isNew = useRouteMatch('/users/new');

    const { data: userData } = useGetUserQuery({
        variables: { id },
        skip: !id || !!isNew,
    });

    // if we are loading from network, put that user data into state
    const getUser = userData?.getUser;
    const userFromState = useUsersSelector(s => s.user);

    if (id && getUser && !isEqual(getUser, userFromState)) {
        editUser({ user: getUser });
    }

    if (!!isNew && userFromState) {
        editUser({ user: undefined });
    }

    const user = useUsersSelector(s => s.user);
    const onRolesLoaded = useUsersAction('ROLES_LOADED');
    const { submit: upsertUser, submitting } = useUpsertUser();

    useListUserPositionsQuery({
        variables: { user_id: user?.id ?? '', active: null },
        skip: !user,
        onCompleted: result => {
            const roles = result.listUserPositions;
            if (roles) {
                onRolesLoaded({ roles });
            }
        },
    });

    if (!open) {
        return null;
    }

    return (
        <Grid container direction={'column'} style={{ padding: '16px 16px 48px' }}>
            <Grid container spacing={1} item direction={'column'}>
                <Grid item>
                    <Grid container spacing={2} style={{ padding: '8px 0px 16px 0px' }} direction={'row'}>
                        <Grid item>
                            <Text variant={'h3'}>{user ? 'Editing' : 'New User'}</Text>
                        </Grid>
                        {user && (
                            <Grid item>
                                <Text variant={'h3'} color={'DARK_GRAY'}>
                                    {user.first_name} {user.last_name}
                                </Text>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
                <Grid item>
                    <EditUserCommonFields setRequiredFieldsCompleted={setRequiredFieldsCompleted} />
                </Grid>
                <Grid item>
                    <LoadBlocker blocking={submitting}>
                        <Button
                            startIcon={'Save'}
                            onClick={upsertUser}
                            variant={'primary'}
                            disabled={!edited || !requiredFieldsCompleted}
                        >
                            Save User
                        </Button>
                    </LoadBlocker>
                </Grid>
                <Grid item>
                    <Divider />
                </Grid>
            </Grid>
            {id && (
                <>
                    <SalesforceSync userId={user?.id || ''} salesforceContactId={user?.salesforce_contact_id || ''} />
                    <Grid item>
                        <Divider />
                    </Grid>
                    <Grid container item>
                        <EditUserRoles />
                        <Grid item>
                            <Divider />
                        </Grid>
                    </Grid>
                    <DesignStaffSection userId={id} />
                </>
            )}
        </Grid>
    );
};
