import { Format } from '@orthly/runtime-utils';
import { PencilOutlinedIcon, XYZArrowsIcon } from '@orthly/ui';
import {
    Button,
    FlossPalette,
    Text,
    stylesFactory,
    useScreenIsMobileOrVerticalTablet,
    Chip,
    ZoomInIcon,
} from '@orthly/ui-primitives';
import React from 'react';

const useStyles = stylesFactory(theme => ({
    mobileChipContainer: {
        position: 'absolute',
        bottom: 16,
        left: 16,
        zIndex: 1,
    },
    bottomRightModelViewer: {
        position: 'absolute',
        bottom: 16,
        right: 16,
        zIndex: 1,
    },
    chip: {
        backgroundColor: FlossPalette.SECONDARY_BACKGROUND,
        margin: 8,
    },
    mobileMarkupBtn: {
        marginRight: 8,
    },
    zoomIcon: {
        marginRight: 8,
        [theme.breakpoints.down('lg')]: {
            marginRight: 0,
        },
    },
}));

interface GuidedWaxupModelCanvasButtonProps {
    setIsAnnotationListModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    displayMobileMarkupButton: boolean;
    markupButtonClick: () => void;
    zoomToVisButtonClick: () => void;
    annotatedImageUrls?: string[];
    resetCameraView: () => void;
}

export const GuidedWaxupModelCanvasButtons: React.VFC<GuidedWaxupModelCanvasButtonProps> = ({
    setIsAnnotationListModalOpen,
    displayMobileMarkupButton,
    markupButtonClick,
    zoomToVisButtonClick,
    annotatedImageUrls,
    resetCameraView,
}) => {
    const classes = useStyles();
    const isMobile = useScreenIsMobileOrVerticalTablet();
    return (
        <>
            {isMobile && annotatedImageUrls?.length && (
                <div className={classes.mobileChipContainer}>
                    <Chip
                        label={
                            <Text variant={'caption'} medium color={'SECONDARY_FOREGROUND'}>
                                {Format.pluralize('Screen Capture', annotatedImageUrls.length)} Saved
                            </Text>
                        }
                        onClick={() => setIsAnnotationListModalOpen(true)}
                        className={classes.chip}
                    />
                </div>
            )}
            <div className={classes.bottomRightModelViewer}>
                {displayMobileMarkupButton && (
                    <Button variant={'secondary'} onClick={markupButtonClick} className={classes.mobileMarkupBtn}>
                        <PencilOutlinedIcon color={'disabled'} /> Markup
                    </Button>
                )}
                <Button
                    title={'Restore camera view'}
                    variant={'secondary'}
                    onClick={resetCameraView}
                    className={classes.mobileMarkupBtn}
                >
                    <XYZArrowsIcon className={classes.zoomIcon} />
                    {!isMobile ? 'Reset View' : ''}
                </Button>
                <Button variant={'secondary'} onClick={zoomToVisButtonClick}>
                    <ZoomInIcon className={classes.zoomIcon} />
                    {!isMobile ? 'Zoom To Fit' : ''}
                </Button>
            </div>
        </>
    );
};
