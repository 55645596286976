import { useOrderDetailContext } from '../../state/OrderDetailProvider.graphql';
import { RequestRushDialog } from '../EditOrderSla/RequestRushDialog.graphql';
import { useFeatureFlag } from '@orthly/veneer';
import React from 'react';

export const OrderDetailToolbarRequestRush: React.FC = () => {
    const { order, refetch } = useOrderDetailContext();

    const { value: enableRushRequests } = useFeatureFlag('rushRequests');

    if (!order || order.manufacturer_sla.rush_request || !enableRushRequests) {
        return null;
    }

    return <RequestRushDialog order={order} refetch={refetch} />;
};
