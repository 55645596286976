import { PrintableSlipPatientInfo } from '../../../../../PrintableSlip/components/header/PrintableSlipPatientInfo';
import { PrintableSlipPracticeInfo } from '../../../../../PrintableSlip/components/header/PrintableSlipPracticeInfo';
import { PrintableSlipSectionTitle } from '../../../../../PrintableSlip/components/shared/PrintableSlipSectionTitle';
import { useFeatureFlag } from '../../../../../Providers/LaunchDarkly';
import { getOrderQRCodeSrcUrl } from '../../../../util/useLabSlipQrCodes';
import type { LabSlipCopyInfo } from './LabSlipOrderInfo';
import { LabSlipOrderInfo } from './LabSlipOrderInfo';
import { LabSlipOrderStickers } from './LabSlipOrderStickers';
import type { LabsGqlLabOrderForLabSlipFragment } from '@orthly/graphql-operations';
import { Grid, makeStyles, Text } from '@orthly/ui-primitives';
import React from 'react';
import QRCode from 'react-qr-code';

const useStyles = makeStyles({
    slipHeaderSection: {
        alignItems: 'flex-end',
        justifyContent: 'space-between',
    },
    practicePatientSection: {
        alignItems: 'flex-end',
        justifyContent: 'space-between',
        fontSize: '0.9rem !important',
        fontWeight: 'bold',
    },
    divider: {
        marginTop: '3px',
        marginBottom: '3px',
        width: '100%',
        borderBottom: '1px solid green',
    },
});

interface ILabSlipHeaderProps {
    order: Exclude<LabsGqlLabOrderForLabSlipFragment, 'stackAndPanCodes'>;
    stackCode?: string;
    copyInfo?: LabSlipCopyInfo;
    batchIds?: string[] | null;
}

/**
 * Renders the topmost section of the Order Lab Slip.
 * Contains the basic order information & QR code as well as the practice and patient names
 * @param props
 * @returns Topmost section of the Order Lab Slip
 */
export const LabSlipHeader: React.VFC<ILabSlipHeaderProps> = props => {
    const classes = useStyles();
    const { order, stackCode, copyInfo, batchIds } = props;
    const qrCodeSrcUrl = React.useMemo(() => getOrderQRCodeSrcUrl(order.id), [order.id]);
    const { value: hidePracticeDetails = false } = useFeatureFlag('hidePracticeDetailsWherePossible');

    return (
        <Grid container>
            <LabSlipOrderStickers order={order} />
            {/* Row 1 - LabSlipOrderInfo only */}
            <Grid container spacing={1} className={classes.slipHeaderSection}>
                <Grid item xs={12}>
                    <LabSlipOrderInfo order={order} stackCode={stackCode} copyInfo={copyInfo} batchIds={batchIds} />
                </Grid>
                <div className={classes.divider} />
            </Grid>
            {/* Row 2 - Labtrac QR, Address ID, and Portal QR */}
            <Grid container spacing={1} className={classes.slipHeaderSection} alignItems={'center'}>
                {/* Labtrac QR Code Section */}
                {order.labtrac_id && (
                    <Grid item xs={4} container justifyContent={'center'}>
                        <Grid container direction={'column'} alignItems={'center'}>
                            <QRCode value={`CN${order.labtrac_id}`} style={{ height: '2.5rem', width: '2.5rem' }} />
                            <PrintableSlipSectionTitle text={'Labtrac'} />
                        </Grid>
                    </Grid>
                )}
                {/* Address ID and Address Details */}
                <Grid
                    item
                    xs={4}
                    container
                    direction={'column'}
                    alignItems={'center'}
                    wrap={'nowrap'}
                    justifyContent={'center'}
                >
                    {order.delivery_address_alias && (
                        <>
                            <PrintableSlipSectionTitle text={'Address ID'} />
                            <Text variant={'h6'} style={{ fontSize: '0.9rem' }}>
                                {order.delivery_address_alias}
                            </Text>
                        </>
                    )}
                </Grid>
                {/* Portal QR Code Section */}
                {order.delivery_address_alias && (
                    <Grid item xs={4} container justifyContent={'center'}>
                        <Grid container direction={'column'} alignItems={'center'}>
                            <QRCode value={qrCodeSrcUrl} style={{ height: '2.5rem', width: '2.5rem' }} />
                            <PrintableSlipSectionTitle text={'Portal'} />
                        </Grid>
                    </Grid>
                )}
                <div className={classes.divider} />
            </Grid>
            {/* Row 3 */}
            <Grid container>
                <Grid container spacing={3} className={classes.slipHeaderSection}>
                    {!hidePracticeDetails && <PrintableSlipPracticeInfo order={order} />}
                    <PrintableSlipPatientInfo patient={order.patient} />
                </Grid>
            </Grid>
        </Grid>
    );
};
