import { LabsGqlOrderTrackerStepDefinitionStyle } from '@orthly/graphql-schema';
import type { TextColor } from '@orthly/ui-primitives';
import { FlossPalette, stylesFactory } from '@orthly/ui-primitives';
import type { CSSProperties } from 'react';

export const getStepperMargin = (padded?: boolean) => {
    if (!padded) {
        return 0;
    }

    return '12px 0 60px';
};

export const getStepperBackgroundColor = (needsAttention: boolean) => {
    return needsAttention ? FlossPalette.STROKE_LIGHT : FlossPalette.DARK_TAN;
};

export const getStepperBarColor = (needsAttention: boolean, simple?: boolean) => {
    if (simple) {
        return FlossPalette.GRAY;
    }

    if (needsAttention) {
        return FlossPalette.ATTENTION;
    }

    return FlossPalette.LIGHT_GRAY;
};

export const getStepperBarBaseWidth = (activeStepIndex: number, stepCount: number) => {
    return activeStepIndex * ((1 / (stepCount - 1)) * 100);
};

export const getStepperBarWidth = (activeStepIndex: number, stepCount: number, percentagePadding: number) => {
    // the number of steps multiplied by the width of each step,
    // plus any (optional) additional padding between the active step and the step that follows it
    const baseWidth = getStepperBarBaseWidth(activeStepIndex, stepCount) + percentagePadding;
    let addedWidth = 0;

    if (activeStepIndex === 0) {
        addedWidth = 20;
    } else if (activeStepIndex < stepCount - 1) {
        addedWidth = 16;
    }

    return `calc(${baseWidth}% + ${addedWidth}px)`;
};

export const getSubtitleAlignment = (idx: number, stepCount: number): CSSProperties['alignItems'] => {
    if (idx === 0) {
        return 'flex-start';
    }

    if (idx === stepCount - 1) {
        return 'flex-end';
    }

    return 'center';
};

export const getStepTitleColor = (
    index: number,
    activeStepIndex: number,
    isMobile: boolean,
    stepCount: number,
    alert?: boolean,
    simple?: boolean,
): TextColor => {
    if (simple) {
        return 'GRAY';
    }

    if (alert) {
        return 'ATTENTION';
    }

    if (index === activeStepIndex || (isMobile && index !== stepCount - 1)) {
        return 'BLACK';
    }

    return 'GRAY';
};

export const getStepSubtitleColor = (
    barVariant: LabsGqlOrderTrackerStepDefinitionStyle | undefined,
    index: number,
    stepCount: number,
    isPractice: boolean,
): TextColor => {
    const isLastStep = index === stepCount - 1;

    if (barVariant === LabsGqlOrderTrackerStepDefinitionStyle.Cancelled) {
        return 'GRAY';
    }

    if (isPractice && barVariant === LabsGqlOrderTrackerStepDefinitionStyle.Attention && isLastStep) {
        return 'ATTENTION';
    }

    if (isLastStep) {
        return 'BLACK';
    }

    return 'GRAY';
};

export const useStepperStyles = stylesFactory(() => ({
    stepper: {
        position: 'relative',
        borderRadius: 16,
    },
    step: {
        width: 8,
        aspectRatio: 1,
        borderRadius: '100%',
        margin: 4,
    },
    animatedStep: {
        animation: '$backgroundColorChange 2s infinite',
    },
    '@keyframes backgroundColorChange': {
        '0%': { backgroundColor: FlossPalette.WHITE },
        '50%': { backgroundColor: FlossPalette.DARK_GRAY },
    },
    stepTextWrapper: {
        position: 'absolute',
        top: 24,
    },
    firstStepTextWrapper: {
        left: 0,
    },
    lastStepTextWrapper: {
        right: 0,
        alignItems: 'end',
    },
    middleStepTextWrapper: {
        left: 0,
        alignItems: 'center',
    },
    stepWrapper: {
        zIndex: 1,
        position: 'relative',
    },
    inactiveStep: {
        background: FlossPalette.STROKE_DARK,
    },
    activeStep: {
        background: FlossPalette.WHITE,
    },
    stepText: {
        whiteSpace: 'nowrap',
    },
}));
