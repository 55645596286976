import type { RouterTabItem } from '../../../components/RouterTabs';
import { RouterTabs } from '../../../components/RouterTabs';
import { OrganizationUsersTable } from '../OrganizationDetails/OrganizationUsersTable';
import { PracticePaymentMethods } from './PracticePaymentMethods/PracticePaymentMethods.graphql';
import { PracticeSettings } from './PracticeSettings';
import { useQuery } from '@apollo/client';
import type { PracticePaymentMethodsQueryQuery } from '@orthly/graphql-inline-react';
import { graphql } from '@orthly/graphql-inline-react';
import { useHasCapability } from '@orthly/session-client';
import { Grid } from '@orthly/ui-primitives';
import { DeliveryAddresses, EditDeliveryAddressModal } from '@orthly/veneer';
import _ from 'lodash';
import React from 'react';
import type { RouteComponentProps } from 'react-router-dom';

interface PracticeSettingsBaseProps {
    titleAction?: React.ReactNode;
    Content: React.ReactNode;
}

export const PracticeDeliveryAddressesBase: React.FC<PracticeSettingsBaseProps> = props => {
    return (
        <Grid container alignContent={'flex-start'} justifyContent={'flex-end'}>
            {props.titleAction}
            <Grid container xs={12}>
                {props.Content}
            </Grid>
        </Grid>
    );
};

const PracticePaymentMethodsQuery = graphql(`
    query PracticePaymentMethodsQuery($limit: Int, $offset: Int, $partner_id: String!) {
        paymentMethods: getLabsPaymentMethods(partnerId: $partner_id) {
            ...PaymentSourceFragment
        }
        partnerBillingChangelog: fetchPartnerBillingChangelog(partner_id: $partner_id) {
            ...PartnerBillingChangelogEntryFragment
        }
        partnerBillingState: listPartnerBillingAccounts(limit: $limit, offset: $offset, partner_ids: [$partner_id]) {
            will_be_charged_cc_fee
        }
        getOrganization(id: $partner_id) {
            legal_name
        }
    }
`);

export const PracticeDetailsRoot: React.FC<RouteComponentProps<{ practiceId: string }>> = props => {
    const practiceId = props.match.params.practiceId;
    const [open, setOpen] = React.useState(false);

    const { data: practicePaymentMethodsInfoQueryData, loading: practicePaymentMethodsInfoLoading } =
        useQuery<PracticePaymentMethodsQueryQuery>(PracticePaymentMethodsQuery, {
            variables: {
                limit: null,
                offset: null,
                partner_id: practiceId,
            },
        });

    const showOpsPaymentMethodTab = useHasCapability('user', 'user.collect_payment_method');

    const tabItems: (RouterTabItem | undefined)[] = [
        showOpsPaymentMethodTab
            ? {
                  path: 'payment_methods',
                  label: 'Payment Methods',
                  Component: () => (
                      <PracticePaymentMethods
                          practiceId={practiceId}
                          loading={practicePaymentMethodsInfoLoading}
                          practicePaymentMethodsInfoQueryData={practicePaymentMethodsInfoQueryData}
                      />
                  ),
              }
            : undefined,
        {
            path: 'users',
            label: 'Users',
            Component: () => <OrganizationUsersTable organizationId={practiceId} />,
        },
        {
            path: 'delivery',
            label: 'Delivery',
            Component: () => (
                <PracticeDeliveryAddressesBase
                    titleAction={<EditDeliveryAddressModal open={open} setOpen={setOpen} partnerId={practiceId} />}
                    Content={<DeliveryAddresses partnerId={practiceId} />}
                />
            ),
        },
        {
            path: 'settings',
            label: 'Settings',
            Component: () => <PracticeSettings practiceId={practiceId} />,
        },
    ];
    return (
        <RouterTabs defaultPath={showOpsPaymentMethodTab ? 'payment_methods' : 'users'} items={_.compact(tabItems)} />
    );
};
