// // @ts-ignore
// import Worker from 'worker-loader!./worker.js';
//
// const worker = Worker(); // `new` is optional
//
// const ITERATIONS = 25;
//
// if (process.env.REACT_APP_USE_WEB_WORKER) {
//     console.time('Fibonnoci');
//     worker.postMessage({ iterations: ITERATIONS });
//
//     worker.addEventListener('message', (event: MessageEvent<{ result: number }>) => {
//         console.log(`Fibonnoci result after ${ITERATIONS} iterations: ${event.data.result}`, event);
//         console.timeEnd('Fibonnoci');
//     });
// }
