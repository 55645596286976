import type { FinishingStage } from '../AppState.hooks';
import { useDeformState, useSculptingState, useStageState } from '../AppState.hooks';
import { IPContactsStage } from './Stages/IPContactsStage';
import { OcclusionStage } from './Stages/OcclusionStage';
import { TouchUpStage } from './Stages/TouchUpStage';
import { stylesFactory, Button, Text, Grid, List, ListItem } from '@orthly/ui-primitives';
import React from 'react';

const useStyles = stylesFactory(() => ({
    tinyButton: {
        height: 32,
        minWidth: `0px`,
        padding: `2px 4px !important`,
    },
    finishingStagesNavigationWrapper: {
        height: 64,
        padding: 16,
        overflow: 'hidden',
    },
    finishingStagesNavigationTitle: {
        padding: `0px 14px`,
        textAlign: 'left',
        fontWeight: 600,
        margin: `auto 0px`,
        fontSize: 16,
        color: 'gray',
    },
    finishingStagesContainer: {
        height: '170px',
    },
    finishingStageRowWrapper: {
        padding: `10px 14px`,
        textAlign: 'left',
        cursor: 'pointer',
    },
    finishingStageRowWrapperSelected: {
        padding: `10px 14px`,
        textAlign: 'left',
        backgroundColor: '#E8F3E8',
        cursor: 'pointer',
    },
    finishingStageTitleRowWrapper: {
        borderTop: '1px solid #e0e0e0',
        padding: `5px 14px`,
        textAlign: 'left',
    },
}));

const FinishingTypeToLabelMap: { [K in FinishingStage['type']]: string } = {
    occlusion: 'Occlusion & marginal ridges',
    ip_contacts: 'IP contacts',
    touch_up: 'Touch up',
};

interface FinishingStageRowProps {
    selected: boolean;
    stage: FinishingStage;
    idx: number;
    setCurrentStageIdx: (idx: number) => void;
}

const FinishingStageRow: React.VFC<FinishingStageRowProps> = ({ selected, stage, idx, setCurrentStageIdx }) => {
    const classes = useStyles();

    return (
        <div
            role={'button'}
            className={selected ? classes.finishingStageRowWrapperSelected : classes.finishingStageRowWrapper}
            onClick={() => setCurrentStageIdx(idx)}
        >
            <Text variant={'body2'} medium color={selected ? 'PRIMARY_FOREGROUND' : 'BLACK'}>
                {FinishingTypeToLabelMap[stage.type]}
            </Text>
        </div>
    );
};

interface FinishingStageTitleRowProps {
    selected: boolean;
    stage: FinishingStage;
}

const FinishingStageTitleRow: React.VFC<FinishingStageTitleRowProps> = ({ selected, stage }) => {
    const classes = useStyles();

    return (
        <ListItem className={classes.finishingStageTitleRowWrapper}>
            <Grid item>
                <Text variant={'body2'} medium color={selected ? 'PRIMARY_FOREGROUND' : 'BLACK'}>
                    {FinishingTypeToLabelMap[stage.type]}
                </Text>
            </Grid>
        </ListItem>
    );
};

const FinishingStagesNavigation: React.VFC = () => {
    const classes = useStyles();
    const { stages, setCurrentStageIdx, currentStageIdx } = useStageState();
    const { setSculptingEnabled, enableAddBrush } = useSculptingState();
    const { setDeformEnabled } = useDeformState();

    const previousStageIdx = currentStageIdx - 1;
    const nextStageIdx = currentStageIdx + 1;

    React.useEffect(() => {
        const currentStage = stages[currentStageIdx];
        if (currentStage && currentStage?.type === 'occlusion') {
            setDeformEnabled();
        }
        if ((currentStage && currentStage?.type === 'ip_contacts') || currentStage?.type === 'touch_up') {
            setSculptingEnabled();
        }
        enableAddBrush();
    }, [currentStageIdx, stages, setSculptingEnabled, setDeformEnabled, enableAddBrush]);

    return (
        <Grid item container direction={'row'} className={classes.finishingStagesNavigationWrapper} spacing={1}>
            <Grid item xs className={classes.finishingStagesNavigationTitle}>
                Finishing
            </Grid>
            <Grid item>
                <Button
                    variant={'nav'}
                    className={classes.tinyButton}
                    disabled={previousStageIdx < 0}
                    onClick={() => setCurrentStageIdx(previousStageIdx)}
                >
                    Prev
                </Button>
            </Grid>

            <Grid item>
                <Button
                    variant={'nav'}
                    className={classes.tinyButton}
                    disabled={nextStageIdx > stages.length - 1}
                    onClick={() => setCurrentStageIdx(nextStageIdx)}
                >
                    Next
                </Button>
            </Grid>
        </Grid>
    );
};

export const FinishingStagesMenu: React.VFC = () => {
    const classes = useStyles();

    const stagesState = useStageState();

    const stages = stagesState.stages;
    const currentStage = stages[stagesState.currentStageIdx];

    return (
        <Grid container direction={'column'}>
            <FinishingStagesNavigation />

            <Grid item className={classes.finishingStagesContainer}>
                <List>
                    {stages.map((stage, idx) => (
                        <FinishingStageRow
                            key={idx}
                            selected={idx === stagesState.currentStageIdx}
                            stage={stage}
                            idx={idx}
                            setCurrentStageIdx={stagesState.setCurrentStageIdx}
                        />
                    ))}
                </List>
            </Grid>

            <Grid item>
                <List>{currentStage && <FinishingStageTitleRow selected={true} stage={currentStage} />}</List>
            </Grid>
            {currentStage?.type === 'occlusion' && <OcclusionStage />}
            {currentStage?.type === 'ip_contacts' && <IPContactsStage />}
            {currentStage?.type === 'touch_up' && <TouchUpStage />}
        </Grid>
    );
};
