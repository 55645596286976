import { EditCapabilitiesDialog } from './EditCapabilitiesDialog';
import { useMutation } from '@apollo/client';
import { graphql } from '@orthly/graphql-inline-react';
import type { LabsGqlDesignStaffAllTaskCapabilitiesDtoFragment } from '@orthly/graphql-operations';
import { useApolloRefetch, GetDesignStaffDocument } from '@orthly/graphql-react';
import { DesignPrepCapabilities, DesignPrepCapabilityTypeToName } from '@orthly/shared-types';
import { useRootActionCommand } from '@orthly/ui';
import _ from 'lodash';
import React from 'react';

const SetDesignStaffPrepCapabilitiesMutation = graphql(`
    mutation SetDesignStaffPrepCapabilities($userId: String!, $capabilities: DesignPrepCapabilitiesInput!) {
        setDesignStaffPrepCapabilities(user_id: $userId, capabilities: $capabilities) {
            id
        }
    }
`);

export const DesignPrepCapabilitiesDialog: React.FC<{
    userId: string;
    capabilities: LabsGqlDesignStaffAllTaskCapabilitiesDtoFragment['DesignPrep'];
}> = props => {
    const { userId, capabilities: existingCapabilities } = props;
    const refetch = useApolloRefetch();
    const mutation = useMutation(SetDesignStaffPrepCapabilitiesMutation);

    const { submit, submitting } = useRootActionCommand(mutation, {
        successMessage: 'Design Staff Updated!',
        onSuccess: () => {
            refetch.query(GetDesignStaffDocument);
        },
    });

    return (
        <EditCapabilitiesDialog
            title={`Edit Design Prep Capabilities`}
            existingCapabilities={_.omit(existingCapabilities, '__typename')}
            typeToName={DesignPrepCapabilityTypeToName}
            capabilitiesDef={DesignPrepCapabilities}
            onSubmit={capabilities => {
                void submit({
                    userId,
                    capabilities,
                });
            }}
            submitting={submitting}
        />
    );
};
