import type { BrushSettings } from '../PortalDesignEditor';
import * as THREE from 'three';

export interface DeformState {
    deformEnabled: boolean;
    setDeformEnabled: () => void;
    toggleDeformEnabled: () => void;
    brushSettings: BrushSettings;
    setBrushRadius: (radius: number) => void;
    setBrushIntensity: (intensity: number) => void;
    cameraControlsEnabled: boolean; // We need it to re-render the deform tools if camera controls are set
    setCameraControlsEnabled: React.Dispatch<React.SetStateAction<boolean>>;
}

export class MorphPointMesh extends THREE.Mesh<THREE.SphereGeometry, THREE.MeshBasicMaterial> {
    groupIndex: number = -1;
    point: THREE.Vector3 = new THREE.Vector3();
    precomputedNeighborPositions: THREE.Vector3[] = [];
    precomputedNeighbors: number[] = [];
    nearestVertexIndex: number = -1;
    nearestPointOnMesh?: THREE.Intersection;
    type: string = '';
    isMarginalRidge: boolean = false;
    fallOffPrimaryDirection: THREE.Vector3 | undefined = undefined;
    fallOffSecondaryDirection: THREE.Vector3 | undefined = undefined;
    movementDirection: THREE.Vector3 | undefined = undefined;
    originalMorphPoint: {
        x: number;
        y: number;
        z: number;
    } = { x: 0, y: 0, z: 0 };
}

export type MorphPointsGroup = MorphPointMesh[];
