import { DesignTrainingFullscreenViewer } from './DesignTrainingFullscreenViewer';
import { NarrowButton } from './NarrowButton';
import type { DesignTrainingOrderFragment } from '@orthly/graphql-inline-react';
import { useOrder } from '@orthly/graphql-react';
import { useQueryParam, StackX } from '@orthly/ui';
import { CircularProgress, Fade, FlossPalette, Popover, Text, styled } from '@orthly/ui-primitives';
import { useDesignOrderRevision, DesignViewerLiteWrapper, ModelAssetCacheProvider } from '@orthly/veneer';

const Container = styled('div')({ height: '100%', width: '100%', position: 'relative' });
const TitleRow = styled(StackX)({ position: 'absolute', top: 8, right: 16 });

/** A DesignViewerLite-based viewer for training orders. */
export const DesignTrainingMiniViewer: React.VFC<{ trainingOrder: DesignTrainingOrderFragment }> = ({
    trainingOrder,
}) => {
    const { order: labOrder } = useOrder(trainingOrder.orderId);

    // If the design is rejected show the latest design revision.
    // Otherwise show the original design revision.
    const revisionId =
        (!trainingOrder.automateDesignApproved && trainingOrder.latestDesignRevisionId) ||
        trainingOrder.designRevisionId;
    const { data: designRevisionData } = useDesignOrderRevision(revisionId ?? '');
    const designRevision = designRevisionData?.getDesignOrderDesignRevisionById;

    const [fullscreen, setFullscreen] = useQueryParam('fs');

    if (!labOrder || !designRevision) {
        return <CircularProgress />;
    }

    return (
        // We want to use the cache to share assets between the mini viewer and the full-screen viewer
        // for a single order. Use a `key` prop to release the cache when the order changes.
        <ModelAssetCacheProvider key={trainingOrder.id}>
            <Container>
                <DesignViewerLiteWrapper
                    order={labOrder}
                    designFragment={designRevision}
                    style={{
                        height: '100%',
                        width: '100%',
                        border: `1px solid ${FlossPalette.STROKE_LIGHT}`,
                        borderRadius: 8,
                    }}
                />
                <TitleRow>
                    <Text variant={'body2'} color={'GRAY'}>
                        {`Case #${trainingOrder.orderNumber} preview — `}
                    </Text>
                    <NarrowButton variant={'ghost'} onClick={() => setFullscreen('t')}>
                        Open fullscreen view
                    </NarrowButton>
                </TitleRow>

                <Popover
                    anchorReference={'none'}
                    open={!!fullscreen}
                    PaperProps={{
                        style: { width: '100vw', height: '100vh', maxWidth: '100vw', maxHeight: '100vh' },
                    }}
                    TransitionComponent={Fade}
                >
                    {fullscreen && (
                        <DesignTrainingFullscreenViewer
                            order={labOrder}
                            design={designRevision}
                            caseNumber={trainingOrder.orderNumber}
                            close={() => setFullscreen(undefined)}
                        />
                    )}
                </Popover>
            </Container>
        </ModelAssetCacheProvider>
    );
};
