import type { DesignQcRubricCategoryEntry } from './DesignQc.config.types';
import { DEFAULT_SCAN_APPEARANCE, DEFAULT_RESTORATIVE_APPEARANCE, RestorativeView } from '@orthly/dentin';
import { HeatMapType } from '@orthly/forceps';

export const ponticsRubric: DesignQcRubricCategoryEntry = {
    category: 'pontics',
    subcategories: [
        {
            category: 'pontics',
            name: 'Tissue Pressure',
            grading: {
                type: 'binary',
                bad: '<100% of pontic pressures tissue within acceptable band',
                ideal: '100% of pontic pressures tissue within acceptable band',
            },
            description: `Tissue depth is at least .05mm of intersection, but less than .25mm of pressure`,
            selectable_bad_reasons: ['<100% of pontic pressures tissue within acceptable band'],
            edge_case: `Fresh Extractions, hygienic pontics, and pontics over thin tissue`,
            presets: {
                view_direction_preset: {
                    type: 'axis_view',
                    view: 'opposing_from_prep',
                },
                payload_presets: {
                    restoratives_cad: {
                        ...DEFAULT_RESTORATIVE_APPEARANCE,
                        visible: false,
                    },
                    preparation_jaw: {
                        ...DEFAULT_SCAN_APPEARANCE,
                        visible: true,
                    },
                    opposing_jaw: {
                        ...DEFAULT_SCAN_APPEARANCE,
                        visible: false,
                    },
                    printed_models: {
                        ...DEFAULT_SCAN_APPEARANCE,
                        visible: false,
                    },
                },
                qc_settings: {
                    activeHeatMap: HeatMapType.TissuePressure,
                    restorativeView: RestorativeView.HeatMap,
                },
            },
            when: flags => flags.hasBridge,
        },
        {
            category: 'pontics',
            name: 'Tissue Contact',
            grading: {
                type: 'tertiary',
                bad: 'Pontic contacts <X% of viable surface',
                acceptable: 'Pontic contacts between X% and Y% of viable surface',
                ideal: 'Pontic contacts >X% of viable surface',
            },
            description: `Pontic contacts X% of viable surface`,
            selectable_bad_reasons: ['<100% of pontic pressures tissue within acceptable band'],
            edge_case: `Fresh Extractions, hygienic pontics, and pontics over thin tissue`,
            presets: {
                view_direction_preset: {
                    type: 'axis_view',
                    view: 'opposing_from_prep',
                },
                payload_presets: {
                    restoratives_cad: {
                        ...DEFAULT_RESTORATIVE_APPEARANCE,
                        visible: true,
                    },
                    preparation_jaw: {
                        ...DEFAULT_SCAN_APPEARANCE,
                        visible: true,
                    },
                    opposing_jaw: {
                        ...DEFAULT_SCAN_APPEARANCE,
                        visible: false,
                    },
                    printed_models: {
                        ...DEFAULT_SCAN_APPEARANCE,
                        visible: false,
                    },
                },
            },
            when: flags => flags.hasBridge,
        },
        {
            category: 'pontics',
            name: 'Adherence to Style Request',
            grading: {
                type: 'binary',
                bad: 'Does not match requested pontic design',
                ideal: 'Matches requested pontic design',
            },
            description: `Does the intaglio surface of the pontic match what the doctor requested?`,
            selectable_bad_reasons: ['Does not match requested pontic design'],
            edge_case: `Tissue site is not prepped for an ovate pontic, default to modified ridge.
            <br><br>If Ovate pontic style fits an extraction space better than Dr's preference, it's ok to change.`,
            presets: {
                view_direction_preset: {
                    type: 'axis_view',
                    view: 'FRONT',
                },
                payload_presets: {
                    restoratives_cad: {
                        ...DEFAULT_RESTORATIVE_APPEARANCE,
                        visible: true,
                    },
                    preparation_jaw: {
                        ...DEFAULT_SCAN_APPEARANCE,
                        visible: false,
                    },
                    opposing_jaw: {
                        ...DEFAULT_SCAN_APPEARANCE,
                        visible: false,
                    },
                    printed_models: {
                        ...DEFAULT_SCAN_APPEARANCE,
                        visible: false,
                    },
                },
            },
            when: flags => flags.hasBridge,
        },
        {
            category: 'pontics',
            name: 'Intaglio Contour',
            grading: {
                type: 'binary',
                bad: 'Not smooth',
                ideal: 'Smooth',
            },
            description: `Is the pontic intaglio smooth?`,
            selectable_bad_reasons: ['Not smooth'],
            presets: {
                view_direction_preset: {
                    type: 'axis_view',
                    view: 'opposing_from_prep',
                },
                payload_presets: {
                    restoratives_cad: {
                        ...DEFAULT_RESTORATIVE_APPEARANCE,
                        visible: true,
                    },
                    preparation_jaw: {
                        ...DEFAULT_SCAN_APPEARANCE,
                        visible: false,
                    },
                    opposing_jaw: {
                        ...DEFAULT_SCAN_APPEARANCE,
                        visible: false,
                    },
                    printed_models: {
                        ...DEFAULT_SCAN_APPEARANCE,
                        visible: false,
                    },
                },
            },
            when: flags => flags.hasBridge,
        },
        {
            category: 'pontics',
            name: 'Buccal-Gingival Apex Placement',
            grading: {
                type: 'tertiary',
                bad: 'Pontic extends beyond +/- 10% of abutment height',
                acceptable: 'Pontic extends within +/- 10% of abutment height',
                ideal: 'As close to perfect as situation permits (100%)',
            },
            description: `<strong>ONLY FOR MODIFIED AND FULL RIDGE LAP</strong>
                <br><br>Buccal-ginvigal edge of pontic should be as close as possible to abutment margin height`,
            selectable_bad_reasons: ['Pontic extends beyond +/- 10% of abutment height'],
            presets: {
                view_direction_preset: {
                    type: 'tooth_view',
                    view: 'buccal',
                },
                payload_presets: {
                    restoratives_cad: {
                        ...DEFAULT_RESTORATIVE_APPEARANCE,
                        visible: true,
                    },
                    preparation_jaw: {
                        ...DEFAULT_SCAN_APPEARANCE,
                        visible: true,
                    },
                    opposing_jaw: {
                        ...DEFAULT_SCAN_APPEARANCE,
                        visible: false,
                    },
                    printed_models: {
                        ...DEFAULT_SCAN_APPEARANCE,
                        visible: false,
                    },
                },
            },
            when: flags => flags.hasBridge,
        },
    ],
};
