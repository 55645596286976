import type { WaxupOrder } from '../../Waxups.types';
import type { OrderDesignPreviewDesign_FragmentFragment } from '@orthly/graphql-inline-react';
import { LabsGqlGuidedWaxupPresetType, LabsGqlOrderItemSkuType } from '@orthly/graphql-schema';
import { CartItemV2Utils, OrderItemV2Utils, ToothUtils, type IOrderItemV2DTO } from '@orthly/items';

const PRESET_PREFERRED_ORDER = [
    LabsGqlGuidedWaxupPresetType.ToothDesign,
    LabsGqlGuidedWaxupPresetType.MarginView,
    LabsGqlGuidedWaxupPresetType.AnteriorContour,
    LabsGqlGuidedWaxupPresetType.PosteriorContour,
    LabsGqlGuidedWaxupPresetType.ContactDesign,
    LabsGqlGuidedWaxupPresetType.MarginalRidge,
    LabsGqlGuidedWaxupPresetType.OcclusalAnatomy,
    LabsGqlGuidedWaxupPresetType.ContourView,
    LabsGqlGuidedWaxupPresetType.FacialAnatomy,
    LabsGqlGuidedWaxupPresetType.GeneralView,
] as const satisfies LabsGqlGuidedWaxupPresetType[];

type SkuPresetConfig = Record<'anterior' | 'posterior', LabsGqlGuidedWaxupPresetType[]>;

const PRESET_SKU_LOOKUP = {
    default: {
        anterior: [
            LabsGqlGuidedWaxupPresetType.ToothDesign,
            LabsGqlGuidedWaxupPresetType.MarginView,
            LabsGqlGuidedWaxupPresetType.ContourView,
        ],
        posterior: [
            LabsGqlGuidedWaxupPresetType.ContactDesign,
            LabsGqlGuidedWaxupPresetType.OcclusalAnatomy,
            LabsGqlGuidedWaxupPresetType.MarginView,
        ],
    },
    crownBridge: {
        anterior: [
            LabsGqlGuidedWaxupPresetType.ToothDesign,
            LabsGqlGuidedWaxupPresetType.MarginView,
            LabsGqlGuidedWaxupPresetType.AnteriorContour,
            LabsGqlGuidedWaxupPresetType.FacialAnatomy,
        ],
        posterior: [
            LabsGqlGuidedWaxupPresetType.PosteriorContour,
            LabsGqlGuidedWaxupPresetType.MarginView,
            LabsGqlGuidedWaxupPresetType.ContactDesign,
            LabsGqlGuidedWaxupPresetType.MarginalRidge,
            LabsGqlGuidedWaxupPresetType.OcclusalAnatomy,
        ],
    },
    implant: {
        anterior: [
            LabsGqlGuidedWaxupPresetType.ToothDesign,
            LabsGqlGuidedWaxupPresetType.AnteriorContour,
            LabsGqlGuidedWaxupPresetType.FacialAnatomy,
        ],
        posterior: [
            LabsGqlGuidedWaxupPresetType.PosteriorContour,
            LabsGqlGuidedWaxupPresetType.ContactDesign,
            LabsGqlGuidedWaxupPresetType.MarginalRidge,
            LabsGqlGuidedWaxupPresetType.OcclusalAnatomy,
        ],
    },
    veneer: {
        anterior: [
            LabsGqlGuidedWaxupPresetType.ToothDesign,
            LabsGqlGuidedWaxupPresetType.MarginView,
            LabsGqlGuidedWaxupPresetType.AnteriorContour,
        ],
        posterior: [],
    },
} as const satisfies Record<string, SkuPresetConfig>;

const SKU_PRESETS_MAPPING = {
    [LabsGqlOrderItemSkuType.Inlay]: PRESET_SKU_LOOKUP.default,
    [LabsGqlOrderItemSkuType.Other]: PRESET_SKU_LOOKUP.default,
    [LabsGqlOrderItemSkuType.Model]: PRESET_SKU_LOOKUP.default,
    [LabsGqlOrderItemSkuType.Bridge]: PRESET_SKU_LOOKUP.crownBridge,
    [LabsGqlOrderItemSkuType.Crown]: PRESET_SKU_LOOKUP.crownBridge,
    [LabsGqlOrderItemSkuType.Implant]: PRESET_SKU_LOOKUP.implant,
    [LabsGqlOrderItemSkuType.ImplantBridge]: PRESET_SKU_LOOKUP.implant,
    [LabsGqlOrderItemSkuType.Veneer]: PRESET_SKU_LOOKUP.veneer,
} as const satisfies Partial<Record<LabsGqlOrderItemSkuType, SkuPresetConfig>>;

const SKU_GENERAL_VIEW_FORCED = [
    LabsGqlOrderItemSkuType.Partial,
    LabsGqlOrderItemSkuType.Denture,
    LabsGqlOrderItemSkuType.Waxup,
] as const satisfies LabsGqlOrderItemSkuType[];

const arePresetsEnabledForSku = (sku: `${LabsGqlOrderItemSkuType}`): sku is keyof typeof SKU_PRESETS_MAPPING =>
    sku in SKU_PRESETS_MAPPING;

const arePresetsDisabledForSku = (sku: `${LabsGqlOrderItemSkuType}`) =>
    SKU_GENERAL_VIEW_FORCED.includes(sku as (typeof SKU_GENERAL_VIEW_FORCED)[number]);

const isSkuLimitedToDefaultPresets = (sku: `${LabsGqlOrderItemSkuType}`) =>
    arePresetsEnabledForSku(sku) && SKU_PRESETS_MAPPING[sku] === PRESET_SKU_LOOKUP.default;

const getDefaultPresetsForItems = (items: IOrderItemV2DTO[], extraPresets: Set<LabsGqlGuidedWaxupPresetType>) => {
    const position = items.every(item => ToothUtils.isAnterior(CartItemV2Utils.getUniqueUNNs(item)))
        ? 'anterior'
        : 'posterior';

    return new Set([LabsGqlGuidedWaxupPresetType.GeneralView, ...PRESET_SKU_LOOKUP.default[position], ...extraPresets]);
};

const sortPresets = (presets: LabsGqlGuidedWaxupPresetType[]) =>
    presets.sort((a, b) => PRESET_PREFERRED_ORDER.indexOf(a) - PRESET_PREFERRED_ORDER.indexOf(b));

const getPresetConfigForOrder = (
    order: WaxupOrder,
    review: OrderDesignPreviewDesign_FragmentFragment['doctor_review'],
    organizeGuidedWaxupPresetsBySKU: boolean,
): Set<LabsGqlGuidedWaxupPresetType> => {
    // If historical annotations are present, we should use them
    if (review?.annotations) {
        return new Set(review.annotations.map(annotation => annotation.preset_type));
    }

    // Always include GeneralView in the presets
    const presets = new Set<LabsGqlGuidedWaxupPresetType>([LabsGqlGuidedWaxupPresetType.GeneralView]);
    const parsedItems = order.items_v2.map(OrderItemV2Utils.parseItem);

    // If guided wax-ups are disabled for any sku on the order,
    // we should only include what we've gathered from the review plus the general view
    if (parsedItems.some(item => arePresetsDisabledForSku(item.sku))) {
        return presets;
    }

    // If we're not organizing by sku or the sku is limited to the default presets,
    // we should only include what we've gathered from the review plus the default presets
    if (!organizeGuidedWaxupPresetsBySKU || parsedItems.some(item => isSkuLimitedToDefaultPresets(item.sku))) {
        return getDefaultPresetsForItems(parsedItems, presets);
    }

    // Otherwise, we should include the presets for each sku
    for (const item of parsedItems) {
        const sku = `${item.sku}` as const;
        if (arePresetsEnabledForSku(sku)) {
            const position = ToothUtils.isAnterior(CartItemV2Utils.getUniqueUNNs(item)) ? 'anterior' : 'posterior';

            for (const presetType of SKU_PRESETS_MAPPING[sku][position]) {
                presets.add(presetType);
            }
        }
    }

    return presets;
};

export const getSortedPresetConfigForOrder = (
    order: WaxupOrder,
    review: OrderDesignPreviewDesign_FragmentFragment['doctor_review'],
    organizeGuidedWaxupPresetsBySKU: boolean,
) => {
    const presets = Array.from(getPresetConfigForOrder(order, review, organizeGuidedWaxupPresetsBySKU));
    return sortPresets(presets);
};
