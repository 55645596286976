import { useFeatureFlag } from '../Providers/LaunchDarkly';
import { showBrowserTabAlert } from './BrowserTabAlert';
import {
    StyledBoxButton,
    StyledButtonTitleIcon,
    StyledButtonTitleRow,
    StyledButtonsContainer,
    StyledDialogContentBox,
    StyledSubTitle,
    StyledTitle,
} from './styles';
import { useQuery } from '@apollo/client';
import { graphql } from '@orthly/graphql-inline-react';
import {
    usePreemptDesignTaskTimeoutMutation,
    useApolloRefetch,
    ListMyDesignTasksDocument,
} from '@orthly/graphql-react';
import type { LabsGqlDesignStaffDetailedStatusAssignment } from '@orthly/graphql-schema';
import { LabsGqlPreemptDesignTaskTimeoutAction } from '@orthly/graphql-schema';
import { useSession } from '@orthly/session-client';
import { LoadBlocker, useRootActionCommand } from '@orthly/ui';
import { FlossPalette, Dialog, Text } from '@orthly/ui-primitives';
import FaviconBell from '@orthly/ui/assets/logos/bell.png';
import NotificationSound from '@orthly/ui/assets/sounds/Alert_5.wav';
import moment from 'moment';
import React from 'react';

type Assignment = Pick<
    LabsGqlDesignStaffDetailedStatusAssignment,
    'auto_assignment_expiration' | 'order_id' | 'task_id'
>;

interface DesignerStatusTimeoutDialogProps {
    open: boolean;
    onClose: () => void;
    assignment: Assignment;
    refetch?: () => any;
}

// Keep in sync with DesignStaffCommandHandler.TASK_EXTENSION_MS
const TASK_EXTENSION_MINUTES = 30;

const VeneerDesignerStatusTimeoutDialog_Query = graphql(`
    query DesignerStatusTimeoutDialog_Query($orderId: String!) {
        lab_order(id: $orderId) {
            order_number
        }
    }
`);

export const DesignerStatusTimeoutDialog: React.FC<DesignerStatusTimeoutDialogProps> = props => {
    const { assignment, refetch, open, onClose } = props;
    const { value: notifyDesignerOfAssignmentExpiration } = useFeatureFlag('notifyDesignerOfAssignmentExpiration');

    const session = useSession();
    const user_id = session?.user_id ?? '';

    const { data: orderData } = useQuery(VeneerDesignerStatusTimeoutDialog_Query, {
        variables: {
            orderId: assignment.order_id,
        },
        // This query was causing other components to undesirably unmount and remount. Using the 'no-cache' fetch policy
        // prevents that from happening.
        fetchPolicy: 'no-cache',
    });
    const friendlyTimeout = React.useMemo(
        () => moment(assignment.auto_assignment_expiration ?? undefined).format('h:mm a'),
        [assignment],
    );

    const [loading, setLoading] = React.useState(false);

    const apolloRefetch = useApolloRefetch();
    const onMutationSuccess = React.useCallback(async () => {
        apolloRefetch.query(ListMyDesignTasksDocument);
        setLoading(false);
        onClose();
        refetch?.();
    }, [apolloRefetch, refetch, onClose]);

    const { submit: submitExtend } = useRootActionCommand(usePreemptDesignTaskTimeoutMutation(), {
        successMessage: `The task deadline has been extended.`,
        onSuccess: onMutationSuccess,
    });

    const { submit: submitRelease } = useRootActionCommand(usePreemptDesignTaskTimeoutMutation(), {
        successMessage: `The order has been released and your status has been updated.`,
        onSuccess: onMutationSuccess,
    });

    React.useEffect(() => {
        if (notifyDesignerOfAssignmentExpiration && open) {
            showBrowserTabAlert('Assignment expiring - Dandy Ops', NotificationSound, FaviconBell);
        }
    }, [open, notifyDesignerOfAssignmentExpiration]);

    if (!orderData) {
        return null;
    }

    // focus the window right after this renders
    // this will also run on re-render, should one occur, but the user should make a selection here prior to going back to
    // literally anything else on their system, so that's fine.
    // at some point soon I imagine we'll want to add browser notifications
    setTimeout(() => window.focus(), 1000);

    return (
        <Dialog
            open={open}
            onClose={(e: Event) => e.stopPropagation()}
            PaperProps={{ style: { borderRadius: 8, background: FlossPalette.WHITE } }}
        >
            <LoadBlocker blocking={loading}>
                <StyledDialogContentBox>
                    <StyledTitle variant={'h4'}>Assignment Expiring:</StyledTitle>
                    <StyledSubTitle variant={'body2'}>
                        Your assignment for order{' '}
                        <span style={{ color: FlossPalette.GRAY }}>({orderData.lab_order.order_number})</span> will time
                        out at {friendlyTimeout}
                    </StyledSubTitle>
                    <StyledButtonsContainer container>
                        <StyledBoxButton
                            onClick={() => {
                                setLoading(true);
                                void submitExtend({
                                    data: {
                                        user_id,
                                        order_id: assignment.order_id,
                                        task_id: assignment.task_id,
                                        action: LabsGqlPreemptDesignTaskTimeoutAction.Extend,
                                    },
                                });
                            }}
                        >
                            <StyledButtonTitleRow container>
                                <Text variant={'body2'} medium>
                                    Extend
                                </Text>
                                <StyledButtonTitleIcon />
                            </StyledButtonTitleRow>
                            <Text variant={'body2'} color={'GRAY'}>
                                Extend timeout by {TASK_EXTENSION_MINUTES} minutes. Keep your status on Active.
                            </Text>
                        </StyledBoxButton>

                        <StyledBoxButton
                            onClick={() => {
                                setLoading(true);
                                void submitRelease({
                                    data: {
                                        user_id,
                                        order_id: assignment.order_id,
                                        task_id: assignment.task_id,
                                        action: LabsGqlPreemptDesignTaskTimeoutAction.Release,
                                    },
                                });
                            }}
                        >
                            <StyledButtonTitleRow container>
                                <Text variant={'body2'} medium>
                                    Wind Down
                                </Text>
                                <StyledButtonTitleIcon />
                            </StyledButtonTitleRow>
                            <Text variant={'body2'} color={'GRAY'}>
                                Release order from your queue. Set your status to Paused.
                            </Text>
                        </StyledBoxButton>
                    </StyledButtonsContainer>
                    <Text variant={'caption'} color={'GRAY'}>
                        Assignment will be released automatically if you do not make a selection.
                    </Text>
                </StyledDialogContentBox>
            </LoadBlocker>
        </Dialog>
    );
};
