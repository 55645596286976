import type { TagsAutocompleteProps } from './TagsAutocomplete.types';
import type {
    LabsGqlTagFragment,
    LabsGqlCreateTagMutationVariables,
    LabsGqlEditTagMutationVariables,
    LabsGqlDeleteTagMutationVariables,
} from '@orthly/graphql-operations';
import { useCreateTagMutation, useEditTagMutation, useDeleteTagMutation } from '@orthly/graphql-react';
import { useHasCapability } from '@orthly/session-client';
import type { SimpleSelectOption } from '@orthly/ui';
import { useChangeSubmissionFn, RootActionDialog, QuickForm } from '@orthly/ui';
import { Button, FlossPalette, makeStyles, createStyles, Grid } from '@orthly/ui-primitives';
import React from 'react';

const useStyles = makeStyles(() =>
    createStyles({
        deleteButton: {
            backgroundColor: `${FlossPalette.ATTENTION} !important`,
        },
    }),
);

interface TagDialogProps extends TagsAutocompleteProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    tag: Omit<LabsGqlTagFragment, 'id'> & { id?: string };
    onChange: () => void;
}

// color options to be presented in the color picker.
// note that this list is not backwards compatible.
// there may be colors stored in the database that are not reflected here.

export const TAG_STANDARD_COLORS: SimpleSelectOption[] = [
    { label: `Orange`, value: `#ECA356` },
    { label: `Yellow`, value: `#ECD556` },
    { label: `Lime`, value: `#C9EC56` },
    { label: `Light Blue`, value: `#56C8EC` },
    { label: `Maroon`, value: `#8F184B` },
    { label: `Blue`, value: `#567EEC` },
    { label: `Purple`, value: `#9256EC` },
    { label: `Fuchsia`, value: `#DD56EC` },
    { label: `Violet`, value: `#EC5697` },
    { label: `Black`, value: `#545350` },
    { label: `Dark Gray`, value: `#7E7C78` },
    { label: `Gray`, value: `#ECEAE6` },
    { label: `Light Gray`, value: `#FDFCFA` },
];

export const TagDialog: React.FC<TagDialogProps> = props => {
    const classes = useStyles();
    const { open, setOpen, entityType, entityId, tag, onChange } = props;

    const canDeleteTags = useHasCapability('tag', 'tag.delete');

    const [createTagMutation] = useCreateTagMutation();
    const { submit: createTag, submitting: creatingTag } = useChangeSubmissionFn(
        (variables: LabsGqlCreateTagMutationVariables) => createTagMutation({ variables }),
        {
            successMessage: () => [`New tag created`, {}],
            onSuccess: () => onChange(),
            onError: () => onChange(),
        },
    );

    const [editTagMutation] = useEditTagMutation();
    const { submit: editTag, submitting: editingTag } = useChangeSubmissionFn(
        (variables: LabsGqlEditTagMutationVariables) => editTagMutation({ variables }),
        {
            successMessage: () => [`Tag saved`, {}],
            onSuccess: () => onChange(),
            onError: () => onChange(),
        },
    );

    const [deleteTagMutation] = useDeleteTagMutation();
    const { submit: deleteTag, submitting: deletingTag } = useChangeSubmissionFn(
        (variables: LabsGqlDeleteTagMutationVariables) => deleteTagMutation({ variables }),
        {
            successMessage: () => [`Tag deleted`, {}],
            onSuccess: () => onChange(),
            onError: () => onChange(),
        },
    );

    return (
        <RootActionDialog
            loading={creatingTag || editingTag || deletingTag}
            open={open}
            setOpen={setOpen}
            title={`${tag.id ? `Edit` : `Create`} Tag`}
            content={
                <Grid container spacing={1}>
                    <Grid item>
                        <QuickForm<Omit<LabsGqlTagFragment, '__typename' | 'id' | 'entity_type' | 'entity_ids'>>
                            dirtySubmitOnly={!!tag.id}
                            fields={{
                                name: {
                                    type: `text`,
                                    label: `Tag Name`,
                                },
                                description: {
                                    type: `text`,
                                    label: `Tag Description`,
                                    optional: true,
                                },
                                color: {
                                    type: `select`,
                                    options: TAG_STANDARD_COLORS,
                                    label: `Tag Color`,
                                    optional: true,
                                },
                            }}
                            initialValues={{
                                name: tag.name,
                                description: tag.description,
                                color: tag.color,
                            }}
                            onSubmit={async data => {
                                if (!tag.id) {
                                    await createTag({
                                        ...data,
                                        entity_type: entityType,
                                        entity_ids: [entityId],
                                    });
                                    setOpen(false);
                                } else {
                                    await editTag({ ...data, id: tag.id });
                                    setOpen(false);
                                }
                            }}
                        />
                    </Grid>
                    <Grid item style={{ flex: `1` }}>
                        {tag.id && canDeleteTags && (
                            <Button
                                onClick={async () => {
                                    if (
                                        window.confirm(
                                            `Are you sure you want to remove this tag from every ${entityType.toLowerCase()}?`,
                                        )
                                    ) {
                                        await deleteTag({ id: tag.id as string });
                                        setOpen(false);
                                    }
                                }}
                                fullWidth
                                variant={'primary'}
                                className={classes.deleteButton}
                            >
                                Delete Tag Everywhere
                            </Button>
                        )}
                    </Grid>
                </Grid>
            }
            CustomButton={() => <></>}
            buttonText={``}
        />
    );
};
