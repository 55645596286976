import { OnHoldActionCard } from './OnHoldActionCard';
import { OrderCancelledActionCard } from './OrderCancelledActionCard';
import { OrderNeedsReviewAlert } from './OrderNeedsReviewAlert';
import type { LabsGqlOrder } from '@orthly/graphql-operations';
import { Grid } from '@orthly/ui-primitives';
import React from 'react';

interface OrderDetailAlertsProps {
    order: LabsGqlOrder;
    refetchOrder: () => Promise<unknown>;
}

export const OrderDetailAlerts: React.FC<OrderDetailAlertsProps> = props => {
    const { order, refetchOrder } = props;

    return (
        <Grid
            container
            item
            xs={12}
            style={{
                padding: '12px 12px 0',
            }}
        >
            <OnHoldActionCard order={order} refetchOrder={refetchOrder} />
            <OrderNeedsReviewAlert order={order} refetchOrder={refetchOrder} variant={'standard'} />
            <OrderCancelledActionCard order={order} />
        </Grid>
    );
};
