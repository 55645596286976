import { InlineEditButton } from '../../../components/InlineEditButton';
import { useMutation, useQuery } from '@apollo/client';
import { SimpleCopyToClipboard } from '@orthly/dentin';
import type { ListPracticeBillingContactUsersQuery } from '@orthly/graphql-inline-react';
import { graphql } from '@orthly/graphql-inline-react';
import { LabsGqlBillingContactUserType } from '@orthly/graphql-schema';
import { QFSelectField, QuickForm, RootActionDialog, useRootActionCommand } from '@orthly/ui';
import { styled, IconButton, Icon, Link, Tooltip } from '@orthly/ui-primitives';
import { useFeatureFlag } from '@orthly/veneer';
import React from 'react';
import { z } from 'zod';

type BillingContactUser = ListPracticeBillingContactUsersQuery['listPracticeBillingContactUsers'][number];

interface PrimaryBillingContactUserSelectComponentProps {
    users: BillingContactUser[];
}

const compareProfiles = (a: BillingContactUser, b: BillingContactUser): number => {
    // If active states are the same, sort by first_name
    return a.first_name.localeCompare(b.first_name);
};

const PrimaryBillingContactUserSelectComponent: React.FC<PrimaryBillingContactUserSelectComponentProps> = ({
    users,
}) => {
    const sortedUsers = users.sort(compareProfiles);
    return (
        <QFSelectField
            field={{
                options: sortedUsers.map(u => ({
                    value: u.id,
                    label: `${u.first_name} ${u.last_name} (${u.email})`,
                    disabled: !u.active,
                })),
                type: 'select',
                name: 'primary_billing_contact_user_id',
                label: 'Active Organization Member (Contact Email Address)',
                disableSortOptions: true,
            }}
        />
    );
};

const ListPracticeBillingContactUsers_Query = graphql(`
    query ListPracticeBillingContactUsers($practiceId: String!) {
        listPracticeBillingContactUsers(practice_id: $practiceId) {
            id
            first_name
            last_name
            active
            email
            type
        }
    }
`);

const UpdatePrimaryBillingContactUser_Mutation = graphql(`
    mutation UpdatePrimaryBillingContactUser($data: UpdatePrimaryBillingContactUserCommand!) {
        updatePrimaryBillingContactUser(data: $data) {
            id
        }
    }
`);

type UpdatePrimaryBillingContactUserProps = {
    practiceId: string;
    primaryBillingContactUserId: string;
    refetchPractice: () => void;
};

const FlexContainer = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
});

const StyledLink = styled(Link)({
    cursor: 'pointer',
    marginRight: '8px',
});

export const UpdatePrimaryBillingContactUser: React.FC<UpdatePrimaryBillingContactUserProps> = ({
    practiceId,
    primaryBillingContactUserId,
    refetchPractice,
}) => {
    const { value: enableOpsPortalUXImprovements } = useFeatureFlag('enableOpsPortalUXImprovements');
    const { data: { listPracticeBillingContactUsers: allPracticeBillingContactUsers = [] } = {}, loading } = useQuery(
        ListPracticeBillingContactUsers_Query,
        {
            variables: { practiceId },
            fetchPolicy: 'no-cache',
            nextFetchPolicy: 'no-cache',
        },
    );

    const activePracticeBillingContactUsers = allPracticeBillingContactUsers.filter(u => u.active);

    const primaryBillingContactUser = allPracticeBillingContactUsers.find(u => u.id === primaryBillingContactUserId);
    const primaryBillingContactUserFirstNameLastName = primaryBillingContactUser
        ? `${primaryBillingContactUser.first_name} ${primaryBillingContactUser.last_name}`
        : '';

    const updatePrimaryBillingContactUser = useMutation(UpdatePrimaryBillingContactUser_Mutation);
    const [open, setOpen] = React.useState(false);
    const { submit, submitting } = useRootActionCommand(updatePrimaryBillingContactUser, {
        onSuccess: () => {
            refetchPractice();
            setOpen(false);
        },
    });

    return (
        <RootActionDialog
            loading={loading || submitting}
            open={open}
            setOpen={setOpen}
            title={'Select Primary Billing Contact'}
            buttonText={''}
            CustomButton={({ onClick }) => (
                <FlexContainer>
                    {enableOpsPortalUXImprovements ? (
                        <>
                            {primaryBillingContactUser?.email && (
                                <StyledLink href={`mailto:${primaryBillingContactUser.email}`}>
                                    {primaryBillingContactUserFirstNameLastName}
                                </StyledLink>
                            )}
                            <Tooltip arrow title={'Edit'}>
                                <IconButton onClick={onClick} size={'small'}>
                                    <Icon icon={'PencilOutlinedIcon'} fontSize={'small'} />
                                </IconButton>
                            </Tooltip>
                            {primaryBillingContactUser?.email && (
                                <SimpleCopyToClipboard
                                    tooltip={'Copy email address'}
                                    size={'small'}
                                    value={primaryBillingContactUser.email}
                                    isIconButton={true}
                                />
                            )}
                        </>
                    ) : (
                        <InlineEditButton label={primaryBillingContactUserFirstNameLastName} onClick={onClick} />
                    )}
                </FlexContainer>
            )}
            content={
                <QuickForm<{ primary_billing_contact_user_id: string }>
                    fields={{
                        primary_billing_contact_user_id: {
                            type: 'custom',
                            component: () => (
                                <PrimaryBillingContactUserSelectComponent users={activePracticeBillingContactUsers} />
                            ),
                            label: 'Select Primary Billing Contact',
                            validation: z.string(),
                        },
                    }}
                    initialValues={{
                        primary_billing_contact_user_id: primaryBillingContactUserId ?? '',
                    }}
                    onSubmit={async result => {
                        await submit({
                            data: {
                                partner_id: practiceId,
                                new_primary_billing_contact_user_id: result.primary_billing_contact_user_id,
                                new_primary_billing_contact_user_type:
                                    allPracticeBillingContactUsers.find(
                                        s => s.id === result.primary_billing_contact_user_id,
                                    )?.type ?? LabsGqlBillingContactUserType.RetainerStaffMember,
                            },
                        });
                    }}
                    dirtySubmitOnly={true}
                />
            }
        />
    );
};
