import { useOrderDetailContext } from '../../state/OrderDetailProvider.graphql';
import { Button } from '@orthly/ui-primitives';
import { useCopyToClipboard } from '@orthly/veneer';
import React from 'react';

export const OrderDetailToolbarCopyId: React.FC = () => {
    const { order } = useOrderDetailContext();
    const orderId = order?.id;
    const copyToClipboard = useCopyToClipboard();
    const copyId = React.useCallback(() => {
        copyToClipboard({
            text: orderId ?? ``,
            successMessage: `Full order id copied to clipboard!`,
            errorMessage: `Failed to copy order id :(`,
        });
    }, [copyToClipboard, orderId]);

    return (
        <Button
            onClick={copyId}
            variant={`ghost`}
            startIcon={'CopyLinkIcon'}
            style={{ marginRight: 8 }}
            disabled={!orderId}
        >
            Order ID
        </Button>
    );
};
