import { DeleteNoteDialogContent } from './NotesSummary/DeleteNoteDialogContent';
import type { NotesSummaryMenuActionProps } from './NotesSummary/NotesSummary.types';
import { useEditOrderNoteMutation } from '@orthly/graphql-react';
import { useHasCapability } from '@orthly/session-client';
import { useChangeSubmissionFn, RootActionDialog, QuickForm } from '@orthly/ui';
import { PencilOutlinedIcon, TrashIcon, styled, Button } from '@orthly/ui-primitives';
import React from 'react';

type Args = { newNote: string | null };

const StyledNoteButton = styled(Button)({
    minWidth: 'unset',
    padding: 0,
    height: 'fit-content',
});

export const EditDoctorNotesAction: React.FC<NotesSummaryMenuActionProps> = ({
    refetch,
    childProps,
    open,
    setOpen,
}) => {
    const hasEditCapability = useHasCapability('order', 'order.doctor_notes.edit');
    const deleteNoteContent = childProps?.deleteNoteContent;
    const order = childProps?.order;

    const [submitNotesMtn] = useEditOrderNoteMutation();
    const mtnNotesSubmitter = ({ newNote }: Args) =>
        submitNotesMtn({ variables: { newNote, orderId: order?.id ?? '', category_ids: null } });
    const { submit, submitting } = useChangeSubmissionFn<any, [Args]>(mtnNotesSubmitter, {
        closeOnComplete: true,
        successMessage: () => ['Doctor note updated!', {}],
        onSuccess: async () => {
            if (refetch) {
                await refetch();
            }
            setOpen(false);
        },
    });

    const openButton = React.useMemo(() => {
        return (
            <StyledNoteButton variant={'ghost'} onClick={() => setOpen(true)}>
                {deleteNoteContent ? <TrashIcon /> : <PencilOutlinedIcon />}
            </StyledNoteButton>
        );
    }, [setOpen, deleteNoteContent]);

    if (!hasEditCapability) {
        return null;
    }

    return (
        <RootActionDialog
            loading={submitting}
            open={open}
            setOpen={setOpen}
            title={deleteNoteContent ? 'Are you sure you want to delete this note?' : 'Edit Doctor Notes'}
            showCloseButton={true}
            content={
                deleteNoteContent ? (
                    <DeleteNoteDialogContent
                        setOpen={setOpen}
                        submit={async () => {
                            await submit({ newNote: null });
                        }}
                    />
                ) : (
                    <QuickForm<{ doctor_notes?: string }>
                        fields={{
                            doctor_notes: {
                                type: 'text',
                                fieldProps: { multiline: true, minRows: 3, maxRows: 100 },
                            },
                        }}
                        initialValues={{
                            doctor_notes: order?.doctor_notes ?? undefined,
                        }}
                        onSubmit={async ({ doctor_notes }) => {
                            if (doctor_notes !== order?.doctor_notes) {
                                await submit({ newNote: doctor_notes ?? null });
                            }
                        }}
                    />
                )
            }
            CustomButton={() => openButton}
        />
    );
};
