import { ScanReviewShadeMatchingView } from './ScanReviewShadeMatchingView';
import { ScanReviewShadeMatchingViewAppProvider } from './ScanReviewShadeMatchingView.hooks';
import type { ScanReviewRecordFactory, ScanReviewViewState } from './ScanReviewTypes';
import type { Jaw } from '@orthly/shared-types';
import React from 'react';

export interface ScanReviewShadeMatchingRootProps {
    lowerJawFactory: ScanReviewRecordFactory | null;
    upperJawFactory: ScanReviewRecordFactory | null;
    jawType: Jaw | null;
    initialViewState: ScanReviewViewState;
    onShadePicked: (color: [number, number, number]) => void;
}

export const ScanReviewShadeMatchingRoot: React.FC<ScanReviewShadeMatchingRootProps> = ({
    upperJawFactory,
    lowerJawFactory,
    initialViewState,
    onShadePicked,
    jawType,
    ...props
}) => {
    return (
        <ScanReviewShadeMatchingViewAppProvider
            upperJawFactory={upperJawFactory}
            lowerJawFactory={lowerJawFactory}
            viewState={initialViewState}
            onShadePicked={onShadePicked}
        >
            <ScanReviewShadeMatchingView jawType={jawType} maxRadiusMm={1.6} />
            {props.children}
        </ScanReviewShadeMatchingViewAppProvider>
    );
};
