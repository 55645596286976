import { getAdminReduxMiddleware } from './admin-analytics.middleware';
import { rootReducer } from './index';
import type { AdminState } from './redux.types';
import * as Sentry from '@sentry/react';
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import type { History } from 'history';
import { applyMiddleware, compose, createStore } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import type { PersistPartial } from 'redux-persist/es/persistReducer';
import localStorage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';

export const initRedux = (history: History) => {
    const initialState = {};
    const isDev = process.env.NODE_ENV === 'development' || process.env.REACT_APP_STAGING === 'true';
    const composeEnhancers =
        (isDev &&
            (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
            (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
                trace: false,
                traceLimit: 5,
            })) ||
        compose;

    const composedEnhancers = composeEnhancers(
        applyMiddleware(...getAdminReduxMiddleware([routerMiddleware(history), thunk])),
        Sentry.createReduxEnhancer(),
    );

    const persistedReducer = persistReducer<AdminState>(
        // don't persist the support state, no benefit
        { key: 'root', storage: localStorage, blacklist: ['support'] },
        rootReducer(history),
    );

    const store = createStore<AdminState & PersistPartial, any, any, any>(
        persistedReducer,
        initialState as any,
        composedEnhancers,
    );

    return store;
};

// we export singletons so that we use the same store across the entire app
export const reduxHistory = createBrowserHistory();
export const store = initRedux(reduxHistory);
export const persistor = persistStore(store);

if (window.Cypress || process.env.REACT_APP_NODE_ENV === 'test') {
    window.store = store;
}
