import type { EditPracticeContractSpendTermsProps } from './EditPracticeContractSpendTermsAction.graphql';
import type { PracticeContractSpendTermInfo } from './PracticeContracts.types';
import { useMutation } from '@apollo/client';
import { graphql } from '@orthly/graphql-inline-react';
import type { LabsGqlEffectiveDateInput, LabsGqlUpdateSpendTermInput } from '@orthly/graphql-schema';
import type { FieldDefArray } from '@orthly/ui';
import { QuickForm, useRootActionCommand } from '@orthly/ui';
import moment from 'moment';
import React from 'react';
import { z } from 'zod';

const UpdatePracticeContractSpendTerms_Mutation = graphql(`
    mutation UpdatePracticeContractSpendTerms($data: UpdatePracticeContractSpendTermsCommand!) {
        updatePracticeContractSpendTerms(data: $data) {
            spend_terms {
                id
                created_at
                effective_end_date
                effective_start_date
                spend_minimum_cents
            }
        }
    }
`);

const spendTermFieldDefinition = (): FieldDefArray => ({
    type: 'array',
    label: 'Monthly Minimum Commitment',
    elementName: 'Monthly Minimum Commitment',
    min: 1,
    of: {
        type: 'nested',
        fields: {
            id: {
                type: 'text',
                hidden: true,
                optional: true,
            },
            deleted_at: {
                type: 'date',
                hidden: true,
                optional: true,
            },
            effective_start_date: {
                type: 'date',
                label: 'Effective Month Start Date (Not Invoice Date)',
                optional: false,
                validation: z
                    .date()
                    .refine(
                        value => moment.utc(value).get('date') === 1,
                        'The selected date must be the first of the month',
                    ),
            },
            spend_minimum_dollars: {
                type: 'number',
                label: 'Minimum Commitment',
                optional: false,
                validation: z.number().min(0),
                fieldProps: { InputProps: { startAdornment: '$' } },
            },
        },
    },
});

type SpendTermInputEntry = {
    id: string | null;
    deleted_at: Date | null;
    effective_start_date: string | null;
    spend_minimum_dollars: number;
};

const convertDateToDateInput = (date: string | null): LabsGqlEffectiveDateInput => {
    const momentUtc = moment.utc(date).format('YYYY-MM-DD');
    const [year, month] = momentUtc.split('-');
    return { month: Number(month), year: Number(year) };
};

const toSpendTermFormValue = (term: PracticeContractSpendTermInfo): SpendTermInputEntry => {
    const { id, effective_start_date, spend_minimum_cents } = term;
    const momentUtc = moment.utc(effective_start_date).format('YYYY-MM-DD');
    return {
        id,
        deleted_at: null,
        effective_start_date: momentUtc,
        spend_minimum_dollars: Math.floor(spend_minimum_cents / 100),
    };
};

const parseInputEntry = (entry: SpendTermInputEntry): LabsGqlUpdateSpendTermInput => ({
    id: entry.id,
    effective_date: convertDateToDateInput(entry.effective_start_date),
    spend_minimum_cents: entry.spend_minimum_dollars * 100,
});

const filterAndParseChangedTerms = (
    originalTerms: PracticeContractSpendTermInfo[],
    formTerms: SpendTermInputEntry[],
): LabsGqlUpdateSpendTermInput[] => {
    const updates = formTerms
        .filter(term => {
            const originalTerm = originalTerms.find(ot => ot.id === term.id);
            if (!originalTerm) {
                return true;
            } else {
                return (
                    moment.utc(originalTerm.effective_start_date).diff(moment.utc(term.effective_start_date)) !== 0 ||
                    originalTerm.spend_minimum_cents !== term.spend_minimum_dollars * 100
                );
            }
        })
        .map(updatedTerm => parseInputEntry(updatedTerm));

    const deletions: LabsGqlUpdateSpendTermInput[] = originalTerms
        .filter(og => {
            return formTerms.find(ft => ft.id === og.id) === undefined;
        })
        .map(deletedTerm => ({
            id: deletedTerm.id,
            deleted_at: new Date().toString(),
            spend_minimum_cents: deletedTerm.spend_minimum_cents,
            effective_date: convertDateToDateInput(deletedTerm.effective_start_date ?? null),
        }));

    return [...updates, ...deletions];
};

type EditPracticeContractSpendTermsModalProps = EditPracticeContractSpendTermsProps & {
    closeDialog: () => void;
};

export const EditPracticeContractSpendTermsModal: React.FC<EditPracticeContractSpendTermsModalProps> = ({
    contractId,
    spendTerms,
    closeDialog,
    refetch,
}) => {
    const editSpendTerms = useMutation(UpdatePracticeContractSpendTerms_Mutation);
    const { submit, submitting } = useRootActionCommand(editSpendTerms, {
        successMessage: 'Updated monthly minimum commitments',
        onSuccess: async () => {
            await refetch();
            closeDialog();
        },
    });

    return (
        <>
            <QuickForm<{
                spend_terms: SpendTermInputEntry[];
            }>
                fields={{ spend_terms: spendTermFieldDefinition() }}
                initialValues={{
                    spend_terms: spendTerms.map(st => toSpendTermFormValue(st)),
                }}
                onSubmit={async result => {
                    const updatedTerms = filterAndParseChangedTerms(spendTerms, result.spend_terms);
                    await submit({
                        data: {
                            practice_contract_id: contractId,
                            spend_terms: updatedTerms,
                        },
                    });
                }}
                dirtySubmitOnly={true}
                disabled={submitting}
                resetOnInitialValueChange={true}
            />
        </>
    );
};
