/*
 * hotkeys implemented with useRegisterHotkeys are not case sensitive
 * hotkeys implemented with custom event listening are case sensitive
 * */

// reference: https://meetdandy.atlassian.net/browse/EPDCAD-865
export const DEFAULT_FINISHING_HOT_KEY_CONFIG = {
    toggleCollisions: 'C',
    toggleCollisionsWithCurtains: 'shift+C',
    toggleCurtains: 'F',
    toggleInsertionAxis: 'V',
    toggleCrossSectionActive: 'X',
    toggleMargin: 'H',
    toggleTextureOnScans: 'T',

    // Heatmaps
    toggleOcclusalHeatmap: 'Q',
    toggleProximalHeatmap: 'W',
    toggleThicknessHeatmap: 'E',
    toggleRestorativeUndercutHeatmap: 'G',
    toggleScanUndercutHeatmap: 'D',
    toggleSculptMask: 'M',

    // Scan appearance
    togglePrep: 'S',
    togglePreprep: 'P',
    toggleAntagonist: 'A',
    toggleRestorative: 'R',

    // undo/redo
    undoAction: 'ctrl+Z',
    redoAction: 'ctrl+shift+Z',

    // Camera View
    zoomToFront: 'alt+1',
    zoomToLeft: 'alt+2',
    zoomToRight: 'alt+3',
    zoomToTop: 'alt+4',
    zoomToBottom: 'alt+5',
    zoomToMesial: 'alt+6',
    zoomToDistal: 'alt+7',
    zoomToFacial: 'alt+8',
    zoomToLingual: 'alt+9',
    zoomToPOI: 'alt+0',

    // Sculpting
    sculptAdd: '+',
    sculptSubtract: '-',
    sculptSmooth: '*',
};
