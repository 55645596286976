import { AdminLabsEditInvoicingLineItemsAction } from '../../../../../Billing/actions/AdminLabsEditInvoicingLineItemsAction.graphql';
import { IssueCreditOrRefundButton } from '../../../../../Billing/actions/CreateCreditOrRefund/IssueCreditOrRefundButton';
import type { LabsGqlOrder } from '@orthly/graphql-operations';
import { Format } from '@orthly/runtime-utils';
import { styled, Text } from '@orthly/ui-primitives';
import { OrderDetailBlock, useFeatureFlag } from '@orthly/veneer';

const OrderDetailBillingPracticePriceWrapper = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    gap: 8,
});

interface OrderDetailBillingPracticePriceProps {
    labOrder: Pick<LabsGqlOrder, 'dentist_amount_due_cents'>;
}

const OrderDetailBillingPracticePrice: React.VFC<OrderDetailBillingPracticePriceProps> = ({ labOrder }) => {
    return (
        <OrderDetailBillingPracticePriceWrapper>
            <Text variant={'body1'} color={'GRAY'}>
                Practice Total
            </Text>
            <Text variant={'body1'} color={'BLACK'}>
                {Format.currency(labOrder.dentist_amount_due_cents ?? 0)}
            </Text>
        </OrderDetailBillingPracticePriceWrapper>
    );
};

interface OrderDetailBillingActionsProps {
    labOrder: LabsGqlOrder;
    refetch: () => Promise<unknown>;
}

const OrderDetailBillingActions: React.VFC<OrderDetailBillingActionsProps> = ({ labOrder, refetch }) => {
    const { value: enableCreditAndRefundOverhaul } = useFeatureFlag('enableCreditAndRefundOverhaul');

    return (
        <>
            <AdminLabsEditInvoicingLineItemsAction order={labOrder} refetchOrder={refetch} />
            {enableCreditAndRefundOverhaul && (
                <IssueCreditOrRefundButton
                    organizationId={labOrder.partner_id}
                    order={labOrder}
                    startIcon={'AddIcon'}
                />
            )}
        </>
    );
};

interface OrderDetailBillingBlockProps {
    labOrder: LabsGqlOrder;
    refetch: () => Promise<unknown>;
}

export const OrderDetailBillingBlock: React.VFC<OrderDetailBillingBlockProps> = ({ labOrder, refetch }) => {
    return (
        <OrderDetailBlock
            title={'Billing'}
            variant={'full'}
            actions={<OrderDetailBillingActions labOrder={labOrder} refetch={refetch} />}
        >
            <OrderDetailBillingPracticePrice labOrder={labOrder} />
        </OrderDetailBlock>
    );
};
