import { InfoIcon, Tooltip, styled } from '@orthly/ui-primitives';

const Container = styled('div')({
    alignItems: 'center',
});

const StyledIcon = styled(InfoIcon)({
    marginLeft: 4,
    width: 16,
    height: 16,
});

export const EditBillingContactsToolTip: React.FC = () => (
    <Container>
        Secondary Contact Emails
        <Tooltip
            title={
                'This field should only be used for practices whose primary billing contact is not stored in our system.'
            }
        >
            <StyledIcon color={'action'} />
        </Tooltip>
    </Container>
);
