import type { QcHeatmapRange } from '../ColorRamp';
import { ScanReviewCompleteView } from './ScanReviewCompleteView';
import { ScanReviewCompleteViewAppProvider } from './ScanReviewCompleteView.hooks';
import type { ScanReviewViewType } from './ScanReviewTypes';
import { type ScanReviewRecordFactory, type ScanReviewViewState } from './ScanReviewTypes';
import React from 'react';

export interface ScanReviewRootProps {
    initialViewType: ScanReviewViewType;
    lowerJawFactory: ScanReviewRecordFactory | null;
    upperJawFactory: ScanReviewRecordFactory | null;
    initialViewState: ScanReviewViewState;
    initialHeatmapRange: QcHeatmapRange;
}

export const ScanReviewCompleteViewRoot: React.FC<ScanReviewRootProps> = ({
    initialViewType,
    lowerJawFactory,
    upperJawFactory,
    initialViewState,
    initialHeatmapRange,
    children,
}) => {
    return (
        <ScanReviewCompleteViewAppProvider
            lowerJawFactory={lowerJawFactory}
            upperJawFactory={upperJawFactory}
            initialViewType={initialViewType}
            initialHeatmapRange={initialHeatmapRange}
            viewState={initialViewState}
        >
            <ScanReviewCompleteView />
            {children}
        </ScanReviewCompleteViewAppProvider>
    );
};
