import contacts_adjacent_undercuts_BAD from '../../../../assets/images/design-qc/contacts/contacts_adjacent_undercuts_BAD.png';
import contacts_adjacent_undercuts_BAD_2 from '../../../../assets/images/design-qc/contacts/contacts_adjacent_undercuts_BAD_2.png';
import contacts_adjacent_undercuts_GOOD from '../../../../assets/images/design-qc/contacts/contacts_adjacent_undercuts_GOOD.png';
import contacts_shape_contour_ALL from '../../../../assets/images/design-qc/contacts/contacts_shape_contour_ALL.png';
import contacts_shape_contour_BAD from '../../../../assets/images/design-qc/contacts/contacts_shape_contour_BAD.png';
import contacts_shape_contour_GOOD from '../../../../assets/images/design-qc/contacts/contacts_shape_contour_GOOD.png';
import contacts_vertical_ALL from '../../../../assets/images/design-qc/contacts/contacts_vertical_ALL.png';
import contacts_width_ALL from '../../../../assets/images/design-qc/contacts/contacts_width_ALL.png';
import type { DesignQcRubricCategoryEntry } from './DesignQc.config.types';
import { DEFAULT_SCAN_APPEARANCE, DEFAULT_RESTORATIVE_APPEARANCE, RestorativeView } from '@orthly/dentin';
import { HeatMapType } from '@orthly/forceps';

export const contactsRubric: DesignQcRubricCategoryEntry = {
    category: 'contacts',
    subcategories: [
        {
            category: 'contacts',
            name: 'Pressure / Depth',
            grading: {
                type: 'binary',
                bad: '< 60% of contact surface matches doctor preference (Tolerance threshold of +/-0.01mm)',
                ideal: 'At least 60% of contact surface matches doctor preference (Tolerance threshold of +/-0.01mm)',
            },
            description:
                'Depth relative to doctor-requested depth. Includes terminal molars<br><br>At least 60% of contact surface match doctor preference (Tolerance threshold of +/-0.01mm). Remainder of contact surface can be lighter, but not heavier. This is mostly to account for the perimeter of the contact surface',
            edge_case: 'Irregular contact exceptions - Increase tolerance',
            selectable_bad_reasons: [
                'Too TIGHT - Contact tightness does not match doctor preference',
                'Too LIGHT - Contact tightness does not match doctor preference',
            ],
            presets: {
                view_direction_preset: {
                    type: 'axis_view',
                    view: 'FRONT',
                },
                payload_presets: {
                    opposing_jaw: {
                        ...DEFAULT_SCAN_APPEARANCE,
                        visible: false,
                    },
                    preparation_jaw: {
                        ...DEFAULT_SCAN_APPEARANCE,
                        visible: false,
                    },
                    printed_models: {
                        ...DEFAULT_SCAN_APPEARANCE,
                        visible: false,
                    },
                    restoratives_cad: {
                        ...DEFAULT_RESTORATIVE_APPEARANCE,
                        visible: true,
                    },
                },
                qc_settings: {
                    activeHeatMap: HeatMapType.Proximal,
                    showCollisions: true,
                    showAnatomyLayers: false,
                    restorativeView: RestorativeView.HeatMap,
                },
            },
        },
        {
            category: 'contacts',
            name: 'Shape / Contour',
            grading: {
                type: 'binary',
                bad: '<100% of contact is smooth<br>OR<br>Concavity >30% of contact surface',
                ideal: '100% of contact is smooth<br>AND<br>Concavity <30% of contact surface',
            },
            examples: {
                bad: [contacts_shape_contour_BAD, contacts_shape_contour_ALL],
                ideal: [contacts_shape_contour_GOOD, contacts_shape_contour_ALL],
            },
            description:
                'Contact must be smooth<br><br><strong>And</strong> concave areas should be avoided. If they cannot, do not deviate from doctor preference by any more than -0.02mm. No more than 30% of your contact area should be concave.',
            edge_case:
                'Rough contacts<br><br>Adjacent contacts to rotated dentition can be slightly wrapped. Need to define acceptable levels of "chase" in the future',
            selectable_bad_reasons: ['Contact is not smooth', 'Contact surface has a large concavity/dent'],
            presets: {
                view_direction_preset: {
                    type: 'axis_view',
                    view: 'FRONT',
                },
                payload_presets: {
                    opposing_jaw: {
                        ...DEFAULT_SCAN_APPEARANCE,
                        visible: false,
                    },
                    preparation_jaw: {
                        ...DEFAULT_SCAN_APPEARANCE,
                        visible: false,
                    },
                    printed_models: {
                        ...DEFAULT_SCAN_APPEARANCE,
                        visible: false,
                    },
                    restoratives_cad: {
                        ...DEFAULT_RESTORATIVE_APPEARANCE,
                        visible: true,
                        showUndercutShadow: true,
                    },
                },
                qc_settings: {
                    showCollisions: true,
                    restorativeView: RestorativeView.CAD,
                },
            },
        },
        {
            category: 'contacts',
            name: 'Width/Broadness of contact',
            grading: {
                type: 'tertiary',
                bad: '<70% of possible width',
                acceptable: '70-90% of possible width',
                ideal: '90-100% of possible width',
            },
            examples: {
                bad: [contacts_width_ALL],
                acceptable: [contacts_width_ALL],
                ideal: [contacts_width_ALL],
            },
            description: 'Contact should be as wide as possible',
            edge_case: 'Worth noting - sometimes the widest possible contact is a pinpoint contact.',
            selectable_bad_reasons: [
                'Contact is not wide enough - Currently less than 70% of possible width',
                'Contact is wrapping around adjacent',
            ],
            presets: {
                view_direction_preset: {
                    type: 'axis_view',
                    view: 'prep_from_opposing',
                },
                payload_presets: {
                    opposing_jaw: {
                        ...DEFAULT_SCAN_APPEARANCE,
                        visible: false,
                    },
                    preparation_jaw: {
                        ...DEFAULT_SCAN_APPEARANCE,
                        visible: true,
                    },
                    printed_models: {
                        ...DEFAULT_SCAN_APPEARANCE,
                        visible: false,
                    },
                    restoratives_cad: {
                        ...DEFAULT_RESTORATIVE_APPEARANCE,
                        visible: true,
                        showUndercutShadow: true,
                    },
                },
                qc_settings: {
                    showCollisions: true,
                    restorativeView: RestorativeView.CAD,
                },
            },
        },
        {
            category: 'contacts',
            name: 'Vertical position of contact',
            grading: {
                type: 'tertiary',
                bad: 'Top of contact (occlusally) has reached <70% of possible height',
                acceptable: 'Top of contact surface (occlusally) has reached 70-90% of possible height',
                ideal: 'Top of contact surface (occlusally) has reached 90-100% of possible height ',
            },
            examples: {
                bad: [contacts_vertical_ALL],
                acceptable: [contacts_vertical_ALL],
                ideal: [contacts_vertical_ALL],
            },
            description:
                'Vertical position of contact (occlusally) should be as high up the adjacent contact surface as possible, and extend downward to cover the incisal 1/3',
            selectable_bad_reasons: ['Top of contact surface is too low (occlusally) – can be pulled up higher'],
            presets: {
                view_direction_preset: {
                    type: 'tooth_view',
                    view: 'buccal',
                },
                payload_presets: {
                    opposing_jaw: {
                        ...DEFAULT_SCAN_APPEARANCE,
                        visible: false,
                    },
                    preparation_jaw: {
                        ...DEFAULT_SCAN_APPEARANCE,
                        visible: true,
                    },
                    printed_models: {
                        ...DEFAULT_SCAN_APPEARANCE,
                        visible: false,
                    },
                    restoratives_cad: {
                        ...DEFAULT_RESTORATIVE_APPEARANCE,
                        visible: true,
                        showUndercutShadow: true,
                    },
                },
                qc_settings: {
                    showCollisions: true,
                    restorativeView: RestorativeView.CAD,
                },
            },
        },
        {
            category: 'contacts',
            name: 'Vertical position',
            grading: {
                type: 'tertiary',
                bad: '<70% of possible height AND/OR Contact is beyond the incisal half',
                acceptable: '70-90% of possible height',
                ideal: '90-100% of possible height AND Contact is within the incisal half',
            },
            examples: {
                bad: [contacts_vertical_ALL],
                acceptable: [contacts_vertical_ALL],
                ideal: [contacts_vertical_ALL],
            },
            description: `Contact surface should not take up more than 50% of the vertical height of the axial wall. 
            <br><br>The contact should be as high as possible and begin at the bottom of the incisal embrasure.
            <br><br>Any more contact area makes insertion difficult`,
            selectable_bad_reasons: ['Contact surface is too low - can be pulled up higher'],
            presets: {
                view_direction_preset: {
                    type: 'tooth_view',
                    view: 'buccal',
                },
                payload_presets: {
                    opposing_jaw: {
                        ...DEFAULT_SCAN_APPEARANCE,
                        visible: false,
                    },
                    preparation_jaw: {
                        ...DEFAULT_SCAN_APPEARANCE,
                        visible: true,
                    },
                    printed_models: {
                        ...DEFAULT_SCAN_APPEARANCE,
                        visible: false,
                    },
                    restoratives_cad: {
                        ...DEFAULT_RESTORATIVE_APPEARANCE,
                        visible: true,
                        showUndercutShadow: true,
                    },
                },
                qc_settings: {
                    showCollisions: true,
                    restorativeView: RestorativeView.CAD,
                },
            },
            when: flags => flags.hasAnteriors,
        },
        {
            category: 'contacts',
            name: 'Adjacent Undercut',
            grading: {
                type: 'binary',
                bad: 'Any adjacent undercut is present',
                ideal: 'No adjacent undercut is present',
            },
            examples: {
                bad: [contacts_adjacent_undercuts_BAD, contacts_adjacent_undercuts_BAD_2],
                ideal: [contacts_adjacent_undercuts_GOOD],
            },
            description:
                'There should be no adjacent contact undercuts, and black triangles should not be excessively open',
            edge_case: `For QC/auditors - the mere presence of a purple undercut area is not reason for rejection. In order to be "ideal," the height of the purple undercut area (occlusal to gingival) must be NO MORE than 10% of the total height of the contact itself, and also must not exceed a depth of .02mm`,
            selectable_bad_reasons: ['Interproximal undercut is present'],
            presets: {
                view_direction_preset: {
                    type: 'tooth_view',
                    view: 'buccal',
                },
                payload_presets: {
                    opposing_jaw: {
                        ...DEFAULT_SCAN_APPEARANCE,
                        visible: false,
                    },
                    preparation_jaw: {
                        ...DEFAULT_SCAN_APPEARANCE,
                        visible: true,
                    },
                    printed_models: {
                        ...DEFAULT_SCAN_APPEARANCE,
                        visible: false,
                    },
                    restoratives_cad: {
                        ...DEFAULT_RESTORATIVE_APPEARANCE,
                        visible: true,
                        showUndercutShadow: true,
                        showInsertionAxis: true,
                    },
                },
                qc_settings: {
                    showCollisions: true,
                    restorativeView: RestorativeView.CAD,
                },
            },
        },
    ],
    design_standard: {
        googleEmbedStub: `2PACX-1vSvJg_Q_Afol4i1EtK9vfQUSk1ptnX6Q4Qg6R--zTBobPmjT8At7j59b5_Ml7peonXtqcFsnKryVecu`,
    },
};
