import { EditAutomateReviewRequired } from '../actions/design/EditAutomateReviewRequired';
import { EditSeparateModelDesignStep } from '../actions/design/EditSeparateModelDesignStep';
import { EditOrderDesignPrep } from '../actions/design/OrderDesignPrepSummaryRow';
import { OrderDesignReview2SummaryRow, OrderDesignReviewAction } from '../actions/design/OrderDesignReviewSummaryRow';
import { EditOrderDesignerType } from '../actions/design/OrderDesignerTypeSummaryRow';
import { EditOrderWaxupRequirement } from '../actions/design/OrderWaxupSummaryRow';
import type { LabsGqlLabOrderFragment } from '@orthly/graphql-operations';
import { OrderDetailFactRow } from '@orthly/veneer';
import React from 'react';

interface OrderDetailSummaryDesignInfoProps {
    labOrder: LabsGqlLabOrderFragment;
    refetchLabOrder: () => Promise<void>;
    manufacturerName: string;
}

export const OrderDetailSummaryDesignInfo: React.VFC<OrderDetailSummaryDesignInfoProps> = ({
    labOrder,
    refetchLabOrder,
    manufacturerName,
}) => {
    if (labOrder.aligner_case) {
        return null;
    }

    const automateEnabled = labOrder.fulfillment_workflow.configuration.enable_threeshape_automate;

    return (
        <>
            <OrderDetailFactRow
                title={'Design Preview'}
                value={<EditOrderWaxupRequirement order={labOrder} refetchOrder={refetchLabOrder} />}
                valueStyle={{ width: '100%' }}
            />
            <OrderDetailFactRow
                title={'Designer'}
                value={
                    <EditOrderDesignerType
                        order={labOrder}
                        refetchOrder={refetchLabOrder}
                        manufacturer_name={manufacturerName}
                    />
                }
                valueStyle={{ width: '100%' }}
            />
            <OrderDetailFactRow
                title={'Prep'}
                value={<EditOrderDesignPrep order={labOrder} refetchOrder={refetchLabOrder} />}
                valueStyle={{ width: '100%' }}
            />
            {automateEnabled && (
                <OrderDetailFactRow
                    title={'Automate Rev.'}
                    value={<EditAutomateReviewRequired order={labOrder} refetchOrder={refetchLabOrder} />}
                    valueStyle={{ width: '100%' }}
                />
            )}
            <OrderDetailFactRow
                title={'Sep. Model'}
                value={<EditSeparateModelDesignStep order={labOrder} refetchOrder={refetchLabOrder} />}
                valueStyle={{ width: '100%' }}
            />
            <OrderDetailFactRow
                title={'Design QC'}
                value={<OrderDesignReviewAction order={labOrder} refetchOrder={refetchLabOrder} />}
                valueStyle={{ width: '100%' }}
            />
            <OrderDetailFactRow
                title={'Double QC?'}
                value={<OrderDesignReview2SummaryRow order={labOrder} refetchOrder={refetchLabOrder} />}
                valueStyle={{ width: '100%' }}
            />
        </>
    );
};
