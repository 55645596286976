import { OrderDownloadFilesUtils } from '../../util/OrderDownloadFiles.utils';
import { BulkDownloadDialog } from '../BulkDownloadDialog/BulkDownloadDialog';
import { useFeatureFlag } from '../Providers/LaunchDarkly';
import type { MinimalOrder } from './DesignPrep.types';
import { DesignPrepBannerCommon } from './DesignPrepBannerCommon';
import { DesignPrepStartPrepInBrowserBanner } from './DesignPrepStartPrepInBrowserBanner';
import type { IOrderItemV2DTO } from '@orthly/items';
import { CartItemV2Utils, LabOrderItemSKUType, OrderItemV2Utils } from '@orthly/items';
import { useRetainerToken } from '@orthly/session-client';
import { Grid } from '@orthly/ui-primitives';
import React from 'react';

interface DesignPrepStartPrepInThreeshapeBannerProps {
    order: Pick<MinimalOrder, 'scan_export' | 'patient' | 'order_number' | 'id'>;
}

const DesignPrepStartPrepInThreeshapeBanner: React.VFC<DesignPrepStartPrepInThreeshapeBannerProps> = ({ order }) => {
    const { retainerToken } = useRetainerToken();
    const [dialogOpen, setDialogOpen] = React.useState<boolean>(false);

    const fileName = `Order${order.order_number}.3oxz`;

    if (!order.scan_export) {
        return null;
    }

    return (
        <Grid container direction={'row'} wrap={'nowrap'}>
            <DesignPrepBannerCommon
                leftLabel={'Download files to prep in 3Shape'}
                rightLabel={'Download'}
                variant={'secondary'}
                onClick={() => setDialogOpen(true)}
            />
            <BulkDownloadDialog
                open={dialogOpen}
                setOpen={setDialogOpen}
                files={
                    retainerToken
                        ? [
                              {
                                  source: 'external',
                                  path: OrderDownloadFilesUtils.getSanitizedScanUrl({
                                      retainerToken,
                                      orderId: order.id,
                                      scanExportId: order.scan_export.id,
                                  }),
                                  name: fileName,
                              },
                          ]
                        : []
                }
            />
        </Grid>
    );
};

function isSingleCrown(items: IOrderItemV2DTO[]) {
    return (
        items.some(item => OrderItemV2Utils.itemIsType(item, LabOrderItemSKUType.Crown)) &&
        CartItemV2Utils.getItemGroupUniqueUNNs(items).length === 1
    );
}

interface DesignPrepStartPrepBannerProps {
    order: MinimalOrder;
    refetch: () => Promise<void>;
}

export const DesignPrepStartPrepBanner: React.VFC<DesignPrepStartPrepBannerProps> = ({ order, refetch }) => {
    const { value: isPrepInBrowserEnabled } = useFeatureFlag('prepInBrowserGuidedUiEnabled');
    const { value: enablePrepInBrowserMultiUnit } = useFeatureFlag('enablePrepInBrowserMultiUnit');

    const parsedItems = OrderItemV2Utils.parseItems(order.items_v2);
    const isCaseLayered = parsedItems.some(CartItemV2Utils.hasLayeredMaterial);

    // We only allow single-crown orders to be done in PIB, and they must not be layered.
    // Layered cases require some extra work in the design file generator that have not yet been completed.
    const canPrepBeDoneInBrowser = (enablePrepInBrowserMultiUnit || isSingleCrown(parsedItems)) && !isCaseLayered;

    if (order.fulfillment_workflow.active_task?.type !== 'DesignPrep') {
        return null;
    }

    if (isPrepInBrowserEnabled && canPrepBeDoneInBrowser) {
        return <DesignPrepStartPrepInBrowserBanner order={order} refetch={refetch} />;
    }

    return <DesignPrepStartPrepInThreeshapeBanner order={order} />;
};
