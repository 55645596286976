import type { AdminLabsOrderActionProps } from '../order-action-types';
import { useIsDesignActionEditable } from './DesignActionUtils';
import { DesignConfigDropdown } from './DesignConfigDropdown';
import { useConfigureDesignerTypeMutation } from '@orthly/graphql-react';
import { LoadBlocker, useChangeSubmissionFn } from '@orthly/ui';
import React from 'react';

export const EditOrderDesignPrep: React.FC<AdminLabsOrderActionProps> = props => {
    const { order, refetchOrder } = props;
    const { internal_design_required, internal_design_prep_required } = order.fulfillment_workflow.configuration;
    const isEditable = useIsDesignActionEditable(order);
    const [submitMtn] = useConfigureDesignerTypeMutation();
    const { submit, submitting } = useChangeSubmissionFn<any, []>(
        () =>
            submitMtn({
                variables: {
                    internal_design_required,
                    id: order.id,
                    internal_design_prep_required: !internal_design_prep_required,
                    separate_model_design_step: null,
                    automate_review_required: null,
                },
            }),
        {
            successMessage: () => [`Design prep ${!internal_design_prep_required ? 'enabled' : 'disabled'}`, {}],
            onSuccess: async () => {
                await refetchOrder();
            },
        },
    );

    const currentValue = { label: internal_design_prep_required ? 'Yes' : 'No', value: internal_design_prep_required };

    return (
        <LoadBlocker
            blocking={submitting}
            overlayColor={'transparent'}
            ContainerProps={{ style: { alignItems: 'center' } }}
        >
            <DesignConfigDropdown<boolean>
                options={[
                    { label: 'Yes', value: true },
                    { label: 'No', value: false },
                ]}
                selected={currentValue}
                submit={async value => {
                    if (
                        value !== currentValue.value &&
                        window.confirm(`${value ? 'Enable' : 'Disable'} design prep in the workflow?`)
                    ) {
                        await submit();
                        return true;
                    }

                    return false;
                }}
                disabled={!isEditable}
            />
        </LoadBlocker>
    );
};
