import type { ToolbarActionDefinition, ToolbarActionProps } from './ToolbarActionDefinition';
import { useMarkPartialsFulfillmentReturnReceivedMutation } from '@orthly/graphql-react';
import { useChangeSubmissionFn } from '@orthly/ui';
import React from 'react';

const MarkPartialsFulfillmentReturnReceivedButton: React.VFC<ToolbarActionProps> = props => {
    const { order, setOpen, open, refetchOrder } = props;
    const [markReturnReceivedMtn] = useMarkPartialsFulfillmentReturnReceivedMutation({
        variables: { data: { lab_order_id: order.id } },
    });
    const { submit, submitting, called } = useChangeSubmissionFn(markReturnReceivedMtn, {
        successMessage: () => [`Successfully marked return as received`, {}],
        errorMessage: () => [`Failed to mark return as received, please try again`, {}],
        onSuccess: () => {
            void refetchOrder();
        },
    });

    React.useEffect(() => {
        if (open && !submitting && !called) {
            if (window.confirm('Mark partial order returned?')) {
                void submit();
            }
            setOpen(false);
        }
    }, [open, submitting, submit, called, setOpen]);

    return <div />;
};

export const MarkPartialsFulfillmentReturnReceivedToolbarAction: ToolbarActionDefinition = {
    label: 'Mark Partial Return Received',
    Component: MarkPartialsFulfillmentReturnReceivedButton,
};
