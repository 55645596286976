import { getPresetTitle, useIsRejectionNoteRequired } from '../GuidedWaxup.util';
import { useGuidedWaxupContext } from '../state/GuidedWaxupContext';
import { useGuidedWaxupAction } from '../state/GuidedWaxupState';
import { LabsGqlGuidedWaxupPresetType } from '@orthly/graphql-schema';
import { SimpleInput } from '@orthly/ui';
import { Text, useScreenIsMobileOrVerticalTablet, Grid } from '@orthly/ui-primitives';
import React from 'react';

export const RejectionNoteInput: React.VFC<{
    rejectionNote: string | undefined;
}> = ({ rejectionNote }) => {
    const isMobile = useScreenIsMobileOrVerticalTablet();
    const { selectedTab } = useGuidedWaxupContext();
    const setPresetRejectionNotes = useGuidedWaxupAction('SET_PRESET_REJECTION_NOTES');
    const isRejectionNoteRequired = useIsRejectionNoteRequired();
    const tabTitle = selectedTab === LabsGqlGuidedWaxupPresetType.GeneralView ? 'Overall' : getPresetTitle(selectedTab);

    return (
        <Grid item container direction={'column'}>
            <Grid item container justifyContent={'space-between'}>
                <Grid item>
                    <Text variant={isMobile ? 'body2' : 'body1'} medium>
                        {`${tabTitle} Rejection Note`}
                    </Text>
                </Grid>
                <Grid item>
                    <Text variant={'body2'} color={isRejectionNoteRequired ? 'ATTENTION_FOREGROUND' : 'BLACK'}>
                        {isRejectionNoteRequired ? 'Required field' : 'Optional'}
                    </Text>
                </Grid>
            </Grid>
            <Grid item sx={{ marginTop: '8px' }}>
                <SimpleInput
                    onChange={value => {
                        setPresetRejectionNotes({
                            presetName: selectedTab ?? LabsGqlGuidedWaxupPresetType.GeneralView,
                            rejectionNotes: value,
                        });
                    }}
                    value={rejectionNote}
                    label={'Tell your designer what could be done differently'}
                    fullWidth
                    TextFieldProps={{
                        multiline: true,
                        rows: 5,
                        autoFocus: true,
                    }}
                />
            </Grid>
        </Grid>
    );
};
