import type { BrushSettings } from '../PortalDesignEditor';
import { BrushType, updateGeometryByVertices, brushTypeActions } from '../PortalDesignEditor';
import { GeometrySelectMode } from '../PortalScanEditor/Trim.types';
import type { MorphPointsGroup } from './Deform.types';
import { MorphPointMesh } from './Deform.types';
import { ensureMeshIndex, getFace, getFaceNearestVertex, getFaceNormal, getVertex } from '@orthly/forceps';
import type { MorphPointsInternalData } from '@orthly/shared-types';
import _ from 'lodash';
import * as THREE from 'three';

// get more vibrant and appealing colors
// https://gist.github.com/bendc/76c48ce53299e6078a76
const randomInt = (min: number, max: number) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const vibrantRandomColor = () => {
    const h = randomInt(0, 360);
    const s = randomInt(42, 98);
    const l = randomInt(40, 90);
    return `hsl(${h},${s}%,${l}%)`;
};

export const colorsMap = new Map<string, string>([
    ['MorphPoints Buccal Cusp', 'hsl(143,69%,66%)'],
    ['MorphPoints Lingual Cusp', 'hsl(246,61%,77%)'],
    ['MorphPoints Groves', 'hsl(97,55%,52%)'],
    ['MorphPoints Facial Occlusal', 'hsl(101,47%,80%)'],
    ['MorphPoints Lingual Occlusal', 'hsl(13,88%,83%)'],
    ['MorphPoints Vestibular', 'hsl(267,88%,44%)'],
]);

export const allowedMorphPointsTypes = ['MorphPoints Groves', 'MorphPoints Buccal Cusp', 'MorphPoints Lingual Cusp'];

export function createDefaultDeformBrushSettings(): BrushSettings {
    return {
        brushType: BrushType.deform,
        brushAction: brushTypeActions[BrushType.deform],
        radius: 1.75,
        intensity: 0.27,
        proximalLimitMm: -0.03,
        occlusalLimitMm: 0.35,
        curtainsLimitMm: -0.03,
        smoothingRounds: 2,
        alongNormal: false,
        reverseOnBack: false,
        geometrySelectMode: GeometrySelectMode.Additive,
        ranges: {
            intensityMin: 0,
            intensityMax: 1.0,
            intensityStep: 0.025,
            proxTargetMin: -0.09,
            proxTargetMax: 0,
            proxTargetStep: 0.005,
            occlusalTargetMin: 0,
            occlusalTargetMax: 0.5,
            occlusalTargetStep: 0.05,
            curtainsTargetMin: -0.1,
            curtainsTargetMax: 0.1,
            curtainsTargetStep: 0.01,
            radiusMin: 0.05,
            radiusMax: 5.0,
            radiusStep: 0.05,
        },
    };
}

export function drawLine(points: THREE.Vector3[]): THREE.LineSegments<THREE.BufferGeometry, THREE.LineBasicMaterial> {
    const lineGeometry = new THREE.BufferGeometry();
    lineGeometry.setFromPoints(points);
    const material = new THREE.LineBasicMaterial({ color: 0x0000ff });
    const line = new THREE.LineSegments(lineGeometry, material);
    line.renderOrder = 1000;
    line.visible = false;
    line.matrixAutoUpdate = false;
    return line;
}

export function updateMarginalRidges(
    morphPointsMap: Map<string, MorphPointsGroup>,
    insertionAxis: THREE.Vector3 | undefined,
) {
    // find the marginal ridges
    // This should be grooves but is the name in the 3Shape types
    // the marginal ridges are expressed as the ends of the grooves morph points
    const groves = morphPointsMap.get('MorphPoints Groves');
    const buccalCusps = morphPointsMap.get('MorphPoints Buccal Cusp');
    const lingualCusps = morphPointsMap.get('MorphPoints Lingual Cusp');
    if (groves && groves.length > 0) {
        const averagePt = new THREE.Vector3();
        groves.forEach(pt => {
            averagePt.add(pt.position);
        });
        averagePt.divideScalar(groves.length);

        // find the two points that are farthest from the average point (representing the marginal ridges)
        groves.sort((a, b) => {
            return b.position.distanceToSquared(averagePt) - a.position.distanceToSquared(averagePt);
        });

        const farthestPoints = groves.slice(0, 2);
        farthestPoints.forEach(pt => {
            // find the closest cusps to this marginal ridge point
            if (buccalCusps && lingualCusps) {
                const closestBuccalCusp = buccalCusps.reduce((prev, curr) =>
                    curr.position.distanceToSquared(pt.position) < prev.position.distanceToSquared(pt.position)
                        ? curr
                        : prev,
                );
                const closestLingualCusp = lingualCusps.reduce((prev, curr) =>
                    curr.position.distanceToSquared(pt.position) < prev.position.distanceToSquared(pt.position)
                        ? curr
                        : prev,
                );
                const direction = new THREE.Vector3().subVectors(
                    closestBuccalCusp.position,
                    closestLingualCusp.position,
                );
                const normalizedDirection = direction.normalize();
                pt.movementDirection = insertionAxis?.clone();
                const fallOffOrthoDirection = new THREE.Vector3()
                    .crossVectors(normalizedDirection, insertionAxis ?? new THREE.Vector3())
                    .normalize();
                const fallOffDirection = new THREE.Vector3()
                    .crossVectors(insertionAxis ?? new THREE.Vector3(), fallOffOrthoDirection)
                    .normalize();
                pt.fallOffPrimaryDirection = fallOffDirection;
                pt.fallOffSecondaryDirection = fallOffOrthoDirection;
            }
            pt.isMarginalRidge = true;
        });
    }
}

export function getUpdatedPosition(downIntersection: THREE.Intersection, ray: THREE.Ray) {
    const updatedPosition = new THREE.Vector3();
    // restrict the movement for marginal ridges morph points
    if (
        downIntersection.object &&
        downIntersection.object instanceof MorphPointMesh &&
        (downIntersection.object as MorphPointMesh).movementDirection
    ) {
        const movementDirection = (downIntersection.object as MorphPointMesh).movementDirection;
        ray.closestPointToPoint(downIntersection.point, updatedPosition);
        if (movementDirection) {
            updatedPosition.sub(downIntersection.point).projectOnVector(movementDirection).add(downIntersection.point);
        }
    } else {
        ray.closestPointToPoint(downIntersection.point, updatedPosition);
    }
    return updatedPosition;
}

export function cloneMorphPointIntersection(selectedMorphPoint: MorphPointMesh) {
    if (selectedMorphPoint.nearestPointOnMesh) {
        return {
            point: selectedMorphPoint.nearestPointOnMesh.point.clone(),
            face: selectedMorphPoint.nearestPointOnMesh.face?.clone(),
            distance: 0,
            object: selectedMorphPoint,
        };
    }
    return undefined;
}

export function updateGeometryByDeformBrush(
    updatedPositionsMap: Map<number, THREE.Vector3[]>,
    originalPositionsMap: Map<number, THREE.Vector3>,
    geometry: THREE.BufferGeometry,
) {
    const vertexIndices: number[] = [];
    const finalUpdatedPositions: THREE.Vector3[] = [];
    updatedPositionsMap.forEach((updatedPositions, index) => {
        if (updatedPositions.length > 0) {
            // check which one is farthest to original position and use it
            const originalPosition = originalPositionsMap.get(index);
            if (originalPosition) {
                const farthest = updatedPositions.reduce((prev, curr) =>
                    originalPosition.distanceTo(curr) > originalPosition.distanceTo(prev) ? curr : prev,
                );
                vertexIndices.push(index);
                finalUpdatedPositions.push(farthest);
            }
        }
    });
    updateGeometryByVertices(finalUpdatedPositions, vertexIndices, geometry);

    if (geometry.attributes.position) {
        geometry.attributes.position.needsUpdate = true;
    }
    if (geometry.attributes.normal) {
        geometry.attributes.normal.needsUpdate = true;
    }
    geometry.boundsTree?.refit();
}

export function updatePositionsMap(
    positions: THREE.Vector3[],
    map: Map<number, THREE.Vector3[]>,
    vertexIndices: number[],
) {
    positions.forEach((pos, index) => {
        const vertexIndex = vertexIndices[index];
        if (vertexIndex !== undefined) {
            if (!map.has(vertexIndex)) {
                map.set(vertexIndex, []);
            }
            map.get(vertexIndex)?.push(pos);
        }
    });
}

export function setMorphPointMeshData(
    point: { x: number; y: number; z: number },
    groupIndex: number,
    morphPoint: MorphPointMesh,
    geometry: THREE.BufferGeometry | undefined,
) {
    if (!geometry) {
        return;
    }
    morphPoint.originalMorphPoint = point;
    morphPoint.groupIndex = groupIndex;
    morphPoint.point = new THREE.Vector3(point.x, point.y, point.z);
    morphPoint.position.set(point.x, point.y, point.z);

    const nearestPointOnMesh = ensureMeshIndex(geometry).closestPointToPoint(morphPoint.point);
    if (!nearestPointOnMesh) {
        return;
    }

    morphPoint.nearestPointOnMesh = { ..._.clone(nearestPointOnMesh), object: morphPoint };
    // get face from nearestPointOnMesh.faceIndex
    const face = getFace(nearestPointOnMesh.faceIndex, geometry);
    if (face) {
        face.normal = getFaceNormal(geometry, nearestPointOnMesh.faceIndex);
        morphPoint.nearestPointOnMesh.face = face;
    }
    morphPoint.nearestVertexIndex = getFaceNearestVertex(geometry, nearestPointOnMesh.faceIndex, morphPoint.point);
    const updatedVertex = new THREE.Vector3();
    if (morphPoint.nearestVertexIndex !== -1 && geometry) {
        getVertex(morphPoint.nearestVertexIndex, geometry, updatedVertex);
        morphPoint.position.copy(updatedVertex);
        morphPoint.point.copy(updatedVertex);
        morphPoint.nearestPointOnMesh?.point.copy(updatedVertex);
        morphPoint.originalMorphPoint.x = updatedVertex.x;
        morphPoint.originalMorphPoint.y = updatedVertex.y;
        morphPoint.originalMorphPoint.z = updatedVertex.z;
    }
}

export function cloneMorphPointsData(morphPoints: MorphPointsInternalData) {
    return morphPoints?.map(group => {
        return {
            name: group.name,
            points: group.points.map(point => {
                return { x: point.x, y: point.y, z: point.z };
            }),
        };
    });
}

export function getMorphPointPrimaryDirection(morphPoint: THREE.Object3D | undefined): THREE.Vector3 | undefined {
    return morphPoint && morphPoint instanceof MorphPointMesh
        ? (morphPoint as MorphPointMesh).fallOffPrimaryDirection
        : undefined;
}

export function getMorphPointSecondaryDirection(morphPoint: THREE.Object3D | undefined): THREE.Vector3 | undefined {
    return morphPoint && morphPoint instanceof MorphPointMesh
        ? (morphPoint as MorphPointMesh).fallOffSecondaryDirection
        : undefined;
}
