import { useAdminSelector } from '../redux';
import { OpsInboxActions } from '../screens/Inbox/state/Inbox.actions';
import { OrdersOverviewActions } from '../screens/Orders/state/OrdersOverview.actions';
import { useApolloClient } from '@apollo/client';
import { useAsyncIsLoading } from '@orthly/redux-async-actions';
import moment from 'moment-timezone';
import React from 'react';
import { useDispatch } from 'react-redux';

function getAction(type: 'inbox' | 'orders') {
    switch (type) {
        case 'inbox':
            return OpsInboxActions.LOAD_VIEWS;
        case 'orders':
            return OrdersOverviewActions.LOAD_VIEWS;
    }
}

export function useSavedSearches(type: 'inbox' | 'orders') {
    const client = useApolloClient();
    const dispatch = useDispatch();
    const action = getAction(type);
    const isLoading = useAsyncIsLoading(action.NAME);
    const lastLoadInitial = useAdminSelector(s => s.async.succeeded[action.NAME]);
    const [lastLoadDate, setLastLoadDate] = React.useState<string | undefined>(lastLoadInitial);
    const loadViews = React.useCallback(() => {
        setLastLoadDate(new Date().toJSON());
        dispatch(action({ client }));
    }, [action, client, dispatch]);
    React.useEffect(() => {
        if (isLoading) {
            return;
        }
        // refresh views every 10 minutes for users who don't reload site
        if (!lastLoadDate || moment(lastLoadDate).diff(moment(), 'minutes') > 10) {
            loadViews();
        }
    }, [loadViews, lastLoadDate, isLoading]);
}
