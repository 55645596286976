import { useOrdersOverviewState } from '../../screens/Orders/state/OrdersOverview.selectors';
import { customOrdersScreenActive } from '../../screens/Orders/state/OrdersOverview.state';
import { useOpenOrderDetailOps } from '../../utils/router/useOpenOrderDetail';
import type { OrderListItemProps } from './OrderListItem.utils';
import { orderListItemPropsEqual } from './OrderListItem.utils';
import { OrderListItemActions } from './OrderListItemActions';
import { OrderListItemPartnerCell, OrderListItemItemsLabCell, OrderListItemTrackerCell } from './OrderListItemCells';
import { OrderListItemLayout, OrderListItemLoading } from './OrderListItemLayout';
import { OrderListItemTitle } from './OrderListItemTitle';
import { OrderFormatUtils } from '@orthly/shared-types';
import { stylesFactory } from '@orthly/ui-primitives';
import type { OrderOpsListItemContentProps } from '@orthly/veneer';
import React from 'react';

const useStyles = stylesFactory(() => ({
    rootGrid: {
        '& $actions': { display: 'none' },
        '&:hover $actions': { display: 'flex' },
    },
    actions: {},
}));

const OrderListItemContent: React.FC<OrderOpsListItemContentProps> = props => {
    const classes = useStyles();
    const { order, partner_name, manufacturer_name, productsText, trackerProps, review_flag_reasons, flags } = props;
    const { id, patient, doctor_name, updated_at, created_at, patient_order_count } = order;
    const openOrder = useOpenOrderDetailOps();
    const updatedSinceLastView = useOrdersOverviewState<boolean>(s => {
        const lastViewTime = customOrdersScreenActive(s) ? s.history.custom[s.view.id] : s.history[s.screen];
        if (!lastViewTime) {
            return false;
        }
        return new Date(created_at).valueOf() > lastViewTime || new Date(updated_at).valueOf() > lastViewTime;
    });
    const onClick = React.useCallback(e => openOrder(id, e), [openOrder, id]);
    return (
        <OrderListItemLayout
            updatedSinceLastView={updatedSinceLastView}
            onClick={onClick}
            className={classes.rootGrid}
            rowTitle={`Order Id: ${id}`}
            title={
                <OrderListItemTitle
                    patient_name={`${patient.first_name} ${patient.last_name}`}
                    flags={flags}
                    review_flag_reasons={review_flag_reasons}
                    patient_order_count={patient_order_count}
                />
            }
            middleCellOne={
                <OrderListItemPartnerCell
                    partner_name={partner_name}
                    doctor_name={OrderFormatUtils.getDisplayedStaffMemberName(doctor_name)}
                />
            }
            middleCellTwo={<OrderListItemItemsLabCell manufacturer_name={manufacturer_name} products={productsText} />}
            trackerCell={
                <OrderListItemTrackerCell
                    order={order}
                    actions={<OrderListItemActions className={classes.actions} orderId={id} />}
                    trackerProps={trackerProps}
                />
            }
        />
    );
};

export const OrderListItem: React.FC<OrderListItemProps> = React.memo<OrderListItemProps>(props => {
    return props.listItemContent ? (
        <OrderListItemContent {...props.listItemContent} />
    ) : (
        <OrderListItemLoading columns={4} />
    );
}, orderListItemPropsEqual);
