import type { OrderForOrderTracker } from '../OrderDetailTracker';
import { OrderDetailTrackerV2 } from '../OrderDetailTrackerV2';
import { OrderEditDeliveryDate, OrderEditMode } from '../OrderEdit';
import { friendlyCancelAndResubmitOrderStatus } from '../OrderEdit/utils/orderStatusUtils';
import { AskDoctorAttachments } from './AskDoctorAttachments';
import { AskDoctorOptions } from './AskDoctorOptions';
import { useQuery } from '@apollo/client';
import type { VeneerAskDoctorPracticeDialogContent_QueryQuery } from '@orthly/graphql-inline-react';
import { graphql } from '@orthly/graphql-inline-react';
import {
    LabsGqlDoctorRequestOptionModifyOrderMode,
    LabsGqlDoctorRequestOptionType,
    LabsGqlWorkflowStateEnum,
} from '@orthly/graphql-schema';
import { OrderItemV2Utils, type ICartItemV2DTO } from '@orthly/items';
import { LoadBlocker, SimpleInput, styled } from '@orthly/ui';
import { Button, Grid, Text } from '@orthly/ui-primitives';
import React from 'react';

const TrackerWrapper = styled('div')({
    padding: '28px 0 20px',
});

const gqlToOrderEditMap = {
    [LabsGqlDoctorRequestOptionModifyOrderMode.CancelAndResubmit]: OrderEditMode.CancelAndResubmit,
    [LabsGqlDoctorRequestOptionModifyOrderMode.Update]: OrderEditMode.OrderEdit,
};

export type AskDoctorV2ViewableOption = {
    id: string;
    label: string;
    type: LabsGqlDoctorRequestOptionType | null;
    modify_order_config?: { items_v2: ICartItemV2DTO[]; mode?: LabsGqlDoctorRequestOptionModifyOrderMode };
};

export type AskDoctorPracticeDialogContentProps = {
    questionForDoctor: string;
    attachmentUrls: string[];
    options: AskDoctorV2ViewableOption[];
    onSubmit: (args: { selectedOptionId: string; freeformText?: string }) => void;
    onCancel: () => void;
    ChatButton: React.FC;
    order: OrderForOrderTracker;
    isSubmitting: boolean;
};

const VeneerAskDoctorPracticeDialogContent_Query = graphql(`
    query VeneerAskDoctorPracticeDialogContent_Query($order_id: String!, $timezone_offset_minutes: Int!) {
        getOrderTrackerEntries(order_id: $order_id, timezone_offset_minutes: $timezone_offset_minutes) {
            active
            alert
            subtitle
            subtitleAttachment
            title
            infoTooltip
            style
        }
    }
`);

export interface AskDoctorPracticeDialogContentInternalProps extends AskDoctorPracticeDialogContentProps {
    orderTrackerEntries: VeneerAskDoctorPracticeDialogContent_QueryQuery['getOrderTrackerEntries'];
}

// This is exported only for testing purposes
// We use an internal component so that our stories and specs do not need to deal with mocking queries.
export const AskDoctorPracticeDialogContentInternal: React.VFC<AskDoctorPracticeDialogContentInternalProps> = ({
    questionForDoctor,
    attachmentUrls,
    options,
    onSubmit,
    onCancel,
    ChatButton,
    order,
    isSubmitting,
    orderTrackerEntries,
}) => {
    const [selectedOption, setSelectedOption] = React.useState<AskDoctorV2ViewableOption | undefined>();
    const [freeformText, setFreeformText] = React.useState<string | undefined>();
    const [hasPassedEtaValidation, setHasPassedEtaValidation] = React.useState<boolean>(false);

    const needsEtaValidation = selectedOption?.type === LabsGqlDoctorRequestOptionType.ModifyOrder;
    const hasFailedEtaValidation = needsEtaValidation && hasPassedEtaValidation === false;
    const canAddFreeformText = selectedOption?.type === LabsGqlDoctorRequestOptionType.Other;
    const isMissingFreeformText = canAddFreeformText && (freeformText ?? '').length === 0;

    const doesSelectedOptionTriggerCancelAndResubmit =
        selectedOption?.modify_order_config?.mode &&
        gqlToOrderEditMap[selectedOption?.modify_order_config?.mode] === OrderEditMode.CancelAndResubmit;
    const isSubmitDisabled = !selectedOption || isSubmitting || isMissingFreeformText || hasFailedEtaValidation;

    return (
        <Grid item container direction={'column'} spacing={3}>
            {doesSelectedOptionTriggerCancelAndResubmit && (
                <Grid item>
                    <TrackerWrapper>
                        <OrderDetailTrackerV2
                            simple
                            animated
                            orderTrackerEntries={orderTrackerEntries ?? []}
                            stepOverrides={[
                                'Placed',
                                friendlyCancelAndResubmitOrderStatus(
                                    order.status,
                                    order.fulfillment_workflow?.active_task?.type || '',
                                ),
                            ]}
                            isPractice
                            isLab={false}
                            isOnHold={
                                order?.workflow_state.state === LabsGqlWorkflowStateEnum.OnHold &&
                                !order?.hold_in_review
                            }
                        />
                    </TrackerWrapper>
                </Grid>
            )}

            <Grid item>
                <Text variant={'body1'} sx={{ whiteSpace: 'pre-wrap' }}>
                    {questionForDoctor}
                </Text>
            </Grid>

            <Grid item container>
                <AskDoctorAttachments attachmentUrls={attachmentUrls} orderId={order.id} />
            </Grid>

            <Grid item container>
                <AskDoctorOptions
                    options={options}
                    selectedOptionId={selectedOption?.id}
                    onOptionSelected={selectedOption => {
                        setSelectedOption(selectedOption);

                        // We reset eta validation on option change
                        setHasPassedEtaValidation(false);
                    }}
                />
            </Grid>

            {canAddFreeformText && (
                <Grid item>
                    <SimpleInput
                        TextFieldProps={{ multiline: true, rows: 3 }}
                        fullWidth={true}
                        onChange={setFreeformText}
                        value={freeformText}
                        label={'Extra instructions'}
                        data-testid={'extra-instructions-ask-doctor-input'}
                    />
                </Grid>
            )}

            <Grid item container direction={'column'} spacing={1}>
                <Grid item>
                    <ChatButton />
                </Grid>
            </Grid>

            <Grid container item data-test={'doctor-response-button-container'} direction={'row'} wrap={'nowrap'}>
                {/* We use visibility here so we don't fetch the eta multiple times when we're just selecting options */}
                <Grid container style={{ visibility: needsEtaValidation ? 'visible' : 'hidden' }}>
                    <OrderEditDeliveryDate
                        originalDeliveryEta={order.practice_dates.estimated_delivery_date}
                        originalDesignPreviewEta={order.practice_dates.digital_design_preview_estimated_completion_date}
                        changedItems={selectedOption?.modify_order_config?.items_v2 ?? []}
                        hasPassedEtaValidation={hasPassedEtaValidation}
                        setHasPassedEtaValidation={setHasPassedEtaValidation}
                        doctorId={order.doctor_preferences_id}
                        hasWaxup={order.fulfillment_workflow.configuration.waxup_required}
                        isLastPage={true}
                        orderItems={OrderItemV2Utils.parseItems(order.items_v2)}
                    />
                </Grid>

                <Grid
                    container
                    direction={'row'}
                    justifyContent={'flex-end'}
                    spacing={1}
                    sx={{ flex: `0 0 200px`, alignItems: `flex-end` }}
                >
                    <Grid item>
                        <Button variant={'secondary'} onClick={() => onCancel()}>
                            Cancel
                        </Button>
                    </Grid>
                    <Grid item>
                        <LoadBlocker blocking={isSubmitting}>
                            <Button
                                variant={'primary'}
                                onClick={() => {
                                    if (selectedOption?.id) {
                                        onSubmit({
                                            selectedOptionId: selectedOption.id,
                                            // We keep the freeform text in state for UX purposes, but on submit, if the option is not of the other type, we do not submit it.
                                            freeformText: canAddFreeformText ? freeformText : undefined,
                                        });
                                    }
                                }}
                                disabled={isSubmitDisabled}
                                data-test={`doctor-response-proceed-button`}
                            >
                                Proceed
                            </Button>
                        </LoadBlocker>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

/**
 * This is built in Veneer so we can also show in the Ops portal what a request created by ops users will look like.
 * There is a preview mode that lets users preview how the request will appear for the doctor.
 */
export const AskDoctorPracticeDialogContent: React.VFC<AskDoctorPracticeDialogContentProps> = props => {
    const { data: additionalData } = useQuery(VeneerAskDoctorPracticeDialogContent_Query, {
        variables: {
            order_id: props.order.id,
            timezone_offset_minutes: new Date().getTimezoneOffset(),
        },
    });

    return (
        <AskDoctorPracticeDialogContentInternal
            {...props}
            orderTrackerEntries={additionalData?.getOrderTrackerEntries ?? []}
        />
    );
};
