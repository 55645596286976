import { useFeatureFlag } from '../../Providers/LaunchDarkly';
import { useOrder, useFinishingCallbacks, useIsTrainingOrder } from '../OrderState.hooks';
import { useSubmissionMutation } from './Submission.hooks';
import { SimpleCopyToClipboard } from '@orthly/dentin';
import { StackX, StackY, styled } from '@orthly/ui';
import { FlossPalette, Button, Text, Tooltip } from '@orthly/ui-primitives';
import moment from 'moment';
import React from 'react';

const FooterRoot = styled(StackY)({
    padding: 12,
    backgroundColor: FlossPalette.STROKE_LIGHT,
    justifyContent: 'space-between',
    height: 128,
    flex: 'none',
});

const StyledButton = styled(Button)({
    height: 40,
    padding: 8,
});

const ButtonsBox = styled(StackX)({
    padding: `0px 16px`,
    justifyContent: 'space-between',
    alignItems: 'center',
});

function formatDueDate(date: Date | string) {
    return moment(date).isSame(moment(), 'd') ? moment(date).format('h:00A') : moment(date).format('MMM D, yyyy');
}

export const SidebarFooter: React.VFC = () => {
    const { value: enableSubmitDandyFinishing } = useFeatureFlag('enableSubmitDandyFinishing');
    const isTraining = useIsTrainingOrder();

    const order = useOrder();
    const { closeWindow } = useFinishingCallbacks();

    const dueText = `Due ${formatDueDate(order.design_due_date)}`;

    const { submit, submitting } = useSubmissionMutation();

    const submissionDisabledReason = getSubmissionDisabledReason(isTraining || enableSubmitDandyFinishing, submitting);
    const tooltip = submissionDisabledReason ?? 'Submit design and complete finishing task';

    return (
        <FooterRoot>
            <Text variant={'body2'} medium color={'LIGHT_GRAY'}>
                {dueText}
            </Text>
            <StackX>
                <Text variant={'body2'} bold style={{ marginRight: 12 }}>
                    {order.patient.first_name[0]}. {order.patient.last_name}
                </Text>
                <Text variant={`body2`} color={'GRAY'}>
                    {moment().diff(moment(order.patient.birthday), 'years')}{' '}
                    {order.patient.gender === 'Male' ? 'M' : 'F'}
                </Text>
                <SimpleCopyToClipboard tooltip={'Copy order ID'} size={'small'} value={order.order_number}>
                    ID
                </SimpleCopyToClipboard>
            </StackX>

            <ButtonsBox>
                <Tooltip title={tooltip}>
                    <span>
                        <StyledButton variant={'primary'} disabled={!!submissionDisabledReason} onClick={submit}>
                            Submit finish
                        </StyledButton>
                    </span>
                </Tooltip>
                <StyledButton variant={'ghost'} onClick={closeWindow}>
                    Cancel
                </StyledButton>
            </ButtonsBox>
        </FooterRoot>
    );
};

function getSubmissionDisabledReason(
    enableSubmitDandyFinishing: boolean | undefined,
    submitting: boolean,
): string | null {
    if (!enableSubmitDandyFinishing) {
        return 'Disabled by feature flag';
    }

    if (submitting) {
        return 'Submitting';
    }

    return null;
}
