import type { QcHeatmapRange } from '../ColorRamp';
import { createOcclusalHeatmapShader } from '../PrepMaterials/OcclusalShader';
import { DandyShaderMaterial } from '../PrepMaterials/dandyShaderMaterial';
import { AttributeName } from '@orthly/forceps';
import * as THREE from 'three';

export function initializeDistanceAttribute(geometry: THREE.BufferGeometry | undefined) {
    if (!geometry) {
        return;
    }
    const positionAttribute = geometry.getAttribute(AttributeName.Position);
    let distancesAttribute = geometry.getAttribute(AttributeName.OcclusalDistance);
    if (!distancesAttribute) {
        distancesAttribute = new THREE.BufferAttribute(new Float32Array(positionAttribute.count), 1);
    }
    (distancesAttribute.array as Float32Array).fill(-101.0);
    geometry.setAttribute(AttributeName.OcclusalDistance, distancesAttribute);
    distancesAttribute.needsUpdate = true;
}

export function createScanMeshStoneMaterial() {
    const scanMeshStoneMaterial = new THREE.MeshPhongMaterial({
        color: new THREE.Color(220 / 255, 200 / 255, 178 / 255),
        shininess: 90,
        specular: new THREE.Color(17 / 255, 17 / 255, 17 / 255),
        side: THREE.DoubleSide,
    });
    return scanMeshStoneMaterial;
}

export function createScanMeshHeatmapMaterial(heatmapRange: QcHeatmapRange) {
    const scanMeshHeatMapShader = createOcclusalHeatmapShader({
        showHeatmap: true,
        heatMapRange: heatmapRange,
    });

    const diffuse = new THREE.Color(151 / 255, 145 / 255, 122 / 255);
    const roughness = 0.5;

    scanMeshHeatMapShader.uniforms = {
        ...THREE.UniformsUtils.clone(scanMeshHeatMapShader.uniforms),
        diffuse: { value: diffuse },
        roughness: { value: roughness },
    };

    const scanMeshHeatMapMaterial = new DandyShaderMaterial({
        ...scanMeshHeatMapShader,
        side: THREE.DoubleSide,
    });

    const minUniform = scanMeshHeatMapShader.uniforms.vMin;
    const maxUniform = scanMeshHeatMapShader.uniforms.vMax;

    const updateHeatmapRange = (newMin: number, newMax: number) => {
        if (minUniform) {
            minUniform.value = newMin;
        }
        if (maxUniform) {
            maxUniform.value = newMax;
        }
        scanMeshHeatMapMaterial.needsUpdate = true;
    };

    return { scanMeshHeatMapMaterial, updateHeatmapRange };
}
