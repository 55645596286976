import type { ICameraControls } from '../ModelViewer';
import { ScanReviewPanel } from './ScanReviewPanel';
import { ScanReviewShadeMatchingTool } from './ScanReviewShadeMatchingTool';
import { useScanReviewShadeMatchingViewContext } from './ScanReviewShadeMatchingView.hooks';
import { ScanReviewPanelType } from './ScanReviewTypes';
import type { Jaw } from '@orthly/shared-types';
import React from 'react';

export interface ScanReviewShadeMatchingPanelProps {
    jawType: Jaw | null;
    maxRadiusMm: number;
}

export const ScanReviewShadeMatchingView: React.VFC<ScanReviewShadeMatchingPanelProps> = ({ jawType, maxRadiusMm }) => {
    const ctx = useScanReviewShadeMatchingViewContext();
    const scene = ctx.picker.scene;
    const callbackRef = React.useCallback(
        (controls: ICameraControls) => {
            ctx.picker.viewManager.initializeViewState(controls, ScanReviewPanelType.Front);
        },
        [ctx.picker.viewManager],
    );
    if (jawType) {
        ctx.picker.setCurrentJawType(jawType);
    }

    const shadeMatchingTool = jawType ? (
        <ScanReviewShadeMatchingTool
            key={`shade-match_${jawType}`}
            picker={ctx.picker}
            maxRadiusMm={maxRadiusMm}
            onShadePicked={ctx.onShadePicked}
        />
    ) : null;

    return (
        <ScanReviewPanel
            scene={scene.scene}
            initializeCameraControlsCallback={callbackRef}
            canvasRef={ctx.picker.viewManager.canvasRef}
            cameraRef={ctx.picker.viewManager.cameraRef}
            key={`shade-match_${jawType}`}
        >
            {shadeMatchingTool}
        </ScanReviewPanel>
    );
};
