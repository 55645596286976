import { Text, Grid, styled, Button, FlossPalette } from '@orthly/ui-primitives';
import React from 'react';

interface DeleteNoteDialogContentProps {
    setOpen: (open: boolean) => void;
    submit: () => Promise<any>;
}

const StyledDeleteDialogButton = styled(Button)({
    backgroundColor: FlossPalette.TAN,
});

const StyledDialogContent = styled(Grid)({
    marginTop: 48,
});

export const DeleteNoteDialogContent: React.FC<DeleteNoteDialogContentProps> = props => {
    const { setOpen, submit } = props;

    return (
        <>
            <Text variant={'body2'}>Notes can't be recovered after they are deleted.</Text>
            <StyledDialogContent container direction={'row'} justifyContent={'flex-end'} spacing={1}>
                <Grid item>
                    <StyledDeleteDialogButton variant={'secondary'} onClick={() => setOpen(false)}>
                        Cancel
                    </StyledDeleteDialogButton>
                </Grid>
                <Grid item>
                    <StyledDeleteDialogButton
                        variant={'alert-secondary'}
                        onClick={async () => {
                            await submit();
                        }}
                    >
                        Delete note
                    </StyledDeleteDialogButton>
                </Grid>
            </StyledDialogContent>
        </>
    );
};
