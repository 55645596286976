import { FlossPalette, styled } from '@orthly/ui-primitives';
import React from 'react';

type Props = {
    placement?: 'top' | 'bottom';
    blockClicks?: boolean;
};

const TEXT_COLOR = FlossPalette.WHITE;
const BORDER_COLOR = FlossPalette.SECONDARY_FOREGROUND;
const BORDER_WIDTH = 8; // px
const CURVE_RADIUS = 8; // px
const CURVE_DIAMETER = CURVE_RADIUS * 2;

const Border = styled('div')(({ blockClicks }: Props) => ({
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    borderStyle: 'solid',
    borderColor: BORDER_COLOR,
    borderWidth: BORDER_WIDTH,
    color: TEXT_COLOR,
    pointerEvents: blockClicks ? 'all' : 'none',
}));

const BorderLabel = styled('div')(({ placement }: Props) => ({
    position: 'fixed',
    top: placement === 'top' ? 0 : 'initial',
    bottom: placement === 'bottom' ? 0 : 'initial',
    minHeight: CURVE_DIAMETER + BORDER_WIDTH,
    padding: '4px 16px',
    background: BORDER_COLOR,
    borderTopLeftRadius: placement === 'bottom' ? CURVE_RADIUS : 0,
    borderTopRightRadius: placement === 'bottom' ? CURVE_RADIUS : 0,
    borderBottomLeftRadius: placement === 'top' ? CURVE_RADIUS : 0,
    borderBottomRightRadius: placement === 'top' ? CURVE_RADIUS : 0,
    pointerEvents: 'all',
    '&::before, &::after': {
        content: '""',
        position: 'absolute',
        top: placement === 'top' ? BORDER_WIDTH : 'initial',
        bottom: placement === 'bottom' ? BORDER_WIDTH : 'initial',
        height: CURVE_DIAMETER,
        width: CURVE_DIAMETER,
        boxShadow: `0 ${placement === 'top' ? -CURVE_RADIUS : CURVE_RADIUS}px ${BORDER_COLOR}`,
    },
    '&::before': {
        left: -CURVE_DIAMETER,
        borderTopRightRadius: placement === 'top' ? CURVE_RADIUS : 0,
        borderBottomRightRadius: placement === 'bottom' ? CURVE_RADIUS : 0,
    },
    '&::after': {
        right: -CURVE_DIAMETER,
        borderTopLeftRadius: placement === 'top' ? CURVE_RADIUS : 0,
        borderBottomLeftRadius: placement === 'bottom' ? CURVE_RADIUS : 0,
    },
}));

export const LabeledWindowBorder: React.FC<Props> = ({ children, placement = 'top', blockClicks = true }) => (
    <Border blockClicks={blockClicks}>
        <BorderLabel placement={placement}>{children}</BorderLabel>
    </Border>
);
