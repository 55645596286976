import { ProductionPlansRoot } from '../../../../../Routing/screens/ProductionPlans/ProductionPlansRoot.graphql';
import { OrderDetailAccordionBlock } from '@orthly/veneer';
import React from 'react';

export const ProductionPlanPreviewBlock: React.FC<{ orderId: string }> = ({ orderId }) => {
    // only load the preview after the accordion has been opened
    const [hasOpened, setHasOpened] = React.useState(false);
    return (
        <OrderDetailAccordionBlock title={`Production Plan Preview`} variant={'full'} onOpen={() => setHasOpened(true)}>
            {hasOpened && <ProductionPlansRoot orderId={orderId} />}
        </OrderDetailAccordionBlock>
    );
};
