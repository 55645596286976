/* eslint-disable max-lines */
import { updateRaycaster } from '../ModelViewer';
import type { ICameraControls } from '../ModelViewer/utils3d/TrackballControls';
import type { BrushSettings } from '../PortalDesignEditor';
import { applyDeformStrokeToVertices, BrushType } from '../PortalDesignEditor';
import type { HandleSculptFn } from '../PortalDesignEditor/SculptingTool.types';
import type { MorphPointsGroup } from './Deform.types';
import { MorphPointMesh } from './Deform.types';
import {
    colorsMap,
    vibrantRandomColor,
    cloneMorphPointIntersection,
    drawLine,
    getUpdatedPosition,
    updateGeometryByDeformBrush,
    updateMarginalRidges,
    updatePositionsMap,
    setMorphPointMeshData,
    allowedMorphPointsTypes,
    getMorphPointPrimaryDirection,
    getMorphPointSecondaryDirection,
} from './Deform.utils';
import type { IOperationsManager } from './OperationsManager.types';
import type { HandleSculptCompleteFn } from './Sculpting.types';
import { BaseSculptTool } from './SculptingTool';
import type { MeshConnectivityGraph } from '@orthly/forceps';
import {
    ensureMeshIndex,
    getBoundaryEdges,
    getFaceNormal,
    getInsertionAxisFromOrientation,
    getNeighbors,
    getVertex,
} from '@orthly/forceps';
import type { MorphPointsInternalData } from '@orthly/shared-types';
import _ from 'lodash';
import * as THREE from 'three';

export class DeformTool extends BaseSculptTool {
    private morphPointsData: MorphPointsInternalData = [];
    private morphPoints = new Map<string, MorphPointsGroup>();

    private normalArrow: THREE.ArrowHelper;
    private effectBoundaries?: THREE.LineSegments<THREE.BufferGeometry, THREE.LineBasicMaterial>;

    private pointerMorphIntersection?: THREE.Intersection;
    private downMorphIntersections: THREE.Intersection[] = [];

    private precomputedNeighborPositions: THREE.Vector3[][] = [];
    private precomputedNeighbors: number[][] = [];

    private ctrlDown: boolean = false;
    private shiftDown: boolean = false;

    private alongNormal: boolean = false;

    constructor(
        active: boolean,
        canvas: HTMLCanvasElement | null,
        cameraControls: ICameraControls | null,
        activeBrush: BrushSettings,
        onSculptIter: HandleSculptFn,
        onSculptComplete: HandleSculptCompleteFn,
        meshConnectivityGraph: MeshConnectivityGraph,
        group: THREE.Group,
        geometry: THREE.BufferGeometry | undefined,
        morphPoints: MorphPointsInternalData,
        operationsManager: IOperationsManager,
    ) {
        super(
            active,
            canvas,
            cameraControls,
            activeBrush,
            onSculptIter,
            onSculptComplete,
            meshConnectivityGraph,
            group,
            geometry,
        );

        this.setMorphPoints(morphPoints, operationsManager.editInsertionAxis);
        this.normalArrow = new THREE.ArrowHelper(new THREE.Vector3(), new THREE.Vector3(), 1.5, 0x000000, 0.5, 0.25);
        this.normalArrow.visible = false;
        this.group?.add(this.normalArrow);

        operationsManager.registerInsertionAxisChangedCallback(this.updateMarginalRidges.bind(this));
    }

    private setMorphPoints(morphPoints: MorphPointsInternalData, insertionAxis: THREE.Vector3): void {
        this.morphPointsData = morphPoints;
        if (this.active && morphPoints && morphPoints.length > 0 && this.geometry !== undefined) {
            morphPoints.forEach(innerPts => {
                if (allowedMorphPointsTypes.includes(innerPts.name)) {
                    const color = colorsMap.get(innerPts.name) ?? vibrantRandomColor();
                    const groupIndex = this.morphPoints.size;
                    const group: MorphPointMesh[] = [];
                    innerPts.points.forEach(pt => {
                        const geometry = new THREE.SphereGeometry(0.25, 32, 32);
                        const material = new THREE.MeshBasicMaterial({ color: color });
                        const sphere = new MorphPointMesh(geometry, material);
                        sphere.type = innerPts.name;
                        setMorphPointMeshData(pt, groupIndex, sphere, this.geometry);
                        this.precomputeNeighborsForAMorphPoint(sphere);
                        group.push(sphere);
                        this.group?.add(sphere);
                    });
                    this.morphPoints.set(innerPts.name, group);
                }
            });

            updateMarginalRidges(this.morphPoints, insertionAxis);
        }
    }

    private updateMarginalRidges(insertionOrientation: THREE.Quaternion): void {
        const insertionAxis = getInsertionAxisFromOrientation(insertionOrientation);
        updateMarginalRidges(this.morphPoints, insertionAxis);
    }

    private precomputeNeighborsForAllMorphPoints() {
        this.morphPoints.forEach(group => {
            group.forEach(morphPoint => {
                this.precomputeNeighborsForAMorphPoint(morphPoint);
            });
        });
    }

    private precomputeNeighborsForAMorphPoint(morphPoint: MorphPointMesh) {
        if (this.geometry) {
            const neighbors = this.getNeighborsForVertexIndex(morphPoint.nearestVertexIndex);
            morphPoint.precomputedNeighborPositions = [];
            morphPoint.precomputedNeighbors = [];
            neighbors.forEach(n => {
                if (this.geometry !== undefined) {
                    const v = new THREE.Vector3();
                    getVertex(n, this.geometry, v);
                    morphPoint.precomputedNeighborPositions.push(v);
                    morphPoint.precomputedNeighbors.push(n);
                }
            });
        }
    }

    private precomputeNeighbors() {
        if (this.geometry && this.downIntersection && this.downIntersection.face) {
            const neighbors = this.getNeighborsForVertexIndex(this.downIntersection.face.a);
            // Save the neighbor verts we have traversed
            this.precomputedNeighborPositions = [[]];
            this.precomputedNeighbors = [[]];
            neighbors.forEach(n => {
                if (this.geometry !== undefined) {
                    const v = new THREE.Vector3();
                    getVertex(n, this.geometry, v);
                    this.precomputedNeighborPositions[0]?.push(v);
                    this.precomputedNeighbors[0]?.push(n);
                }
            });
        }
    }

    private getNeighborsForVertexIndex(vertexIndex: number): number[] {
        if (!this.geometry) {
            return [];
        }
        const neighbors = getNeighbors({
            adjacencyMatrix: this.meshConnectivityGraph.adjacencyVertexToVertex,
            mainHandle: vertexIndex,
            maxRadiusMm: this.activeBrush.radius,
            geometry: this.geometry,
            excludeMask: !this.isScan,
            maxNumRings: 35,
        });
        return neighbors;
    }

    private updateBoundaries() {
        let neighbors: number[] = [];
        if (this.pointerMorphIntersection && this.pointerMorphIntersection.point) {
            const selectedMorphPoint = this.pointerMorphIntersection.object as MorphPointMesh;
            const allNeighbors = [selectedMorphPoint.precomputedNeighbors];
            if (this.shiftDown) {
                this.morphPoints.forEach(group => {
                    group.forEach(morphPoint => {
                        if (
                            morphPoint !== selectedMorphPoint &&
                            morphPoint.groupIndex === selectedMorphPoint.groupIndex &&
                            morphPoint.nearestPointOnMesh
                        ) {
                            allNeighbors.push(morphPoint.precomputedNeighbors);
                        }
                    });
                });
            }
            neighbors = _.uniq(allNeighbors.flat());
        } else if (this.pointerIntersection && this.pointerIntersection.face) {
            neighbors = this.getNeighborsForVertexIndex(this.pointerIntersection.face.a);
        }

        if (!this.group || !this.geometry) {
            return;
        }
        const boundaryEdges = getBoundaryEdges(neighbors, this.meshConnectivityGraph);
        const boundaryPoints = boundaryEdges
            .flatMap(edge => {
                const v1 = new THREE.Vector3();
                const v2 = new THREE.Vector3();
                if (this.geometry) {
                    getVertex(edge[0], this.geometry, v1);
                    getVertex(edge[1], this.geometry, v2);
                }
                return [v1, v2];
            })
            .map(p => {
                if (this.geometry) {
                    const nearestPointOnMesh = ensureMeshIndex(this.geometry).closestPointToPoint(p);
                    if (nearestPointOnMesh) {
                        const normal = getFaceNormal(this.geometry, nearestPointOnMesh.faceIndex);
                        return nearestPointOnMesh.point.clone().add(normal.multiplyScalar(0.01));
                    }
                }
                return p;
            });
        if (!this.effectBoundaries) {
            this.effectBoundaries = drawLine(boundaryPoints);
            this.group?.add(this.effectBoundaries);
        } else {
            this.effectBoundaries.geometry.dispose();
            this.effectBoundaries.geometry.setFromPoints(boundaryPoints);
        }
        this.effectBoundaries.visible = true;
    }

    private updateNormalArrow(position?: THREE.Vector3, normal?: THREE.Vector3) {
        if (!this.group || !position || !normal) {
            return;
        }

        this.normalArrow.setDirection(normal);
        this.normalArrow.position.copy(position);

        this.normalArrow.visible = true;
    }

    private hideVisualEffects() {
        if (this.effectBoundaries) {
            this.effectBoundaries.visible = false;
        }
        if (this.normalArrow) {
            this.normalArrow.visible = false;
        }
    }

    removeVisualEffects() {
        if (this.effectBoundaries) {
            this.effectBoundaries.geometry.dispose();
            this.group?.remove(this.effectBoundaries);
        }
        if (this.normalArrow) {
            this.group?.remove(this.normalArrow);
        }
        this.morphPoints.forEach(group => {
            group.forEach(morphPoint => {
                morphPoint.geometry.dispose();
                this.group?.remove(morphPoint);
            });
        });
    }

    private applyDeformStroke() {
        const pointerDiff = new THREE.Vector2(0, 0);
        if (this.lastPointer) {
            pointerDiff.subVectors(this.pointer, this.lastPointer);
        }

        let downIntersections: THREE.Intersection[] = [];
        if (this.downMorphIntersections.length > 0) {
            downIntersections = this.downMorphIntersections;
        } else if (this.downIntersection) {
            downIntersections = [this.downIntersection];
        }

        // Apply deform brush
        let firstDiff = new THREE.Vector3();
        let firstNormal = new THREE.Vector3();
        const originalPositionsMap = new Map<number, THREE.Vector3>();
        const updatedPositionsMap = new Map<number, THREE.Vector3[]>();
        for (let i = 0; i < downIntersections.length; i++) {
            const downIntersection = downIntersections[i];
            const precomputedNieghbors = this.precomputedNeighbors[i];
            const precomputedNeighborPositions = this.precomputedNeighborPositions[i];
            if (
                precomputedNieghbors &&
                precomputedNeighborPositions &&
                precomputedNieghbors.length > 0 &&
                downIntersection &&
                downIntersection.face &&
                this.geometry
            ) {
                let updatedPosition = new THREE.Vector3();
                if (i === 0) {
                    updatedPosition = getUpdatedPosition(downIntersection, this.raycaster.ray);
                    firstDiff = updatedPosition.clone().sub(downIntersection.point);
                    firstNormal = downIntersection.face.normal;
                } else {
                    updatedPosition.copy(downIntersection.point).add(firstDiff);
                }
                precomputedNeighborPositions.forEach((pos, index) => {
                    const vertexIndex = precomputedNieghbors[index];
                    if (vertexIndex !== undefined) {
                        originalPositionsMap.set(vertexIndex, pos);
                    }
                });

                // deform the geometry
                const updatedPositions = applyDeformStrokeToVertices(
                    downIntersection.point,
                    updatedPosition,
                    firstNormal,
                    precomputedNeighborPositions,
                    this.activeBrush.radius,
                    this.activeBrush.intensity,
                    this.activeBrush.alongNormal || this.alongNormal,
                    getMorphPointPrimaryDirection(downIntersection.object),
                    getMorphPointSecondaryDirection(downIntersection.object),
                    downIntersection.object && downIntersection.object instanceof MorphPointMesh
                        ? undefined
                        : pointerDiff,
                );
                updatePositionsMap(updatedPositions, updatedPositionsMap, precomputedNieghbors);
            }
        }

        if (downIntersections.length > 0 && this.geometry) {
            updateGeometryByDeformBrush(updatedPositionsMap, originalPositionsMap, this.geometry);

            this.updateMorphPointsPositions();

            this.onSculptIter(this.geometry, _.uniq(this.precomputedNeighbors.flat()));
        }
    }

    updateMorphPointsPositions() {
        this.morphPoints.forEach(group => {
            group.forEach(morphPoint => {
                const updatedVertex = new THREE.Vector3();
                if (morphPoint.nearestVertexIndex !== -1 && this.geometry) {
                    getVertex(morphPoint.nearestVertexIndex, this.geometry, updatedVertex);
                    morphPoint.position.copy(updatedVertex);
                    morphPoint.point.copy(updatedVertex);
                    morphPoint.nearestPointOnMesh?.point.copy(updatedVertex);
                    morphPoint.originalMorphPoint.x = updatedVertex.x;
                    morphPoint.originalMorphPoint.y = updatedVertex.y;
                    morphPoint.originalMorphPoint.z = updatedVertex.z;
                }
            });
        });
    }

    intersectMorphPoints(): boolean {
        if (!this.raycaster || !this.morphPoints || !this.camera || !this.canvas) {
            return false;
        }

        const individualMorphPoints = Array.from(this.morphPoints.values()).flat();
        this.pointerMorphIntersection = this.raycaster.intersectObjects(individualMorphPoints, true)[0];
        return this.pointerMorphIntersection !== undefined;
    }

    pointerMove(evt: MouseEvent): void {
        if (!this.active) {
            return;
        }

        // communicate mouse position to scene via camera etc
        this.pointer.set(evt.clientX, evt.clientY);

        if (this.canvas) {
            updateRaycaster(this.raycaster, this.canvas, this.camera, evt, this.targetTWorld);
        }

        if (this.mouseState !== 'pressed') {
            this.mouseState = 'dragging';
        }
        if (this.downIntersection === undefined && this.downMorphIntersections.length === 0) {
            // change alongNormal mode only if we are not deforming
            if (this.ctrlDown) {
                this.alongNormal = true;
            } else {
                this.alongNormal = false;
            }

            // hovering
            let intersected = this.intersectMorphPoints();
            if (!intersected) {
                intersected = this.intersectGeometry();
            }
            if (!intersected) {
                this.hideVisualEffects();
            } else {
                this.updateVisualEffects();
            }
        } else {
            // deforming
            const startTime = performance.now();
            this.applyDeformStroke();
            const endTime = performance.now();
            const runTime = endTime - startTime;
            if (runTime > 0) {
                this.totalFrameTime += runTime;
                this.totalFrames++;
                if (runTime < this.minimumFrameTime) {
                    this.minimumFrameTime = runTime;
                }
                if (runTime > this.maximumFrameTime) {
                    this.maximumFrameTime = runTime;
                }
            }
        }
    }

    updateVisualEffects() {
        let point: THREE.Vector3 | undefined;
        let normal: THREE.Vector3 | undefined;
        if (this.pointerMorphIntersection) {
            const selectedMorphPoint = this.pointerMorphIntersection.object as MorphPointMesh;
            point = selectedMorphPoint.nearestPointOnMesh?.point;
            normal = selectedMorphPoint.nearestPointOnMesh?.face?.normal;
        } else if (this.pointerIntersection) {
            point = this.pointerIntersection.point;
            normal = this.pointerIntersection.face?.normal;
        }
        if (this.alongNormal) {
            this.updateNormalArrow(point, normal);
        } else if (this.normalArrow) {
            this.normalArrow.visible = false;
        }
        this.updateBoundaries();
    }

    customPointerDown(_evt: MouseEvent): void {
        if (this.geometry === undefined) {
            return;
        }

        if (this.pointerMorphIntersection && this.pointerMorphIntersection.point) {
            // for deform brush we want to store the initial neighbors positions before we start moving them
            if (this.cameraControls) {
                this.cameraControls.enabled = false;
            }

            const selectedMorphPoint = this.pointerMorphIntersection.object as MorphPointMesh;

            this.downMorphIntersections = [];
            const downIntersection = cloneMorphPointIntersection(selectedMorphPoint);
            if (downIntersection) {
                this.downMorphIntersections.push(downIntersection);
            }
            this.precomputedNeighborPositions = [selectedMorphPoint.precomputedNeighborPositions];
            this.precomputedNeighbors = [selectedMorphPoint.precomputedNeighbors];

            if (this.shiftDown) {
                this.morphPoints.forEach(group => {
                    group.forEach(morphPoint => {
                        if (
                            morphPoint !== selectedMorphPoint &&
                            morphPoint.groupIndex === selectedMorphPoint.groupIndex &&
                            morphPoint.nearestPointOnMesh
                        ) {
                            const downIntersection = cloneMorphPointIntersection(morphPoint);
                            if (downIntersection) {
                                this.downMorphIntersections.push(downIntersection);
                            }
                            this.precomputedNeighbors.push(morphPoint.precomputedNeighbors);
                            this.precomputedNeighborPositions.push(morphPoint.precomputedNeighborPositions);
                        }
                    });
                });
            }
        } else if (this.pointerIntersection && this.pointerIntersection.point) {
            // for deform brush we want to store the initial neighbors positions before we start moving them
            if (this.cameraControls) {
                this.cameraControls.enabled = false;
            }
            this.downIntersection = _.clone(this.pointerIntersection);
            this.precomputeNeighbors();
        }
    }

    customPointerUp(_evt: MouseEvent): void {
        this.downIntersection = undefined;
        this.downMorphIntersections = [];

        this.hideVisualEffects();

        // update non-active heatmaps on accumulated region
        if (this.precomputedNeighbors.length) {
            const accumulatedVerts = _.uniq(this.precomputedNeighbors.flat());
            const trackingInfo = {
                minFrameTime: this.minimumFrameTime,
                maxFrameTime: this.maximumFrameTime,
                avgFrameTime: this.totalFrameTime / this.totalFrames,
                totalNeighbors: accumulatedVerts.length,
                brushRadius: this.activeBrush.radius,
                brushType: BrushType[this.activeBrush.brushType],
                isScan: this.isScan ?? false,
            };
            this.onSculptComplete(this.geometry, accumulatedVerts, trackingInfo, this.morphPointsData);

            // reset for the next round
            this.totalFrameTime = 0;
            this.totalFrames = 0;
            this.minimumFrameTime = Infinity;
            this.maximumFrameTime = 0;
        }

        // update precomputed neighbors for morph points
        this.precomputeNeighborsForAllMorphPoints();

        this.hideVisualEffects();
    }

    keyDown(evt: KeyboardEvent): void {
        if (evt.ctrlKey) {
            this.ctrlDown = true;
        }
        if (evt.shiftKey) {
            this.shiftDown = true;
        }
    }

    keyUp(_evt: KeyboardEvent) {
        this.ctrlDown = false;
        this.shiftDown = false;
    }
}
