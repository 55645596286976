import { DesignDetailSidebar } from '../../../../../../../components/DesignDetailSidebar/DesignDetailSidebar';
import { AnalyticsClient } from '../../../../../../../utils/analyticsClient';
import type { OrderDetailSidebarTabPieces } from '../../../OrderDetail.types';
import { OrderDetailPageFrame } from '../../../OrderDetailPageFrame.graphql';
import { OrderDetailPageSidebar } from '../../../OrderDetailPageSidebar';
import { useOrderDetailContext } from '../../../state/OrderDetailProvider.graphql';
import { OrderDesignToolbarFileDownload } from '../../OrderDesignToolbar/OrderDesignToolbarFileDownload.graphql';
import { OrderDesignToolbarFileUpload } from '../../OrderDesignToolbar/OrderDesignToolbarFileUpload';
import { useOrderChatSidebarParts } from '../../OrderDetailChat/OrderDetailChat';
import { ForceCreateDesignOrderMenu } from './OrderDesignForceCreate.graphql';
import { ReRunDesignConversionMenu } from './OrderDesignRerunConversion.graphql';
import { OrderAnalyticsContext } from '@orthly/analytics/dist/browser';
import { MODEL_VIEWER_INITIAL_APPEARANCE } from '@orthly/dentin';
import type { ModelAppearance, MainViewCameraControlsRef, MainViewModelRef } from '@orthly/dentin';
import { useSession } from '@orthly/session-client';
import { WindowHeightMinusToolbar, getSentryReplayUrl, useEnableSentrySessionReplay } from '@orthly/ui';
import { stylesFactory, Grid } from '@orthly/ui-primitives';
import {
    DesignQcAnalyticsContext,
    OrderDesignPreview,
    OrderDesignRevisions,
    useFeatureFlag,
    OrderDesignPreppedBanner,
    useDesignOrderRevisionsLoader,
} from '@orthly/veneer';
import React from 'react';

const useStyles = stylesFactory(theme => ({
    root: {
        background: 'linear-gradient(112.77deg, #FDFCFA 0%, #FFFFFF 100%)',
        ...WindowHeightMinusToolbar(theme),
    },
}));

export const OrderDetailDesignTabPage: React.FC = () => {
    const classes = useStyles();
    const { order, refetch: refetchOrder, id: orderId } = useOrderDetailContext();
    useEnableSentrySessionReplay();

    React.useEffect(() => {
        if (orderId) {
            AnalyticsClient.track(`Ops - Portal - Order Design Tab Opened`, {
                $groups: {
                    order: orderId,
                },
            });
        }
    }, [orderId]);

    const {
        slimDesignFragments,
        selectedDesignFragment: selectedDesign,
        previousDesignFragment: previousDesignRaw,
        refetch: refetchDesignFragments,
        loadAndSelectDesign,
    } = useDesignOrderRevisionsLoader(orderId);

    const { value: isDesignComparisonEnabled } = useFeatureFlag('enableDesignComparison');
    const { value: enableRerun } = useFeatureFlag('rerunDesignConversionEnabled');

    const session = useSession();
    const isDeveloper = session?.roles.includes('internal__developer');

    const previousDesign = isDesignComparisonEnabled ? previousDesignRaw : undefined;

    React.useEffect(() => {
        if (orderId && selectedDesign) {
            AnalyticsClient.track(`Ops - Portal - Order Design Viewed`, {
                isMostRecentDesign: selectedDesign.is_latest_design,
                $groups: {
                    order: orderId,
                },
            });
        }
    }, [orderId, selectedDesign]);

    const refetch = React.useCallback(async () => {
        await refetchDesignFragments();
        await refetchOrder();
    }, [refetchOrder, refetchDesignFragments]);

    const [appearance, setAppearance] = React.useState<ModelAppearance>(MODEL_VIEWER_INITIAL_APPEARANCE);
    const modelRef: MainViewModelRef = React.useRef(undefined);
    const controlRef: MainViewCameraControlsRef = React.useRef(null);

    // sidebar tabs
    const orderDetailSidebarParts: OrderDetailSidebarTabPieces = {
        name: 'Details',
        sidebarBody: (
            <DesignDetailSidebar
                order={order}
                refetchOrder={refetchOrder}
                designFragment={selectedDesign ?? undefined}
            />
        ),
    };
    const chatSidebarParts = useOrderChatSidebarParts();

    // track page open time from the timestamp of first render, and never update
    const [designQcAnalyticsInfo] = React.useState(() => ({
        designTabOpenTime: Date.now(),
        designTabOpenFullstoryLink: getSentryReplayUrl(),
    }));

    return (
        <DesignQcAnalyticsContext.Provider value={designQcAnalyticsInfo}>
            <OrderAnalyticsContext.Provider value={{ orderId }}>
                <OrderDetailPageFrame
                    body={
                        order && (
                            <Grid container className={classes.root} key={order.id}>
                                <OrderDesignPreppedBanner order={order} />
                                <OrderDesignPreview
                                    fullScreen={true}
                                    order={order}
                                    userRole={'psr'}
                                    refetch={refetch}
                                    selectedDesignFragment={selectedDesign ?? undefined}
                                    previousDesignFragment={previousDesign ?? undefined}
                                    enableNewViewerWithProps={{
                                        appearance,
                                        setAppearance,
                                        modelRef,
                                        controlRef,
                                    }}
                                />
                            </Grid>
                        )
                    }
                    sidebar={
                        <OrderDetailPageSidebar
                            // the details tab's "Review" button that opens the DDC QC panel uses internal state to keep the
                            // checklist open. `keepOffscreenTabsMounted` lets us switch tabs without losing the checklist
                            keepOffscreenTabsMounted
                            registerTabHotKeys={true}
                            tabs={[orderDetailSidebarParts, chatSidebarParts]}
                        />
                    }
                    toolbarActions={
                        <Grid container style={{ flexWrap: 'nowrap', justifyContent: 'flex-end' }}>
                            {order && isDeveloper && <ForceCreateDesignOrderMenu order={order} />}
                            {order && selectedDesign && enableRerun && (
                                <ReRunDesignConversionMenu order={order} selectedDesignFragment={selectedDesign} />
                            )}
                            {order && selectedDesign && (
                                <OrderDesignToolbarFileDownload
                                    order={order}
                                    selectedDesignFragment={selectedDesign}
                                    isDesignMostRecent={selectedDesign.source_file_zip_path === order.design_file_path}
                                />
                            )}
                            <OrderDesignToolbarFileUpload />
                            {order && (
                                <OrderDesignRevisions
                                    order={order}
                                    userRole={'psr'}
                                    setSelectedDesignRevisionId={loadAndSelectDesign}
                                    designRevisionFragments={slimDesignFragments}
                                    selectedDesignRevisionId={selectedDesign?.id}
                                />
                            )}
                        </Grid>
                    }
                />
            </OrderAnalyticsContext.Provider>
        </DesignQcAnalyticsContext.Provider>
    );
};
