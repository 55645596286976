import { Text } from '@orthly/ui-primitives';
import React from 'react';

interface NotesTileBodyProps {
    noteText: string[];
    expanded: boolean;
    numberOfLinesShown: number;
    textAreaRef: React.Ref<HTMLSpanElement>;
}

export const NotesTileBody: React.FC<NotesTileBodyProps> = ({
    noteText,
    expanded,
    numberOfLinesShown,
    textAreaRef,
}) => {
    return (
        <Text
            ref={textAreaRef}
            variant={'body2'}
            sx={{
                height: '100%',
                // Using -webkit- prefix to ensure compatibility across most modern browsers,
                // as the standard line-clamp property is not yet fully supported.
                display: '-webkit-box',
                '-webkit-box-orient': 'vertical',
                textOverflow: expanded ? 'inital' : 'ellipsis',
                overflow: expanded ? 'visible' : 'hidden',
                '-webkit-line-clamp': expanded ? 'unset' : numberOfLinesShown,
            }}
        >
            {noteText.map(text => (
                <React.Fragment key={text}>
                    {text.split('\n').map(line => (
                        <React.Fragment key={line}>
                            {line}
                            <br />
                        </React.Fragment>
                    ))}
                </React.Fragment>
            ))}
        </Text>
    );
};
