import { OrderDetailStatusNoRushBanner } from './OrderDetailStatusNoRushBanner.graphql';
import type { OrderDetailTrackingBlockProps } from './OrderDetailTrackingBlock.graphql';
import {
    getFragmentData,
    AdminOrderDetailTrackingBlockSalesOrder_FragmentFragmentDoc,
} from '@orthly/graphql-inline-react';
import type { LabsGqlOrder } from '@orthly/graphql-operations';
import { useRefreshOrderShippingStatusMutation, useRemoveLabsFulfillmentShippingMutation } from '@orthly/graphql-react';
import { LabsGqlLabOrderStatus, LabsGqlWorkflowStateEnum } from '@orthly/graphql-schema';
import { useHasCapability } from '@orthly/session-client';
import { DateUtils, ShippingUtils } from '@orthly/shared-types';
import { useChangeSubmissionFn, LoadBlocker, TrashIcon } from '@orthly/ui';
import type { GridJustification, IconName } from '@orthly/ui-primitives';
import {
    Text,
    FlossPalette,
    stylesFactory,
    Button,
    Grid,
    IconButton,
    Tooltip,
    RefreshIcon,
    Icon,
} from '@orthly/ui-primitives';
import { OrderDetailTrackerV2, useFeatureFlag } from '@orthly/veneer';
import moment from 'moment';
import React from 'react';

const useShipmentStyles = stylesFactory(() => ({
    icon: { color: FlossPalette.GRAY, width: 16, height: 16, marginRight: 4 },
}));

interface MessageBlockProps {
    message: string;
    iconName: IconName;
    iconColor?: string;
    tooltip?: React.ReactNode;
    style?: React.CSSProperties;
    justify?: GridJustification;
}

const MessageBlock: React.FC<MessageBlockProps> = props => {
    const { iconName, message, tooltip, justify, style } = props;
    const classes = useShipmentStyles();
    return (
        <Grid container alignItems={'center'} justifyContent={justify ?? 'space-between'} style={style} wrap={'nowrap'}>
            <Grid container style={{ width: 'auto' }} alignItems={'center'} wrap={'nowrap'}>
                <Icon icon={iconName} className={classes.icon} style={{ color: props.iconColor }} />
                <Text variant={'caption'}>{message}</Text>
                {tooltip ?? null}
            </Grid>
            {props.children}
        </Grid>
    );
};
const ShipmentStatusMessage: React.VFC<{ order: LabsGqlOrder }> = props => {
    const { order } = props;
    const message = React.useMemo(() => {
        if (order.status === LabsGqlLabOrderStatus.Shipped) {
            return order.ship_status ?? 'Unknown';
        }
        if (order.delivery_date) {
            const onTime = moment(order.delivery_date).isSameOrBefore(order.manufacturer_sla.due_date, 'day');
            return `Delivered${!onTime ? ' late' : ''} (on ${DateUtils.format(order.delivery_date, 'MM/DD')})`;
        }
        return undefined;
    }, [order.delivery_date, order.manufacturer_sla.due_date, order.ship_status, order.status]);
    if (!message) {
        return null;
    }
    return <MessageBlock message={message} iconName={'TruckIconOutlined'} />;
};
export const DeleteShipmentIconButton: React.VFC<TrackingBlockProps> = props => {
    const REMOVE_SHIPMENT_ENABLED_STATES = [LabsGqlLabOrderStatus.Shipped, LabsGqlLabOrderStatus.Delivered];
    const { order, refetchOrder } = props;
    const [submitMtn] = useRemoveLabsFulfillmentShippingMutation({ variables: { data: { orderId: order.id } } });
    const { submit, submitting } = useChangeSubmissionFn<any, []>(() => submitMtn(), {
        closeOnComplete: true,
        successMessage: () => ['Order shipment removed', {}],
        onSuccess: () => {
            void refetchOrder();
        },
    });

    const hasCapability = useHasCapability('order', 'order.fulfillment.shipment.delete');

    if (!REMOVE_SHIPMENT_ENABLED_STATES.includes(order.status) || !hasCapability) {
        return null;
    }

    return (
        <LoadBlocker blocking={submitting} ContainerProps={{ style: { width: 'auto', marginLeft: 8 } }}>
            <Tooltip title={'Remove shipment (will ask for confirmation)'}>
                <IconButton
                    size={'small'}
                    disabled={submitting}
                    onClick={() => window.confirm('Remove shipment?') && submit()}
                >
                    <TrashIcon style={{ width: 16, height: 16 }} />
                </IconButton>
            </Tooltip>
        </LoadBlocker>
    );
};
export const RefreshShipmentIconButton: React.VFC<TrackingBlockProps> = props => {
    const { order, refetchOrder } = props;
    const [submitMtn] = useRefreshOrderShippingStatusMutation({ variables: { orderId: order.id } });
    const { submit, submitting } = useChangeSubmissionFn<any, []>(() => submitMtn(), {
        closeOnComplete: true,
        successMessage: () => ['Order shipment status refreshed', {}],
        onSuccess: () => {
            void refetchOrder();
        },
    });
    if (order.status !== LabsGqlLabOrderStatus.Shipped) {
        return null;
    }
    return (
        <LoadBlocker blocking={submitting} ContainerProps={{ style: { width: 'auto', marginLeft: 8 } }}>
            <Tooltip title={'Refresh shipment status'}>
                <IconButton size={'small'} disabled={submitting} onClick={() => submit()}>
                    <RefreshIcon style={{ width: 16, height: 16 }} />
                </IconButton>
            </Tooltip>
        </LoadBlocker>
    );
};
const HideDelayStatuses: LabsGqlLabOrderStatus[] = [
    LabsGqlLabOrderStatus.Cancelled,
    LabsGqlLabOrderStatus.Delivered,
    LabsGqlLabOrderStatus.NeedsRefabrication,
];
const ShipmentDateMessage: React.VFC<TrackingBlockProps> = props => {
    const { order } = props;
    const onTime = moment(order.ship_date ?? undefined).isSameOrBefore(order.manufacturer_sla.ship_by_date, 'day');
    const trackingLink =
        order.carrier && order.tracking_number
            ? ShippingUtils.getTrackingLinkForCarrier(order.carrier, order.tracking_number)
            : undefined;

    const isDelayAccountedFor = order.practice_dates.is_delivery_delayed;

    const message = React.useMemo(() => {
        if (!onTime) {
            if (order.ship_date) {
                return `Shipped late (on ${DateUtils.format(order.ship_date, 'MM/DD')})`;
            }

            if (order.status === LabsGqlLabOrderStatus.Fabrication) {
                return 'Shipping late';
            }
        }

        if (isDelayAccountedFor) {
            if (order.ship_date) {
                return 'Shipped (delay accounted for)';
            }
            if (order.status === LabsGqlLabOrderStatus.Fabrication) {
                return 'Delay accounted for';
            }
        }

        if (order.ship_date) {
            return 'Shipped on time';
        }
        if (order.status === LabsGqlLabOrderStatus.Fabrication) {
            return 'Shipping on time';
        }

        return undefined;
    }, [order.ship_date, onTime, order.status, isDelayAccountedFor]);
    if (!message) {
        return null;
    }

    return (
        <MessageBlock style={{ paddingTop: 16 }} message={message} iconName={onTime ? 'CheckIcon' : 'XIcon'}>
            <Grid container alignItems={'center'} justifyContent={'flex-end'} style={{ width: 'auto' }}>
                {trackingLink && (
                    <Button
                        size={'small'}
                        variant={'ghost'}
                        style={{ padding: 0, minWidth: 24 }}
                        onClick={() => window.open(trackingLink, '_blank')}
                    >
                        {order.tracking_number ?? 'Track'}
                    </Button>
                )}
                <DeleteShipmentIconButton {...props} />
                <RefreshShipmentIconButton {...props} />
            </Grid>
        </MessageBlock>
    );
};
const DeliveryDelayMessage: React.VFC<{ order: LabsGqlOrder }> = props => {
    const { order } = props;
    if (HideDelayStatuses.includes(order.status) || ShippingUtils.isReturned(order.ship_status)) {
        return null;
    }
    const isDelayed = order.practice_dates.is_delivery_delayed;
    if (!isDelayed) {
        return null;
    }

    return (
        <MessageBlock
            style={{ paddingTop: 8 }}
            message={`Confirmed delay`}
            iconName={'WarningIcon'}
            iconColor={FlossPalette.ATTENTION}
        />
    );
};

export interface TrackingBlockProps extends OrderDetailTrackingBlockProps {}

export const StatusTrackingBlock: React.VFC<TrackingBlockProps> = props => {
    const { order, orderTrackerEntries, salesOrderFragment } = props;

    const { value: noRushShippingBannerInOrderPagesVisible } = useFeatureFlag(
        'noRushShippingBannerInOrderPagesVisible',
    );

    const wasCancelledAndResubmitted = (order?.replaced_by_ids ?? []).length > 0;
    const salesOrder = getFragmentData(AdminOrderDetailTrackingBlockSalesOrder_FragmentFragmentDoc, salesOrderFragment);

    return (
        <>
            {order.status === LabsGqlLabOrderStatus.Cancelled && (
                <Text variant={'h5'} style={{ color: FlossPalette.ATTENTION }}>
                    Canceled {wasCancelledAndResubmitted && 'and resubmitted with changes.'}
                </Text>
            )}
            {noRushShippingBannerInOrderPagesVisible && (
                <OrderDetailStatusNoRushBanner salesOrderFragment={salesOrder} />
            )}
            <OrderDetailTrackerV2
                orderTrackerEntries={orderTrackerEntries}
                isPractice={false}
                isOnHold={order?.workflow_state.state === LabsGqlWorkflowStateEnum.OnHold && !order?.hold_in_review}
                isLab={false}
                padded={true}
            />
            <ShipmentDateMessage {...props} />
            <ShipmentStatusMessage order={order} />
            <DeliveryDelayMessage order={order} />
        </>
    );
};
