import { EditPracticeContractSpendTermsModal } from './EditPracticeContractSpendTermsModal.graphql';
import type { PracticeContractSpendTermInfo, RefetchContracts } from './PracticeContracts.types';
import { RootActionDialog } from '@orthly/ui';
import { EditIcon, IconButton, Tooltip } from '@orthly/ui-primitives';
import React from 'react';

export interface EditPracticeContractSpendTermsProps {
    contractId: string;
    spendTerms: PracticeContractSpendTermInfo[];
    refetch: RefetchContracts;
}

export const EditPracticeContractSpendTermsAction: React.FC<EditPracticeContractSpendTermsProps> = ({
    contractId,
    spendTerms,
    refetch,
}) => {
    const [open, setOpen] = React.useState<boolean>(false);

    return (
        <RootActionDialog
            loading={false}
            open={open}
            setOpen={setOpen}
            dialogProps={{ maxWidth: 'sm' }}
            title={'Edit monthly minimum commitments'}
            content={
                <EditPracticeContractSpendTermsModal
                    contractId={contractId}
                    spendTerms={spendTerms}
                    closeDialog={() => setOpen(false)}
                    refetch={refetch}
                />
            }
            style={{ padding: 0 }}
            buttonText={'Submit Form'}
            CustomButton={() => {
                return (
                    <Tooltip title={'Edit monthly minimum commitments'}>
                        <IconButton size={'small'} onClick={() => setOpen(true)}>
                            <EditIcon />
                        </IconButton>
                    </Tooltip>
                );
            }}
        />
    );
};
