import { BooleanIcon } from '../../../../components/BooleanIcon';
import { InlineEditButton } from '../../../../components/InlineEditButton';
import type { PartnerBillingOverviewData } from '../../types';
import { PaymentSplitEntityForm } from './PaymentSplitEntityForm';
import { usePartnerPaymentSplitSourceOptions } from './usePartnerPaymentSplitSourceOptions.graphql';
import type { PaymentSplitConfigType } from './useSubmitPaymentSplitConfig';
import { useSubmitPaymentSplitConfig } from './useSubmitPaymentSplitConfig';
import { LabsGqlPaymentSplitConfigType } from '@orthly/graphql-schema';
import { LoadBlocker, RootActionDialog, SimpleSelect } from '@orthly/ui';
import { Avatar, Button, FlossPalette, Grid, Icon, Tooltip } from '@orthly/ui-primitives';
import React from 'react';

interface SetSplitConfigFormProps {
    practiceId: string;
    config: PartnerBillingOverviewData['usage_payment_source_config'];
    onComplete: () => void;
}

// TODO: Create as story for this component
function initialConfigTypeFromPartner(
    config: PartnerBillingOverviewData['usage_payment_source_config'],
): PaymentSplitConfigType {
    switch (config?.__typename) {
        case 'PaymentSplitConfigDoctor':
            return 'doctor';
        case 'PaymentSplitConfigLocation':
            return 'location';
        case 'PaymentSplitConfigPercent':
        default:
            return 'doctor';
    }
}

const SplitConfigFormRoot: React.FC<SetSplitConfigFormProps> = ({ practiceId, config, onComplete }) => {
    const { sourcesLoading, sourceOptions } = usePartnerPaymentSplitSourceOptions(practiceId);
    const [splitType, setSplitType] = React.useState<PaymentSplitConfigType>(initialConfigTypeFromPartner(config));
    const { submit, submitting, removeConfig } = useSubmitPaymentSplitConfig(practiceId, onComplete);
    return (
        <LoadBlocker blocking={submitting || sourcesLoading}>
            <Grid container style={{ display: !config ? 'none' : undefined, padding: '4px 0' }}>
                <Button variant={'secondary'} fullWidth onClick={() => removeConfig()} startIcon={'CloseIcon'}>
                    Unset config
                </Button>
            </Grid>
            <Grid container style={{ padding: '12px 0' }}>
                <SimpleSelect
                    value={splitType}
                    onChange={value => {
                        value && setSplitType(value as PaymentSplitConfigType);
                    }}
                    label={'Select Payment Split Type'}
                    options={[
                        { value: 'doctor', label: 'By Doctor' },
                        { value: 'location', label: 'By Address' },
                    ]}
                />
            </Grid>
            {splitType !== 'percent' && !sourcesLoading && (
                <PaymentSplitEntityForm
                    sourceOptions={sourceOptions}
                    splitType={splitType}
                    practiceId={practiceId}
                    config={config}
                    onSubmit={submit}
                />
            )}
        </LoadBlocker>
    );
};

type PaymentSplitConfigIconProps = { currentConfigType?: LabsGqlPaymentSplitConfigType };

const PaymentSplitConfigIcon: React.FC<PaymentSplitConfigIconProps> = props => {
    const { currentConfigType } = props;
    if (!currentConfigType) {
        return <BooleanIcon val={!!currentConfigType} />;
    }
    switch (currentConfigType) {
        case LabsGqlPaymentSplitConfigType.Doctor:
            return <Icon icon={'SupervisedUserCircle'} style={{ color: FlossPalette.GREEN }} />;
        case LabsGqlPaymentSplitConfigType.Location:
            return <Icon icon={'HomeIcon'} style={{ color: FlossPalette.GREEN }} />;
        case LabsGqlPaymentSplitConfigType.Percent:
            return <Avatar style={{ width: 24, height: 24, background: FlossPalette.GREEN }}>%</Avatar>;
    }
};

interface EditPaymentSplitConfigProps {
    practiceId: string;
    config: PartnerBillingOverviewData['usage_payment_source_config'];
}

export const EditPaymentSplitConfig: React.FC<EditPaymentSplitConfigProps> = ({ practiceId, config }) => {
    const [open, setOpen] = React.useState<boolean>(false);
    const title = 'Edit invoice payment config';
    const tooltipTitle = config ? `Invoices split by ${config.type}` : `Split payment sources for invoices not set`;

    const CustomButton = React.useCallback(
        () => (
            <InlineEditButton
                onClick={() => setOpen(true)}
                label={
                    <Tooltip title={tooltipTitle}>
                        <Grid container style={{ maxWidth: 75 }}>
                            <PaymentSplitConfigIcon currentConfigType={config?.type} />
                        </Grid>
                    </Tooltip>
                }
            />
        ),
        [config, tooltipTitle],
    );
    return (
        <RootActionDialog
            loading={false}
            open={open}
            setOpen={setOpen}
            title={title}
            content={
                <SplitConfigFormRoot
                    practiceId={practiceId}
                    config={config}
                    onComplete={() => {
                        setOpen(false);
                    }}
                />
            }
            buttonText={''}
            CustomButton={CustomButton}
        />
    );
};
