import { useFirebaseStorage } from '../../context';
import type { UserRole } from '../../util';
import type { ToolStateSetter } from '../Annotations/DrawingControls';
import { INITIAL_TOOL_STATE } from '../Annotations/DrawingControls';
import { useDesignOrderRevisionsLoader } from '../DesignViewer/OrderDesign.hooks.graphql';
import { useFeatureFlag } from '../Providers/LaunchDarkly';
import type { CurrentWaxup, WaxupOrder } from './Waxups.types';
import type { DandyAnalyticsEventSchemaType } from '@orthly/analytics/dist/browser';
import { BrowserAnalyticsClientFactory } from '@orthly/analytics/dist/browser';
import type { CropInsets, RefabFlowLabOrder, TakeSnapshotRef, ToolState } from '@orthly/dentin';
import type { FragmentType } from '@orthly/graphql-inline-react';
import { getFragmentData, graphql } from '@orthly/graphql-inline-react';
import type {
    LabsGqlWaxupReviewRejectionFragment,
    LabsGqlWaxupReviewSubmissionFragment,
} from '@orthly/graphql-operations';
import {
    LabsGqlDesignOrderDoctorReviewStatus,
    LabsGqlOrderItemSkuType,
    LabsGqlWorkflowTaskType,
} from '@orthly/graphql-schema';
import { DesignStorageConfigs, getFullStoragePath } from '@orthly/shared-types';
import { OrthlyBrowserConfig } from '@orthly/ui';
import type Firebase from 'firebase/compat/app';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import React from 'react';

// When taking a snapshot of the 3D model, we crop away the edges of the snapshot so that
// it's sized better for the annotation view. These insets have been chosen to "look about right".
export const SNAPSHOT_INSETS: CropInsets = { top: 20, left: 42, bottom: 20, right: 42 };

export type WaxupSubmission = LabsGqlWaxupReviewRejectionFragment | LabsGqlWaxupReviewSubmissionFragment;

export interface OrderWaxupProps {
    order: WaxupOrder;
    userRole: UserRole;
    refetchOrder: () => Promise<any>;

    // loading is quite expensive in CPU, especially for large models. Without clickToLoad=true,
    // the user pays for it whether they care about the waxup or not
    clickToLoad?: boolean;
}

export const shouldShowWaxup = (order: Pick<WaxupOrder, 'fulfillment_workflow'>) => {
    if (!order.fulfillment_workflow.configuration.waxup_required) {
        return false;
    }

    return (
        order.fulfillment_workflow.active_task?.__typename === 'WaxupReviewWorkflowTask' ||
        order.fulfillment_workflow.closed_tasks.some(task => task.type === LabsGqlWorkflowTaskType.WaxupReview)
    );
};

const VeneerWaxupUtilDesignOrderRevision_Fragment = graphql(`
    fragment VeneerWaxupUtilDesignOrderRevision_Fragment on DesignOrderDesignRevisionDTO {
        id
        is_latest_design
        source_file_zip_path
        doctor_review {
            status
        }
    }
`);

const getCurrentWaxupWithDesign = (
    slimDesignFragments: FragmentType<typeof VeneerWaxupUtilDesignOrderRevision_Fragment>[],
    order?: Pick<WaxupOrder, 'fulfillment_workflow' | 'id' | 'waxup_status'>,
    selectedRevisionId?: string,
    internalEvaluation?: boolean,
) => {
    const slimDesigns = getFragmentData(VeneerWaxupUtilDesignOrderRevision_Fragment, slimDesignFragments);

    if (!order?.waxup_status && !internalEvaluation) {
        return undefined;
    }

    const currentDesign =
        slimDesigns.find(design => design.id === selectedRevisionId) ??
        slimDesigns.find(design => design.is_latest_design) ??
        slimDesigns.find(design => design.doctor_review?.status === LabsGqlDesignOrderDoctorReviewStatus.Approved);

    return {
        currentDesignId: currentDesign?.id,
    };
};

// Gets the design from the waxup that was rejected most recently prior to the currently selected waxup, if there is such a waxup.
const getPreviousWaxupDesignId = (
    slimDesignFragments: FragmentType<typeof VeneerWaxupUtilDesignOrderRevision_Fragment>[],
    order?: Pick<WaxupOrder, 'fulfillment_workflow' | 'id'>,
    selectedRevisionId?: string,
): string | undefined => {
    if (!order || !order.fulfillment_workflow.configuration.waxup_required) {
        return undefined;
    }

    const slimDesigns = getFragmentData(VeneerWaxupUtilDesignOrderRevision_Fragment, slimDesignFragments);
    const rejectedDesigns = slimDesigns.filter(
        design => design.doctor_review?.status === LabsGqlDesignOrderDoctorReviewStatus.Rejected,
    );

    if (!rejectedDesigns.length) {
        // There is no rejection history, so there is no previous waxup.
        return undefined;
    }

    const selectedRejectionIdx = rejectedDesigns.findIndex(design => design.id === selectedRevisionId);

    // Return the last rejection
    if (selectedRejectionIdx === -1) {
        return _.last(rejectedDesigns)?.id;
    }

    // Return the rejection before the current one
    return rejectedDesigns[selectedRejectionIdx - 1]?.id;
};

/*
 * A utility hook to fetch the current state of the waxup.
 * This will also handle loading the correct design revision.
 * Please do not call this other than in the root of your viewer state, or they will likely get out of sync.
 */
export function useCurrentWaxup(
    order?: Pick<WaxupOrder, 'fulfillment_workflow' | 'id' | 'waxup_status'>,
    seedId?: string,
    internalEvaluation?: boolean,
): CurrentWaxup | undefined {
    const [selectedRevisionId, setSelectedRevisionId] = React.useState<string | undefined>(seedId);
    const { refetch, loadDesign, loadAndSelectDesign, loadedDesignsById, slimDesignFragments } =
        useDesignOrderRevisionsLoader(order?.id);

    const previousDesignId = getPreviousWaxupDesignId(slimDesignFragments ?? [], order, selectedRevisionId);

    const slimDesigns = getFragmentData(VeneerWaxupUtilDesignOrderRevision_Fragment, slimDesignFragments ?? []);
    const hasApproval = slimDesigns.some(
        design => design.doctor_review?.status === LabsGqlDesignOrderDoctorReviewStatus.Approved,
    );

    const viewableSlimDesignIds = slimDesigns
        .filter(d => {
            // If they've approved a design, only show the ones they've explicitly approved or rejected.
            if (hasApproval) {
                return !!d.doctor_review;
            }

            // IF they haven't yet approved a design, we show any that they've reviewed _plus_ the latest one.
            return d.is_latest_design || !!d.doctor_review;
        })
        .map(d => d.id);
    const viewableSlimDesignFragments = (slimDesignFragments ?? []).filter(d => viewableSlimDesignIds.includes(d.id));

    const currentWaxup = getCurrentWaxupWithDesign(
        viewableSlimDesignFragments ?? [],
        order,
        selectedRevisionId,
        internalEvaluation,
    );

    React.useEffect(() => {
        if (!selectedRevisionId && currentWaxup?.currentDesignId) {
            setSelectedRevisionId(currentWaxup.currentDesignId);
        }
    }, [selectedRevisionId, setSelectedRevisionId, currentWaxup]);

    // Whenever the selected design changes, we load it if it hasn't already been requested.
    // TODO: revisit this, as it's not ideal.
    React.useEffect(() => {
        if (!selectedRevisionId) {
            return;
        }

        void loadAndSelectDesign(selectedRevisionId);
    }, [loadAndSelectDesign, selectedRevisionId]);

    // Whenever the previous design changes, we load it if it hasn't already been requested.
    // TODO: revisit this, as it's not ideal.
    React.useEffect(() => {
        if (!previousDesignId) {
            return;
        }

        void loadDesign(previousDesignId);
    }, [loadDesign, previousDesignId]);

    if (!currentWaxup) {
        return undefined;
    }

    return {
        selectedRevisionId,
        setSelectedRevisionId,
        refetch,
        currentDesignFragment: selectedRevisionId ? loadedDesignsById[selectedRevisionId] ?? undefined : undefined,
        previousDesignFragment: previousDesignId ? loadedDesignsById[previousDesignId] ?? undefined : undefined,
        slimDesignFragments: viewableSlimDesignFragments,
    };
}

// This hook determines if we want to enter the guided waxup flow or not. Guided waxups are only
// available for orders where all of the items fall into one or more of these skus: crown, implay, inlay, model or
// full denture if the flag is enabled.
export const useShouldShowGuidedWaxupFlow = (order?: Pick<WaxupOrder, 'items_v2'>) => {
    const { value: enableFullDentureGuidedReview } = useFeatureFlag('enableFullDentureGuidedReview');
    const itemsOk =
        order?.items_v2.every(
            item =>
                item.sku === LabsGqlOrderItemSkuType.Bridge ||
                item.sku === LabsGqlOrderItemSkuType.Crown ||
                item.sku === LabsGqlOrderItemSkuType.Implant ||
                item.sku === LabsGqlOrderItemSkuType.ImplantBridge ||
                item.sku === LabsGqlOrderItemSkuType.Inlay ||
                item.sku === LabsGqlOrderItemSkuType.Model ||
                item.sku === LabsGqlOrderItemSkuType.Other ||
                item.sku === LabsGqlOrderItemSkuType.Veneer ||
                item.sku === LabsGqlOrderItemSkuType.Waxup ||
                item.sku === LabsGqlOrderItemSkuType.Partial ||
                (enableFullDentureGuidedReview && item.sku === LabsGqlOrderItemSkuType.Denture),
        ) && order?.items_v2.some(item => item.sku !== LabsGqlOrderItemSkuType.Other);
    return !!itemsOk;
};

export async function uploadAnnotatedImage(
    storage: Firebase.storage.Storage,
    storagePath: string,
    image: Blob,
): Promise<string> {
    return new Promise((resolve, reject) => {
        storage
            .ref(`${storagePath}/annotation-${new Date().toISOString()}.png`)
            .put(image)
            .then(
                snapshot => resolve(snapshot.ref.fullPath),
                error => reject(error),
            );
    });
}

export const useUploadCurrentAnnotatedImage = (takeSnapshot: () => Promise<Blob | null>, orderId: string) => {
    const { enqueueSnackbar } = useSnackbar();
    const storage = useFirebaseStorage();

    return React.useCallback(async (): Promise<string | null> => {
        try {
            const blob = await takeSnapshot();
            if (!blob) {
                return null;
            }
            const storagePathConfig = getFullStoragePath(
                OrthlyBrowserConfig.env,
                DesignStorageConfigs.designAnnotations,
                orderId,
            );
            return await uploadAnnotatedImage(storage, storagePathConfig.path, blob);
        } catch (error) {
            enqueueSnackbar(`Error uploading annotation: ${error}`, { variant: 'error' });
            throw error;
        }
    }, [takeSnapshot, orderId, storage, enqueueSnackbar]);
};

export const useGetCommonAnnotationState = () => {
    const takeSnapshotRef: TakeSnapshotRef = React.useRef();
    const [isAnnotationListModalOpen, setIsAnnotationListModalOpen] = React.useState(false);
    const [screenshotToAnnotate, setScreenshotToAnnotate] = React.useState<Blob | null>(null);
    const [isUploading, setIsUploading] = React.useState(false);
    const [canvasSize, setCanvasSize] = React.useState<{ width: number; height: number }>({
        width: 400,
        height: 400,
    });

    // We need to memoize this because `URL.createObjectURL` returns a new result every time.
    const backgroundImageUrl = React.useMemo(
        () => screenshotToAnnotate && URL.createObjectURL(screenshotToAnnotate),
        [screenshotToAnnotate],
    );

    return {
        takeSnapshotRef,
        isAnnotationListModalOpen,
        setIsAnnotationListModalOpen,
        screenshotToAnnotate,
        setScreenshotToAnnotate,
        isUploading,
        setIsUploading,
        canvasSize,
        setCanvasSize,
        backgroundImageUrl,
    };
};

export const useGetAnnotationCanvasFunctions = (
    order: RefabFlowLabOrder | WaxupOrder,
    takeSnapshotRef: TakeSnapshotRef,
    screenshotToAnnotate: Blob | null,
    setScreenshotToAnnotate: (value: React.SetStateAction<Blob | null>) => void,
    clear: () => void,
    setToolState: (action: ToolState | ((prevState: ToolState) => ToolState)) => void,
    trackingSource?: DandyAnalyticsEventSchemaType['Order Annotation - Capture Screenshot Clicked']['source'],
    setShowAnnotationCanvas?: (value: React.SetStateAction<boolean>) => void,
) => {
    const handleTakeScreenshot = React.useCallback(async () => {
        if (trackingSource) {
            BrowserAnalyticsClientFactory.Instance?.track('Order Annotation - Capture Screenshot Clicked', {
                source: trackingSource,
                $groups: { order: order.id },
            });
        }
        takeSnapshotRef?.current && setScreenshotToAnnotate(await takeSnapshotRef.current(SNAPSHOT_INSETS));
    }, [takeSnapshotRef, order.id, trackingSource, setScreenshotToAnnotate]);

    // If `screenshotToAnnotate` is nullish, takes a screenshot to enter annotation
    // mode, then calls through to `setToolState`.
    const setToolStateWrapped: ToolStateSetter = React.useCallback(
        arg => {
            if (!screenshotToAnnotate) {
                clear();
                void handleTakeScreenshot();
            }
            setToolState(arg);
        },
        [screenshotToAnnotate, handleTakeScreenshot, setToolState, clear],
    );

    const closeDrawingCanvas = React.useCallback(() => {
        setScreenshotToAnnotate(null);
        setToolState(INITIAL_TOOL_STATE);
        setShowAnnotationCanvas?.(false);
    }, [setScreenshotToAnnotate, setToolState, setShowAnnotationCanvas]);

    return {
        handleTakeScreenshot,
        setToolStateWrapped,
        closeDrawingCanvas,
    };
};
