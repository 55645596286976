import { BrushType, brushTypeActions } from '../PortalDesignEditor/SculptingBrush';
import type { BrushSettings } from '../PortalDesignEditor/SculptingBrush';
import { GeometrySelectMode } from '../PortalScanEditor/Trim.types';
import { AttributeName } from '@orthly/forceps';

export function createDefaultBrushSettings(): BrushSettings {
    return {
        brushType: BrushType.add,
        brushAction: brushTypeActions[BrushType.add],
        radius: 0.3,
        intensity: 0.1,
        proximalLimitMm: -0.03,
        occlusalLimitMm: 0.35,
        curtainsLimitMm: -0.03,
        smoothingRounds: 2,
        alongNormal: false,
        reverseOnBack: false,
        geometrySelectMode: GeometrySelectMode.Additive,
        ranges: {
            intensityMin: 0,
            intensityMax: 0.5,
            intensityStep: 0.025,
            proxTargetMin: -0.09,
            proxTargetMax: 0,
            proxTargetStep: 0.005,
            occlusalTargetMin: 0,
            occlusalTargetMax: 0.5,
            occlusalTargetStep: 0.05,
            curtainsTargetMin: -0.1,
            curtainsTargetMax: 0.1,
            curtainsTargetStep: 0.01,
            radiusMin: 0.05,
            radiusMax: 2.0,
            radiusStep: 0.05,
        },
        isProximalRaiseVerticesEnabled: false,
    };
}

export function isIntersectionInSculptMask(
    pointerIntersection: THREE.Intersection,
    geometry?: THREE.BufferGeometry,
): boolean {
    const face = pointerIntersection.face;
    if (!geometry || !face) {
        return false;
    }
    const maskAttribute = geometry.getAttribute(AttributeName.SculptMask);
    if (!maskAttribute) {
        return false;
    }

    const faceVertices = [face.a, face.b, face.c];
    return faceVertices.some(vertexIndex => maskAttribute.getX(vertexIndex) === 1);
}
