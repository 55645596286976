import { useListManufacturerIntegrationsQuery } from '@orthly/graphql-react';
import type { LabsGqlManufacturerIntegration } from '@orthly/graphql-schema';
import _ from 'lodash';
import React from 'react';

export function useManufacturerIntegrations(): _.Dictionary<LabsGqlManufacturerIntegration | undefined> {
    const { data: manufacturerIntegrations } = useListManufacturerIntegrationsQuery({ fetchPolicy: 'cache-first' });
    return React.useMemo(() => {
        return _.keyBy(manufacturerIntegrations?.manufacturer_integrations ?? [], integration => integration.id);
    }, [manufacturerIntegrations]);
}
