import type { LastEditedData, NotesSummaryMenuActionProps, NotesSummaryActionChildProps } from './NotesSummary.types';
import { NotesTileAlerts } from './NotesTileComponents/NotesTileAlerts';
import { NotesTileBody } from './NotesTileComponents/NotesTileBody';
import { NotesTileFooter } from './NotesTileComponents/NotesTileFooter';
import { NotesTileTitle } from './NotesTileComponents/NotesTileTitle';
import { styled, FlossPalette } from '@orthly/ui-primitives';
import React from 'react';

export interface NotesTileButtonProps {
    Component: React.FC<NotesSummaryMenuActionProps>;
    refetch: () => Promise<void>;
    childProps?: NotesSummaryActionChildProps;
}

export interface NotesTileProps {
    noteTitle: string;
    noteText: string[];
    lastEditedData?: LastEditedData;
    editButtonProps?: NotesTileButtonProps;
    deleteButtonProps?: NotesTileButtonProps;
    alerts?: string[];
    forceExpanded?: boolean;
}

const StyledBox = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    padding: 16,
    gridGap: 8,
    backgroundColor: FlossPalette.WARNING_HIGHLIGHT,
    boxShadow:
        '0px 1px 2px 0px #7070701A, 0px 4px 4px 0px #70707017, 0px 9px 5px 0px #7070700D, 0px 15px 6px 0px #70707003, 0px 24px 7px 0px #70707000',
});

export const NotesTile: React.FC<NotesTileProps> = props => {
    const { noteText, noteTitle, editButtonProps, deleteButtonProps, alerts, forceExpanded, lastEditedData } = props;
    const textAreaRef = React.useRef(null);
    const [fullHeightVisible, setFullHeightVisible] = React.useState<boolean>(false);
    const [numberOfLinesShown, setNumberOfLinesShown] = React.useState(0);
    const [isOverflowing, setIsOverflowing] = React.useState(false);

    const expanded = forceExpanded || fullHeightVisible;
    const height = expanded ? 'auto' : 250;
    const readMoreText = fullHeightVisible ? 'Read less...' : 'Read more...';

    React.useEffect(() => {
        if (textAreaRef.current) {
            const { scrollHeight, clientHeight, offsetHeight } = textAreaRef.current;
            const computedStyle = window.getComputedStyle(textAreaRef.current);
            const lineHeight = parseFloat(computedStyle.lineHeight);
            const numberOfLines = Math.floor(offsetHeight / lineHeight);
            setNumberOfLinesShown(numberOfLines);
            setIsOverflowing(scrollHeight > clientHeight);
        }
    }, [props]);

    const buttons: JSX.Element[] = [];
    const [openEdit, setOpenEdit] = React.useState<boolean>(false);
    const [openDelete, setOpenDelete] = React.useState<boolean>(false);
    if (editButtonProps) {
        const EditButton: React.ComponentType<NotesSummaryMenuActionProps> = editButtonProps?.Component;
        buttons.push(
            <EditButton
                open={openEdit}
                setOpen={setOpenEdit}
                refetch={editButtonProps?.refetch}
                childProps={editButtonProps?.childProps}
            />,
        );
    }
    if (deleteButtonProps) {
        const DeleteButton: React.ComponentType<NotesSummaryMenuActionProps> = deleteButtonProps?.Component;
        buttons.push(
            <DeleteButton
                open={openDelete}
                setOpen={setOpenDelete}
                refetch={deleteButtonProps?.refetch}
                childProps={deleteButtonProps?.childProps}
            />,
        );
    }

    return (
        <StyledBox style={{ height }}>
            <NotesTileTitle noteTitle={noteTitle} buttons={buttons} />
            {alerts && <NotesTileAlerts alerts={alerts} />}
            <NotesTileBody
                textAreaRef={textAreaRef}
                noteText={noteText}
                expanded={expanded}
                numberOfLinesShown={numberOfLinesShown}
            />
            <NotesTileFooter
                lastEditedData={lastEditedData}
                setFullHeightVisible={setFullHeightVisible}
                fullHeightVisible={expanded}
                showReadMoreButton={isOverflowing}
                readMoreText={readMoreText}
            />
        </StyledBox>
    );
};
