import { DesignAnalyticsSection } from '../../../../../../components/DesignDetailSidebar/DesignDetailSidebarAnalytics';
import { DesignItemsSection } from '../../../../../../components/DesignDetailSidebar/DesignDetailSidebarItems';
import { DesignPreferencesSection } from '../../../../../../components/DesignDetailSidebar/DesignDetailSidebarPreferences';
import { DesignSidebarSection } from '../../../../../../components/DesignDetailSidebar/DesignDetailSidebarSection';
import { DesignDetailSidebarTags } from '../../../../../../components/DesignDetailSidebar/DesignDetailSidebarTags';
import {
    DesignDoctorNotesSection,
    DesignClinicalNotesSection,
} from '../../../../../../components/DesignDetailSidebar/DesignNotesSection';
import { useAdminUiAction } from '../../../../../../redux/ui/ui.actions';
import { AnalyticsClient } from '../../../../../../utils/analyticsClient';
import { useChatMessages } from '../OrderDetailChat/OrderDetailChat.hooks';
import { SubmitOrderScanReview } from '../actions/SubmitOrderScanReview';
import { DesignFileValidationErrorsCard } from './DesignFileValidationOverrides';
import { DoctorDesignTaskAlerts } from './DoctorDesignTaskAlerts';
import { BrowserAnalyticsClientFactory } from '@orthly/analytics/dist/browser';
import { SimpleCopyToClipboard } from '@orthly/dentin';
import type {
    FragmentType,
    OrderDesignPreviewDesign_FragmentFragmentDoc,
    VeneerOrderDesignAnalyticsDesign_FragmentFragmentDoc,
} from '@orthly/graphql-inline-react';
import type {
    LabsGqlOrder,
    LabsGqlWorkflowTask_InternalDesignWorkflowTask_Fragment as LabsGqlInternalDesignTaskFragment,
    LabsGqlDesignQaEvaluationResultsOrderFragmentFragment,
} from '@orthly/graphql-operations';
import { useConfigureDesignReviewMutation } from '@orthly/graphql-react';
import { IOrganizationType } from '@orthly/retainer-common';
import { useSession } from '@orthly/session-client';
import type { ActionCardButtonPropsBase } from '@orthly/ui';
import { WarningIcon, useChangeSubmissionFn } from '@orthly/ui';
import { FlossPalette, Text, Grid } from '@orthly/ui-primitives';
import type { CondensedActionCardVariant, DesignQcSidebarEntry } from '@orthly/veneer';
import {
    CondensedActionCard,
    CompleteDesignReviewTaskModal,
    DandyChatWithConversation,
    PreviousDesignRejectionDetailsFromTask,
} from '@orthly/veneer';
import _ from 'lodash';
import React from 'react';

interface OrderDesignReviewTaskAlertProps {
    order: LabsGqlOrder;
    refetchOrder: () => Promise<any>;
    variant: CondensedActionCardVariant;
    // If provided null, will fetch the latest design for the order.
    // If provided undefined or a design fragment, will utilize what was provided.
    designFragment?:
        | (FragmentType<typeof OrderDesignPreviewDesign_FragmentFragmentDoc> &
              FragmentType<typeof VeneerOrderDesignAnalyticsDesign_FragmentFragmentDoc>)
        | null;
}

const DesignQcChat: React.VFC<{ order: LabsGqlDesignQaEvaluationResultsOrderFragmentFragment }> = ({ order }) => {
    const session = useSession();
    const staffId = session?.id ?? '';
    const {
        data: messages,
        addChatFn: { submitting },
    } = useChatMessages({ order, staffId, orderId: order.id });

    return (
        <DandyChatWithConversation<IOrganizationType>
            chatProps={{
                messages,
                events: [],
                submitting,
                currentStaffId: staffId,
                highlight_since: null,
                lab_id: order.manufacturer_id,
                practice_id: order.partner_id,
                orderId: order.id,
            }}
            buttonProps={{
                convoKeysEnum: IOrganizationType,
            }}
            initialConvo={IOrganizationType.internal}
        />
    );
};

export function useGuidedDesignQcSidebar(
    order: LabsGqlDesignQaEvaluationResultsOrderFragmentFragment | undefined,
    designFragment: FragmentType<typeof VeneerOrderDesignAnalyticsDesign_FragmentFragmentDoc> | undefined,
): DesignQcSidebarEntry[] {
    // We find the last Internal Design task, as it will have the last rejection info stored in its configuration.
    const previousDesignTask: LabsGqlInternalDesignTaskFragment | undefined = _.findLast(
        order?.fulfillment_workflow.closed_tasks,
        (task): task is LabsGqlInternalDesignTaskFragment => task.__typename === 'InternalDesignWorkflowTask',
    );

    const elements: (DesignQcSidebarEntry | undefined)[] = order
        ? [
              {
                  name: 'Items',
                  sidebarBody: (
                      <>
                          {/* Order ID */}
                          <DesignSidebarSection>
                              <Grid container direction={'column'}>
                                  <Grid item>
                                      <SimpleCopyToClipboard
                                          onClick={() =>
                                              BrowserAnalyticsClientFactory.Instance?.track('Order Number Copied', {
                                                  $groups: { order: order.id },
                                              })
                                          }
                                          tooltip={'Copy order ID'}
                                          size={'small'}
                                          value={order.order_number}
                                      >{`Order ID: #${order.order_number}`}</SimpleCopyToClipboard>
                                  </Grid>
                                  <Grid item>
                                      <Text variant={'body2'} color={'DARK_GRAY'}>
                                          Patient: {order.patient.first_name} {order.patient.last_name}
                                      </Text>
                                  </Grid>
                              </Grid>
                          </DesignSidebarSection>
                          {/* Items */}
                          <DesignItemsSection order={order} />
                          {/* Preferences */}
                          <DesignPreferencesSection order={order} />
                          {/* Notes */}
                          <DesignDoctorNotesSection order={order} />
                          {/* Clinical Notes */}
                          <DesignClinicalNotesSection order={order} />
                          {/* Tags */}
                          <DesignDetailSidebarTags orderId={order.id} />
                      </>
                  ),
              },
              {
                  name: 'Chat',
                  sidebarBody: <DesignQcChat order={order} />,
              },
              designFragment
                  ? {
                        name: 'Analytics',
                        sidebarBody: <DesignAnalyticsSection orderId={order.id} designFragment={designFragment} />,
                    }
                  : undefined,
              !!previousDesignTask
                  ? {
                        name: 'Previous Reviews',
                        sidebarBody: <PreviousDesignRejectionDetailsFromTask task={previousDesignTask} />,
                    }
                  : undefined,
          ]
        : [];

    return _.compact(elements);
}

function useSkipReview(orderId: string, onSuccess: () => void) {
    const [submitMtn] = useConfigureDesignReviewMutation({
        variables: { order_id: orderId, design_review_required: false, external_design_review_blocking: false },
    });
    const { submit, submitting } = useChangeSubmissionFn(() => submitMtn(), {
        successMessage: () => [`Design review skipped`, {}],
        onSuccess,
    });
    return { submit, submitting };
}

export const OrderDesignReviewTaskAlert: React.VFC<OrderDesignReviewTaskAlertProps> = props => {
    const { order, refetchOrder } = props;

    const [scanReviewOpen, setScanReviewOpen] = React.useState<boolean>(false);
    const [designReviewTaskOpen, setDesignReviewTaskOpenInner] = React.useState<boolean>(false);
    const setSidebarOpen = useAdminUiAction('SET_SIDEBAR_OPEN');

    const { submit: skipReview, submitting: skipReviewSubmitting } = useSkipReview(order.id, () => {
        void refetchOrder();
    });

    const setDesignReviewTaskOpen = React.useCallback(
        (open: boolean) => {
            // Visually, this modal by itself cover the design portal's controls (on the left-hand
            // side of the screen). We prevent this behavior by opening the portal sidebar
            // with `setSidebarOpen` when the modal is opened, which will then both shrink
            // the design portal to the right and also make the tab controls available to the user.
            if (open) {
                setSidebarOpen(open);
            }

            setDesignReviewTaskOpenInner(open);
        },
        [setSidebarOpen, setDesignReviewTaskOpenInner],
    );

    const isSidebarElement = props.variant === 'sidebar';
    const primaryAction = React.useMemo<ActionCardButtonPropsBase>(() => {
        return {
            endIcon: 'CheckIcon',
            text: isSidebarElement ? 'Review' : 'Review design',
            onClick: () => {
                AnalyticsClient.track(`Button Clicked`, {
                    AssetType: 'button',
                    AssetName: 'Design QC Review',
                    AssetVersion: '1.0',
                    AssetCTAText: isSidebarElement ? 'Review' : 'Review design',
                });
                setDesignReviewTaskOpen(true);
            },
        };
    }, [isSidebarElement, setDesignReviewTaskOpen]);

    const secondaryAction = {
        text: 'Skip design review',
        onClick: () => {
            if (window.confirm(`Skip design review?`)) {
                void skipReview();
            }
        },
        disabled: skipReviewSubmitting,
    };

    const task = order.fulfillment_workflow.active_task;
    if (task?.__typename !== 'DesignReviewWorkflowTask') {
        return null;
    }
    const { assignee } = task;
    const assignedUser = assignee
        ? `${assignee.assigned_user.first_name} ${assignee.assigned_user.last_name}`
        : 'Unassigned';
    const internalDesignNotes = task.configuration.internal_design_notes;
    const designFileValidationErrors = order.latest_design_file_validation_errors;

    return (
        <>
            <CondensedActionCard
                title={task.type === 'DesignReview' ? 'Design Review' : 'Double QC'}
                assignee={assignedUser}
                primaryAction={primaryAction}
                secondaryAction={secondaryAction}
                IconComponent={WarningIcon}
                refetch={refetchOrder}
                variant={props.variant}
                task={task}
                subtitle={
                    <>
                        <Text variant={'body2'} color={'DARK_GRAY'}>
                            {`Design completed by ${
                                order.fulfillment_workflow.configuration.internal_design_required ? 'internal' : 'lab'
                            } designer`}
                        </Text>
                        {internalDesignNotes && (
                            <Text variant={'body2'} color={'DARK_GRAY'}>
                                Internal design notes:{' '}
                                <span style={{ color: FlossPalette.BLACK }}>{internalDesignNotes}</span>
                            </Text>
                        )}
                        {designFileValidationErrors?.length > 0 && (
                            <DesignFileValidationErrorsCard validationErrors={designFileValidationErrors} />
                        )}
                        <DoctorDesignTaskAlerts lab_slip_alerts={order.lab_slip_alerts} />
                    </>
                }
            />
            {designReviewTaskOpen && (
                <CompleteDesignReviewTaskModal
                    order={props.order}
                    refetchOrder={props.refetchOrder}
                    setOpen={setDesignReviewTaskOpen}
                    open={designReviewTaskOpen}
                />
            )}
            <SubmitOrderScanReview
                initialResponse={'flag'}
                open={scanReviewOpen}
                reasons={[]}
                setOpen={setScanReviewOpen}
                {...props}
            />
        </>
    );
};
