import { mergeOrderItems, useGroupPreferences } from '../../OrderSummary';
import { NOTE_TO_DR_PREFIX } from '../../PrintableSlip/utils/notes.util';
import type { AllNotes, NotesSummaryOrder, LastEditedData } from './NotesSummary.types';
import type { LabsGqlOrderTimelineItemFragment, LabsGqlTimelineItemActorFragment } from '@orthly/graphql-operations';
import { useTimelineItemsQuery, useZendeskLsrTicketForScanQuery } from '@orthly/graphql-react';
import { LabsGqlDoctorAlertLocation, LabsGqlOrderTimelineEventNameEnum } from '@orthly/graphql-schema';
import type { IOrderItemV2DTO } from '@orthly/items';
import { CartItemV2Utils, OrderItemV2Utils } from '@orthly/items';
import { UuidUtils } from '@orthly/runtime-utils';
import _ from 'lodash';
import React from 'react';

export function getNotesCountForOrder(
    allNotes: AllNotes,
    items: IOrderItemV2DTO[],
    showLabSlipAlerts: boolean = false,
): number {
    const numNotes = _.compact([
        allNotes.clinicalSupportNotes?.noteText,
        allNotes.doctorNotes?.noteText,
        ...allNotes.itemNotes,
        allNotes.refabNotes.noteText,
    ]).length;

    const designTaskAlertCount =
        hasCrownUnderPartial(items) || allNotes.designTaskAlertNotes.noteText || allNotes.lsrNotes?.noteText ? 1 : 0;

    const labSlipAlertCount =
        showLabSlipAlerts && allNotes.labSlipAlertNotes && allNotes.labSlipAlertNotes.noteText ? 1 : 0;

    return numNotes + designTaskAlertCount + labSlipAlertCount;
}

export function hasCrownUnderPartial(items: IOrderItemV2DTO[]): boolean {
    return items.some(item => {
        return item.preference_fields.some(
            ({ field_id, value }) => field_id === 'crown-under-partial' && value === 'Yes',
        );
    });
}

function filterForApplicableItemEvents(
    eventName: LabsGqlOrderTimelineEventNameEnum,
    item: LabsGqlOrderTimelineItemFragment,
    itemIdentifier?: string,
) {
    if (eventName !== LabsGqlOrderTimelineEventNameEnum.OrderItemsUpdatedV2) {
        return true;
    }

    // Each item can have it's own note - this identifier uses the same
    // util creation as the Event to find recent edits for this item only.
    const eventItemIdentifier = item.subtitle[0] ?? '';
    if (!itemIdentifier || !eventItemIdentifier.includes(itemIdentifier)) {
        return false;
    }

    const changeText = item.subtitle[1] ?? '';
    return !!(changeText.startsWith('Notes changed') || changeText.startsWith('Notes added'));
}

function getLastEditedDataForNote({
    eventName,
    timelineItems,
    otherLastEditedData = [],
    itemIdentifier,
}: {
    eventName: LabsGqlOrderTimelineEventNameEnum;
    timelineItems: LabsGqlOrderTimelineItemFragment[];
    otherLastEditedData?: LastEditedData[];
    itemIdentifier?: string;
}): LastEditedData | undefined {
    const timelineLastEdited: LastEditedData[] =
        timelineItems
            .filter(e => e.orderEvent === eventName && filterForApplicableItemEvents(eventName, e, itemIdentifier))
            .map<LastEditedData>(item => ({
                lastEditedBy: item.actor as LabsGqlTimelineItemActorFragment,
                lastEditedTime: new Date(item.date),
            })) ?? [];

    return _.orderBy([...timelineLastEdited, ...otherLastEditedData], e => e.lastEditedTime.valueOf(), 'desc')[0];
}

export function useGetAllNotes(order: NotesSummaryOrder): AllNotes {
    const { data: lsrData, loading: lsrDataLoading } = useZendeskLsrTicketForScanQuery({
        variables: { scan_export_id: order.scan_export_id },
    });
    const { data: timelineData } = useTimelineItemsQuery({
        variables: { entityId: order.id, entityType: 'order' },
        skip: !UuidUtils.isUUID(order.id),
    });

    const allItems = OrderItemV2Utils.parseItems(order.items_v2);
    const itemsWithNotes = allItems.filter(item => item.item_notes);
    const { majorityPrefValues } = useGroupPreferences(itemsWithNotes);
    const mergedItems = mergeOrderItems(itemsWithNotes, { majorityPrefs: majorityPrefValues });

    const designTaskAlerts = order.lab_slip_alerts.filter(
        alert => !!alert.visibility?.includes(LabsGqlDoctorAlertLocation.DesignTask),
    );

    const labSlipAlerts = order.lab_slip_alerts.filter(
        alert =>
            !!alert.visibility?.includes(LabsGqlDoctorAlertLocation.LabSlip) &&
            !alert.text.startsWith(NOTE_TO_DR_PREFIX),
    );

    const timelineItems = React.useMemo(() => {
        return (timelineData?.timelineItems ?? []).filter(
            (i): i is LabsGqlOrderTimelineItemFragment => i.__typename === 'OrderTimelineItem',
        );
    }, [timelineData]);

    return React.useMemo<AllNotes>(() => {
        return {
            clinicalSupportNotes: {
                noteText: order.clinical_support_notes?.notes,
                lastEditedData: getLastEditedDataForNote({
                    eventName: LabsGqlOrderTimelineEventNameEnum.OrderClinicalSupportNotesUpdated,
                    timelineItems,
                }),
            },
            doctorNotes: {
                noteText: order.doctor_notes,
                lastEditedData: getLastEditedDataForNote({
                    eventName: LabsGqlOrderTimelineEventNameEnum.OrderDoctorNoteEdited,
                    timelineItems,
                }),
            },
            itemNotes: mergedItems.map(i => {
                return {
                    noteText: i.item_notes,
                    lastEditedData: getLastEditedDataForNote({
                        eventName: LabsGqlOrderTimelineEventNameEnum.OrderItemsUpdatedV2,
                        timelineItems,
                        itemIdentifier: CartItemV2Utils.getFullDisplayName(i),
                    }),
                    item: i,
                };
            }),
            labSlipAlertNotes: {
                noteText: labSlipAlerts.map(alert => ` - ${alert.text}`).join('\n'),
                lastEditedData: getLastEditedDataForNote({
                    eventName: LabsGqlOrderTimelineEventNameEnum.LabSlipAlertsSaved,
                    timelineItems,
                }),
            },
            designTaskAlertNotes: {
                noteText: designTaskAlerts.map(alert => alert.text).join('\n'),
                lastEditedData: getLastEditedDataForNote({
                    eventName: LabsGqlOrderTimelineEventNameEnum.LabSlipAlertsSaved,
                    timelineItems,
                    otherLastEditedData: lsrData?.zendeskLSRTicketForScan?.created_at
                        ? [
                              {
                                  lastEditedTime: new Date(lsrData.zendeskLSRTicketForScan.created_at),
                              },
                          ]
                        : [],
                }),
            },
            refabNotes: {
                noteText: order.notes_for_refabrication,
                lastEditedData: getLastEditedDataForNote({
                    eventName: LabsGqlOrderTimelineEventNameEnum.OrderRefabricationEdited,
                    timelineItems,
                }),
            },
            lsrNotes: {
                noteText: lsrData?.zendeskLSRTicketForScan?.interaction_notes,
                lastEditedData: lsrData?.zendeskLSRTicketForScan?.created_at
                    ? {
                          lastEditedTime: new Date(lsrData?.zendeskLSRTicketForScan?.created_at),
                      }
                    : undefined,
            },
            isLoading: lsrDataLoading,
        };
    }, [order, mergedItems, designTaskAlerts, timelineItems, lsrData, labSlipAlerts, lsrDataLoading]);
}
