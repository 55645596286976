import type { AdminLabsOrderActionProps } from '../../Orders/screens/OrderDetail/components/actions/order-action-types';
import { useBillingDetailsContext } from '../BillingDetails/providers/BillingDetailsProvider.graphql';
import { AdminLabsEditInvoicingLineItemsAction } from '../actions/AdminLabsEditInvoicingLineItemsAction.graphql';
import { IssueCreditOrRefundButton } from '../actions/CreateCreditOrRefund/IssueCreditOrRefundButton';
import type { Invoice, InvoiceItem, InvoiceItemRow } from './InvoiceTable.types';
import { ManualInvoiceItemDelete } from './ManualInvoiceItemDeleteModal';
import { ManualInvoiceItemEdit } from './ManualInvoiceItemEditModal';
import type { LabsGqlLabOrderFragment } from '@orthly/graphql-operations';
import { useOrderRefetch, useOrdersByIds } from '@orthly/graphql-react';
import { LabsGqlInvoiceItemCategory } from '@orthly/graphql-schema';
import type { MUITableProps } from '@orthly/mui-table';
import { MUITable } from '@orthly/mui-table';
import { dayjsExt as dayjs } from '@orthly/runtime-utils';
import { PencilOutlinedIcon, RootActionDialog, TrashIcon } from '@orthly/ui';
import { Grid, IconButton, styled, Text } from '@orthly/ui-primitives';
import { useFeatureFlag } from '@orthly/veneer';
import { compact, flatMap, groupBy, keyBy, sortBy, uniq } from 'lodash';
import React from 'react';

function useItemRows(items: InvoiceItem[]): InvoiceItemRow[] {
    const orderIds = React.useMemo(() => uniq(compact(items.map(i => i.order_id))), [items]);
    const { orders: ordersRaw } = useOrdersByIds(orderIds);

    const getEffectiveDate = (item: InvoiceItem, labOrder?: LabsGqlLabOrderFragment) => {
        if (!labOrder || item.category === LabsGqlInvoiceItemCategory.OrderRefund) {
            return dayjs(item.created_at).toDate();
        }

        return dayjs(labOrder.created_at).toDate();
    };

    const sortAndFlattenGroupedInvoiceItems = (items: InvoiceItemRow[]): InvoiceItemRow[] => {
        // Attributed credits will have an attribution_key on them that will tie back to the
        // order_id or invoice_item_id of original item it is attributed to. Grouping them together
        // here so that we guarantee those items are next to each other in the list to be properly sorted
        const itemsGroupedByCreditAttributions = groupBy(items, item => {
            return item.used_credit_attribution_key || item.order_id || item.id;
        });

        return flatMap(itemsGroupedByCreditAttributions, group => {
            return group.sort((a, b) => {
                if (a.used_credit_attribution_key && !b.used_credit_attribution_key) {
                    return 1; // The order/invoice item item should be first
                } else if (!a.used_credit_attribution_key && b.used_credit_attribution_key) {
                    return -1; // The item's associated attributed credit should be second
                } else {
                    return 0;
                }
            });
        });
    };

    return React.useMemo(() => {
        const ordersById = keyBy(compact(ordersRaw), p => p.id);
        const findOrderById = (order_id: string | null) => (order_id ? ordersById[order_id] : undefined);
        const rowsFromItems = items.map<InvoiceItemRow>(item => {
            const lab_order = findOrderById(item.order_id ?? null);
            const effective_date = getEffectiveDate(item, lab_order);
            return {
                ...item,
                description: item.used_credit_attribution_description || item.description,
                lab_order,
                effective_date,
                doctor_name:
                    item.category === LabsGqlInvoiceItemCategory.OtherCharge && !!item.recurring_item_id
                        ? 'Dandy Internal'
                        : lab_order?.doctor_name,
            };
        });
        // First we sort in descending order by effective_date
        const descendingSortedItems = sortBy(rowsFromItems, r => -r.effective_date.valueOf());
        // Then we sort by items and their attributed credits
        return sortAndFlattenGroupedInvoiceItems(descendingSortedItems);
    }, [items, ordersRaw]);
}

interface InvoiceItemsTableProps {
    items: InvoiceItem[];
    invoice?: Invoice;
    title: string;
    loading?: boolean;
    tableProps?: Partial<Omit<MUITableProps<InvoiceItemRow>, 'data' | 'columns' | 'title' | 'loading'>>;
    refetchItems?: () => Promise<unknown>;
    hasPendingPaymentOnInvoice?: boolean;
}

interface InvoiceItemActionProps {
    item: InvoiceItemRow;
    refetchCredits: () => Promise<unknown>;
    invoice?: Invoice;
    refetchItems?: () => Promise<unknown>;
}

const InvoiceItemAction: React.FC<InvoiceItemActionProps> = ({ item, invoice, refetchItems, refetchCredits }) => {
    const { value: enableCreditAndRefundOverhaul } = useFeatureFlag('enableCreditAndRefundOverhaul');
    if (!enableCreditAndRefundOverhaul || !invoice) {
        return null;
    }
    return (
        <IssueCreditOrRefundButton
            organizationId={invoice.organization_id}
            invoiceItem={{ ...item, invoice_id: invoice.id }}
            existingCreditId={item.used_credit_id}
            refetchItems={refetchItems}
            refetchCredits={refetchCredits}
        />
    );
};

interface InvoiceOrderItemActionProps extends AdminLabsOrderActionProps {
    isPreview: boolean;
    isRefundInvoiceItem: boolean;
    refetchCredits: () => Promise<unknown>;
    refetchItems?: () => Promise<unknown>;
}

const InvoiceOrderItemAction: React.FC<InvoiceOrderItemActionProps> = ({
    order,
    refetchOrder,
    hasPendingPaymentOnInvoice,
    isPreview,
    isRefundInvoiceItem,
    refetchItems,
    refetchCredits,
}) => {
    const { value: enableCreditAndRefundOverhaul } = useFeatureFlag('enableCreditAndRefundOverhaul');
    const showIssueCreditButton = !isPreview && !isRefundInvoiceItem;
    return (
        <>
            <AdminLabsEditInvoicingLineItemsAction
                refetchOrder={refetchOrder}
                order={order}
                hasPendingPaymentOnInvoice={hasPendingPaymentOnInvoice}
            />
            {enableCreditAndRefundOverhaul && showIssueCreditButton && (
                <IssueCreditOrRefundButton
                    organizationId={order.partner_id}
                    order={order}
                    refetchItems={refetchItems}
                    refetchCredits={refetchCredits}
                />
            )}
        </>
    );
};

const Stacked = styled('div')({
    display: 'flex',
    flexDirection: 'column',
});

const ItemDescription: React.FC<{ item: InvoiceItemRow }> = ({ item }) => {
    const { value: enableCreditAndRefundOverhaul } = useFeatureFlag('enableCreditAndRefundOverhaul');
    const { credits, creditCategories } = useBillingDetailsContext();

    const creditsById = keyBy(credits, c => c.id);
    const creditCategoriesById = keyBy(creditCategories, c => c.id);
    const usedCredit = item.used_credit_id ? creditsById[item.used_credit_id] : undefined;
    const usedCreditCategory =
        usedCredit && usedCredit.credit_category_id ? creditCategoriesById[usedCredit.credit_category_id] : undefined;

    const splitDescription = (item.description ?? '').split(',').join('\n');

    if (!enableCreditAndRefundOverhaul || !usedCreditCategory) {
        return (
            <Text variant={'body2'} color={'BLACK'}>
                {splitDescription}
            </Text>
        );
    }

    return (
        <Stacked>
            <Text variant={'body2'} color={'BLACK'}>
                {usedCreditCategory.name}
            </Text>
            <Text variant={'caption'} color={'BLACK'}>
                {splitDescription}
            </Text>
        </Stacked>
    );
};

export const InvoiceItemsTable: React.FC<InvoiceItemsTableProps> = ({
    items,
    invoice,
    tableProps,
    title,
    loading,
    refetchItems,
    hasPendingPaymentOnInvoice,
}) => {
    const { refetchCredits } = useBillingDetailsContext();
    const [openManualChargeEdit, setOpenManualChargeEdit] = React.useState<boolean>(false);
    const [openManualChargeDelete, setOpenManualChargeDelete] = React.useState<boolean>(false);
    const itemRows = useItemRows(items);
    const isPreview = !invoice;
    const [activeInvoiceItem, setActiveInvoiceItem] = React.useState<InvoiceItemRow>();
    const refetchOrder = useOrderRefetch();
    const RenderActionCell = React.useCallback(
        (r: InvoiceItemRow) => {
            if (!!r.recurring_item_id && isPreview) {
                return refetchItems ? (
                    <Grid container alignItems={'center'}>
                        <Grid item>
                            <RootActionDialog
                                open={openManualChargeEdit}
                                setOpen={setOpenManualChargeEdit}
                                loading={false}
                                title={'Edit Invoice Item'}
                                content={
                                    <ManualInvoiceItemEdit
                                        invoiceItem={activeInvoiceItem}
                                        setOpenManualChargeEdit={setOpenManualChargeEdit}
                                        refetch={refetchItems}
                                    />
                                }
                                CustomButton={({ onClick }) => (
                                    <IconButton
                                        onClick={ev => {
                                            setActiveInvoiceItem(r);
                                            onClick?.(ev);
                                        }}
                                    >
                                        <PencilOutlinedIcon />
                                    </IconButton>
                                )}
                                showCloseButton
                            />
                        </Grid>
                        <Grid item>
                            <RootActionDialog
                                open={openManualChargeDelete}
                                setOpen={setOpenManualChargeDelete}
                                loading={false}
                                title={'Delete invoice item'}
                                subtitle={'This action cannot be reversed. Are you sure you want to proceed?'}
                                content={
                                    <ManualInvoiceItemDelete
                                        invoiceItem={activeInvoiceItem}
                                        setOpenManualChargeEdit={() => {}}
                                        setOpenManualChargeDelete={setOpenManualChargeDelete}
                                        refetch={refetchItems}
                                    />
                                }
                                CustomButton={({ onClick }) => (
                                    <IconButton
                                        onClick={ev => {
                                            setActiveInvoiceItem(r);
                                            onClick?.(ev);
                                        }}
                                    >
                                        <TrashIcon />
                                    </IconButton>
                                )}
                                showCloseButton
                            />
                        </Grid>
                    </Grid>
                ) : null;
            }
            return !r.lab_order ? (
                <InvoiceItemAction
                    item={r}
                    invoice={invoice}
                    refetchItems={refetchItems}
                    refetchCredits={refetchCredits}
                />
            ) : (
                <InvoiceOrderItemAction
                    order={r.lab_order}
                    refetchOrder={async () => {
                        const orderId = r.lab_order?.id;
                        if (orderId) {
                            await refetchOrder(orderId);
                        }
                        void refetchItems?.();
                    }}
                    hasPendingPaymentOnInvoice={hasPendingPaymentOnInvoice}
                    isPreview={isPreview}
                    isRefundInvoiceItem={r.category === LabsGqlInvoiceItemCategory.OrderRefund}
                    refetchItems={refetchItems}
                    refetchCredits={refetchCredits}
                />
            );
        },
        [
            refetchOrder,
            openManualChargeEdit,
            openManualChargeDelete,
            setOpenManualChargeEdit,
            refetchItems,
            activeInvoiceItem,
            hasPendingPaymentOnInvoice,
            invoice,
            refetchCredits,
            isPreview,
        ],
    );

    return (
        <MUITable<InvoiceItemRow>
            {...tableProps}
            loading={loading}
            columns={[
                { name: 'Order ID', type: 'string', render: 'order_id', hidden: true },
                {
                    name: 'Date',
                    type: 'date',
                    render: 'effective_date',
                },
                {
                    name: 'Category',
                    render: r =>
                        r.category === LabsGqlInvoiceItemCategory.OtherCharge && !!r.recurring_item_id ? (
                            <Grid container direction={'column'}>
                                <Grid item>{r.category}</Grid>
                                <Grid item>{r.subcategory}</Grid>
                            </Grid>
                        ) : (
                            r.category
                        ),
                    filterOptions: { exact: true, type: 'multiselect' },
                },
                {
                    name: 'Description',
                    render: r => <ItemDescription item={r} />,
                    field: 'description',
                    bodyCellWrapStyle: { whiteSpace: 'pre-line', maxWidth: 100 },
                },
                { name: 'Doctor', render: 'doctor_name' },
                { name: 'Amount', render: 'amount_cents', type: 'currency' },
                { name: 'Action', render: RenderActionCell },
            ]}
            data={itemRows}
            title={title}
            displayOptions={{ fixedSearch: true, download: true, elevation: 0, ...tableProps?.displayOptions }}
        />
    );
};
