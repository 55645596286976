import type { LabsGqlOrderProcedureFragment } from '@orthly/graphql-operations';
import { LoadBlocker } from '@orthly/ui';
import { Text, Grid, stylesFactory } from '@orthly/ui-primitives';
import moment from 'moment';
import React from 'react';

const useLabtracDetailStyles = stylesFactory(() => ({
    labtracDetailsContainer: {
        overflowY: 'scroll',
    },
    stationContainer: {
        overflowY: 'scroll',
        padding: '24px',
    },
    stationDetail: {
        display: 'flex',
        flexDirection: 'row',
    },
    stationDetailHeader: {
        fontWeight: 'bold',
        paddingRight: '8px',
    },
    headerContainer: {
        padding: '24px 0 0 24px',
    },
    errorWrapper: {
        padding: '24px',
        fontWeight: 'bold',
    },
}));

const formatDateTime = (date: string) => moment(date).format('MMMM D, h:mmA');

const LabtracStationDetail: React.FC<{ data: any }> = ({ data }) => {
    const classes = useLabtracDetailStyles();
    return (
        <Grid className={classes.stationContainer}>
            <Grid className={classes.stationDetail}>
                <Grid className={classes.stationDetailHeader}>Station</Grid>
                <Grid>{data.description}</Grid>
            </Grid>
            <Grid className={classes.stationDetail}>
                <Grid className={classes.stationDetailHeader}>Status</Grid>
                <Grid>{data.status}</Grid>
            </Grid>
            <Grid className={classes.stationDetail}>
                <Grid className={classes.stationDetailHeader}>Booked On</Grid>
                <Grid>{formatDateTime(data.bookedOn)}</Grid>
            </Grid>
            <Grid className={classes.stationDetail}>
                <Grid className={classes.stationDetailHeader}>Booked Off</Grid>
                <Grid>{formatDateTime(data.bookedOff)}</Grid>
            </Grid>
            <Grid className={classes.stationDetail}>
                <Grid className={classes.stationDetailHeader}>Internal Remake</Grid>
                <Grid>{data.redo ? 'Yes' : 'No'}</Grid>
            </Grid>
            <Grid className={classes.stationDetail}>
                <Grid className={classes.stationDetailHeader}>Technician ID</Grid>
                <Grid>{data.technicianID}</Grid>
            </Grid>
        </Grid>
    );
};

export const LabtracDetailsSidebar: React.FC<{
    dataLoading: boolean;
    labtracId?: string;
    procedureData?: LabsGqlOrderProcedureFragment[];
}> = ({ dataLoading, labtracId, procedureData }) => {
    const classes = useLabtracDetailStyles();
    if (!dataLoading && !labtracId && !procedureData) {
        return <Grid className={classes.errorWrapper}>Sorry, we don't have any data to show you</Grid>;
    }
    return (
        <LoadBlocker blocking={dataLoading} ContainerProps={{ style: { overflow: 'auto', height: '100%' } }}>
            {labtracId && (
                <Grid className={classes.headerContainer}>
                    <Grid className={classes.stationDetail}>
                        <Text variant={'h6'} className={classes.stationDetailHeader}>
                            Labtrac ID
                        </Text>
                        <Text variant={'body1'}>{`CN${labtracId}`}</Text>
                    </Grid>
                </Grid>
            )}
            <Grid className={classes.labtracDetailsContainer}>
                {procedureData?.map((procedure, i) => <LabtracStationDetail key={i} data={procedure} />)}
            </Grid>
        </LoadBlocker>
    );
};
