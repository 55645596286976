import { inboxReducer } from '../screens/Inbox/state/Inbox.state';
import { ordersOverviewReducer } from '../screens/Orders/state/OrdersOverview.state';
import { palateReducer } from '../screens/Palate/state/Palate.reducer';
import { usersReducer } from '../screens/Users/state/Users.state';
import type { AdminState } from './redux.types';
import { adminUiReducer } from './ui/ui.state';
import { createAsyncReducer } from '@orthly/redux-async-actions';
import { connectRouter } from 'connected-react-router';
import type { History } from 'history';
import type { TypedUseSelectorHook } from 'react-redux';
import { useSelector } from 'react-redux';
import type { AnyAction } from 'redux';
import { combineReducers } from 'redux';

const reducer = (history: History) =>
    combineReducers<AdminState>({
        ui: adminUiReducer,
        async: createAsyncReducer(false),
        router: connectRouter(history),
        ordersOverview: ordersOverviewReducer,
        inbox: inboxReducer,
        users: usersReducer,
        palate: palateReducer,
    });

export const rootReducer = (history: History) => (state: AdminState | undefined, action: AnyAction) =>
    reducer(history)(state, action);

export const useAdminSelector: TypedUseSelectorHook<AdminState> = useSelector;
