import type { UnsavedFilterCriteria } from './OrderFilterBuilder.types';
import { OrderFilterCombinatorSwitch } from './OrderFilterCombinatorSwitch';
import { OrderFilterCriteriaRow } from './OrderFilterCriteriaRow';
import type { ListOrderFiltersQueryHookResult, ListPalateOrderFiltersQueryHookResult } from '@orthly/graphql-react';
import type {
    LabsGqlFilterCriteriaSubmissionInput,
    LabsGqlFilterCombinator as Combinator,
} from '@orthly/graphql-schema';
import { LabsGqlFilterCombinator } from '@orthly/graphql-schema';
import { LoadBlocker } from '@orthly/ui';
import { Text, Grid, Button } from '@orthly/ui-primitives';
import _ from 'lodash';
import React from 'react';

interface OrderFilterBuilderState {
    combinator?: LabsGqlFilterCombinator;
    setCombinator?: (combinator: LabsGqlFilterCombinator) => void;
    criterias: UnsavedFilterCriteria[];
    setCriterias: (criteriaValues: UnsavedFilterCriteria[]) => void;
    filtersQuery: ListOrderFiltersQueryHookResult | ListPalateOrderFiltersQueryHookResult;
    changed: boolean;
    setChanged: (changed: boolean) => void;
}

export function isCompletedFilterCriteria(
    criteria: UnsavedFilterCriteria,
): criteria is LabsGqlFilterCriteriaSubmissionInput {
    // If the comparator is 'exists' or 'not exists', the comparison value can be undefined
    // otherwise, it must be defined!
    if (!!criteria.comparator && !['notSet', 'set'].includes(criteria.comparator) && !criteria.comparison_value) {
        return false;
    }
    return !!criteria.comparator && !!criteria.filter_id;
}

export interface OrderFilterBuilderRootProps extends OrderFilterBuilderState {
    actionsBlock?: React.ReactNode;
}

export const OrderFilterBuilderRoot: React.FC<OrderFilterBuilderRootProps> = props => {
    const {
        filtersQuery,
        combinator = LabsGqlFilterCombinator.And,
        setCombinator,
        criterias,
        setCriterias,
        changed,
        setChanged,
    } = props;
    const filterDefinitions = filtersQuery.data?.rules ?? [];
    const completedCriterias = criterias.filter(isCompletedFilterCriteria);
    const onChange = (idx: number) => (criteria: UnsavedFilterCriteria) => {
        !changed && setChanged(true);
        setCriterias(criterias.map((c, currentIdx) => (currentIdx !== idx ? c : { ...c, ...criteria })));
    };
    const onFilterRuleChange = (idx: number) => (criteria: UnsavedFilterCriteria) => {
        !changed && setChanged(true);
        criteria.comparison_value = undefined;
        setCriterias(criterias.map((c, currentIdx) => (currentIdx !== idx ? c : { ...c, ...criteria })));
    };
    const onRemove = (idx: number) => () => {
        !changed && setChanged(true);
        setCriterias(criterias.filter((_c, currentIdx) => currentIdx !== idx));
    };
    const onCombinatorChange = React.useCallback(
        (newCombinator: Combinator) => {
            newCombinator !== combinator && !changed && setChanged(true);
            setCombinator?.(newCombinator);
        },
        [combinator, changed, setChanged, setCombinator],
    );
    return (
        <LoadBlocker blocking={filtersQuery.loading && filterDefinitions.length === 0}>
            {criterias.map((criteria, idx) => (
                <React.Fragment key={idx}>
                    {idx === 1 && setCombinator && (
                        <OrderFilterCombinatorSwitch combinator={combinator} setCombinator={onCombinatorChange} />
                    )}
                    {idx > 0 && (!setCombinator || idx > 1) && (
                        <Text color={'DARK_GRAY'} variant={'caption'} style={{ paddingBottom: 8, marginTop: -8 }}>
                            {_.startCase(combinator)}
                        </Text>
                    )}
                    <OrderFilterCriteriaRow
                        key={idx}
                        onChange={onChange(idx)}
                        onRuleFieldChange={onFilterRuleChange(idx)}
                        onRemove={onRemove(idx)}
                        filterDefinitions={filterDefinitions}
                        criteria={criteria}
                    />
                </React.Fragment>
            ))}
            <Grid container alignItems={'center'} justifyContent={'space-between'} wrap={'nowrap'} spacing={2}>
                <Grid container item xs={5}>
                    <Button
                        disabled={completedCriterias.length !== criterias.length}
                        variant={'ghost'}
                        startIcon={'PlusIcon'}
                        onClick={() => setCriterias([...criterias, {}])}
                    >
                        {_.startCase(combinator)}
                    </Button>
                </Grid>
                {props.actionsBlock ?? null}
            </Grid>
        </LoadBlocker>
    );
};
