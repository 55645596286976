import { InlineEditButton } from '../../../components/InlineEditButton';
import { useMutation } from '@apollo/client';
import { graphql } from '@orthly/graphql-inline-react';
import { QuickForm, QuickFormValidations, RootActionDialog, useRootActionCommand } from '@orthly/ui';
import { Icon, IconButton, styled, Text } from '@orthly/ui-primitives';
import { useFeatureFlag } from '@orthly/veneer';
import React from 'react';

type EditAccountManagementContactProps = {
    practiceId: string;
    acctManagementContactEmails: string[];
};

const EditAccountManagementContactEmail_Mutation = graphql(`
    mutation EditAccountManagementContacts($data: EditAccountManagementContactsCommand!) {
        editAccountManagementContacts(data: $data) {
            id
        }
    }
`);

const EmailContainer = styled('div')({
    display: 'flex',
    flexDirection: 'row',
});

const EmailText = styled(Text)({
    display: 'block',
});

export const EditAccountManagementContact: React.FC<EditAccountManagementContactProps> = ({
    practiceId,
    acctManagementContactEmails,
}) => {
    const { value: enableOpsPortalUXImprovements } = useFeatureFlag('enableOpsPortalUXImprovements');
    const [open, setOpen] = React.useState(false);
    const editAccountManagementContactEmail = useMutation(EditAccountManagementContactEmail_Mutation);
    const { submit, submitting } = useRootActionCommand(editAccountManagementContactEmail, {
        onSuccess: () => setOpen(false),
    });

    const CustomButton = React.useCallback(
        () =>
            enableOpsPortalUXImprovements ? (
                <EmailContainer>
                    <div>
                        {acctManagementContactEmails?.map(email => (
                            <EmailText variant={'caption'} key={email}>
                                {email}
                            </EmailText>
                        ))}
                    </div>
                    <IconButton onClick={() => setOpen(true)}>
                        <Icon icon={'PencilOutlinedIcon'} fontSize={'small'} />
                    </IconButton>
                </EmailContainer>
            ) : (
                <InlineEditButton label={acctManagementContactEmails.join('\n')} onClick={() => setOpen(true)} />
            ),
        [acctManagementContactEmails, setOpen, enableOpsPortalUXImprovements],
    );

    return (
        <RootActionDialog
            loading={submitting}
            open={open}
            setOpen={setOpen}
            title={'Edit Account Management Contact Email(s)'}
            buttonText={''}
            CustomButton={CustomButton}
            content={
                <QuickForm<{ account_management_contact_emails: string[] }>
                    fields={{
                        account_management_contact_emails: {
                            type: 'array',
                            of: { type: 'text', validation: QuickFormValidations.email },
                            label: 'Contact Email',
                            min: 1,
                        },
                    }}
                    initialValues={{ account_management_contact_emails: acctManagementContactEmails }}
                    onSubmit={async result => {
                        void submit({
                            data: {
                                partner_id: practiceId,
                                account_management_contact_emails: result.account_management_contact_emails,
                            },
                        });
                    }}
                    dirtySubmitOnly={true}
                />
            }
        />
    );
};
