import { AnalyticsClient } from '../../../../../../../utils/analyticsClient';
import type { ToolbarActionDefinition, ToolbarActionProps } from './ToolbarActionDefinition';
import { useQuery } from '@apollo/client';
import { OrderCancelReasonFormComponent } from '@orthly/dentin';
import type { PreviewCreditAndRefundOptionsForOrderCancellationQuery } from '@orthly/graphql-inline-react';
import { graphql } from '@orthly/graphql-inline-react';
import type { LabsGqlPsrCancelOrderMutationVariables } from '@orthly/graphql-operations';
import { usePsrCancelOrderMutation } from '@orthly/graphql-react';
import type { LabsGqlElementPriceHierarchyInput } from '@orthly/graphql-schema';
import {
    LabsGqlCancellationReasonCode,
    LabsGqlCancelOrderBillingActionType,
    LabsGqlLabOrderStatus,
} from '@orthly/graphql-schema';
import { Format } from '@orthly/runtime-utils';
import type { CancellationReasonCode } from '@orthly/shared-types';
import { OrthlyErrorBoundary, RootActionDialog, useChangeSubmissionFn } from '@orthly/ui';
import {
    Button,
    FlossPalette,
    CheckboxPrimitive as Checkbox,
    FormControlLabel,
    FormHelperText,
    RadioPrimitive as Radio,
    RadioGroupPrimitive as RadioGroup,
    MenuItem,
    Select,
    Text,
    Tooltip,
    styled,
} from '@orthly/ui-primitives';
import { useFeatureFlag } from '@orthly/veneer';
import dayjs from 'dayjs';
import type { ReactNode } from 'react';
import React from 'react';

type Vars = LabsGqlPsrCancelOrderMutationVariables['data'];

// Order statuses where you cannot cancel the order.
// This list is intentionally permissive for the admin Ops portal.
// It intentionally does not include Shipped order status.

export const disallowedStatusesForCancel: LabsGqlLabOrderStatus[] = [
    LabsGqlLabOrderStatus.Cancelled,
    LabsGqlLabOrderStatus.NeedsRefabrication,
    LabsGqlLabOrderStatus.Delivered,
];

const PreviewCreditAndRefundOptionsForOrderCancellation_Query = graphql(`
    query PreviewCreditAndRefundOptionsForOrderCancellation($orderId: String!) {
        previewCreditAndRefundOptionsForOrderCancellation(orderId: $orderId) {
            creditExistingInvoiceOption {
                amountCents
                createdAt
                invoiceId
                invoiceNumber
            }
            creditFutureInvoiceOption {
                amountCents
            }
            refundExistingInvoiceOption {
                amountCents
                invoiceId
            }
        }
    }
`);

interface FooterCaptionProps {
    enableCreditAndRefundOverhaul: boolean;
    canRefund: boolean;
    creditFutureOrExistingInvoice: FutureOrExisting;
    cancellationOptions:
        | PreviewCreditAndRefundOptionsForOrderCancellationQuery['previewCreditAndRefundOptionsForOrderCancellation']
        | undefined;
}

const FooterCaption: React.FC<FooterCaptionProps> = ({
    enableCreditAndRefundOverhaul,
    canRefund,
    creditFutureOrExistingInvoice,
    cancellationOptions,
}: FooterCaptionProps) => {
    return (
        <>
            {enableCreditAndRefundOverhaul && (
                <>
                    <Text variant={'caption'} color={'LIGHT_GRAY'}>
                        {canRefund
                            ? 'Since this order has been paid, you can choose to apply a credit or a refund for '
                            : `Since this order has not been paid, the cancelled order will be credited ${creditFutureOrExistingInvoice === 'future' ? 'for the next invoice ' : ''} for `}
                    </Text>
                    <Text variant={'caption'} color={'GREEN'}>
                        {canRefund
                            ? Format.currency(cancellationOptions?.refundExistingInvoiceOption?.amountCents || 0)
                            : Format.currency(cancellationOptions?.creditFutureInvoiceOption.amountCents || 0)}
                    </Text>
                </>
            )}
        </>
    );
};

interface MaybeTooltipProps {
    content: ReactNode;
    show: boolean;
}

const MaybeDisabledTooltip: React.FC<MaybeTooltipProps> = ({ content, show, children }) => {
    return show ? (
        <Tooltip
            title={content}
            slotProps={{
                popper: {
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [0, -270],
                            },
                        },
                    ],
                },
            }}
            arrow
            placement={'right'}
        >
            <span>{children}</span>
        </Tooltip>
    ) : (
        <>{children}</>
    );
};

type CancellationActionType = LabsGqlCancelOrderBillingActionType | 'cancel';
type FutureOrExisting = 'future' | 'existing';

interface CreditOrRefundRadioGroupProps {
    enableCreditAndRefundOverhaul: boolean;
    canCredit: boolean;
    canRefund: boolean;
    cancellationActionType: CancellationActionType;
    setCancellationActionType: (value: LabsGqlCancelOrderBillingActionType) => void;
    creditFutureOrExistingInvoice: FutureOrExisting;
    setCreditFutureOrExistingInvoice: (value: FutureOrExisting) => void;
    shouldSetAmountToZero: boolean;
    setShouldSetAmountToZero: (value: boolean) => void;
    existingInvoiceToCredit: PreviewCreditAndRefundOptionsForOrderCancellationQuery['previewCreditAndRefundOptionsForOrderCancellation']['creditExistingInvoiceOption'];
}

const Row = styled('div')`
    display: flex;
`;

const InvoiceSelect = styled(Select)`
    margin-left: 72px;
    max-width: 400px;
    min-width: 200px;
`;

const RadioButtonLabel = styled('p')`
    margin-left: 16px;
`;

const CreditOrRefundRadioGroup: React.VFC<CreditOrRefundRadioGroupProps> = ({
    enableCreditAndRefundOverhaul,
    canCredit,
    canRefund,
    cancellationActionType,
    setCancellationActionType,
    creditFutureOrExistingInvoice,
    setCreditFutureOrExistingInvoice,
    shouldSetAmountToZero,
    setShouldSetAmountToZero,
    existingInvoiceToCredit,
}) => {
    return (
        <>
            <FormHelperText>Cancellation parameters:</FormHelperText>
            {enableCreditAndRefundOverhaul ? (
                <RadioGroup
                    onChange={s => setCancellationActionType(s.target.value as LabsGqlCancelOrderBillingActionType)}
                    value={cancellationActionType}
                >
                    {canCredit && (
                        <>
                            <Row>
                                <Radio value={'credit'} name={'credit'} />
                                <RadioButtonLabel>Issue as credit</RadioButtonLabel>
                            </Row>
                            <MaybeDisabledTooltip
                                show={!existingInvoiceToCredit}
                                content={
                                    'The latest applicable invoice for this order has been paid, so only a future invoice is creditable.'
                                }
                            >
                                <InvoiceSelect
                                    value={creditFutureOrExistingInvoice}
                                    onChange={e => setCreditFutureOrExistingInvoice(e.target.value as FutureOrExisting)}
                                    disabled={!existingInvoiceToCredit}
                                    autoWidth
                                >
                                    <MenuItem value={'future'} selected>
                                        Credit Future Invoice
                                    </MenuItem>
                                    {existingInvoiceToCredit && (
                                        <MenuItem value={'existing'}>
                                            Credit Invoice: {dayjs(existingInvoiceToCredit.createdAt).format('MMM')}
                                            {' - '}
                                            {Format.currency(existingInvoiceToCredit.amountCents)}
                                            {' - #'}
                                            {existingInvoiceToCredit.invoiceNumber}
                                        </MenuItem>
                                    )}
                                </InvoiceSelect>
                            </MaybeDisabledTooltip>
                        </>
                    )}
                    {canRefund && (
                        <Row>
                            <Radio value={'refund'} name={'refund'} />
                            <RadioButtonLabel>Issue as refund</RadioButtonLabel>
                        </Row>
                    )}
                    <Row>
                        <Radio value={'cancel'} name={'cancel'} />
                        <RadioButtonLabel>Do not credit or refund</RadioButtonLabel>
                    </Row>
                </RadioGroup>
            ) : (
                <FormControlLabel
                    control={
                        <Checkbox
                            color={'secondary'}
                            checked={shouldSetAmountToZero}
                            onChange={event => setShouldSetAmountToZero(event.target.checked)}
                            name={'shouldSetAmountToZero'}
                        />
                    }
                    label={'Set amount due to zero?'}
                />
            )}
        </>
    );
};

export const OpsCancelOrderAction: React.FC<ToolbarActionProps> = props => {
    const { order, refetchOrder, open, setOpen, CustomButton } = props;
    const [submitMtn] = usePsrCancelOrderMutation();
    const mtnSubmitter = (data: Vars) => submitMtn({ variables: { data } });
    const { submit, submitting } = useChangeSubmissionFn<any, [Vars]>(mtnSubmitter, {
        closeOnComplete: true,
        successMessage: () => ['Order canceled', {}],
        onSuccess: async () => {
            await refetchOrder();
            setOpen(false);
        },
    });
    const [cancellationReasonCode, setCancellationReasonCode] = React.useState<Vars['cancellation_reason_code'] | null>(
        null,
    );
    const [cancellationReason, setCancellationReason] = React.useState<Vars['cancellation_reason']>(null);
    const [skipNotification, setSkipNotification] = React.useState<boolean>(true);
    const [confirmCancellation, setConfirmCancellation] = React.useState<boolean>(false);
    const [cancellationActionType, setCancellationActionType] = React.useState<
        LabsGqlCancelOrderBillingActionType | 'cancel'
    >(LabsGqlCancelOrderBillingActionType.Credit);
    const [shouldSetAmountToZero, setShouldSetAmountToZero] = React.useState<boolean>(true);
    const [creditFutureOrExistingInvoice, setCreditFutureOrExistingInvoice] =
        React.useState<FutureOrExisting>('future');
    const { value: enableCreditAndRefundOverhaul } = useFeatureFlag('enableCreditAndRefundOverhaul');
    console.log(`enableCreditAndRefundOverhaul: ${enableCreditAndRefundOverhaul}`);
    const { data: orderCancellationPreview, loading: loadingOrderCancellationPreview } = useQuery(
        PreviewCreditAndRefundOptionsForOrderCancellation_Query,
        {
            variables: {
                orderId: order.id,
            },
            skip: !enableCreditAndRefundOverhaul,
        },
    );
    const submitIsDisabled = !cancellationReasonCode || !confirmCancellation || submitting;
    const cancellationOptions = orderCancellationPreview?.previewCreditAndRefundOptionsForOrderCancellation;
    const canCredit = !!(
        cancellationOptions?.creditExistingInvoiceOption || cancellationOptions?.creditFutureInvoiceOption
    );
    const canRefund = !!cancellationOptions?.refundExistingInvoiceOption;
    const existingInvoiceToCredit = cancellationOptions?.creditExistingInvoiceOption;

    const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!cancellationReasonCode || !confirmCancellation) {
            return;
        }
        const cancellationReasonForReasonCode = [
            LabsGqlCancellationReasonCode.AnotherLabOther,
            LabsGqlCancellationReasonCode.Other,
        ].includes(cancellationReasonCode)
            ? cancellationReason
            : null;
        await submit({
            cancellation_reason_code: cancellationReasonCode,
            cancellation_reason: cancellationReasonForReasonCode,
            skip_notification: skipNotification,
            delete_scan: false,
            items_prices:
                !enableCreditAndRefundOverhaul && shouldSetAmountToZero
                    ? ([] as LabsGqlElementPriceHierarchyInput[])
                    : undefined,
            orderId: order.id,
            cancelOrderBillingAction:
                enableCreditAndRefundOverhaul && cancellationActionType !== 'cancel'
                    ? {
                          cancelOrderBillingActionType: cancellationActionType,
                          ...(cancellationActionType === 'credit' && {
                              creditInvoiceId:
                                  creditFutureOrExistingInvoice === 'future'
                                      ? null
                                      : existingInvoiceToCredit?.invoiceId,
                          }),
                      }
                    : undefined,
        });
        AnalyticsClient.track('All - Cancel Order - Submitted', {
            $groups: { order: order.id },
            cancellationReasonCode: cancellationReasonCode as unknown as CancellationReasonCode,
            cancellationReason: cancellationReasonForReasonCode ?? undefined,
        });
    };

    if (disallowedStatusesForCancel.includes(order.status)) {
        return null;
    }

    return (
        <RootActionDialog
            loading={submitting || loadingOrderCancellationPreview}
            open={open}
            setOpen={setOpen}
            title={`Cancel Order for ${order.patient.first_name} ${order.patient.last_name}`}
            content={
                <OrthlyErrorBoundary>
                    <form onSubmit={onSubmit}>
                        <FormHelperText>Reason for canceling the order:</FormHelperText>
                        <OrderCancelReasonFormComponent
                            cancellationReasonCode={cancellationReasonCode}
                            setCancellationReasonCode={setCancellationReasonCode}
                            cancellationReason={cancellationReason}
                            setCancellationReason={setCancellationReason}
                        />
                        <CreditOrRefundRadioGroup
                            enableCreditAndRefundOverhaul={enableCreditAndRefundOverhaul || false}
                            canCredit={canCredit}
                            canRefund={canRefund}
                            cancellationActionType={cancellationActionType}
                            setCancellationActionType={setCancellationActionType}
                            creditFutureOrExistingInvoice={creditFutureOrExistingInvoice}
                            setCreditFutureOrExistingInvoice={setCreditFutureOrExistingInvoice}
                            shouldSetAmountToZero={shouldSetAmountToZero}
                            setShouldSetAmountToZero={setShouldSetAmountToZero}
                            existingInvoiceToCredit={existingInvoiceToCredit}
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    color={'secondary'}
                                    checked={skipNotification}
                                    onChange={event => setSkipNotification(event.target.checked)}
                                    name={'skipNotification'}
                                />
                            }
                            label={'Skip notifications for this cancellation?'}
                        />
                        <hr />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    color={'secondary'}
                                    checked={confirmCancellation}
                                    onChange={event => setConfirmCancellation(event.target.checked)}
                                    name={'confirmCancellation'}
                                />
                            }
                            label={
                                cancellationActionType === LabsGqlCancelOrderBillingActionType.Refund
                                    ? 'Do you have approval to refund this order?'
                                    : 'Are you sure you want to cancel this order?'
                            }
                        />
                        <br />
                        <FooterCaption
                            enableCreditAndRefundOverhaul={enableCreditAndRefundOverhaul || false}
                            canRefund={canRefund}
                            creditFutureOrExistingInvoice={creditFutureOrExistingInvoice}
                            cancellationOptions={cancellationOptions}
                        />
                        <Button
                            type={'submit'}
                            disabled={submitIsDisabled}
                            fullWidth
                            variant={'primary'}
                            style={{ marginTop: 10 }}
                            data-test={'cancel-order-submit-button'}
                        >
                            Submit Cancellation
                        </Button>
                    </form>
                </OrthlyErrorBoundary>
            }
            onClose={() => {
                AnalyticsClient.track('All - Cancel Order - Abandoned', {
                    $groups: { order: order.id },
                });
            }}
            style={{ padding: 0 }}
            buttonText={'Cancel Order'}
            buttonColor={FlossPalette.ATTENTION}
            buttonTextColor={'#fff'}
            buttonProps={{
                style: { height: '100%' },
            }}
            CustomButton={CustomButton}
        />
    );
};

export const CancelOrderToolbarAction: ToolbarActionDefinition = {
    disallowedStatuses: disallowedStatusesForCancel,
    Component: OpsCancelOrderAction,
    label: 'Cancel order',
    capabilityRequirement: 'order.cancel',
};
