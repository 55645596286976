import { BooleanIcon } from '../../../components/BooleanIcon';
import { InlineEditButton } from '../../../components/InlineEditButton';
import { useMutation } from '@apollo/client';
import { graphql } from '@orthly/graphql-inline-react';
import type { LabsGqlPartnerBillingFragment } from '@orthly/graphql-operations';
import { LabsGqlPartnerBillingBoolean } from '@orthly/graphql-schema';
import { QuickForm, RootActionDialog, useRootActionCommand } from '@orthly/ui';
import { Grid, Icon, IconButton, Text } from '@orthly/ui-primitives';
import { useFeatureFlag } from '@orthly/veneer';
import React from 'react';

interface EditBillingFlagProps {
    practiceId: string;
    currentValue:
        | LabsGqlPartnerBillingFragment['autocharge_enabled']
        | LabsGqlPartnerBillingFragment['is_on_payment_plan'];
    property: LabsGqlPartnerBillingBoolean.AutochargeEnabled | LabsGqlPartnerBillingBoolean.IsOnPaymentPlan;
}

const flagPropertyToLabel: Record<EditBillingFlagProps['property'], string> = {
    [LabsGqlPartnerBillingBoolean.AutochargeEnabled]: 'Auto Charge Enabled',
    [LabsGqlPartnerBillingBoolean.IsOnPaymentPlan]: 'Is on Payment Plan',
};

const BulkEditBillingFlag_Mutation = graphql(`
    mutation BulkUpdatePartnerBillingConfigFlag($update: PartnerBillingBulkUpdateFlagCommand!) {
        bulkUpdatePartnerBillingConfigFlag(update: $update) {
            id
        }
    }
`);

export const EditBillingFlag: React.FC<EditBillingFlagProps> = ({ practiceId, currentValue, property }) => {
    const { value: enableOpsPortalUXImprovements } = useFeatureFlag('enableOpsPortalUXImprovements');
    const [open, setOpen] = React.useState(false);
    const editBillingFlag = useMutation(BulkEditBillingFlag_Mutation);
    const { submit, submitting } = useRootActionCommand(editBillingFlag, {
        onSuccess: () => setOpen(false),
    });

    const label = flagPropertyToLabel[property];

    const CustomButton = React.useCallback(
        () =>
            enableOpsPortalUXImprovements ? (
                <>
                    <Text variant={'caption'}>{currentValue === true ? 'Enabled' : 'Disabled'}</Text>
                    <IconButton onClick={() => setOpen(true)}>
                        <Icon icon={'PencilOutlinedIcon'} fontSize={'small'} />
                    </IconButton>
                </>
            ) : (
                <InlineEditButton
                    label={
                        <Grid style={{ display: 'flex', alignItems: 'center' }}>
                            <BooleanIcon val={!!currentValue} />
                        </Grid>
                    }
                    onClick={() => setOpen(true)}
                />
            ),
        [currentValue, setOpen, enableOpsPortalUXImprovements],
    );

    return (
        <RootActionDialog
            loading={submitting}
            open={open}
            setOpen={setOpen}
            title={`Edit Billing Account`}
            buttonText={''}
            CustomButton={CustomButton}
            content={
                <QuickForm<{ value: boolean }>
                    fields={{ value: { label, type: 'boolean' } }}
                    initialValues={{ value: !!currentValue }}
                    onSubmit={async result => {
                        await submit({ update: { property, partner_ids: [practiceId], new_value: result.value } });
                    }}
                    dirtySubmitOnly={true}
                />
            }
        />
    );
};
