import type { FragmentType } from '@orthly/graphql-inline-react';
import { getFragmentData, graphql } from '@orthly/graphql-inline-react';
import { LabsGqlLabSalesOrderServiceSpeed } from '@orthly/graphql-schema';
import { FlossPalette, styled, Text } from '@orthly/ui-primitives';
import React from 'react';

const AdminOrderDetailAdditionalInfoSalesOrder_Fragment = graphql(`
    fragment AdminOrderDetailAdditionalInfoSalesOrder_Fragment on LabSalesOrderDTO {
        service_level {
            speed
        }
    }
`);

const Wrapper = styled('div')({
    marginLeft: '-24px',
    width: 'calc(100% + 48px)',
    padding: '8px 16px',
    backgroundColor: FlossPalette.GRAY,

    display: 'flex',
    flexWrap: 'wrap',
    columnGap: 80,
    containerType: 'inline-size',
});

const StyledText = styled(Text)({
    margin: 'auto 0px',
});

interface OrderDetailStatusNoRushBannerProps {
    salesOrderFragment: FragmentType<typeof AdminOrderDetailAdditionalInfoSalesOrder_Fragment>;
}

export const OrderDetailStatusNoRushBanner: React.VFC<OrderDetailStatusNoRushBannerProps> = ({
    salesOrderFragment,
}) => {
    const salesOrder = getFragmentData(AdminOrderDetailAdditionalInfoSalesOrder_Fragment, salesOrderFragment);
    const speed = salesOrder?.service_level.speed;

    // TODO: update this days amount with a real value that is stored in the backend.
    // We don't presently store this, but will once the value stops being static.
    const delayDays = 4;

    if (speed !== LabsGqlLabSalesOrderServiceSpeed.Slow) {
        return null;
    }

    return (
        <Wrapper>
            <StyledText variant={'body2'} color={'WHITE'}>
                This doctor has opted into <strong>no-rush shipping</strong>
            </StyledText>
            <StyledText variant={'caption'} color={'WHITE'}>
                This will result in a {delayDays}-day delay from the regular ETA
            </StyledText>
        </Wrapper>
    );
};
