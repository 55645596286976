import { DesignPrepBannerCommon } from '../DesignPrep/DesignPrepBannerCommon';
import { useFeatureFlag } from '../Providers/LaunchDarkly';
import { useTagsQuery } from '@orthly/graphql-react';
import { LabsGqlTaggableEntityType } from '@orthly/graphql-schema';
import { DTL_ALPHA_TEST_TAG_ID } from '@orthly/shared-types';
import { Tooltip } from '@orthly/ui-primitives';
import React from 'react';
import { Link } from 'react-router-dom';

interface StartBannerProps {
    orderId: string;
    variant: 'review' | 'finishing';
    className?: string;
}

export const DesignFinishingStartBanner: React.VFC<StartBannerProps> = ({ orderId, variant, className }) => {
    // If the user has access to the finishing tab, show this banner that takes them to that tab.
    const { value: enableOrderDetailFinishingTab } = useFeatureFlag('enableOrderDetailFinishingTab');

    // An order should only go through DVD review if it is part of the DVD pilot, which is indicated by the presence of
    // the `DTL_ALPHA_TEST_TAG_ID` tag.
    const { data: tagsData } = useTagsQuery({
        variables: { entity_id: orderId, entity_type: LabsGqlTaggableEntityType.Order, id: DTL_ALPHA_TEST_TAG_ID },
    });

    if (!enableOrderDetailFinishingTab) {
        return null;
    }

    const disabled = !tagsData?.tags.length;
    const label = variant === 'review' ? 'Dandy Verified Design Review' : 'Dandy Finishing';

    const bannerElement = (
        <DesignPrepBannerCommon
            className={className}
            leftLabel={label}
            rightLabel={'Start'}
            variant={'primary'}
            disabled={disabled}
        />
    );

    if (disabled) {
        const tooltip = `This order is not eligible for ${variant === 'review' ? 'Dandy Verified Design review' : 'Dandy Finishing'}.`;
        return (
            <Tooltip title={tooltip}>
                <span>{bannerElement}</span>
            </Tooltip>
        );
    }

    return (
        <Link style={{ textDecoration: 'none', color: 'inherit' }} to={`/tasks/${orderId}/?tab=finishing&open=true`}>
            {bannerElement}
        </Link>
    );
};
