import { RouterTabs } from '../../components/RouterTabs';
import { LabFilterRules } from './screens/Bias/BiasRuleList';
import { CapacityRuleList } from './screens/Capacity/CapacityRuleList';
import { DesignRuleList } from './screens/Design/DesignRuleList';
import { ProductionPlansRoot } from './screens/ProductionPlans/ProductionPlansRoot.graphql';
import { RoutingRulesByOrder } from './screens/RulesByOrder/RoutingRulesByOrder';
import { RoutingSampleLog } from './screens/SampleLog/RoutingSampleLog';
import { TargetDistributionRuleList } from './screens/TargetDistributions/TargetDistributionRuleList';
import React from 'react';

export const RoutingRoot: React.FC = () => {
    return (
        <RouterTabs
            items={[
                { path: 'capacity', label: 'Capacity', Component: CapacityRuleList },
                {
                    path: 'target-distributions',
                    label: 'Target Distribution',
                    Component: TargetDistributionRuleList,
                },
                { path: 'lab_filter_rules', label: 'Lab Filter Rules', Component: LabFilterRules },
                { path: 'design', label: 'Design', Component: DesignRuleList },
                { path: 'rule_by_order', label: 'Rules By Order', Component: RoutingRulesByOrder },
                { path: 'sample-log', label: 'Sample Log', Component: RoutingSampleLog },
                { path: 'production-plans', label: 'Production Planning', Component: ProductionPlansRoot },
            ]}
        />
    );
};
