import { ListAbutmentPartsProvider, useAbutmentPartsLoading } from '../../../context';
import { PrintableSlip } from '../../PrintableSlip';
import { LabSlipPage } from './components/LabSlipPage';
import type { LabsGqlLabOrderForLabSlipFragment } from '@orthly/graphql-operations';
import { useLabOrdersByIdForLabSlipsQuery, useCurrentManufacturerProfileQuery } from '@orthly/graphql-react';
import { ErrorPage, WarningIcon } from '@orthly/ui';
import _ from 'lodash';
import React from 'react';
import { useLocation } from 'react-router-dom';

interface LabSlipV2Props {
    orderIds: string[];
    // if true, the print dialog will trigger as soon as order data is loaded
    autoPrintEnabled?: boolean;
    onPrintComplete?: () => void;
    customLoader?: React.ReactNode;
}

/**
 * Internal component used to ensure abutment part data is loaded before rendering the print window
 */
const AbutmentPartAwareLabSlipV2: React.VFC<LabSlipV2Props> = props => {
    const { orderIds, autoPrintEnabled, onPrintComplete } = props;
    const { data, loading, error } = useLabOrdersByIdForLabSlipsQuery({
        variables: { ids: orderIds },
        fetchPolicy: 'no-cache',
    });
    const { data: manufacturerProfileData } = useCurrentManufacturerProfileQuery({ fetchPolicy: 'cache-first' });
    const orders: LabsGqlLabOrderForLabSlipFragment[] = _.compact(data?.orders);

    const abutmentPartsLoading = useAbutmentPartsLoading();

    if (loading) {
        return props.customLoader !== undefined ? <>{props.customLoader}</> : <h1>Loading...</h1>;
    }
    if (error || !orders || orders.length !== orderIds.length) {
        return <ErrorPage message={'Error'} text={'Something went wrong'} icon={WarningIcon} />;
    }

    return (
        <PrintableSlip
            openPrintWindow={!loading && !!data && !!autoPrintEnabled && !abutmentPartsLoading}
            onPrintComplete={onPrintComplete}
        >
            {orders.map((o: LabsGqlLabOrderForLabSlipFragment) => {
                if (!o.stackAndPanCodes || o.stackAndPanCodes.panCodes.length === 0) {
                    return (
                        <LabSlipPage
                            key={o.id}
                            order={o}
                            manufacturerProfileData={manufacturerProfileData?.profile}
                            stackCode={o.stackAndPanCodes?.stackCode}
                            batchIds={o.batch_ids}
                        />
                    );
                }
                const totalCopies = o.stackAndPanCodes.panCodes.length;
                const stackCode = o.stackAndPanCodes.stackCode;
                return o.stackAndPanCodes.panCodes.map((_, index) => {
                    const copyInfo = {
                        copyNumber: index + 1,
                        totalCopies,
                    };
                    return (
                        <LabSlipPage
                            key={`${o.id}-${index}`}
                            order={o}
                            manufacturerProfileData={manufacturerProfileData?.profile}
                            stackCode={stackCode}
                            copyInfo={copyInfo}
                            batchIds={o.batch_ids}
                        />
                    );
                });
            })}
        </PrintableSlip>
    );
};

/**
 * Retrieves the relevant data for orders and renders "Order Lab Slips"
 * that can be printed by the manufacturer
 * @param props The ids of the orders to render into "Order Lab Slips"
 * @returns Rendered "Order Lab Slips"
 */
export const LabSlipV2: React.VFC<LabSlipV2Props> = props => {
    return (
        <ListAbutmentPartsProvider>
            <AbutmentPartAwareLabSlipV2 {...props} />
        </ListAbutmentPartsProvider>
    );
};

/**
 * FIXME: Remove after manual testing is complete
 * Temporary route handler for rendering individual "Order Lab Slips"
 * during manual testing. Reads the "orderId" query param to load and
 * render the "Order Lab Slip"
 * @link https://meetdandy.atlassian.net/browse/EPDSCM-223
 * See: https://v5.reactrouter.com/web/example/query-parameters
 * @returns Rendered "Order Lab Slip"
 */
export const LabSLipV2Route: React.VFC = () => {
    const { search } = useLocation();
    const queryParams = React.useMemo(() => new URLSearchParams(search), [search]);
    const orderIds = queryParams.get('orderId')?.split(',');
    if (orderIds && orderIds.length > 0) {
        return <LabSlipV2 orderIds={orderIds} />;
    }
    return null;
};
