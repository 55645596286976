import { PrepMarginMeshLineMaterial } from '../PortalScanEditor/PrepMarginMeshLineMaterial';
import * as THREE from 'three';
import type { LineMaterial, LineMaterialParameters } from 'three-stdlib';
import { LineGeometry, Line2 } from 'three-stdlib';

export class MarginLineMesh extends Line2 {
    static readonly DEFAULT_WIDTH = 1.5;
    static readonly DEFAULT_COLOR = 0xff0000;
    static readonly DEFAULT_RESOLUTION = new THREE.Vector2(512, 512);

    public readonly isMarginLineMesh: boolean = true;

    constructor(points: THREE.Vector3[], materialParams: LineMaterialParameters = {}) {
        super();

        this.geometry = new LineGeometry();
        // NB: Calling `setFromPoints` instead of `setPositions` will cause an error because it does not set some
        // internal properties required by `computeLineDistances`.
        this.geometry.setPositions(points.flatMap(p => p.toArray()));

        const finalMaterialParams: LineMaterialParameters = {
            color: MarginLineMesh.DEFAULT_COLOR,
            linewidth: MarginLineMesh.DEFAULT_WIDTH,
            resolution: MarginLineMesh.DEFAULT_RESOLUTION,
            ...materialParams,
        };
        this.material = new PrepMarginMeshLineMaterial(finalMaterialParams) as LineMaterial;

        this.computeLineDistances();
    }
}

export function isMarginLineMesh(object: any): object is MarginLineMesh {
    return !!object?.isMarginLineMesh;
}
