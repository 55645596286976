import { useAcceptDcmFiles } from './AcceptFilesFromBrowser.hooks';
import { File3dDropLoader } from './File3dDropLoader';
import { computeInitialScanReviewViewStateFromDcm } from './ScanReviewLandingPage.utils';
import { FormControlLabel, Checkbox } from '@mui/material';
import {
    type ScanReviewDcmFile,
    extractScanReviewDcmFileData,
    type ScanReviewViewState,
    ScanReviewPanelType,
    ScanReviewCompleteViewRoot,
    ScanReviewViewType,
    loadScanReviewDcmFileData,
    type ScanReviewRecordFactory,
    QCColorLegend,
    ScanReviewDisplayType,
    useScanReviewCompleteViewAppContext,
    type QcHeatmapRange,
    ScanReviewDcmBuilder,
} from '@orthly/dentin';
import { HeatMapType } from '@orthly/forceps';
import { Jaw } from '@orthly/shared-types';
import { StackY, styled, StackX } from '@orthly/ui';
import { Button } from '@orthly/ui-primitives';
import React from 'react';
import type * as THREE from 'three';

const QCToolbarContainer = styled(StackY)({
    position: 'absolute',
    zIndex: 21,
    top: 86,
    right: 16,
});

const QCToolbar: React.VFC = () => {
    const ctx = useScanReviewCompleteViewAppContext();
    return (
        <QCToolbarContainer>
            <QCColorLegend
                heatMapType={HeatMapType.Clearance}
                heatMapRange={ctx.heatMapRange}
                setHeatMapRange={qc => ctx.setHeatMapRangeCallback({ min: qc.min, max: qc.max })}
                dynamicHeatmaps={true}
                heatMapStep={0.05}
            />
        </QCToolbarContainer>
    );
};

const ScanReviewButtonsToolbar: React.VFC = () => {
    const { scene, setViewType, displayType, setDisplayTypeCallback } = useScanReviewCompleteViewAppContext();
    return (
        <StackX>
            <Button variant={'text'} onClick={() => setViewType(ScanReviewViewType.Single)}>
                Single View
            </Button>
            <Button variant={'text'} onClick={() => setViewType(ScanReviewViewType.SideBySide)}>
                Side By Side
            </Button>
            <Button variant={'text'} onClick={() => setViewType(ScanReviewViewType.Complete)}>
                Complete View
            </Button>
            <FormControlLabel
                control={<Checkbox onChange={e => scene.setUpperJawVisibility(e.target.checked)} defaultChecked />}
                label={'Upper'}
            />
            <FormControlLabel
                control={<Checkbox onChange={e => scene.setLowerJawVisibility(e.target.checked)} defaultChecked />}
                label={'Lower'}
            />
            <FormControlLabel
                control={
                    <Checkbox
                        checked={displayType === ScanReviewDisplayType.Scan}
                        onChange={e => e.target.checked && setDisplayTypeCallback(ScanReviewDisplayType.Scan)}
                    />
                }
                label={'Scan'}
            />
            <FormControlLabel
                control={
                    <Checkbox
                        checked={displayType === ScanReviewDisplayType.StoneModel}
                        onChange={e => e.target.checked && setDisplayTypeCallback(ScanReviewDisplayType.StoneModel)}
                    />
                }
                label={'Stone Model'}
            />
            <FormControlLabel
                control={
                    <Checkbox
                        checked={displayType === ScanReviewDisplayType.BiteAnalysis}
                        onChange={e => e.target.checked && setDisplayTypeCallback(ScanReviewDisplayType.BiteAnalysis)}
                    />
                }
                label={'Bite Analysis'}
            />
            {displayType === ScanReviewDisplayType.BiteAnalysis && <QCToolbar />}
        </StackX>
    );
};

const INITIAL_CLEARANCE_RANGE: QcHeatmapRange = { min: -0.1, max: 1.0 };

export const ScanReviewLandingPage: React.VFC = () => {
    const [dcms, setDcms] = React.useState<ScanReviewDcmFile[]>([]);
    const { onDcmDrop } = useAcceptDcmFiles(setDcms);

    const [upperJawFactory, setUpperJawFactory] = React.useState<ScanReviewRecordFactory | null>(null);
    const [lowerJawFactory, setLowerJawFactory] = React.useState<ScanReviewRecordFactory | null>(null);

    const [initialViewState, setInitialViewState] = React.useState<ScanReviewViewState>({
        [ScanReviewPanelType.Upper]: null,
        [ScanReviewPanelType.Lower]: null,
        [ScanReviewPanelType.Left]: null,
        [ScanReviewPanelType.Right]: null,
        [ScanReviewPanelType.Front]: null,
    });

    const onScanImageLoadCallback = (scanGeometry: THREE.BufferGeometry, scanImage: HTMLImageElement) => {
        const factory = () => {
            const heatmapRange = INITIAL_CLEARANCE_RANGE;
            const scanReviewRecord = new ScanReviewDcmBuilder(scanGeometry, scanImage)
                .buildDcmScanStoneMaterial()
                .buildDcmScanHeatmapMaterial(heatmapRange)
                .complete();
            return scanReviewRecord ?? null;
        };
        return factory;
    };

    React.useEffect(() => {
        const upperJawData = extractScanReviewDcmFileData(Jaw.UPPER, dcms);
        const lowerJawData = extractScanReviewDcmFileData(Jaw.LOWER, dcms);
        const computedViewState = computeInitialScanReviewViewStateFromDcm(upperJawData, lowerJawData);
        setInitialViewState(computedViewState);
        if (upperJawData) {
            loadScanReviewDcmFileData(upperJawData, onScanImageLoadCallback, setUpperJawFactory);
        }
        if (lowerJawData) {
            loadScanReviewDcmFileData(lowerJawData, onScanImageLoadCallback, setLowerJawFactory);
        }
    }, [dcms]);

    return (
        <StackY
            sx={{
                flex: 'auto',
                overflow: 'hidden',
                height: '100vh',
            }}
        >
            <File3dDropLoader scan={'Upper Jaw'} onDcmDrop={(files, event) => onDcmDrop(Jaw.UPPER, files, event)} />
            <File3dDropLoader scan={'Lower Jaw'} onDcmDrop={(files, event) => onDcmDrop(Jaw.LOWER, files, event)} />
            <ScanReviewCompleteViewRoot
                lowerJawFactory={lowerJawFactory}
                upperJawFactory={upperJawFactory}
                initialViewType={ScanReviewViewType.Complete}
                initialViewState={initialViewState}
                initialHeatmapRange={{ min: -0.1, max: 1.0 }}
            >
                <ScanReviewButtonsToolbar />
            </ScanReviewCompleteViewRoot>
        </StackY>
    );
};
