import type { AdminLabsOrderActionProps } from '../order-action-types';
import { useIsDesignActionEditable } from './DesignActionUtils';
import { DesignConfigDropdown } from './DesignConfigDropdown';
import { useConfigureDesignerTypeMutation } from '@orthly/graphql-react';
import { LoadBlocker, useChangeSubmissionFn } from '@orthly/ui';
import React from 'react';

const OPTION_YES = { label: 'Yes', value: true };
const OPTION_NO = { label: 'No', value: false };

export const EditSeparateModelDesignStep: React.FC<AdminLabsOrderActionProps> = props => {
    const { order, refetchOrder } = props;
    const { internal_design_required, internal_design_prep_required, separate_model_design_step } =
        order.fulfillment_workflow.configuration;
    const isEditable = useIsDesignActionEditable(order);
    const [submitMtn] = useConfigureDesignerTypeMutation();
    const { submit, submitting } = useChangeSubmissionFn<any, [boolean]>(
        newValue =>
            submitMtn({
                variables: {
                    internal_design_required,
                    internal_design_prep_required,
                    separate_model_design_step: newValue,
                    automate_review_required: null,
                    id: order.id,
                },
            }),
        {
            successMessage: () => [
                `Separate model design step ${!separate_model_design_step ? 'enabled' : 'disabled'}`,
                {},
            ],
            onSuccess: async () => {
                await refetchOrder();
            },
        },
    );

    return (
        <LoadBlocker
            blocking={submitting}
            overlayColor={'transparent'}
            ContainerProps={{ style: { alignItems: 'center' } }}
        >
            <DesignConfigDropdown<boolean>
                options={[OPTION_YES, OPTION_NO]}
                selected={separate_model_design_step ? OPTION_YES : OPTION_NO}
                submit={async value => {
                    if (
                        value !== separate_model_design_step &&
                        window.confirm(`${value ? 'Enable' : 'Disable'} separate model design step in the workflow?`)
                    ) {
                        await submit(value);
                        return true;
                    }
                    return false;
                }}
                disabled={!isEditable}
            />
        </LoadBlocker>
    );
};
