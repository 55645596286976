import { Text, styled, WarningIcon, FlossPalette } from '@orthly/ui-primitives';
import React from 'react';

const AlertSection = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
});

const StyledAlertIcon = styled(WarningIcon)({
    color: FlossPalette.ATTENTION_FOREGROUND,
    marginRight: 8,
});

export const NotesTileAlerts: React.FC<{ alerts: string[] }> = ({ alerts }) => {
    return (
        <>
            {alerts.map(alert => (
                <AlertSection key={alert}>
                    <StyledAlertIcon />
                    <Text variant={'body2'} bold color={'ATTENTION'}>
                        Attn:
                    </Text>
                    &nbsp;
                    <Text variant={'body2'} bold>
                        {alert}
                    </Text>
                </AlertSection>
            ))}
        </>
    );
};
