import type { DeliveryAddressFormProps } from '../DeliveryAddressForm';
import { DeliveryAddressForm } from '../DeliveryAddressForm';
import { SettingsBaseModal } from './SettingsBaseModal';
import { useDeliveryAddressControls } from './useDeliveryAddressControls';
import { apolloErrorMessage } from '@orthly/ui';
import { FlossPalette, Button, useScreenIsMobile, Grid, IconButton, AddIcon } from '@orthly/ui-primitives';
import React from 'react';

type EditDeliveryAddressModalProps = Pick<DeliveryAddressFormProps, 'existingAddress' | 'onRender'> & {
    onSuccess?: () => void;
    open: boolean;
    setOpen: (value: boolean) => void;
    partnerId: string;
};

export const EditDeliveryAddressModal: React.FC<EditDeliveryAddressModalProps> = props => {
    const { open, setOpen, partnerId, onRender, onSuccess } = props;
    const [errorMessage, setErrorMessage] = React.useState<string | undefined>(undefined);
    const isMobile = useScreenIsMobile();
    const { upsertAddress, upsertSubmitting } = useDeliveryAddressControls({
        onSuccess: () => {
            setOpen(false);
            onSuccess && onSuccess();
        },
        onError: e => {
            setErrorMessage(apolloErrorMessage(e));
        },
    });

    return (
        <SettingsBaseModal
            content={
                <DeliveryAddressForm
                    errorMessage={errorMessage}
                    editAddressFormat={false}
                    onRender={onRender}
                    onSubmit={values => {
                        void upsertAddress({
                            partner_id: partnerId,
                            ...values,
                        });
                    }}
                    CustomSubmit={({ triggerSubmit, disabled }) => (
                        <Grid container direction={'row'} style={{ marginTop: '40px', justifyContent: 'right' }}>
                            <Button
                                onClick={() => setOpen(false)}
                                variant={'ghost'}
                                style={{
                                    marginRight: '0.5rem',
                                    backgroundColor: FlossPalette.TAN,
                                }}
                            >
                                Cancel
                            </Button>
                            <Button
                                disabled={disabled}
                                onClick={triggerSubmit}
                                variant={'primary'}
                                style={{ marginLeft: '0.5rem' }}
                            >
                                Add delivery address
                            </Button>
                        </Grid>
                    )}
                />
            }
            CustomButton={({ onClick }) =>
                isMobile ? (
                    <IconButton onClick={onClick}>
                        <AddIcon />
                    </IconButton>
                ) : (
                    <Button variant={'primary'} onClick={onClick}>
                        <AddIcon style={{ marginRight: 8 }} /> Add delivery address
                    </Button>
                )
            }
            loading={upsertSubmitting}
            title={'Add a new delivery address'}
            subtitle={'Add the address information below'}
            open={open}
            setOpen={setOpen}
        />
    );
};
