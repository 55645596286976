import { uploadFilesToGCS, useFirebaseMultiFileUpload } from '../../FirebaseUpload';
import { useOperationsState, useStageState } from '../AppState.hooks';
import { useDesign, useFinishingCallbacks, useOrder } from '../OrderState.hooks';
import { DesignStorageConfigs, getFullStoragePath } from '@orthly/shared-types';
import { apolloErrorMessage, OrthlyBrowserConfig } from '@orthly/ui';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useAsyncCallback } from 'react-async-hook';

export function useSubmissionMutation(): { submit: () => void; submitting: boolean } {
    const { submitFinishedDesign, closeWindow, onComplete } = useFinishingCallbacks();
    const { enqueueSnackbar } = useSnackbar();

    const { loading: mutationSubmitting, execute: submitRaw } = useAsyncCallback(submitFinishedDesign, {
        onSuccess: () => {
            enqueueSnackbar('Design submitted!');
            closeWindow(true);
            onComplete();
        },
        onError: error => {
            enqueueSnackbar(apolloErrorMessage(error), { variant: 'error' });
        },
    });

    const { editingSessionTimer } = useStageState();
    const { id: orderId } = useOrder();
    const { id: designId, case_file_model_elements: caseFileModelElements } = useDesign();
    const { manager } = useOperationsState();

    const storagePathConfig = getFullStoragePath(
        OrthlyBrowserConfig.env,
        DesignStorageConfigs.dandyFinishing,
        'submissions',
        orderId,
        designId,
    );
    const [uploadFn] = useFirebaseMultiFileUpload(storagePathConfig, []);

    const [submissionUploading, setSubmissionUploading] = React.useState(false);

    const submit = React.useCallback(async () => {
        setSubmissionUploading(true);

        editingSessionTimer.handleSubmit();

        // This lookup logic is sufficient for now because we only allow single-item orders
        const modelElementID = caseFileModelElements.find(
            mE => mE.model_element_type === 'meIndicationRegular',
        )?.model_element_id;

        if (!modelElementID) {
            throw new Error('Failed to find model element ID');
        }

        const submissionJson = manager.getSubmission(modelElementID);

        const files: File[] = [new File([submissionJson], getSubmissionFileName())];
        const uploadedFiles = await uploadFilesToGCS({ uploadFn, filesToSubmit: files });

        const submissionFilePath = uploadedFiles[0]?.uploadedPath;
        if (!submissionFilePath) {
            throw new Error('Failed to upload Finishing submission file');
        }

        const sessionTimingData = editingSessionTimer.stop();
        setSubmissionUploading(false);

        await submitRaw({
            payload: {
                design_id: designId,
                session_timing_data: sessionTimingData,
                submission_file_path: submissionFilePath,
            },
        });
    }, [manager, caseFileModelElements, editingSessionTimer, designId, submitRaw, uploadFn]);

    return { submit, submitting: mutationSubmitting || submissionUploading };
}

function getSubmissionFileName(): string {
    return `finishing_submission_${Date.now()}.json`;
}
