import { AnalyticsClient } from '../utils/analyticsClient';
import type { AdminState } from './redux.types';
import { createReduxActionListenerMiddleware } from '@orthly/ui';
import type { LocationChangeAction } from 'connected-react-router';
import { LOCATION_CHANGE } from 'connected-react-router';
import type { Middleware as ReduxMiddleware } from 'redux';

export const adminAnalyticsMiddleware = createReduxActionListenerMiddleware<AdminState>([
    {
        config: {
            actions: [LOCATION_CHANGE],
        },
        handler: (_newState, action, _oldState) => {
            AnalyticsClient.page(`Admin`, `Generic Page`, {
                path: (action as LocationChangeAction).payload.location.pathname,
            });
        },
    },
]);

export function getAdminReduxMiddleware(middleware: ReduxMiddleware[]): ReduxMiddleware[] {
    if (typeof window.analytics !== 'undefined') {
        return [adminAnalyticsMiddleware, ...middleware];
    }
    return middleware;
}
