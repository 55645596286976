import type { ICameraControls } from '../ModelViewer';
import { useScanReviewCompleteViewAppContext } from './ScanReviewCompleteView.hooks';
import { ScanReviewPanel } from './ScanReviewPanel';
import { ScanReviewPanelType, ScanReviewViewType } from './ScanReviewTypes';
import { Box, styled, FlossPalette } from '@orthly/ui-primitives';
import React from 'react';

interface ScanReviewPanelWrapperProps {
    panelType: ScanReviewPanelType;
}

const PanelContainer = styled(Box)({
    borderRadius: 8,
    margin: '4px',
    border: `1px solid ${FlossPalette.STROKE_LIGHT}`,
    backgroundColor: FlossPalette.WHITE,
    minHeight: 0,
    minWidth: 0,
});

const ScanReviewPanelWrapper: React.VFC<ScanReviewPanelWrapperProps> = ({ panelType }) => {
    const ctx = useScanReviewCompleteViewAppContext();
    const scene = ctx.scene.getPartialSceneForPanelType(panelType);
    const callbackRef = React.useCallback(
        (controls: ICameraControls) => {
            ctx.viewManager.initializeViewState(controls, panelType);
        },
        [panelType, ctx.viewManager],
    );
    return (
        <ScanReviewPanel
            scene={scene.scene}
            initializeCameraControlsCallback={callbackRef}
            canvasRef={ctx.viewManager.canvasRef}
            cameraRef={ctx.viewManager.cameraRef}
            key={`scan-review-${panelType}`}
        />
    );
};

const ScanReviewSingleView: React.VFC = () => {
    return (
        <PanelContainer sx={{ gridColumn: '1/7', gridRow: '1/3' }}>
            <ScanReviewPanelWrapper panelType={ScanReviewPanelType.Front} />;
        </PanelContainer>
    );
};

const ScanReviewSideBySideView: React.VFC = () => {
    return (
        <>
            <PanelContainer sx={{ gridColumn: '1/4', gridRow: '1/3' }}>
                <ScanReviewPanelWrapper panelType={ScanReviewPanelType.Upper} />
            </PanelContainer>
            <PanelContainer sx={{ gridColumn: '4/7', gridRow: '1/3' }}>
                <ScanReviewPanelWrapper panelType={ScanReviewPanelType.Lower} />
            </PanelContainer>
        </>
    );
};

export const ScanReviewCompleteViewInner: React.VFC = () => {
    return (
        <>
            <PanelContainer sx={{ gridColumn: '1/4', gridRow: '1/2' }}>
                <ScanReviewPanelWrapper panelType={ScanReviewPanelType.Upper} />
            </PanelContainer>
            <PanelContainer sx={{ gridColumn: '4/7', gridRow: '1/2' }}>
                <ScanReviewPanelWrapper panelType={ScanReviewPanelType.Lower} />
            </PanelContainer>
            <PanelContainer sx={{ gridColumn: '1/3', gridRow: '2/3' }}>
                <ScanReviewPanelWrapper panelType={ScanReviewPanelType.Right} />
            </PanelContainer>
            <PanelContainer sx={{ gridColumn: '3/5', gridRow: '2/3' }}>
                <ScanReviewPanelWrapper panelType={ScanReviewPanelType.Front} />
            </PanelContainer>
            <PanelContainer sx={{ gridColumn: '5/7', gridRow: '2/3' }}>
                <ScanReviewPanelWrapper panelType={ScanReviewPanelType.Left} />
            </PanelContainer>
        </>
    );
};

export const ScanReviewCompleteView: React.VFC = () => {
    const ctx = useScanReviewCompleteViewAppContext();
    const viewType = ctx.viewType;
    return (
        <Box
            style={{
                flex: 'auto',
                display: 'grid',
                gridTemplateColumns: 'repeat(6, 1fr)',
                gridTemplateRows: 'repeat(2, 1fr)',
                minHeight: 0,
                minWidth: 0,
                backgroundColor: FlossPalette.DARK_TAN,
            }}
        >
            {viewType === ScanReviewViewType.Single && <ScanReviewSingleView />}
            {viewType === ScanReviewViewType.SideBySide && <ScanReviewSideBySideView />}
            {viewType === ScanReviewViewType.Complete && <ScanReviewCompleteViewInner />}
        </Box>
    );
};
