import type { LabsGqlLabOrderForLabSlipFragment } from '@orthly/graphql-operations';
import { Text, Grid, makeStyles } from '@orthly/ui-primitives';
import format from 'date-fns/format';
import React from 'react';

const useStyles = makeStyles({
    orderHeader: {
        fontSize: '1rem !important',
    },
    largeText: {
        fontSize: '2rem', // Larger text for dates
        fontWeight: 'bold', // Optionally make the date bold
    },
    fadedText: {
        fontSize: '0.75rem !important',
        marginRight: '1rem',
    },
});

export interface LabSlipCopyInfo {
    copyNumber: number;
    totalCopies: number;
}

interface ILabSlipOrderInfoProps {
    order: Exclude<LabsGqlLabOrderForLabSlipFragment, 'stackAndPanCodes'>;
    stackCode?: string;
    copyInfo?: LabSlipCopyInfo;
    batchIds?: string[] | null;
}

/**
 * Renders the Dandy Id and shipping dates
 * to be displayed on the Order Lab Slip
 * @param props
 * @returns Order Lab Slip Section with information about the order
 */
export const LabSlipOrderInfo: React.VFC<ILabSlipOrderInfoProps> = props => {
    const { order, stackCode, copyInfo, batchIds } = props;
    const classes = useStyles();
    const { ready_for_fabrication_date } = order.manufacturer_sla;
    // Get the start date in EST
    const startDateLocaleString = ready_for_fabrication_date
        ? new Date(ready_for_fabrication_date).toLocaleString('en-US', { timeZone: 'America/New_York' })
        : null;
    const startDate = startDateLocaleString ? format(new Date(startDateLocaleString), 'MM/dd') : 'N/A';
    // Get the ship date in EST
    const shipByDateLocaleString = new Date(order.manufacturer_sla.manufacturer_committed_ship_by_date).toLocaleString(
        'en-US',
        {
            timeZone: 'America/New_York',
        },
    );
    const shipByDate = format(new Date(shipByDateLocaleString), 'MM/dd');
    const copyText = copyInfo ? ` (Slip ${copyInfo.copyNumber} of ${copyInfo.totalCopies})` : '';
    return (
        <Grid container>
            <Grid item xs={4} container direction={'column'} alignItems={'center'}>
                <Text variant={'h6'} className={classes.orderHeader}>
                    {`Dandy (#${order.order_number})`}
                </Text>
            </Grid>
            <Grid item xs={4} container direction={'column'} alignItems={'center'}>
                <Text variant={'h6'} className={classes.orderHeader}>
                    {`In: ${startDate}`}
                </Text>
                <Text variant={'h6'} className={classes.largeText}>
                    {`Ship By: ${shipByDate}`}
                </Text>
            </Grid>
            <Grid item xs={4} container direction={'column'} alignItems={'center'}>
                {stackCode && (
                    <Text variant={'h6'} className={classes.orderHeader}>
                        {`Stack ${stackCode}${copyText}`}
                    </Text>
                )}
                {/* TODO: we are assuming 1 batch per order for now, we need to figure out how to display multiple batches */}
                {batchIds && (
                    <Text variant={'h6'} className={classes.orderHeader}>
                        {`Batch - ${batchIds[0]}`}
                    </Text>
                )}
            </Grid>
        </Grid>
    );
};
