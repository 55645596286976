import { useFeatureFlag } from '../../../Providers/LaunchDarkly';
import type { WaxupOrder } from '../../Waxups.types';
import { useGuidedWaxupContext } from '../state/GuidedWaxupContext';
import { useGuidedWaxupAction } from '../state/GuidedWaxupState';
import { getSortedPresetConfigForOrder } from './useGuidedWaxupPresets.utils';
import type { OrderDesignPreviewDesign_FragmentFragment } from '@orthly/graphql-inline-react';
import type { LabsGqlGuidedWaxupPresetType } from '@orthly/graphql-schema';
import type { LabsGqlGuidedWaxupPresetStatus } from '@orthly/graphql-schema';
import React from 'react';

export interface PresetInfo {
    status?: LabsGqlGuidedWaxupPresetStatus;
    notes?: string;
    annotatedImageUrls?: string[];
}

export interface GuidedWaxupState {
    presets: Partial<Record<LabsGqlGuidedWaxupPresetType, PresetInfo>>;
}

export const useGuidedWaxupPresets = (
    order: WaxupOrder,
    selectedReview: OrderDesignPreviewDesign_FragmentFragment['doctor_review'],
) => {
    const { value: organizeGuidedWaxupPresetsBySKU = false } = useFeatureFlag('organizeGuidedWaxupPresetsBySKU');
    const { setSelectedTab } = useGuidedWaxupContext();
    const patchState = useGuidedWaxupAction('PATCH_STATE');
    const presets = React.useMemo(
        () => getSortedPresetConfigForOrder(order, selectedReview, organizeGuidedWaxupPresetsBySKU),
        [order, selectedReview, organizeGuidedWaxupPresetsBySKU],
    );

    React.useEffect(() => {
        patchState({ presets: Object.fromEntries(presets.map(pt => [pt, {}])) });
    }, [presets, patchState]);

    React.useEffect(() => {
        const initialSelection = presets[0];

        if (initialSelection) {
            setSelectedTab(initialSelection);
        }
    }, [presets, setSelectedTab]);
};
