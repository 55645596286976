import {
    type ScanReviewViewState,
    ScanReviewPanelType,
    ScanReviewPanelCameraState,
    type ScanReviewDcmFileData,
} from '@orthly/dentin';
import * as THREE from 'three';

export function computeInitialScanReviewViewStateFromDcm(
    upperJawData: ScanReviewDcmFileData | null,
    lowerJawData: ScanReviewDcmFileData | null,
): ScanReviewViewState {
    interface CameraData {
        camX: THREE.Vector3;
        camY: THREE.Vector3;
        camZ: THREE.Vector3;
        upperJaw: THREE.BufferGeometry | null;
        lowerJaw: THREE.BufferGeometry | null;
    }

    const computedViewState: ScanReviewViewState = {
        [ScanReviewPanelType.Upper]: null,
        [ScanReviewPanelType.Lower]: null,
        [ScanReviewPanelType.Left]: null,
        [ScanReviewPanelType.Right]: null,
        [ScanReviewPanelType.Front]: null,
    };
    // Based on what I found in MB prep scans
    const xAxis = new THREE.Vector3();
    const yAxis = new THREE.Vector3();
    const zAxis = new THREE.Vector3();

    const getTransform = (jawData: ScanReviewDcmFileData | null) => {
        if (!jawData) {
            return null;
        }
        // NOTE: This transform is only available for DCMs that have been generated
        // NOTE: With 3-Shape Dental Designer. It is only being used for testing
        // NOTE: purposes and should not be relied on in a production scenario.
        const dentalDesignerTransform = jawData.dcmFile.injector.getTransform('Focus2FinalTrans') ?? null;
        return dentalDesignerTransform;
    };

    // Choose a transform.
    const transform = getTransform(upperJawData) ?? getTransform(lowerJawData) ?? new THREE.Matrix4();
    transform.extractBasis(xAxis, yAxis, zAxis);

    const upperJawGeometry = upperJawData ? upperJawData.geometry : null;
    const lowerJawGeometry = lowerJawData ? lowerJawData.geometry : null;
    const basesMap: Record<ScanReviewPanelType, CameraData | null> = {
        [ScanReviewPanelType.Upper]: {
            camX: xAxis.clone(),
            camY: zAxis.clone(),
            camZ: yAxis.clone().negate(),
            upperJaw: upperJawGeometry,
            lowerJaw: null,
        },
        [ScanReviewPanelType.Lower]: {
            camX: xAxis.clone(),
            camY: zAxis.clone().negate(),
            camZ: yAxis.clone(),
            upperJaw: null,
            lowerJaw: lowerJawGeometry,
        },
        [ScanReviewPanelType.Front]: {
            camX: xAxis.clone(),
            camY: yAxis.clone(),
            camZ: zAxis.clone(),
            upperJaw: upperJawGeometry,
            lowerJaw: lowerJawGeometry,
        },
        [ScanReviewPanelType.Left]: {
            camX: zAxis.negate().clone(),
            camY: yAxis.clone(),
            camZ: xAxis.clone(),
            upperJaw: upperJawGeometry,
            lowerJaw: lowerJawGeometry,
        },
        [ScanReviewPanelType.Right]: {
            camX: zAxis.clone(),
            camY: yAxis.clone(),
            camZ: xAxis.clone().negate(),
            upperJaw: upperJawGeometry,
            lowerJaw: lowerJawGeometry,
        },
    };

    for (const panelType of [
        ScanReviewPanelType.Upper,
        ScanReviewPanelType.Lower,
        ScanReviewPanelType.Left,
        ScanReviewPanelType.Right,
        ScanReviewPanelType.Front,
    ]) {
        const cameraData = basesMap[panelType];
        if (!cameraData) {
            continue;
        }

        const { camX, camY, camZ, upperJaw, lowerJaw } = cameraData;
        const camMatrix = new THREE.Matrix4();
        const camOrientation = camMatrix.makeBasis(camX, camY, camZ);

        const camRotation = new THREE.Euler();
        camRotation.setFromRotationMatrix(camOrientation);

        const boundingBox = new THREE.Box3();

        upperJaw?.computeBoundingBox();
        lowerJaw?.computeBoundingBox();

        for (const geometry of [upperJaw, lowerJaw]) {
            if (!geometry || !geometry.boundingBox) {
                continue;
            }
            boundingBox.union(geometry.boundingBox);
        }

        const boundingBoxCenter = new THREE.Vector3();
        boundingBox.getCenter(boundingBoxCenter);
        const camPosition = boundingBoxCenter.clone().add(camZ.clone().multiplyScalar(200));
        computedViewState[panelType] = new ScanReviewPanelCameraState(camPosition, camRotation, camY, 4);
    }
    return computedViewState;
}
