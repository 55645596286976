import { useFilesFromOnPasteEvent } from '../util';
import { SimpleDropzone, FirebaseUploadFileList, useFirebaseMultiFileUpload } from './FirebaseUpload';
import { uploadFilesToGCS } from './FirebaseUpload/FileUploader.utils';
import { getChatMessageVisibilityForWrite } from '@orthly/chat';
import type { LabsGqlOrder } from '@orthly/graphql-operations';
import { useCreateChatMessageMutation } from '@orthly/graphql-react';
import type { LabsGqlCreateChatMessageCommand } from '@orthly/graphql-schema';
import { LabsGqlChatEntityTypes } from '@orthly/graphql-schema';
import type { IOrganizationType } from '@orthly/retainer-common';
import { useHasCapability } from '@orthly/session-client';
import { getFullStoragePath, OrderingStorageConfigs } from '@orthly/shared-types';
import {
    OrthlyBrowserConfig,
    QuickForm,
    RootActionDialog,
    useChangeSubmissionFn,
    type SimpleSelectOption,
} from '@orthly/ui';
import { FlossPalette, Text, Collapse, Grid, IconButton, Tooltip, AttachFileIcon } from '@orthly/ui-primitives';
import React from 'react';
import { v4 } from 'uuid';

/**
 * This utility hook make it easier to pass files from paste or drop events in chat to the add attachment dialog.
 * It wraps the open state of the add attachment action so that pasting files will open it automatically,
 * and closing it will clear the pasted files
 */

export function useOrderChatAttachmentFileControls() {
    const [open, setOpenPrivate] = React.useState<boolean>(false);
    const [pastedFiles, setPastedFilesPrivate] = React.useState<File[]>([]);
    const setPastedFiles = React.useCallback(
        (files: File[]) => {
            setPastedFilesPrivate(files);
            if (files.length > 0 && !open) {
                setOpenPrivate(true);
            }
        },
        [open],
    );
    const setOpen = React.useCallback((newOpenState: boolean) => {
        setOpenPrivate(newOpenState);
        if (!newOpenState) {
            // clear the pasted files when the add attachment dialog is closed
            setPastedFilesPrivate([]);
        }
    }, []);
    return { pastedFiles, setPastedFiles, attachmentDialogOpen: open, setAttachmentDialogOpen: setOpen };
}

type FormFields = Pick<LabsGqlCreateChatMessageCommand, 'text' | 'visible_to_roles'>;

interface ChatAddAttachmentFormContentProps {
    initialFiles: File[];
    submitting: boolean;
    onSubmit: (fields: FormFields & { files: File[] }) => Promise<any>;
}

const ChatAddAttachmentFormContent: React.FC<ChatAddAttachmentFormContentProps> = props => {
    const { initialFiles, onSubmit, submitting } = props;
    const [files, setFiles] = React.useState<File[]>(initialFiles);
    const onDropAccepted = React.useCallback((droppedFiles: File[]) => {
        setFiles(f => [...f, ...droppedFiles]);
    }, []);
    const onPaste = useFilesFromOnPasteEvent(onDropAccepted);

    const canSendToLab = useHasCapability('chat', 'chat.send_to_lab');
    const canSendToPractice = useHasCapability('chat', 'chat.send_to_practice');

    const filteredVisibilityOptions = React.useMemo(() => {
        const options: SimpleSelectOption[] = [];
        if (canSendToPractice) {
            options.push({ label: 'Practice', value: 'practice__any' });
        }
        if (canSendToLab) {
            options.push({ label: 'Lab', value: 'lab__any' });
        }
        return options;
    }, [canSendToLab, canSendToPractice]);

    const filesAttached = files.length > 0;
    return (
        <Grid container>
            <Grid container style={{ paddingBottom: 8 }}>
                <Collapse in={filesAttached} style={{ width: '100%' }}>
                    <Grid container>
                        <Text variant={'body2'}>Attached files</Text>
                        <FirebaseUploadFileList
                            completed={submitting}
                            disableImgPreview={false}
                            files={files}
                            onRemove={(_name, removedIdx) => {
                                setFiles(files => files.filter((_f, fileIdx) => removedIdx !== fileIdx));
                            }}
                        />
                    </Grid>
                </Collapse>
                <SimpleDropzone options={{ onDropAccepted, disabled: submitting, multiple: true }} />
            </Grid>
            <QuickForm<FormFields>
                disabled={!filesAttached}
                fields={{
                    text: {
                        type: 'text',
                        label: 'Message',
                        fieldProps: {
                            onPaste,
                            placeholder: 'Add message (or paste files)',
                            multiline: true,
                            minRows: 3,
                            maxRows: 50,
                        },
                    },
                    visible_to_roles: {
                        label: 'Visible to',
                        type: 'multiselect',
                        optional: true,
                        options: filteredVisibilityOptions,
                        hidden: filteredVisibilityOptions.length === 0,
                    },
                }}
                submitButtonTitle={!filesAttached ? 'Please attach files' : 'Add attachment'}
                initialValues={{ text: '', visible_to_roles: [] }}
                onSubmit={async result => {
                    if (files.length === 0) {
                        return;
                    }
                    await onSubmit({ files, text: result.text, visible_to_roles: result.visible_to_roles });
                }}
            />
        </Grid>
    );
};

interface OrderDetailChatAddAttachmentProps {
    order: LabsGqlOrder;
    onAttached: () => any;
    senderOrgType: IOrganizationType;
    // files that were pasted into chat or dropped onto the sidebar
    initialFiles?: File[];
    controls?: { open: boolean; setOpen: (open: boolean) => void };
}

export const OrderDetailChatAddAttachment: React.FC<OrderDetailChatAddAttachmentProps> = ({
    order,
    senderOrgType,
    onAttached,
    initialFiles,
    controls,
}) => {
    // This modifier is used to prevent two files with the same name overwriting one-another.
    // If you're curious, we can't just prepend a timestamp because the name of the file is actually very important for design files --
    // a one character change in a design file zip's name is enough to make the entire zip fail to import into Dental Designer.
    // To go around that, we just append a random uuid for the upload attempt we are doing.
    const [pathModifier, setPathModifier] = React.useState<string>(v4());
    const [submitMtn] = useCreateChatMessageMutation();

    const storagePathConfig = getFullStoragePath(
        OrthlyBrowserConfig.env,
        OrderingStorageConfigs.attachments,
        order.id,
        pathModifier,
    );
    const [uploadFn] = useFirebaseMultiFileUpload(storagePathConfig, []);
    const submitHandler = React.useCallback(
        async ({ files, ...fields }: FormFields & { files: File[] }) => {
            const uploadedFiles = await uploadFilesToGCS({ uploadFn, filesToSubmit: files });
            // Generate a new path-modifier so that future uploads won't overwrite those with the same name.
            setPathModifier(v4());
            return submitMtn({
                variables: {
                    data: {
                        attachment_urls: uploadedFiles.map(f => f.uploadedPath),
                        text: fields.text,
                        entity_type: LabsGqlChatEntityTypes.Order,
                        entity_id: order.id,
                        visible_to_roles: getChatMessageVisibilityForWrite(
                            senderOrgType,
                            fields.visible_to_roles ?? [],
                        ),
                    },
                },
            });
        },
        [order.id, submitMtn, uploadFn, setPathModifier, senderOrgType],
    );
    const { submit, submitting, ...action } = useChangeSubmissionFn(submitHandler, {
        successMessage: () => ['Attachment added!'],
        onSuccess: () => {
            onAttached();
            controls?.setOpen(false);
        },
    });

    const { open, setOpen } = React.useMemo(
        () => controls ?? { open: action.open, setOpen: action.setOpen },
        [action.open, action.setOpen, controls],
    );

    if (order.refabrication_order_id) {
        return null;
    }
    return (
        <RootActionDialog
            loading={submitting}
            open={open}
            setOpen={setOpen}
            title={'Add Attachment to Order'}
            // we only mount the form when open becomes it makes syncing the initial file state simpler
            content={
                open ? (
                    <ChatAddAttachmentFormContent
                        initialFiles={initialFiles ?? []}
                        submitting={submitting}
                        onSubmit={submit}
                    />
                ) : null
            }
            buttonProps={{ style: { display: 'none' } }}
            buttonText={'Add Attachment'}
            CustomButton={() => (
                <IconButton
                    size={'small'}
                    color={'inherit'}
                    onClick={e => {
                        e.stopPropagation();
                        setOpen(true);
                    }}
                    style={{ color: FlossPalette.STAR_GRASS, marginRight: 4 }}
                >
                    <Tooltip title={'Attach File'} arrow>
                        <AttachFileIcon style={{ color: FlossPalette.STAR_GRASS }} />
                    </Tooltip>
                </IconButton>
            )}
        />
    );
};
