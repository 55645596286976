import { DownloadIcon } from '@orthly/ui';
import { FlossPalette, stylesFactory, Grid, IconButton, Tooltip } from '@orthly/ui-primitives';
import { OrderFilesMenu } from '@orthly/veneer';
import clsx from 'clsx';
import React from 'react';

const useStyles = stylesFactory(() => ({
    root: {
        justifyContent: 'flex-end',
        position: 'absolute',
        right: 0,
        bottom: 0,
        width: '100%',
        display: 'flex',
        padding: `0 6px 7px`,
    },
}));

const DownloadFilesButton: React.FC<{ onClick: (e: React.MouseEvent<HTMLElement>) => void }> = props => {
    return (
        <Tooltip title={'Download files'}>
            <IconButton
                size={'small'}
                onClick={e => {
                    e.stopPropagation();
                    props.onClick(e);
                }}
            >
                <DownloadIcon style={{ color: FlossPalette.STAR_GRASS }} />
            </IconButton>
        </Tooltip>
    );
};

interface OrderListItemActionsProps {
    className: string;
    orderId: string;
}

export const OrderListItemActions: React.FC<OrderListItemActionsProps> = props => {
    const classes = useStyles();
    const { orderId } = props;
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
    return (
        <Grid
            container
            className={clsx(classes.root, props.className)}
            // ensure this displays if menu open
            style={{ display: anchorEl ? 'flex' : undefined }}
        >
            <DownloadFilesButton onClick={e => setAnchorEl(e.currentTarget)} />
            <OrderFilesMenu orderId={orderId} anchorEl={anchorEl} onClose={() => setAnchorEl(null)} />
        </Grid>
    );
};
