import type { FragmentType } from '@orthly/graphql-inline-react';
import { graphql, getFragmentData } from '@orthly/graphql-inline-react';
import type { IOrderItemV2DTO } from '@orthly/items';
import { OrderItemV2Utils } from '@orthly/items';

/**
 * This is the sole shared fragment for order items that should be used for all
 * inline graphql queries that need to retrieve the whole item.
 * To retrieve data, use `getOrderItemFromFragment` or `getOrderItemsFromFragment`
 * which will automatically tranform the data into the shared items types.
 *
 * Alternatively, consider using any of the many pre-calculated display properties
 * of the sales order service. For the vast majority of use cases, these fields probably
 * contain all of the information that you need. It also allows features to stay
 * items-data-model agnostic in case we need to introduce a new items model in the future.
 */
const OrderItemV2DTO_Fragment = graphql(`
    fragment OrderItemV2DTO on LabOrderItemV2 {
        __typename
        ...OrderItemBase
        ... on AlignerItem {
            ...AlignerItem
        }
        ... on DentureItem {
            ...DentureItem
        }
        ... on ImplantItem {
            ...ImplantItem
        }
        ... on CrownItem {
            ...CrownItem
        }
        ... on BridgeItem {
            ...BridgeItem
        }
        ... on PartialDentureItem {
            ...PartialDentureItem
        }
        ... on RemovableItem {
            ...RemovableItem
        }
        ... on SleepApneaItem {
            ...SleepApneaItem
        }
        ... on SurgicalGuideItem {
            ...SurgicalGuideItem
        }
        ... on TMJItem {
            ...TMJItem
        }
        ... on OtherItem {
            ...OtherItem
        }
        ... on ImplantBridgeItem {
            ...ImplantBridgeItem
        }
        ... on ModelItem {
            ...ModelItem
        }
        ... on VeneerItem {
            ...VeneerItem
        }
        ... on InlayItem {
            ...InlayItem
        }
        ... on WaxupItem {
            ...WaxupItem
        }
    }

    fragment OrderItemShade on LabOrderItemShade {
        name
        value
    }

    fragment OrderItemAttachment on LabOrderAttachment {
        filepath
    }

    fragment CustomFieldSubmission on ICustomFieldSubmission {
        field_id
        display_name
        price_cents
        value
    }

    fragment OrderItemBase on IOrderItemBase {
        id
        sku
        pricing {
            amount_due_cents
            override_amount_due_cents
        }
        item_notes
        preference_fields {
            ...CustomFieldSubmission
        }
        shades {
            ...OrderItemShade
        }
        attachments {
            ...OrderItemAttachment
        }
        original_item_id
    }

    fragment OrderItemUnitMetadata on OrderItemUnitMetadata {
        unit_type
        material
    }

    fragment OrderItemArchUnit on OrderItemArchUnit {
        unit_type
        material
        arch
    }

    fragment OrderItemSingleToothUnit on OrderItemSingleToothUnit {
        unit_type
        material
        unn
        precious_metal_pennyweight
    }

    fragment OrderItemMultiToothUnit on OrderItemMultiToothUnit {
        unit_type
        material
        unns
    }

    fragment OrderItemSurgicalGuideUnit on OrderItemSurgicalGuideUnit {
        unit_type
        unns
        implant_metadata {
            manufacturer
            system
            drill_kit
            desired_size
        }
    }

    fragment ImplantToothGroup on ImplantToothGroup {
        abutment {
            ...OrderItemUnitMetadata
        }
        crown {
            ...OrderItemUnitMetadata
        }
        implant_metadata {
            manufacturer
            system
            connection_size
            relationship
            scanbody_id
            authentic
            generic_manufacturer
            part_id
            non_preferred_part_reason
            abutment_part_manufacturer
        }
        unn
    }

    fragment AlignerItem on AlignerItem {
        ...OrderItemBase
        unit {
            ...OrderItemArchUnit
        }
        aligner_treatment_metadata {
            treatment_area
            aligner_plan
            bite_concerns {
                improve_overjet
                improve_overbite
                improve_midline
            }
            crowding_resolution {
                expansion
                proclination
                ipr
            }
            movement_restricted_teeth
            attachment_restricted_teeth
            extraction_teeth
            interproximal_space_sizes {
                space {
                    tooth1
                    tooth2
                }
                size
            }
            proposed_step_limit
            trays_count
        }
    }

    fragment BridgeItem on BridgeItem {
        ...OrderItemBase
        units {
            ...OrderItemSingleToothUnit
        }
    }

    fragment CrownItem on CrownItem {
        ...OrderItemBase
        unit {
            ...OrderItemSingleToothUnit
        }
    }

    fragment DentureItem on DentureItem {
        ...OrderItemBase
        unit {
            ...OrderItemArchUnit
        }
        denture_type
        denture_production_type
        denture_fabrication_method
        denture_production_metadata {
            quantity
            smile_style
            festooning_level
            handle_diastema
            correct_occlusal_scheme
            add_post_dam
            add_stippling
            bite_adjustment {
                adjustment_type
                adjustment_distance_mm
            }
            midline_correction {
                correction_type
                correction_distance_mm
            }
            implant_support {
                is_implant_supported
                collar_heights_mm
                is_horseshoe
            }
            copy_metadata {
                is_exact_copy
                copy_change_notes
            }
            add_metal_framework
            add_metal_mesh
            add_softliner
            cusil_gaskets_teeth
            try_in_design
        }
    }

    fragment ImplantItem on ImplantItem {
        ...OrderItemBase
        unit {
            ...ImplantToothGroup
        }
    }

    fragment ImplantBridgeItem on ImplantBridgeItem {
        ...OrderItemBase
        implants {
            ...ImplantToothGroup
        }
        restoratives {
            ...OrderItemSingleToothUnit
        }
    }

    fragment InlayItem on InlayItem {
        ...OrderItemBase
        unit {
            ...OrderItemSingleToothUnit
        }
    }

    fragment ModelItem on ModelItem {
        ...OrderItemBase
        unit_type
        model_type
        selected_teeth
    }

    fragment OtherItem on OtherItem {
        ...OrderItemBase
        unit {
            ...OrderItemUnitMetadata
        }
    }

    fragment PartialDentureItem on PartialDentureItem {
        ...OrderItemBase
        unit {
            ...OrderItemMultiToothUnit
        }
        partial_production_type
        partial_production_metadata {
            is_replacement
            digital_extractions
            clasp_generic_type
            clasp_shade
            clasp_notes
        }
        clasps {
            type
            count
        }
        coverage_type
    }

    fragment RemovableItem on RemovableItem {
        ...OrderItemBase
        unit {
            ...OrderItemArchUnit
        }
    }

    fragment SleepApneaItem on SleepApneaItem {
        ...OrderItemBase
        sleep_apnea_type
        manufacturing_metadata {
            should_cover_last_molar_distal
            should_include_anterior_discluding_ramp
            should_have_posterior_support_pads
        }
    }

    fragment SurgicalGuideItem on SurgicalGuideItem {
        ...OrderItemBase
        unit {
            ...OrderItemSurgicalGuideUnit
        }
        surgical_guide_type
        treatment_plan_metadata {
            patient_cbct_appointment_date
            treatment_plan_review_type
            planned_procedures
            final_restorative_type
        }
    }

    fragment TMJItem on TMJItem {
        ...OrderItemBase
        unit {
            ...OrderItemArchUnit
        }
        tmj_type
        manufacturing_metadata {
            appliance_material
            arch_separation_distance_mm
            teeth_height_coverage
            splint_material_thickness_mm
            color
            lingual_wire
            teeth_coverage
        }
    }

    fragment VeneerItem on VeneerItem {
        ...OrderItemBase
        unit {
            ...OrderItemSingleToothUnit
        }
    }

    fragment WaxupItem on WaxupItem {
        ...OrderItemBase
        unit_type
        selected_teeth
    }
`);

/**
 * Get a single order item, in shared types format, from the OrderItemV2DTO fragment.
 */
export const getOrderItemFromFragment = (fragment: FragmentType<typeof OrderItemV2DTO_Fragment>): IOrderItemV2DTO => {
    const extracted = getFragmentData(OrderItemV2DTO_Fragment, fragment);
    return OrderItemV2Utils.parseItem(extracted as Parameters<typeof OrderItemV2Utils.parseItem>[0]);
};

/**
 * Get an array of order items, in shared types format, from the OrderItemV2DTO fragment.
 */
export const getOrderItemsFromFragment = (
    fragment: FragmentType<typeof OrderItemV2DTO_Fragment>[],
): IOrderItemV2DTO[] => {
    return fragment.map(f => getOrderItemFromFragment(f));
};
