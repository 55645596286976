import { aestheticsRubric } from './Aesthetics.config';
import { biteRubric } from './Bite.config';
import { contactsRubric } from './Contacts.config';
import type { FilteredRubric } from './DesignQc.config.utils';
import { emergenceProfileAndEmbrasuresRubric } from './EmergenceProfileAndEmbrasures.config';
import { finishingLineRubric } from './FinishingLine.config';
import { marginalRidgesRubric } from './MarginalRidges.config';
import { occlusionRubric } from './Occlusion.config';

export const marginLinePlacementRubric: FilteredRubric = {
    categoryEntry: finishingLineRubric,
    filteredSubcategoryNames: ['Placement %'],
};

export const ipContactsAdjacentUndercutRubric: FilteredRubric = {
    categoryEntry: contactsRubric,
    filteredSubcategoryNames: ['Adjacent Undercut'],
};

export const occlusalOpposingCuspFossaRubric: FilteredRubric = {
    categoryEntry: occlusionRubric,
    filteredSubcategoryNames: ['Opposing Cusp <> Fossa distances'],
};

export const marginalRidgesVerticalPositionRubric: FilteredRubric = {
    categoryEntry: marginalRidgesRubric,
    filteredSubcategoryNames: ['Vertical Positioning'],
};

export const allBiteRubric: FilteredRubric = {
    categoryEntry: biteRubric,
    filteredSubcategoryNames: [
        'Even distribution of contact points',
        'Intersection between scans',
        'Placement of contact points',
    ],
};

export const undercutsAndInterproximalContactsRubric: FilteredRubric = {
    categoryEntry: contactsRubric,
    filteredSubcategoryNames: ['Adjacent Undercut', 'Shape / Contour', 'Vertical position of contact'],
};

export const gigivalEmbrasuresRubric: FilteredRubric = {
    categoryEntry: emergenceProfileAndEmbrasuresRubric,
    filteredSubcategoryNames: ['M/D Gingival Embrasures'],
};

export const cuspAndFossaLocationsRubric: FilteredRubric = {
    categoryEntry: occlusionRubric,
    filteredSubcategoryNames: ['Cusp & Fossa locations'],
};

export const allAestheticsRubric: FilteredRubric = {
    categoryEntry: aestheticsRubric,
    filteredSubcategoryNames: [
        'Line Angles',
        'Facial Anatomy',
        'Mirroring / Symmetry',
        'Incisal Edge',
        'Arch Form',
        'Relative Test',
    ],
};
