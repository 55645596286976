import { PrintableSlipPreferences } from '../../../PrintableSlip/components/PrintableSlipPreferences';
import { PrintableSlipItem } from '../../../PrintableSlip/components/item/PrintableSlipItem';
import { PrintableSlipItemsSection } from '../../../PrintableSlip/components/item/PrintableSlipItemsSection';
import { PrintableSlipPage } from '../../../PrintableSlip/components/page/PrintableSlipPage';
import { PrintableSlipSectionDivider } from '../../../PrintableSlip/components/shared/PrintableSlipSectionDivider';
import { LabSlipNotes } from './sections/LabSlipNotes';
import { LabSlipFooter } from './sections/footer/LabSlipFooter';
import { LabSlipHeader } from './sections/header/LabSlipHeader';
import type { LabSlipCopyInfo } from './sections/header/LabSlipOrderInfo';
import type { LabsGqlLabOrderForLabSlipFragment, LabsGqlManufacturerProfileFragment } from '@orthly/graphql-operations';
import React from 'react';

interface LabSlipPageProps {
    order: Exclude<LabsGqlLabOrderForLabSlipFragment, 'stackAndPanCodes'>;
    manufacturerProfileData?: LabsGqlManufacturerProfileFragment;
    stackCode?: string;
    copyInfo?: LabSlipCopyInfo;
    batchIds?: string[] | null;
}

/**
 * Renders a printable "Lab Slip" for an order.
 * This Order Lab Slip contains all the immediate detail needed by
 * a manufacturer to complete the order
 * @param props The order to render to a "Lab Slip"
 * @returns Printable page(s) containing the order information for the manufacturer
 */
export const LabSlipPage: React.FC<LabSlipPageProps> = ({
    order,
    manufacturerProfileData,
    stackCode,
    copyInfo,
    batchIds,
}) => {
    return (
        <PrintableSlipPage orderNumber={order.order_number}>
            <LabSlipHeader order={order} stackCode={stackCode} copyInfo={copyInfo} batchIds={batchIds} />
            <PrintableSlipSectionDivider />

            <PrintableSlipItemsSection order={order} ItemComponent={PrintableSlipItem} />
            <PrintableSlipSectionDivider />

            <PrintableSlipPreferences order={order} />

            <LabSlipNotes order={order} />

            <LabSlipFooter order={order} manufacturerProfileData={manufacturerProfileData} />
        </PrintableSlipPage>
    );
};
